export const createRequiredMessage = (field: string) =>
  `Field '${field}' is mandatory.`;


export const createMinMessage = (field: string, min: number) =>
  `Field '${field}' minimum number of characters is ${min}.`;


export const createMaxMessage = (field: string, max: number) =>
  `Field '${field}' maximum number of characters is ${max}.`;
