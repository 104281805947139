import { ServiceFactory } from '@dirootie-core/api-types';
import {
  IUserNotificationService,
  deleteErrorNotification,
  deleteSuccessNotification,
} from '@dirootie-web/components-types';
import { FormActionHandlerBase, IFormStateProvider } from './Forms.handlers';
import { IAction } from '../Actions.types';
import { IViewNavigator } from '../../../configuration/ViewNavigator';

export class FormDeleteActionHandler extends FormActionHandlerBase {
  constructor(
    userNotificationService: IUserNotificationService,
    serviceFactory: ServiceFactory,
    private formStateProvider: IFormStateProvider,
    private viewNavigator: IViewNavigator
  ) {
    super(userNotificationService, serviceFactory);
  }

  public handle(action: IAction) {
    var id = this.formStateProvider.getState().getFieldMeta('id').value;
    const apiService = this.serviceFactory.createApiServiceFromPath(
      action.options.path
    );
    return apiService?.delete(id).then(
      (x) => {
        this.userNotificationService.success(deleteSuccessNotification);
        this.viewNavigator.navigate(-1, { relative: true });
      },
      (x) => {
        this.userNotificationService.error(deleteErrorNotification);
      }
    );
  }
}
