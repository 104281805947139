import { notEmptyArray } from '@dirootie-extensions/javascript';
import _ from 'lodash';

export type TabItem = {
  label: string;
  index: number;
  path: string;
  component: JSX.Element;
  children?: {
    path: string;
    component: JSX.Element;
  }[];
};
export type PageTabedProps = {
  title: string;
  showBreadcrumbs?: boolean;
  children?: any;
  pathBase: string;
  tabs: TabItem[];
};

const getTabsFromProps = (props: PageTabedProps | TabItem[]): TabItem[] => {
  if (notEmptyArray(props as TabItem[])) return props as TabItem[];
  const tabProps = props as PageTabedProps;
  if (!_.isUndefined(tabProps) && notEmptyArray(tabProps.tabs))
    return tabProps.tabs;
  return [];
};

export const getSelectedTabByIndex = (
  props: PageTabedProps | TabItem[],
  index: number
) => {
  const tabs = getTabsFromProps(props);
  const selectedTab = tabs.filter((x) => x.index === index);
  return selectedTab;
};

export const getSelectedTabByPath = (
  props: PageTabedProps | TabItem[],
  path: string
) => {
  const tabs = getTabsFromProps(props);
  const selectedTab = tabs.filter((x) => x.path === path);
  return selectedTab;
};
