import {
  Page,
  PresetComponentDetails,
  PresetComponentDetailsProps,
} from "@components";
import {
  buildingComponentsData,
  buildingParametersData,
  ComponentType,
  IPresetElement,
  PresetComponentType,
  presetsData,
} from "@domain/building-components";
import { useParams } from "react-router-dom";

const f = (type: ComponentType) => () =>
  buildingComponentsData.filter((x) => x.type === type);
const typeMapper = new Map<
  PresetComponentType,
  (type: PresetComponentType) => IPresetElement[]
>();
typeMapper.set("FLOOR", f("FLOOR"));
typeMapper.set("WINDOW", f("WINDOW"));
typeMapper.set("ROOF", f("ROOF"));
typeMapper.set("WALL_INTERIOR", () =>
  f("WALL")().filter((x: any) => x.layer === "INTERIOR")
);
typeMapper.set("WALL_EXTERIOR", () =>
  f("WALL")().filter((x: any) => x.layer === "EXTERIOR")
);
typeMapper.set("BUILDING_PARAMETERS", () => buildingParametersData);

const ComponentsPresetDetailsScreen: React.FC<any> = (y) => {
  const { presetId } = useParams();
  const props: PresetComponentDetailsProps = {
    elementId: presetId!,
    getElement: (id: string) =>
      Promise.resolve(presetsData.filter((x) => x.id === id)[0]),
    getElements: (type: PresetComponentType) => {
      const result = typeMapper.get(type)!(type);
      return Promise.resolve(result);
    },
    title: "Building parameter preset details",
    onSave: (elem) => Promise.resolve(console.log("Saved", elem)),
  };
  return <PresetComponentDetails {...props} />;
};

export default ComponentsPresetDetailsScreen;
