import { IHttpClient } from "@dirootie-core/http-types";
import { ILogger } from "@dirootie-core/logging-types";
import { ApiEndpoint } from "configuration/Configuration.types";
import { ApiService } from "@dirootie-core/api-types";
import { Material } from "./Material";

export class MaterialApiService extends ApiService<Material, Material> {
  constructor(httpClient: IHttpClient, endpoint: ApiEndpoint, logger: ILogger) {
    super(httpClient, endpoint, logger);
  }
}
