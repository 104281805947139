import { FC } from "react";

export const ConfigurationScreen: FC<any> = ({...props}) => {

  return (
    <>
      <h3>Configuration screen</h3>
    </>
  );
};
