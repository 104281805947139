import {AreaConfiguration} from "../../domain/web-applications/AreaConfiguration";
import { LayoutTesting } from "./components/LayoutTesting";

export const testingAreaConfiguration: AreaConfiguration = {
  name: "testing",
  roles: [],
  layout: {
    name: "",
    route: "testing",
    component: LayoutTesting,
    menuMain: {
      items: [
        {
          id: "testing_1",
          label: "Dashboard",
          to: "/testing"
        },
        {
          id: "testing_2",
          label: "Table - PrimeReact",
          to: "/testing/tables/prime-react",
        },
        {
          id: "testing_21",
          label: "Table - TalTech",
          to: "/testing/tables/taltech",
        },
        {
          id: "testing_3",
          label: "Notifications",
          to: "/testing/notifications",
        },
        {
          id: "testing_4",
          label: "Domain - enumerations",
          to: "/testing/domain/enumerations",
        },
        {
          id: "testing_54",
          label: "Building Configurations",
          to: "/testing/forms/building-configurations",
        },
        {
          id: "testing_6",
          label: "Building Components",
          to: "/testing/types/building-types",
        },
        {
          id: "testing_7",
          label: "Widgets",
          to: "/testing/widgets/widget",
        },
        {
          id: "testing_7",
          label: "Testing Components",
          to: "/testing/testing-components",
        },
        {
          id: "testing_8",
          label: "BuildComp - Parameter form",
          to: "/testing/building-components",
        },
      ],
    },
  },
};
