import { makeAutoObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import { LogMessage } from "./OutputWindow";


export class OutputWindowService {
  @observable messages: LogMessage[] = [];

  constructor(){
    makeAutoObservable(this);
  }

  log(message: string,data?: any){
      const logMessage = {
          text:message,
          data,
          expanded:false
      };
      this.messages.push(logMessage);
  }

  clear(){
      this.messages = [];
  }
}
const service = new OutputWindowService();
export const OutputWindowContext = createContext(service);

export function useOutputWindowService() {
  return useContext(OutputWindowContext);
}
