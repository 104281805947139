// import { ToolbarProps } from "@dirootie-web/components-types";
import React from "react";
import { elementMapper, mapChildrenElements } from "./Toolbars.utils";
import { ToolbarProps } from "./Toolbars.types";
import { useNavigate } from "react-router-dom";

export const Toolbar: React.FC<any> = (props: ToolbarProps) => {
  const navigator = useNavigate();
  const { left = [], right = [] } = props as any;
  const mapper = props.mapper !== undefined ? props.mapper : elementMapper;
  const leftComponents = mapChildrenElements(
    { ...props, navigator } as any,
    left,
    mapper
  );
  const rightComponents = mapChildrenElements(
    { ...props, navigator } as any,
    right,
    mapper
  );
  return (
    <div className="toolbar">
      <div>{leftComponents}</div>
      <div className="space"></div>
      <div>{rightComponents}</div>
    </div>
  );
};
