import { LayoutWorkspace } from "@components";
import { AreaConfiguration } from "../../domain/web-applications/AreaConfiguration";

export const supportAreaConfiguration: AreaConfiguration = {
  name: "support",
  roles: ["student", "lecturer", "admin"],
  layout: {
    name: "",
    route: "support",
    component: LayoutWorkspace,
    menuProfile: {
      items: [
        {
          id: "support_11",
          children: "Profile",
          to: "/users/profile",
        },
        {
          id: "support_13",
          children: "Settings",
          to: "/users/settings",
        },
      ],
    } as any,
    menuMain: {
      items: [
        {
          id: "support_1",
          label: "Revit plugin",
          to: "/support/revit-plugin",
        },
        {
          id: "support_2",
          label: "Help",
          to: "/support/help",
        },
      ],
    },
  },
};
