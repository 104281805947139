import {
  AssignmentsTable,
  EntityOptions,
  EntityStore,
  IAction,
} from "@components";
import { ToolbarList } from "@components/navigation";
import { useLocation, useParams } from "react-router-dom";
import { getAssignmentFormValidationSchema } from "../../../../../components/assignments/AssignmnentForm.schema";
import { Assignment } from "@domain";

export const assignmentEntityOptions: EntityOptions<Assignment> = {
  schema: getAssignmentFormValidationSchema,
  empty: {
    number: 0,
    title: "",
    description: "",
    state: "ACTIVE",
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    assignmentGroup: "Group 01",
    dateRange: {
      startDate: new Date(Date.now()),
      endDate: new Date(Date.now()),
    },
  },
  path: "assignments",
  actions: {
    FORM_CREATE: {
      converter: (action: IAction) => {
        action.options!.path = "assignments";
        action.options!.navigateTo = "../:id/summary";
        return action;
      },
    },
  },
};

export const entityStore = new EntityStore(assignmentEntityOptions);

export const AssignmentsIndexScreen: React.FC<any> = () => {
  const location = useLocation();
  const { courseId } = useParams();
  const segments = location.pathname.split("/");
  return (
    <>
      <ToolbarList disabled={segments[1] === "assignments"} />
      <AssignmentsTable
        isEditable={segments[1] === "lecturers"}
        source={{
          path: "assignments.summary",
          query: { courseId },
        }}
      />
    </>
  );
};
