import {Route} from "react-router-dom";
import {JsonViewer} from "../../components/assignments/BuildingSpecification";
import BuildingComponentsIndexScreen from "./screens/building-components/BuildingComponentsIndexScreen";
import {
  FloorParameterCreateScreen
} from "./screens/building-components/system/components/floors/FloorParameterCreateScreen";
import {
  FloorParameterDetailsScreen
} from "./screens/building-components/system/components/floors/FloorParameterDetailsScreen";
import {FloorsScreen} from "./screens/building-components/system/components/floors/FloorsScreen";
import FloorTypeCreateScreen from "./screens/building-components/system/components/floors/FloorTypeCreateScreen";
import FloorTypeDetailsScreen from "./screens/building-components/system/components/floors/FloorTypeDetailsScreen";
import {
  RoofParameterCreateScreen
} from "./screens/building-components/system/components/roofs/RoofParameterCreateScreen";
import {
  RoofParameterDetailsScreen
} from "./screens/building-components/system/components/roofs/RoofParameterDetailsScreen";
import RoofsScreen from "./screens/building-components/system/components/roofs/RoofsScreen";
import RoofTypeCreateScreen from "./screens/building-components/system/components/roofs/RoofTypeCreateScreen";
import RoofTypeDetailsScreen from "./screens/building-components/system/components/roofs/RoofTypeDetailsScreen";
import {
  WallParameterCreateScreen
} from "./screens/building-components/system/components/walls/WallParameterCreateScreen";
import {
  WallParameterDetailsScreen
} from "./screens/building-components/system/components/walls/WallParameterDetailsScreen";
import WallsScreen from "./screens/building-components/system/components/walls/WallsScreen";
import WallTypeCreateScreen from "./screens/building-components/system/components/walls/WallTypeCreateScreen";
import WallTypeDetailsScreen from "./screens/building-components/system/components/walls/WallTypeDetailsScreen";
import {
  WindowParameterCreateScreen
} from "./screens/building-components/system/components/windows/WindowParameterCreateScreen";
import {
  WindowParameterDetailsScreen
} from "./screens/building-components/system/components/windows/WindowParameterDetailsScreen";
import WindowsScreen from "./screens/building-components/system/components/windows/WindowsScreen";
import WindowTypeCreateScreen from "./screens/building-components/system/components/windows/WindowTypeCreateScreen";
import {WindowTypeDetailsScreen} from "./screens/building-components/system/components/windows/WindowTypeDetailsScreen";
import BuildingParameterCreateScreen
  from "./screens/building-components/system/data/building-parameters/BuildingParameterCreateScreen";
import BuildingParameterDetailsScreen
  from "./screens/building-components/system/data/building-parameters/BuildingParameterDetailsScreen";
import {
  BuildingParametersScreen
} from "./screens/building-components/system/data/building-parameters/BuildingParametersScreen";
import MaterialCreateScreen from "./screens/building-components/system/data/materials/MaterialCreateScreen";
import MaterialDetailsScreen from "./screens/building-components/system/data/materials/MaterialDetailsScreen";
import {MaterialIndexScreen} from "./screens/building-components/system/data/materials/MaterialIndexScreen";
import SpaceFunctionsScreen from "./screens/building-components/system/data/space-functions/SpaceFunctionsScreen";
import SpaceFunctionTypeCreateScreen
  from "./screens/building-components/system/data/space-functions/SpaceFunctionTypeCreateScreen";
import SpaceFunctionTypeDetailsScreen
  from "./screens/building-components/system/data/space-functions/SpaceFunctionTypeDetailsScreen";
import SpaceParameterCreateScreen
  from "./screens/building-components/system/data/space-functions/SpaceParameterCreateScreen";
import SpaceParameterDetailsScreen
  from "./screens/building-components/system/data/space-functions/SpaceParameterDetailsScreen";
import StructureLayerFunctionsScreen
  from "./screens/building-components/system/data/structure-layer-functions/StructureLayerFunctionsScreen";
import BuildingParametersPresetCreateScreen
  from "./screens/building-components/system/presets/building-parameters/BuildingParametersPresetCreateScreen";
import BuildingParametersPresetDetailsScreen
  from "./screens/building-components/system/presets/building-parameters/BuildingParametersPresetDetailsScreen";
import BuildingParametersPresetScreen
  from "./screens/building-components/system/presets/building-parameters/BuildingParametersPresetScreen";
import ComponentsPresetCreateScreen
  from "./screens/building-components/system/presets/components/ComponentsPresetCreateScreen";
import ComponentsPresetDetailsScreen
  from "./screens/building-components/system/presets/components/ComponentsPresetDetailsScreen";
import ComponentsPresetScreen from "./screens/building-components/system/presets/components/ComponentsPresetScreen";
import SpaceFunctionsPresetCreateScreen
  from "./screens/building-components/system/presets/space-functions/SpaceFunctionsPresetCreateScreen";
import SpaceFunctionsPresetDetailsScreen
  from "./screens/building-components/system/presets/space-functions/SpaceFunctionsPresetDetailsScreen";
import SpaceFunctionsPresetScreen
  from "./screens/building-components/system/presets/space-functions/SpaceFunctionsPresetScreen";
import BuildingConfigurationsScreen from "./screens/building-configurations/BuildingConfigurationScreen";
import ConfigurationCreateScreen from "./screens/building-configurations/ConfigurationCreateScreen";
import ConfigurationDetailsScreen from "./screens/building-configurations/ConfigurationDetailsScreen";
import {AssignmentCreateScreen} from "./screens/courses/assignments/AssignmentCreateScreen";
import {AssignmentEditScreen} from "./screens/courses/assignments/AssignmentEditScreen";
import {AssignmentResourceCreateScreen} from "./screens/courses/assignments/resourses/ResourceCreateScreen";
import {AssignmentResourceDetailsScreen} from "./screens/courses/assignments/resourses/ResourceEditScreen";
import CourseDetailsScreen from "./screens/courses/CourseDetailsScreen";
import CoursesIndexScreen from "./screens/courses/CourseIndexScreen";
import GroupsCreateScreen from "./screens/courses/groups/GroupsCreateScreen";
import GroupsEditScreen from "./screens/courses/groups/GroupsEditScreen";
import {ResourceCreateScreen} from "./screens/courses/resources/ResourceCreateScreen";
import {ResourceDetailsScreen} from "./screens/courses/resources/ResourceDetailsScreen";
import {ResourcesIndexScreen} from "./screens/courses/resources/ResourcesIndexScreen";
import CourseNewScreen from "./screens/courses/summary/CourseCreateScreen";
import DashboardScreen from "./screens/dashboard/DashboardScreen";
import NotificationsScreen from "./screens/notifications/NotificationsScreen";

export const lecturersAreaRoutes = (
  <Route path="/lecturers">
    <Route index={true} element={<DashboardScreen/>}/>
    <Route path="courses">
      <Route index={true} element={<CoursesIndexScreen/>}/>
      <Route path="new">
        <Route index={true} element={<CourseNewScreen/>}/>

        <Route path="assignments/new" element={<AssignmentCreateScreen/>}/>
        <Route
          path="assignments/:assignmentId"
          element={<AssignmentEditScreen/>}
        />
      </Route>
      <Route path=":courseId">
        <Route index={true} element={<CourseDetailsScreen/>}/>
        <Route path=":tabId" element={<CourseDetailsScreen/>}/>
        <Route path="resources/new" element={<ResourceCreateScreen/>}/>
        <Route
          path="resources/:resourceId"
          element={<ResourceDetailsScreen/>}
        />
        <Route path="groups/new" element={<GroupsCreateScreen/>}/>
        <Route path="groups/:groupId" element={<GroupsEditScreen/>}/>
        <Route
          path="assignments/:assignmentId/Submissions/:submissionId"
          element={<JsonViewer/>}
        />
        {/* <Route
          path="assignments/:assignmentId/building-configurations/:typeId"
          element={<ConfigurationDetailsScreen />}
        /> */}
        <Route path="assignments/new" element={<AssignmentCreateScreen/>}/>
        <Route path="assignments/:assignmentId">
          <Route index={true} element={<AssignmentEditScreen/>}/>
          <Route path=":assignmentTabId" element={<AssignmentEditScreen/>}/>
          <Route
            path="resources/new"
            element={<AssignmentResourceCreateScreen/>}
          />
          <Route
            path="resources/:resourceId"
            element={<AssignmentResourceDetailsScreen/>}
          />
          <Route
            path="bulding-configurations/new"
            element={<ConfigurationCreateScreen/>}
          />
          <Route
            path="bulding-configurations/:typeId"
            element={<ConfigurationDetailsScreen/>}
          />
          {/* <Route index={true} element={<AssignmentEditScreen />} />
          <Route
            path="resources/new"
            element={<AssignmentResourcesIndexScreen />}
          /> */}
        </Route>
        <Route path="assignments/:assignmentId">
          <Route index={true} element={<AssignmentEditScreen/>}/>
        </Route>
      </Route>
    </Route>
    <Route path="building-configurations">
      <Route index={true} element={<BuildingConfigurationsScreen/>}/>

      <Route path="new" element={<ConfigurationCreateScreen/>}/>
      <Route path=":typeId" element={<ConfigurationDetailsScreen/>}/>
    </Route>
    <Route path="building-components">
      <Route index={true} element={<BuildingComponentsIndexScreen/>}/>
      <Route path="system">
        <Route path="components">
          <Route path="walls">
            <Route index={true} element={<WallsScreen/>}/>
            <Route path=":tabId" element={<WallsScreen/>}/>
            <Route path="types/new" element={<WallTypeCreateScreen/>}/>
            <Route path="types/:typeId" element={<WallTypeDetailsScreen/>}/>
            <Route
              path="parameters/new"
              element={<WallParameterCreateScreen/>}
            />
            <Route
              path="parameters/:parameterId"
              element={<WallParameterDetailsScreen/>}
            />
          </Route>
          <Route path="floors">
            <Route index={true} element={<FloorsScreen/>}/>
            <Route path=":tabId/" element={<FloorsScreen/>}/>
            <Route path="types/new" element={<FloorTypeCreateScreen/>}/>
            <Route path="types/:typeId" element={<FloorTypeDetailsScreen/>}/>
            <Route
              path="parameters/new"
              element={<FloorParameterCreateScreen/>}
            />
            <Route
              path="parameters/:parameterId"
              element={<FloorParameterDetailsScreen/>}
            />
          </Route>
          <Route path="windows">
            <Route index={true} element={<WindowsScreen/>}/>
            <Route path=":tabId" element={<WindowsScreen/>}/>
            <Route path="types/new" element={<WindowTypeCreateScreen/>}/>
            <Route path="types/:typeId" element={<WindowTypeDetailsScreen/>}/>
            <Route
              path="parameters/new"
              element={<WindowParameterCreateScreen/>}
            />
            <Route
              path="parameters/:parameterId"
              element={<WindowParameterDetailsScreen/>}
            />
          </Route>
          <Route path="roofs">
            <Route index={true} element={<RoofsScreen/>}/>
            <Route path=":tabId" element={<RoofsScreen/>}/>
            <Route path="types/new" element={<RoofTypeCreateScreen/>}/>
            <Route path="types/:typeId" element={<RoofTypeDetailsScreen/>}/>
            <Route
              path="parameters/new"
              element={<RoofParameterCreateScreen/>}
            />
            <Route
              path="parameters/:parameterId"
              element={<RoofParameterDetailsScreen/>}
            />
          </Route>
        </Route>
        <Route path="data">
          <Route path="materials">
            <Route index={true} element={<MaterialIndexScreen/>}/>
            <Route path="new" element={<MaterialCreateScreen/>}/>
            <Route path=":materialId" element={<MaterialDetailsScreen/>}/>
          </Route>
          <Route path="space-functions">
            <Route index={true} element={<SpaceFunctionsScreen/>}/>
            <Route path=":tabId" element={<SpaceFunctionsScreen/>}/>
            <Route
              path="types/new"
              element={<SpaceFunctionTypeCreateScreen/>}
            />
            <Route
              path="types/:typeId"
              element={<SpaceFunctionTypeDetailsScreen/>}
            />
            <Route
              path="parameters/new"
              element={<SpaceParameterCreateScreen/>}
            />
            <Route
              path="parameters/:parameterId"
              element={<SpaceParameterDetailsScreen/>}
            />
          </Route>
          <Route
            path="structure-layer-functions"
            element={<StructureLayerFunctionsScreen/>}
          />
          <Route path="building-parameters">
            <Route index={true} element={<BuildingParametersScreen/>}/>
            <Route path="new" element={<BuildingParameterCreateScreen/>}/>
            <Route
              path=":parameterId"
              element={<BuildingParameterDetailsScreen/>}
            />
          </Route>
        </Route>
        <Route path="presets">
          <Route path="building-parameters">
            <Route index={true} element={<BuildingParametersPresetScreen/>}/>
            <Route
              path="new"
              element={<BuildingParametersPresetCreateScreen/>}
            />
            <Route
              path=":presetId"
              element={<BuildingParametersPresetDetailsScreen/>}
            />
          </Route>
          <Route path="space-functions">
            <Route index={true} element={<SpaceFunctionsPresetScreen/>}/>
            <Route path="new" element={<SpaceFunctionsPresetCreateScreen/>}/>
            <Route
              path=":presetId"
              element={<SpaceFunctionsPresetDetailsScreen/>}
            />
          </Route>
          <Route path="components">
            <Route index={true} element={<ComponentsPresetScreen/>}/>
            <Route path="new" element={<ComponentsPresetCreateScreen/>}/>
            <Route
              path=":presetId"
              element={<ComponentsPresetDetailsScreen/>}
            />
          </Route>
        </Route>
      </Route>
    </Route>
    <Route path="notifications">
      <Route index={true} element={<NotificationsScreen/>}/>
    </Route>
    <Route path="resources">
      <Route index={true} element={<ResourcesIndexScreen/>}/>
      <Route path="new" element={<ResourceCreateScreen/>}/>
    </Route>
  </Route>
);
