import {Form as FormikForm, Formik} from "formik";
import _ from "lodash";
import React, {FC, useContext, useEffect, useState} from "react";
import {Button} from "taltech-styleguide";
import BuildingComponentsContext from '../../../domain/forms/buildingcomponents.context';
import {useStore} from "../../../domain/store";
import {ComponentTypes} from "../../../packages/web/components-types/src/forms/BuildingConfigurationTypes";
import {BuildingComponentsTable} from './BuildingComponentsTable';
import {ComponentsTypeTable} from './ComponentsTypeTable'

export const BuildingComponentsForm: FC<any> = ({...props}) => {

  const ctx = useContext(BuildingComponentsContext);
  const {buildingConfigurationFormStore} = useStore();

  const [initialVal2, setInitialVal2] = useState({});

  const [interiorWallTypes, setInteriorWallTypes] = useState<ComponentTypes[]>([]);
  const [exteriorWallTypes, setExteriorWallTypes] = useState<ComponentTypes[]>([]);
  const [groundFloorTypes, setGroundFloorTypes] = useState<ComponentTypes[]>([]);
  const [floorTypes, setFloorTypes] = useState<ComponentTypes[]>([]);
  const [roofTypes, setRoofTypes] = useState<ComponentTypes[]>([]);
  const [windowTypes, setWindowTypes] = useState<ComponentTypes[]>([]);

  const [renderedElement, setRenderedElement] = useState<ComponentTypes[]>([]);
  const [EnumComponentValues, setEnumComponentValues] = useState<any>({
    0: interiorWallTypes,
    1: exteriorWallTypes,
    2: groundFloorTypes,
    3: floorTypes,
    4: roofTypes,
    5: windowTypes
  });
  useEffect(() => {
    switch (ctx.selectedIndex) {
      case 0:
        setRenderedElement(interiorWallTypes);
        break;
      case 1:
        setRenderedElement(exteriorWallTypes);
        break;
      case 2:
        setRenderedElement(groundFloorTypes);
        break;
      case 3:
        setRenderedElement(floorTypes);
        break;
      case 4:
        setRenderedElement(roofTypes);
        break;
      case 5:
        setRenderedElement(windowTypes);
        break;
      default :
        setRenderedElement(interiorWallTypes)
    }

  }, [ctx.selectedIndex, interiorWallTypes])
  useEffect(() => {
    setEnumComponentValues({
      0: interiorWallTypes,
      1: exteriorWallTypes,
      2: groundFloorTypes,
      3: floorTypes,
      4: roofTypes,
      5: windowTypes
    })
  }, [interiorWallTypes, exteriorWallTypes, groundFloorTypes, floorTypes, roofTypes, windowTypes])
  useEffect(() => {
    buildingConfigurationFormStore.getInteriorWallRandomTypes().then(val => setInteriorWallTypes(val))
    buildingConfigurationFormStore.getExteriorWallRandomTypes().then(val => setExteriorWallTypes(val))
    buildingConfigurationFormStore.getGroundFloorRandomTypes().then(val => setGroundFloorTypes(val))
    buildingConfigurationFormStore.getFloorRandomTypes().then(val => setFloorTypes(val))
    buildingConfigurationFormStore.getRoofRandomTypes().then(val => setRoofTypes(val))
    buildingConfigurationFormStore.getWindowRandomTypes().then(val => setWindowTypes(val))
    const initialValue = {}
    // _.set(initialValue, `buildingType`, 0)
    _.times(ctx.elements.length, (index) => {
      _.set(initialValue, `${ctx.elements[index].key}`, ctx.elements[index].selectedElement);
      _.set(initialValue, `${ctx.elements[index].key}Shuffle`, ctx.elements[index].shuffle);
      renderedElement.length > 0 && _.times((renderedElement.length), (index2) => {
        _.set(initialValue, `${renderedElement[index2].name}_Checked`, renderedElement[index2].isSelected);
      })
    })
    setInitialVal2(initialValue);
  }, [])
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{...initialVal2}}
        onSubmit={(values) => {
          buildingConfigurationFormStore.setComponentsFunctionState(values as any)
          buildingConfigurationFormStore.setWizardState();

        }}>
        {(formik) => {
          return (
            <FormikForm className={!ctx.config.disabled ? "active" : ""}>
              <div>
                <BuildingComponentsTable formikElement={formik} EnumValues={EnumComponentValues}/>

                <ComponentsTypeTable formikElement={formik} renderedElements={renderedElement}/>
              </div>
              {!ctx.config.disabled &&
                <Button className={'position-fixed invisible'} type="submit">Next</Button>}
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};
