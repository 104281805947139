import _ from "lodash";
import { iconMapper } from "./Icons.mapper";
import { IconProps } from "./Icons.types";

export const IconTalTech = (props: IconProps) => {
  const { action } = props;
  const builder = iconMapper.get(action);
  if (_.isUndefined(builder)) {
    return <>{action} icon not found</>;
  }
  const IconComponent = builder(props);
  return IconComponent;
};
