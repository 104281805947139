import {Page, PresetsTable, ToolbarList} from "@components";

const SpaceFunctionsPresetScreen: React.FC<any> = (props: any) => {
  return (
    <Page title="Space functions preset">
      <div>
        <ToolbarList/>

        <PresetsTable type="SPACE_FUNCTION"/>
      </div>
    </Page>
  );
};

export default SpaceFunctionsPresetScreen;
