import {
  colCreateDate,
  colDescription,
  colId,
  colModifiedDate,
  colName,
  cols,
  createColActions,
  defaultProps,
  TableTalTech,
  TableTalTech2
} from "@components";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";

export const ComponentsTable: React.FC<any> = observer((props: any) => {
  const navigator = useNavigate();
  const columns = [
    colId,
    colName,
    colDescription,
    colCreateDate,
    colModifiedDate,
    {formatter: createColActions(navigator, {showCopy: true, showDelete: true, showEdit: true})}
  ];
  const tableProps = {...defaultProps, data: props.elements.items, columns};
  return <TableTalTech tableProps={tableProps}></TableTalTech>;
});


export const ElementsTable: React.FC<any> = observer((props: any) => {
  const navigator = useNavigate();
  const columns = [
    cols.id(),
    cols.name(),
    cols.description(),
    cols.createdDate(),
    cols.modifiedDate(),
    {
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => createColActions(navigator, {
        showCopy: true,
        showDelete: true,
        showEdit: true,
        onEdit: () => navigator(row.id)
      })
    }
  ];
  const tableProps = {...defaultProps, columns} as any;
  return (
    <TableTalTech2
      source={props.source}
      // source={{
      //   elements: props.elements, // path: "buildingComponents.materials",
      //   // query: { componentType: "1" },
      // }}
      table={tableProps}
    ></TableTalTech2>
  );
});
