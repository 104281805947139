import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { CoursesSummaryScreen } from "./summary/CourseSummaryScreen";
import { StudentsIndexScreen } from "./students/StudentsIndexScreen";
import { PageTabed, PageTabedProps } from "@components";
import { AssignmentsIndexScreen } from "./assignments/AssignmentsIndexScreen";
import { LecturersIndexScreen } from "./lecturers/LecturersIndexScreen";
import { GroupsIndexScreen } from "./groups/GroupsIndexScreen";
import { ResourcesIndexScreen } from "./resources/ResourcesIndexScreen";

const CourseDetailsScreen: React.FC<any> = inject("logger")(
  observer((props: any) => {
    let { courseId } = useParams();
    props.logger.trace("[CourseDetailsScreen]", props, courseId);
    const newProps: PageTabedProps = {
      title: "Course details",
      pathBase: "/lecturers/courses/" + courseId,
      tabs: [
        {
          label: "Summary",
          component: <CoursesSummaryScreen />,
          index: 0,
          path: "summary",
        },
        {
          label: "Lecturers",
          component: <LecturersIndexScreen />,
          index: 1,
          path: "lecturers",
        },
        {
          label: "Students",
          component: <StudentsIndexScreen />,
          index: 2,
          path: "students",
        },
        {
          label: "Groups",
          component: <GroupsIndexScreen />,
          index: 3,
          path: "groups",
        },
        {
          label: "Resources",
          component: <ResourcesIndexScreen />,
          index: 4,
          path: "resources",
        },
        {
          label: "Assignments",
          component: <AssignmentsIndexScreen />,
          index: 5,
          path: "assignments",
        },
      ],
    };
    return <PageTabed {...newProps}></PageTabed>;
  })
);

export default CourseDetailsScreen;
