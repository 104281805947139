import {
  cols,
  defaultProps,
  TableCopyBtn,
  TableDeleteBtn,
  TableEditBtn,
  TableTalTech2,
  TableViewBtn,
} from "@components";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import "../../../../styles/tableStyles.css";


export const createCoursesColActions = (
  navigator: any,
  isEditable: boolean
) => {
  const onEditClick = (id: string) => {
    navigator(`${id}/summary`);
  };
  return (cell: any, row: any, rowIndex: number, formatExtraData: any) => {
    return (
      <div className={"d-flex justify-content-end"}>
        {isEditable && <TableCopyBtn/>}
        {isEditable && (
          <TableEditBtn
            onClick={() => {
              console.log(row);
              onEditClick(row.id);
            }}
          />
        )}
        {isEditable && <TableDeleteBtn/>}
        {!isEditable && (
          <div className={"ebd-icon"}>
            <TableViewBtn
              onClick={() => {
                console.log(row);
                onEditClick(row.id);
              }}
            />
          </div>
        )}
      </div>
    );
  };
};

export const CoursesTable: React.FC<any> = observer(
  (props: { isEditable: boolean }) => {
    const navigator = useNavigate();
    const columns = [
      cols.id(),
      cols.courseLogo(),
      cols.number(),
      cols.name(),
      cols.lecturer(),
      cols.state(),
      cols.startDate(),
      cols.endDate(),
      {formatter: createCoursesColActions(navigator, props.isEditable)},
    ];
    const prop = {...defaultProps, columns} as any;
    return (
      <TableTalTech2
        source={{
          path: "courses",
        }}
        table={prop}
      ></TableTalTech2>
    );
  }
);
