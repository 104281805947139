import { Page } from "@components";
import _ from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { FC } from "react";
import { ElementOrSection, PageTestProps } from "./Components.types";
import { LogMessage, OutputWindow } from "./OutputWindow";
import { OutputWindowService, useOutputWindowService } from "./OutputWindow.service";
import { SectionResults, SectionSamples } from "./Section";

const getSection = (SectionElem: any, param?: ElementOrSection, ) => {
  if(_.isUndefined(param)){
    return <></>;
  }
  if(React.isValidElement(param)){
    return <SectionElem component={param} />
  }
  return <SectionElem {...param} />
}

export const PageTest: FC<PageTestProps> = (props) => {
  const samplesSection = getSection(SectionSamples, props.samples);
  const documentationSection = props.documentation || <></>;
  const service = useOutputWindowService()
  let demoSection = <></>;
  let outputWindowSection = <></>;
  if(props.demo){
    const { outputWindow = false, Component, configuration, properties} = props.demo;
    if(outputWindow){
      outputWindowSection = <OutputWindow messages={service.messages}/>;
    }
    if(Component !== undefined || properties !== undefined){
      demoSection = <SectionResults component={<Component {...properties} />}/>
    }
  }

  //const resultSection = getSection(SectionResults, props.result);

  const content = props.children
    ? props.children
    : <>
      {samplesSection}
      {documentationSection}
      {demoSection}
    </>



  return (
    <Page {...props}>
      {content}
      {outputWindowSection}
    </Page>
  );
}
