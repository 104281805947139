import {
  Breadcrumbs,
  DisabledTabComponent,
  ParameterForm,
  ToolbarCreate,
  ToolbarEdit,
} from "@components";
import {
  BuildingComponentsStore,
  ComponentType,
  Parameter,
  ParameterType,
} from "@domain/building-components";
import { inject } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export type ParameterDetailsBaseScreenProps = {
  mode: "edit" | "create";
  parameterId?: string;
  buildingComponentsStore?: BuildingComponentsStore;
};

const emptyParameter: Parameter = {
  modifiedDate: new Date(Date.now()),
  createdDate: new Date(Date.now()),
  category: "ROOF" as ComponentType,
  id: "undefined",
  name: "",
  description: "",
  type: "TEXT" as ParameterType,
  valueDefault: "",
};

export const ParameterDetailsBaseScreen: React.FC<ParameterDetailsBaseScreenProps> =
  inject("buildingComponentsStore")((props) => {
    const [parameter, setParameter] = useState<Parameter | undefined>(
      undefined
    );

    const { parameterId } = useParams();
    useEffect(() => {
      if (props.mode === "edit" && parameterId !== undefined) {
        props.buildingComponentsStore?.getParameter(parameterId!).then((x) => {
          setParameter(x);
        });
      } else {
        setParameter(emptyParameter);
      }
    });
    let tool;
    let onSubmit: any;
    if (props.mode === "edit") {
      tool = <ToolbarEdit />;
      onSubmit = (p: any) => {
        props.buildingComponentsStore?.saveParameter(p).then((x) => {
          setParameter(x);
        });
      };
    } else {
      tool = <ToolbarCreate />;
      onSubmit = (p: any) => {
        props.buildingComponentsStore?.createParameter(p).then((x) => {
          setParameter(x);
        });
      };
    }

    const content =
      parameter !== undefined ? (
        <>
          <ParameterForm
            parameter={parameter!}
            onCancel={() => {}}
            onSubmit={(x) => {
              onSubmit(x);
            }}
            visualization={{
              showModifiedDate: props.mode === "edit",
              showCreatedDate: false,
            }}
          />
        </>
      ) : (
        <></>
      );
    return (
      <div>
        <h1>{parameter?.category}</h1>
        <Breadcrumbs />
        <div className={"container"}>
          <DisabledTabComponent
            elements={["Types", "Parameters"]}
            activeDisabled={1}
          />
          {tool}
          {content}
        </div>
      </div>
    );
  });

export default ParameterDetailsBaseScreen;
