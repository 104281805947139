import {CheckboxComponent, TableMask} from "@components";
import {FormikProps, FormikValues} from "formik";
import React, {FC, useContext, useState} from "react";
import {Table} from "taltech-styleguide";
import BuildingComponentsContext from "../../../domain/forms/buildingcomponents.context";
import {useStore} from "../../../domain/store";
import {ComponentTypes} from "../../../packages/web/components-types/src/forms/BuildingConfigurationTypes";

export const ComponentsTypeTable: FC<{ formikElement: FormikProps<FormikValues>, renderedElements: ComponentTypes[] }> = ({
                                                                                                                            formikElement,
                                                                                                                            ...props
                                                                                                                          }) => {
  const ctx = useContext(BuildingComponentsContext);
  const [checkAll, setCheckAll] = useState(false);
  const {buildingConfigurationFormStore} = useStore();

  return (
    <>
      <div className={'position-relative overflow-x-scroll'}>
        <Table>
          <thead>
          <tr>
            <th>
              <div>
                <CheckboxComponent
                  changeFn={() => setCheckAll(!checkAll)}
                  disabled={buildingConfigurationFormStore.isReadOnly || ctx.config.disabled}
                  type={"text"}
                  name={`x`}/>
              </div>
            </th>
            <th>Name</th>
            <th>Description</th>
            <th>Internal/External</th>
            <th>Created On</th>
            <th>Modified On</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <>
            {props.renderedElements.map((el: any) => {
              return (
                <tr className={'ebd-table-row'} key={el.name}>
                  <td>
                    <div className={'mx-1'}>
                      <CheckboxComponent
                        value={checkAll || Object.keys(ctx.elements[ctx.selectedIndex].shuffledElements || {}).includes(`${el.name}_Checked`)}
                        disabled={buildingConfigurationFormStore.isReadOnly || ctx.config.disabled}
                        changeFn={() => formikElement.submitForm()}
                        type={"text"}
                        name={`${el.name}_Checked`}/>
                    </div>
                  </td>
                  <td className={'d-flex'}>

                    {el.name}
                  </td>
                  <td>{el.description}</td>
                  <td>{el.isExternal ? "External" : "Internal"}</td>
                  <td>{el.createdDate}</td>
                  <td>{el.modifiedOn}</td>
                  <td>...</td>
                </tr>
              )
            })}
          </>
          </tbody>
        </Table>
        {!formikElement.values[`${ctx.elements[ctx.selectedIndex].key}Shuffle`] && <TableMask/>}
      </div>
    </>
  );
};
