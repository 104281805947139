import { InputRandom, RandomChip } from "@components";
import { PageTest, useOutputWindowService } from "@components/testing";
import { FC, useState } from "react";
import { Form as FormikForm, Formik } from "formik";
export const InputRandomUitest = () => {
  const [enabled, toggleEnabled] = useState(false);
  const service = useOutputWindowService();
  return (
    <PageTest title="Input random - uitest">
      <div>
        <RandomChip
          random={enabled}
          onClick={() => {
            service.log("Clicked");
            toggleEnabled(!enabled);
          }}
        />
        <div>
          <Formik initialValues={{}} onSubmit={() => {}}>
            {(formik) => {
              return (
                <FormikForm>
                  <InputRandom id="" formState={formik as any} />
                </FormikForm>
              );
            }}
          </Formik>
        </div>
      </div>
    </PageTest>
  );
};
