import {
  BuildingComponentsWrapper,
  BuildingConfigurationWrapper,
  BuildingSpaceFunctionWrapper,
  IFormBuildingGenerator,
  Page,
  ToolbarEdit,
} from "@components";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {buildingConfiguration} from "../../../../domain/building/SampleBuildingConfiguration";
import {useStore} from "../../../../domain/store";

const ConfigurationDetailsScreen: React.FC<any> = (props) => {
  const {buildingConfigurationFormStore} = useStore();
  const {typeId} = useParams();

  useEffect(() => {
    const c = buildingConfiguration.filter(
      (x) => x.id === typeId?.toString()
    )[0];

    buildingConfigurationFormStore.isReadOnly = true;
    buildingConfigurationFormStore.firstStep = c.firstStep;
    buildingConfigurationFormStore.secondStep = c.secondStep;
    buildingConfigurationFormStore.thirdStep = c.thirdStep;
    buildingConfigurationFormStore.sixthStep = c.sixthStep;
  });

  const buildingConfigurationProps: IFormBuildingGenerator = {
    firstStep: buildingConfigurationFormStore.firstStep,
    secondStep: buildingConfigurationFormStore.secondStep,
    sixthStep: buildingConfigurationFormStore.sixthStep,
    thirdStep: buildingConfigurationFormStore.thirdStep,
  };

  return (
    <Page title="Edit Building Configuration" showBreadcrumbs={true}>
      <ToolbarEdit/>
      {/*<FormBuildingGenerator {...buildingConfigurationProps} />*/}
      <div>
        <h4>Building information</h4>
        <BuildingConfigurationWrapper/>
        <hr/>
        <h4>Space function</h4>
        <BuildingSpaceFunctionWrapper/>
        <hr/>
        <h4>Building components</h4>
        <BuildingComponentsWrapper/>
      </div>
    </Page>
  );
};

export default ConfigurationDetailsScreen;
