import { LayoutPublic } from "@components";
import { inject, observer } from "mobx-react";
import { MenuItem } from "primereact/menuitem";
import { PanelMenu } from "primereact/panelmenu";
import { Outlet } from "react-router-dom";
import { Col, Container, Loader, Row } from "taltech-styleguide";
import { MainAppViewStore } from "../../../configuration/MainAppViewStore";
import { LayoutConfiguration } from "../../../domain/web-applications/AreaConfiguration";

const componentsMenuItems: MenuItem[] = [
  {
    label: "Design",
    items: [
      {
        label: "Typography",
        command: (event) => {
          window.location.href = "/testing/components/design/typography" as any;
        },
      },
    ],
  },
  {
    label: "Notifications",
    command: (event) => {
      window.location.href = "/testing/components/notifications" as any;
    },
  },

  {
    label: "Buttons",
    command: (event) => {
      window.location.href = "/testing/components/buttons" as any;
    },
  },
  {
    label: "Navigation",
    items: [
      {
        label: "Toolbars",
        command: (event) => {
          window.location.href =
            "/testing/components/navigation/toolbar" as any;
        },
      },
    ],
  },
  {
    label: "Widgets",
    command: (event) => {
      window.location.href = "/testing/components/widgets" as any;
    },
  },
  {
    label: "Icons",
    items: [],
  },
  {
    label: "Tables",
    items: [
      {
        label: "Taltech advanced table",
        command: (event) => {
          window.location.href = "/testing/components/tables/taltech" as any;
        },
      },
      {
        label: "Advanced table - prime",
        command: (event) => {
          window.location.href =
            "/testing/components/tables/advanced-table" as any;
        },
      },
      {
        label: "Figma table - prime",
        command: (event) => {
          window.location.href =
            "/testing/components/tables/figma-table" as any;
        },
      },
      {
        label: "Prime react table",
        command: (event) => {
          window.location.href = "/testing/components/tables/di" as any;
        },
      },
    ],
  },
];

const menuItems: MenuItem[] = [
  {
    label: "Configurations",
    items: [
      {
        label: "Application",
        command: (event) => {
          window.location.href = "/testing/configuration/application" as any;
        },
      },
      {
        label: "Api",
        command: (event) => {
          window.location.href = "/testing/configuration/api" as any;
        },
      },
    ],
  },
  {
    label: "Domain",
    items: [
      {
        label: "Enumerations",
        command: (event) => {
          window.location.href = "/testing/domain/enumerations" as any;
        },
      },
    ],
  },
  {
    label: "Components",
    items: componentsMenuItems,
  },
  {
    label: "Areas/screens",
    items: [
      {
        label: "Building components",
        items: [
          {
            label: "Data",
            items: [
              {
                label: "Structure layer functions",
                items: [
                  {
                    label: "Table",
                    command: (event) => {
                      window.location.href =
                        "/testing/areas/building-components/data/structure-layer-functions/structure-layer-function-table" as any;
                    },
                  },
                ],
              },
              {
                label: "Materials",
                items: [
                  {
                    label: "Table",
                    command: (event) => {
                      window.location.href =
                        "/testing/areas/building-components/data/materials/material-table" as any;
                    },
                  },
                ],
              },
              {
                label: "Parameters",
                items: [
                  {
                    label: "Building parameter form",
                    command: (event) => {
                      window.location.href =
                        "/testing/areas/building-components/data/parameters/building-parameter-form" as any;
                    },
                  },
                ],
              },
            ],
          },
          {
            label: "Components",
            items: [
              {
                label: "Parameters",
                items: [
                  {
                    label: "Parameters form",
                    command: (event) => {
                      window.location.href =
                        "/testing/areas/building-components/components/parameters/parameter-form" as any;
                    },
                  },
                  {
                    label: "Parameters table",
                    command: (event) => {
                      window.location.href =
                        "/testing/areas/building-components/components/parameters/parameter-table" as any;
                    },
                  },
                  {
                    label: "Material Structure Table",
                    command: (event) => {
                      window.location.href =
                        "/testing/areas/building-components/data/structure/building-structure-table" as any;
                    },
                  },
                ],
              },
              {
                label: "Types",
                items: [
                  {
                    label: "Floor",
                    command: (event) => {
                      window.location.href =
                        "/testing/areas/building-components/components/types/floor" as any;
                    },
                  },
                  {
                    label: "Window",
                    command: (event) => {
                      window.location.href =
                        "/testing/areas/building-components/components/types/window" as any;
                    },
                  },
                  {
                    label: "Wall",
                    command: (event) => {
                      window.location.href =
                        "/testing/areas/building-components/components/types/wall" as any;
                    },
                  },
                  {
                    label: "Roof",
                    command: (event) => {
                      window.location.href =
                        "/testing/areas/building-components/components/types/floor" as any;
                    },
                  },
                ],
              },
            ],
          },
          {
            label: "Presets",
          },
        ],
      },
      {
        label: "Courses",
        items: [
          {
            label: "Course edit form",
            command: (event) => {
              window.location.href =
                "/testing/areas/courses/course-edit-form" as any;
            },
          },
        ],
      },
      {
        label: "Old - To refactor",
        items: [
          {
            label: "Building Configurations",
            command: (event) => {
              window.location.href =
                "/testing/areas/refactor/forms/building-configurations" as any;
            },
          },
          {
            label: "Building Components",
            command: (event) => {
              window.location.href =
                "/testing/areas/refactor/types/building-types" as any;
            },
          },
          {
            label: "Testing components",
            command: (event) => {
              window.location.href =
                "/testing/areas/refactor/testing-components" as any;
            },
          },
        ],
      },
    ],
  },
  {
    label: "Upload",
    items: [
      {
        label: "Upload",
        command: (event) => {
          window.location.href = "/testing/areas/upload" as any;
        },
      },
    ],
  },
];

export const LayoutTesting: React.FC<LayoutConfiguration> = inject(
  "mainAppViewStore"
)(
  observer(
    (
      props: LayoutConfiguration & {
        children?: JSX.Element[];
        mainAppViewStore?: MainAppViewStore;
      }
    ) => {
      const loader = props.mainAppViewStore?.isLoading ? (
        <Loader overlay />
      ) : (
        <></>
      );
      return (
        <LayoutPublic {...props}>
          <Container>
            {loader}
            <Row>
              <Col xs={"2"}>
                <PanelMenu model={menuItems} />
              </Col>
              <Col xs={"10"}>
                <Outlet />
                {/* {width < 1200 ? menuIsOpen &&
                  <MenuToogle menuState={menuIsOpen} closeFn={setMenuIsOpen} {...menuItem}/> : <></>} */}
              </Col>
            </Row>
          </Container>
        </LayoutPublic>
      );
    }
  )
);
