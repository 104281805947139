import {
  DisabledTabComponent,
  Breadcrumbs,
  ElementDataForm,
  PageContentForm,
  createFormProperties,
} from "@components";
import { SpaceFunction } from "@domain";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { spaceEntityStore } from "./SpaceFunctionTypesScreen";

const SpaceFunctionTypeDetailsScreen: React.FC<any> = () => {
  const navigator = useNavigate();
  const { typeId } = useParams();
  const [element, setElement] = useState<SpaceFunction | undefined>(undefined);

  useEffect(() => {
    if (typeId !== undefined) {
      spaceEntityStore
        .executeAction<SpaceFunction>({
          action: "FORM_GET",
          payload: typeId,
          options: {
            path: "buildingComponents.components",
          },
        })
        .then((x) => {
          setElement(x);
        });
    }
  }, [setElement, typeId]);
  if (element === undefined) {
    return <></>;
  }
  const formProps = createFormProperties<Partial<SpaceFunction>>(
    "EDIT",
    {
      onAction: (action) => {
        return Promise.resolve(spaceEntityStore.onAction(action));
      },
      schema: spaceEntityStore.options.schema,
      element: element,
    },
    spaceEntityStore.options,
    navigator
  );
  return (
    <div>
      <h2>Space Function</h2>
      <Breadcrumbs />
      <div className={"container"}>
        <DisabledTabComponent
          elements={["Functions", "Parameters"]}
          activeDisabled={0}
        />
        <PageContentForm
          form={{ Component: ElementDataForm, options: { ...formProps } }}
          mode="EDIT"
          dispatcher={{
            onAction: (action) => {
              return Promise.resolve(spaceEntityStore.onAction(action));
            },
          }}
        />
      </div>
    </div>
  );
};

export default SpaceFunctionTypeDetailsScreen;
