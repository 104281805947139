import { PageTabed, PageTabedProps }  from "@components";
import { WallParameterIndexScreen } from "./WallParameterIndexScreen";
import { WallTypeIndexScreen } from "./WallTypeIndexScreen";

const WallsScreen: React.FC<any> = (props: any) => {
  const newProps: PageTabedProps = {
    title: "Walls",
    pathBase: "/lecturers/building-components/system/components/walls",
    tabs: [
      {
        label: "Types",
        component: <WallTypeIndexScreen />,
        index: 0,
        path: "types",
      },
      {
        label: "Parameters",
        component: <WallParameterIndexScreen />,
        index: 1,
        path: "parameters",
      }
    ],
  };
  return <PageTabed {...newProps}></PageTabed>;
};

export default WallsScreen;
