import { Page, PageContentForm, createFormProperties } from "@components";
import React from "react";
import CourseEditForm from "./CourseEditForm";
import { useNavigate } from "react-router-dom";
import { entityStore } from "../CourseIndexScreen";
import { observer } from "mobx-react";

const CourseCreateScreen: React.FC<any> = observer((props) => {
  const navigator = useNavigate();
  const formProps = createFormProperties(
    "CREATE",
    {
      onAction: (action) => {
        return Promise.resolve(entityStore.onAction(action));
      },
      schema: entityStore.options.schema,
      element: entityStore.options.empty,
    },
    entityStore.options,
    navigator
  );
  return (
    <Page title="Create course">
      <PageContentForm
        form={{ Component: CourseEditForm, options: { ...formProps } }}
        mode="CREATE"
        dispatcher={{
          onAction: (action) => {
            return Promise.resolve(entityStore.onAction(action));
          },
        }}
      />
    </Page>
  );
});
export default CourseCreateScreen;
