import { Link } from "react-router-dom";
import { Button, BUTTON_COLOR, Col, Container, Row } from "taltech-styleguide";

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  return (
    <Container className="App">
      <Row>
        <Col>
          <img src="/gradientLogo.svg" width={256} alt="Logo"></img>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>TalTech EduBIM Platform</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant={BUTTON_COLOR.SECONDARY} size="lg">
            <Link to={"/auth/login"}>Login</Link>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const HomeScreen: React.FC<any> = () => (
  <>
    <div>
      <MainContent />
    </div>
  </>
);

export default HomeScreen;
