import { AdvancedTable } from "@components";
import { PageTest } from "@components/testing";

export const AdvancedTableUitest = () => {
  return  <PageTest title="Advanced table using prime react table - uitest">
    <p>This table is much similar to the <code>AdvancedTable</code> Component
      in TalTech storyBook but added the table functionality needed to filter
        and search table elements, using Prime React table</p>
    <AdvancedTable />
  </PageTest>;
}
