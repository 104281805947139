import {Field, useField} from "formik";
import React, {FC, useEffect} from "react";
import {CustomInput, Form} from "taltech-styleguide";
import ErrorComponent from "./ErrorComponent";

interface IRadioButtonType {
  options: string[];
  name: string;
  label?: string;
  isInline: boolean;
  disabled?: boolean;
  value?: number;
  changeFn?: (e: any) => void;
  step?: "buildingSpaceFunction"
}

export const RadioButtonComponent: FC<IRadioButtonType> = ({
                                                             step,
                                                             value,
                                                             changeFn,
                                                             options,
                                                             name,
                                                             label,
                                                             isInline,
                                                             disabled,
                                                           }) => {
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    step === "buildingSpaceFunction" && helpers.setValue(value === 0 ? 0 : undefined)
  }, [value, field.value])

  return (
    <div>
      <div
        className={
          isInline ? "d-flex align-items-center justify-content-between" : ""
        }
      >
        {label && <Form.Label>{label}</Form.Label>}
        <div className={"d-flex align-items-center"}>
          <Field isInvalid={meta.touched && meta.error} name={name}>
            {() =>
              options.map((el: string, index) => {
                return (
                  <label key={index} className={'d-flex align-items-center'}>
                    <CustomInput
                      {...field}
                      disabled={disabled || false}
                      className={"mx-2"}
                      type="radio"
                      checked={+field.value === index}
                      value={index}
                      onChange={(e) => helpers.setValue(e.target.value)}
                      id={name}
                      name={name}
                      role="group"
                      onClick={(e) => {
                        if (changeFn) {
                          changeFn(e);
                          field.onChange(e);
                        } else {
                          field.onChange(e);
                        }
                      }}
                    />{el}
                  </label>)
              })}
          </Field>
        </div>
      </div>
      <ErrorComponent fieldName={name}/>
    </div>
  );
};

export default RadioButtonComponent;
