import { WallDetails, DisabledTabComponent, Breadcrumbs } from "@components";
import { BuildingComponent, WallBuildingComponent } from "@domain";
import { inject } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { wallEntityStore } from "./WallTypeIndexScreen";

const WallTypeDetailsScreen: React.FC<any> = inject("buildingComponentsStore")(
  (props) => {
    const { typeId } = useParams();
    const [element, setElement] = useState<BuildingComponent | undefined>(
      undefined
    );

    useEffect(() => {
      if (typeId !== undefined) {
        wallEntityStore
          .executeAction<WallBuildingComponent>({
            action: "FORM_GET",
            payload: typeId,
            options: {
              path: "buildingComponents.components",
            },
          })
          .then((x) => {
            x.layers = { interior: [], exterior: [], core: [] };
            x.parameters = [];
            setElement(x);
          });
      }
    }, [setElement, typeId]);
    if (element === undefined) {
      return <></>;
    }
    return (
      <div>
        <h1>Walls</h1>
        <Breadcrumbs />
        <div className={"container"}>
          <DisabledTabComponent
            elements={["Types", "Parameters"]}
            activeDisabled={0}
          />
          <WallDetails element={element} entityStore={wallEntityStore} />
        </div>
      </div>
    );
  }
);

export default WallTypeDetailsScreen;
