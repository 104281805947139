import {createMaxMessage, createMinMessage, createRequiredMessage} from '@domain/validations';
import * as Yup from 'yup';

export const getBuildingComponentValidationSchema = () => {
  const buildingComponentValidationSchema = Yup.object<any>().shape({
    name: Yup.string().min(1, createMinMessage('name', 1)).max(100, createMaxMessage('name', 100)).required(createRequiredMessage('Name')),
    description: Yup.string().min(1, createMinMessage('description', 1)).max(100, createMaxMessage('description', 10000)).required(createRequiredMessage('description')),
  });

  return buildingComponentValidationSchema;
};
