import {FormInputExtendedProps} from "@dirootie-web/components-types";
import dayjs from "dayjs";
import {Field, useField} from "formik";
import React, {useState} from "react";
import 'react-dates/lib/css/_datepicker.css';
import {Form, SingleDatePicker} from "taltech-styleguide";
import "../../../styles/calendar.css"
import FeedbackMessage from "./FeedbackMessage";

export const InputSingleDatePicker = ({
                                        id,
                                        disabled = true,
                                        formState,
                                        ...props
                                      }: FormInputExtendedProps) => {
  const [field, meta, helpers] = useField(id);
  const [focused, setFocused] = useState(false);

  const handleDateChange = (newDate: any) => {
    helpers.setValue(newDate);
  };
  return (
    <Form.Group className={props.elementClass}>
      {props.label && <Form.Label>{props.label}</Form.Label>}

      <Field {...field} isInvalid={meta.touched && meta.error} name={id}>
        {() => (
          <SingleDatePicker
            disabled={disabled}
            date={dayjs(field?.value)}
            onDateChange={handleDateChange}

            focused={focused}
            onFocusChange={({focused}) => setFocused(focused)}
            id="date-picker"
            numberOfMonths={1}
            displayFormat="DD/MM/YYYY"
            isOutsideRange={(day) => {
              // const d = (props as any).startId;
              // if ((props as any).startId === undefined) {
              //   console.log("A")
              //   return false
              // }
              // ;
              const dat = dayjs(field.value);
              if (day.isAfter(dayjs(new Date(Date.now())).subtract(1, 'days'), "day")) {
                return false
              }
              return true;
            }}
            //daySize={10}
            openDirection="up"
            horizontalMargin={0}

          />
        )}
      </Field>
      <FeedbackMessage fieldId={id} formState={formState}></FeedbackMessage>
    </Form.Group>
  );
};
