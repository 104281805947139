import { UserNotification } from './notifications.types';

const translationBaseKey = 'NOTIFICATION.WEB';

const createTranslations = (notificationKey: string) => {
  return {
    title: { key: `${translationBaseKey}.${notificationKey}.TITLE` },
    message: { key: `${translationBaseKey}.${notificationKey}.MESSAGE` },
  };
};

export const notAuthorizedNotification: UserNotification = {
  ...createTranslations('AUTHORIZATION_FAILED'),
  type: 'error',
};

export const notAuthenticatedNotification: UserNotification = {
  ...createTranslations('AUTHENTICATION_FAILED'),
  type: 'error',
};

const welcomeNotification = (displayName: string) => {
  return {
    title: { key: `NOTIFICATION.WEB.WELCOME.TITLE` },
    message: {
      key: `NOTIFICATION.WEB.WELCOME.MESSAGE`,
      params: { name: displayName },
    },
    type: 'success',
  } as UserNotification;
};

export class WebNotificationFactory {
  static welcome(displayName: string) {
    return welcomeNotification(displayName);
  }
}
