import React, {FC} from "react";
import {Col, Row} from "taltech-styleguide";
import {useStore} from "../../domain/store";
import "../../styles/form.css";
import {BuildingConfigurationForm} from "./first-step";

export const BuildingConfigurationWrapper: FC<any> = ({}) => {

  const {buildingConfigurationFormStore} = useStore();

  return (
    <>
      <Row>
        <Col xs={"12"}>
          <BuildingConfigurationForm
            {...buildingConfigurationFormStore.firstStep}
            config={{
              ...buildingConfigurationFormStore.firstStep.config,
              disabled: !(buildingConfigurationFormStore.currentStep === 0),
            }}/>
        </Col>
      </Row>
    </>
  );
};
