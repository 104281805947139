import {
  Parameter,
  parametersPerTypeFloor,
  parametersPerTypeRoof,
  parametersPerTypeWall,
  parametersPerTypeWindow,
} from '@domain/building-components/shared';
import _ from 'lodash';
import {WallBuildingComponent} from '../WallBuildingComponent';
import {WindowBuildingComponent} from '../WindowBuildingComponent';
import {BuildingComponent} from './BuildingComponent';

const createParametersList = (list: Parameter[], id: string) => {
  return _.cloneDeep(list).map((x) => ({
    ...x,
    elementId: id,
    override: true,
  }));
};

export const buildingComponentsWindowsData: WindowBuildingComponent[] = [
  {
    id: 'id_window_1',
    type: 'WINDOW',
    name: 'Window 1',
    style: 'ISOLATED',
    height: {length: 10, position: 'RELATIVE'},
    sillHeight: {length: 10, position: 'ABSOLUTE'},
    width: {length: 10, position: 'RELATIVE'},
    description: 'Window description 1',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    layers: {core: [], interior: [], exterior: []},
    parameters: createParametersList(parametersPerTypeWindow, 'id_window_1'),
  },
  {
    id: 'id_window_2',
    type: 'WINDOW',
    name: 'Window 2',
    style: 'WALL_TO_WALL',
    height: {length: 10, position: 'RELATIVE', maximum: true},
    sillHeight: {length: 10, position: 'ABSOLUTE'},
    width: {length: 10, position: 'RELATIVE'},
    description: 'Window description 2',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    layers: {core: [], interior: [], exterior: []},
    parameters: createParametersList(parametersPerTypeWindow, 'id_window_2'),
  },
  {
    id: 'id_window_3',
    type: 'WINDOW',
    name: 'Window 3',
    style: 'WALL_TO_WALL',
    height: {length: 10, position: 'ABSOLUTE'},
    sillHeight: {length: 10, position: 'RELATIVE'},
    width: {length: 10, position: 'ABSOLUTE'},
    description: 'Window description 3',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    layers: {core: [], interior: [], exterior: []},
    parameters: createParametersList(parametersPerTypeWindow, 'id_window_3'),
  },
];

export const buildingComponentsFloorsData: BuildingComponent[] = [
  {
    id: 'id_floor_1',
    type: 'FLOOR',
    name: 'Floor 1',
    description: 'Description 1',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    layers: {
      interior: [{id: "Floor1_Id_1", type: "INTERIOR", function: "FINISH1", material: "Material01", thickness: 1}],
      exterior: [{id: "Floor1_Id_2", type: "INTERIOR", function: "FINISH1", material: "Material01", thickness: 1}],
      core: [{id: "Floor1_Id_3", type: "INTERIOR", function: "FINISH1", material: "Material01", thickness: 1}]
    },
    parameters: [
      {
        id: 'id_roof_param_1',
        category: 'ROOF',
        name: 'Roof parameter 1',
        description: 'Roof parameter description 1',
        type: 'NUMBER',
        valueDefault: 12.2,
        createdDate: new Date(Date.now()),
        modifiedDate: new Date(Date.now()),
        elementId: 'id_floor_1',
      },
      {
        id: 'id_roof_param_2',
        category: 'ROOF',
        name: 'Roof parameter 2',
        description: 'Roof parameter description 2',
        type: 'TEXT',
        valueDefault: 'text 2',
        override: true,
        overrideValue: 'asd',
        createdDate: new Date(Date.now()),
        modifiedDate: new Date(Date.now()),
        elementId: 'id_floor_1',
      },
      {
        id: 'id_roof_param_3',
        category: 'ROOF',
        name: 'Roof parameter 3',
        description: 'Roof parameter description 3',
        type: 'TEXT',
        valueDefault: 'asd 1',
        createdDate: new Date(Date.now()),
        modifiedDate: new Date(Date.now()),
        elementId: 'id_floor_1',
      },
    ],
  },
  {
    id: 'id_floor_2',
    type: 'FLOOR',
    name: 'Floor 2',
    description: 'Floor description 2',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    layers: {core: [], interior: [], exterior: []},
    parameters: createParametersList(parametersPerTypeFloor, 'id_floor_2'),
  },
  {
    id: 'id_floor_3',
    type: 'FLOOR',
    name: 'Floor 3',
    description: 'Floor description 3',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    layers: {core: [], interior: [], exterior: []},
    parameters: createParametersList(parametersPerTypeFloor, 'id_floor_3'),
  },
];

export const buildingComponentsWallsData: WallBuildingComponent[] = [
  {
    id: 'id_wall_1',
    type: 'WALL',
    name: 'Wall 1',
    layer: 'INTERIOR',
    description: 'Wall description 1',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    layers: {core: [], interior: [], exterior: []},
    parameters: createParametersList(parametersPerTypeWall, 'id_wall_1'),
  },
  {
    id: 'id_wall_2',
    type: 'WALL',
    name: 'Wall 2',
    layer: 'EXTERIOR',
    description: 'Wall description 2',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    layers: {core: [], interior: [], exterior: []},
    parameters: createParametersList(parametersPerTypeWall, 'id_wall_2'),
  },
  {
    id: 'id_wall_3',
    type: 'WALL',
    name: 'Wall 3',
    layer: 'EXTERIOR',
    description: 'Wall description 3',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    layers: {core: [], interior: [], exterior: []},
    parameters: [],
  },
];

export const buildingComponentsRoofsData: BuildingComponent[] = [
  {
    id: 'id_roof_1',
    type: 'ROOF',
    name: 'Roof 1',
    description: 'Roof description 1',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    layers:
      {
        interior: [{id: "Floor1_Id_1", type: "INTERIOR", function: "FINISH1", material: "Material01", thickness: 1}],
        exterior: [{id: "Floor1_Id_2", type: "INTERIOR", function: "FINISH1", material: "Material01", thickness: 1}],
        core: [{id: "Floor1_Id_3", type: "INTERIOR", function: "FINISH1", material: "Material01", thickness: 1}]
      }
    ,
    parameters: createParametersList(parametersPerTypeRoof, 'id_roof_1'),
  },
  {
    id: 'id_roof_2',
    type: 'ROOF',
    name: 'Roof 2',
    description: 'Roof description 2',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    layers: {core: [], interior: [], exterior: []},
    parameters: createParametersList(parametersPerTypeRoof, 'id_roof_2'),
  },
  {
    id: 'id_roof_3',
    type: 'ROOF',
    name: 'Roof 3',
    description: 'Roof description 3',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    layers: {core: [], interior: [], exterior: []},
    parameters: [],
  },
];

export const buildingComponentsData = [
  ...buildingComponentsWallsData,
  ...buildingComponentsFloorsData,
  ...buildingComponentsRoofsData,
  ...buildingComponentsWindowsData,
];
