import { UserNotification } from './notifications.types';

const translationBaseKey = 'NOTIFICATION.API';

const createTranslations = (notificationKey: string) => {
  return {
    title: { key: `${translationBaseKey}.${notificationKey}.TITLE` },
    message: { key: `${translationBaseKey}.${notificationKey}.MESSAGE` },
  };
};

export const tokenExpiredErrorNotification: UserNotification = {
  ...createTranslations('TOKEN_EXPIRED_ERROR'),
  type: 'error',
};

export const serverConnectionFailedNotification: UserNotification = {
  title: 'Internet connection error',
  message: 'Please verify your internet connection',
  type: 'error',
};

export const internalServerErrorNotification: UserNotification = {
  ...createTranslations('INTERNAL_SERVER_ERROR'),
  type: 'error',
};

const authenticationFailed = (message: string): UserNotification => {
  return {
    title: { key: `${translationBaseKey}.AUTHENTICATION_FAILED.TITLE` },
    message: { key: message },
    type: 'error',
  };
};

export const badRequestNotification = (message: string): UserNotification => {
  return {
    title: 'Bad request. Entity has validation errors',
    message: message,
    type: 'error',
    component: true,
  };
};

export class ApiNotificationFactory {
  static authenticationFailed(message: string) {
    return authenticationFailed(message);
  }
}
