import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  application,
  msalInstance,
} from "./configuration/Application.bootstrap";
import { Loader, ToastContainer } from "taltech-styleguide";
import { MobXProviderContext } from "mobx-react";
import { Navigate } from "react-router-dom";

application.start();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MobXProviderContext.Provider value={application.getStores()}>
        <App />
        <ToastContainer />
      </MobXProviderContext.Provider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
