import {
  Page,
  PageContentForm,
  DisabledTabComponent,
  createFormProperties,
} from "@components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { WindowBuildingComponent } from "@domain";
import { windowEntityStore } from "./WindowTypeIndexScreen";
import { WindowDataForm } from "../../../../../../../components/building-components/components/types/windows/WindowDataForm";

const WindowTypeCreateScreen: React.FC<any> = () => {
  const navigator = useNavigate();
  const formProps = createFormProperties<Partial<WindowBuildingComponent>>(
    "CREATE",
    {
      onAction: (action) => {
        return Promise.resolve(windowEntityStore.onAction(action));
      },
      schema: windowEntityStore.options.schema,
      element: { ...windowEntityStore.options.empty },
    },
    windowEntityStore.options,
    navigator
  );

  return (
    <Page title="Create new wall type" showBreadcrumbs={true}>
      <div className={"container"}>
        <DisabledTabComponent
          elements={["Types", "Parameters"]}
          activeDisabled={0}
        />
        <PageContentForm
          form={{ Component: WindowDataForm, options: { ...formProps } }}
          mode="CREATE"
          dispatcher={{
            onAction: (action) => {
              return Promise.resolve(windowEntityStore.onAction(action));
            },
          }}
        />
      </div>
    </Page>
  );
};

export default WindowTypeCreateScreen;
