import {
  BuildingParameterForm,
  BuildingParameterFormProps,
  ParameterForm,
  ParameterFormProps,
} from "@components/building-components";
import { PageTest, PageTestProps } from "@components/testing";
import { FC } from "react";
import { Parameter } from "@domain/building-components";
import { useOutputWindowService } from "../../../../../components/OutputWindow.service";

export const BuildingParameterFormUitest: FC<any> = () => {
  const service = useOutputWindowService();
  const formProps: BuildingParameterFormProps = {
    element: {
      category: "BUILDING",
      id: "1",
      name: "Name",
      description: "sdfdsfds",
      type: "TEXT",
      valueDefault: "1",
      creationType: "CONSTANT",
    },
    onSubmit: (parameter: Parameter) => {
      service.log("onSubmit", parameter);
    },
    onCancel: (evt: any) => {
      service.log("onCancel");
    },
  };

  const props: PageTestProps = {
    title: "Building parameter form - uitest",
    demo: {
      outputWindow: true,
      outputTracker: ["onSubmit", "onCancel"],
      Component: BuildingParameterForm,
      properties: formProps,
    },
  };

  return <PageTest {...props} />;
};
