import { TableDi } from "@components";
import { PageTest } from "@components/testing";
import { TableProps } from "packages/web/components-types/src/tables/tables.types";

const tableProps: TableProps = {
  columns: [
    {
      header: "Name",
      name: "name",
      type: "text",
      display: true,
    },
    {
      header: "State",
      name: "state",
      type: "text",
      display: true,
    },
    {
      header: "Created at",
      name: "createdDate",
      type: "date",
      display: true,
    },
  ],
  data: [
    {
      name: "Course 1",
      state: "Active",
      createdDate: new Date(Date.now()),
    },
  ],
  onRowClickNavigate: true,
};

export const TableDiUitest = () => {
  return  <PageTest title="DiRoots prime react table - uitest">
    <p>Table from prime react - for future use within DiRoots</p>
    <TableDi {...tableProps} />
  </PageTest>;
}
