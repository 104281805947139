import {BuildingParameterTable, Page, ToolbarList} from "@components";

export const BuildingParametersScreen: React.FC<any> = (props: any) => {
  return (
    <Page title="Building parameters">
      <div>
        <ToolbarList/>

        <BuildingParameterTable/>
      </div>
    </Page>
  );
};
