import {createRequiredMessage} from "@domain/validations";
import _ from 'lodash';
import * as Yup from 'yup';

export const getSpaceValidationSchema = (rowOneRooms: number, rowTwoRooms: number) => {

  const schema = {}
  _.times(rowOneRooms, (index) => {
    _.set(schema, `R_1_${index + 1}_Length`, Yup.string().required(createRequiredMessage(`R 1.${index + 1} Length`))
      .test('ISInteger', 'Field must be Integer Value', (value) => +value! % 1 === 0 || value == '...').test(
        'Is positive?',
        'Field must be greater than 0!',
        (value) => +value! >= 0 || value == '...'
      ))
  })
  _.times(rowTwoRooms, (index) => {
    _.set(schema, `R_2_${index + 1}_Length`, Yup.string().required(createRequiredMessage(`R 2.${index + 1} Length`))
      .test('ISToProps Integer', 'Field must be Integer Value', (value) => +value! % 1 === 0 || value == '...').test(
        'Is positive?',
        'Field must be greater than 0!',
        (value) => +value! >= 0 || value == '...'
      ))
  })
  _.set(schema, `corridorLength`, Yup.string().required(createRequiredMessage(`Corridor length`))
    .test('ISToProps Integer', 'Field must be Integer Value', (value) => +value! % 1 === 0 || value == '...').test(
      'Is positive?',
      'Field must be greater than 0!',
      (value) => +value! >= 0
    ));
  return Yup.object<any>().shape(schema);
};
