import { observer } from "mobx-react";
import {
  colCreateDate,
  colDescription,
  colId,
  colModifiedDate,
  colName,
  createColActions,
  defaultProps,
  InputCheckbox,
  TableTalTech2,
} from "@components";
import { useNavigate } from "react-router-dom";
import { buildingComponentsData } from "@domain";
import { CustomInput } from "taltech-styleguide";
import { ParameterInstanceForm } from "./ParameterInstanceForm";

export const BuildingComponentParametersTable: React.FC<any> = observer(
  (props: any) => {
    const navigator = useNavigate();
    const columns = [
      colId,
      colName,
      {
        classes: "test-column",
        dataField: "type",
        sort: true,
        text: "Type",
      },
      {
        classes: "test-column",
        text: "Override",
        formatter: (
          cell: any,
          row: any,
          rowIndex: number,
          formatExtraData: any
        ) => {
          return <ParameterInstanceForm parameter={row} />;
        },
      },
    ];
    const tableProps: any = {
      ...defaultProps,

      columns,
    };
    return (
      <TableTalTech2
        source={{
          elements: buildingComponentsData.filter(
            (x) => x.id === props.element.id
          )[0].parameters,
        }}
        table={tableProps}
      ></TableTalTech2>
    );
  }
);
