import {FormBase, FormProps, InputRadioGroup, InputText, InputTextArea, InputTextDate,} from "@components";
import {BuildingComponent, getBuildingComponentValidationSchema,} from "@domain/building-components";
import {Form as FormikForm, Formik} from "formik";
import React, {FC} from "react";
import {InputSingleDatePicker} from "../../../../forms/inputs/InputSingleDatePicker";

export type ComponentDataFormProps = {
  showLayer?: boolean;
  element: BuildingComponent;
  onSubmit: (parameter: BuildingComponent) => void;
  onCancel: (evt: any) => void;
  onReset?: () => void;
  submiFormBinder?: any;
  visualization?: {
    showModifiedDate: boolean,
    showCreatedDate: boolean
  }
};

export const BuildingComponentDataForm: FC<ComponentDataFormProps> = ({
                                                                        ...props
                                                                      }) => {
  return (
    <>
      <Formik
        validationSchema={getBuildingComponentValidationSchema()}
        initialValues={props.element}
        onSubmit={(values) => {
          props.onSubmit(values);
        }}
      >
        {(formik) => {
          const submitAndValidate = (): Promise<void> => {
            return formik.validateForm().then((x) => {
              if (formik.isValid) {
                return formik.submitForm();
              } else {
                //userNotificationService.error(createFormHasErrorsNotification());
                return Promise.resolve();
              }
            });
          };
          props.submiFormBinder && props.submiFormBinder(submitAndValidate);

          const layer = !props.showLayer ? (
            <></>
          ) : (
            <InputRadioGroup
              id="layer"
              label="Interior/exterior"
              formState={formik as any}
              options={[
                {id: "interior", label: "Interior", value: "INTERIOR"},
                {id: "exterior", label: "Exterior", value: "EXTERIOR"},
              ]}
            />
          );
          return (
            <FormikForm>
              <InputText id="name" label="Name" formState={formik as any}/>
              {layer}
              <InputTextArea
                id="description"
                label="Description"
                formState={formik as any}
              />
              {props.visualization?.showModifiedDate &&
                <InputTextDate disabled={true} label={'Modified date'} id={'modifiedDate'} formState={formik as any}/>}
              {props.visualization?.showCreatedDate &&
                <InputTextDate disabled={true} label={'Created date'} id={'createdDate'} formState={formik as any}/>}
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};


export type ElementDataFormProps = FormProps<BuildingComponent> & {
  showLayer?: boolean;
};

export const ElementDataForm: FC<ElementDataFormProps> = ({...props}) => {
  const render = (formik: any) => {
    const layer = !props.showLayer ? (
      <></>
    ) : (
      <InputRadioGroup
        id="side"
        label="Interior/exterior"
        formState={formik as any}
        options={[
          {id: "interior", label: "Interior", value: "INTERIOR"},
          {id: "exterior", label: "Exterior", value: "EXTERIOR"},
        ]}
      />
    );
    return (
      <FormikForm>
        <InputText id="name" label="Name" formState={formik as any}/>
        {layer}
        <InputTextArea
          id="description"
          label="Description"
          formState={formik as any}
        />
        {props.visualization?.showModifiedDate &&
          <InputSingleDatePicker id={'modifiedDate'} formState={formik as any} label={'Modified date'}/>}
        {props.visualization?.showCreatedDate &&
          <InputSingleDatePicker label={'Created date'} id={'createdDate'} formState={formik as any}/>}
      </FormikForm>
    );
  };
  return <FormBase {...props} render={render}/>;
};
