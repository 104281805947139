import {
  Page,
  ReactToastifyUserNotificationService
} from "@components";
import { Button, ButtonGroup, BUTTON_COLOR } from "taltech-styleguide";
import { NotificationSeverity } from "packages/web/components-types/src/notifications";
import { application } from "../../../../../configuration/Application.bootstrap";
import { PageTest } from "../../../components/PageTest";

export const NotificationsUitest = () => {
  const notificationService = new ReactToastifyUserNotificationService(
    application.getLogger()
  );
  const clickHandler = (text: string, severity: NotificationSeverity) =>
    notificationService.notify(
      {
        title: text
      },
      severity
    );
  return (
    <PageTest title="Notification testing">
      <h2 className={"edb-heading-bold"}>Figma Table</h2>
      <ButtonGroup>
        <Button
          variant={BUTTON_COLOR.OUTLINE_PRIMARY}
          onClick={() => clickHandler("Success toast text", "success")}
        >
          Success toast
        </Button>
        <Button
          variant={BUTTON_COLOR.OUTLINE_PRIMARY}
          onClick={() => clickHandler("Warning toast text", "warning")}
        >
          Warning toast
        </Button>
        <Button
          variant={BUTTON_COLOR.OUTLINE_PRIMARY}
          onClick={() => clickHandler("Error toast text", "error")}
        >
          Error toast
        </Button>
        <Button
          variant={BUTTON_COLOR.OUTLINE_PRIMARY}
          onClick={() => clickHandler("Info toast text", "info")}
        >
          Info toast
        </Button>
      </ButtonGroup>
    </PageTest>
  );
};
