import { Field } from "formik";
import { Form, Input } from "taltech-styleguide";
import FeedbackMessage from "./FeedbackMessage";
import {
  FormInputExtendedProps,
  isFormFieldValid,
} from "@dirootie-web/components-types";

export type InputTextAreaProps = FormInputExtendedProps & {
  rows?: number;
};

export const InputTextArea = ({
  id,
  formState,
  disabled,
  rows,
  ...props
}: InputTextAreaProps) => {
  // const TextAreaInner = () => (<Input
  //   as="textarea"
  //   rows={rows}
  //   onChange={(evt) => { formState.setFieldValue(id,evt.target.value)}}
  // />);

  return (
    <Form.Group className={props.elementClass}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <Field
        disabled={disabled}
        name={id}
        isInvalid={!isFormFieldValid(id, formState)}
        // component={() => (
        //   <Input
        //     name={id}
        //     as="textarea"
        //     rows={rows}
        //     value={formState.getFieldMeta(id).value}
        //     onChange={(evt) => {
        //       //formState.setFieldValue(id,evt.target.value);
        //       console.log("s", props, evt, formState);
        //     }}
        //   />
        // )}
        component={Input}
        rows={rows}
        renderAs="textarea"
        //placeholder={formState.getFieldMeta(id).value}
      />
      <FeedbackMessage fieldId={id} formState={formState}></FeedbackMessage>
    </Form.Group>
  );
};
