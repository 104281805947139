import { EntityOptions, EntityStore } from "@components";
import { BuildingComponentsIndexBaseScreen } from "@components/building-components";
import { RoofBuildingComponent, getBuildingComponentValidationSchema } from "@domain";

export const componentEntityOptions: EntityOptions<RoofBuildingComponent> = {
  schema: getBuildingComponentValidationSchema,
  empty: {
    name: '',
    description: '',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    category: "ROOFS"
  },
  path: 'buildingComponents.components',
};

export const entityStore = new EntityStore(componentEntityOptions)


export const RoofTypeIndexScreen: React.FC<any> = () => (
  <BuildingComponentsIndexBaseScreen componentType={"ROOFS" as any} />
);
