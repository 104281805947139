import {
  DisabledTabComponent,
  ElementDataForm,
  Page,
  PageContentForm,
  createFormProperties,
} from "@components";
import React from "react";
import { wallEntityStore } from "./WallTypeIndexScreen";
import { useNavigate } from "react-router-dom";
import { WallBuildingComponent } from "@domain";

const WallTypeCreateScreen: React.FC<any> = () => {
  const navigator = useNavigate();
  const formProps = createFormProperties<Partial<WallBuildingComponent>>(
    "CREATE",
    {
      onAction: (action) => {
        return Promise.resolve(wallEntityStore.onAction(action));
      },
      schema: wallEntityStore.options.schema,
      element: { ...wallEntityStore.options.empty },
    },
    wallEntityStore.options,
    navigator
  );
  return (
    <Page title="Create new wall type" showBreadcrumbs={true}>
      <div className={"container"}>
        <DisabledTabComponent
          elements={["Types", "Parameters"]}
          activeDisabled={0}
        />
        <PageContentForm
          form={{
            Component: ElementDataForm,
            options: { ...formProps, showLayer: true },
          }}
          mode="CREATE"
          dispatcher={{
            onAction: (action) => {
              return Promise.resolve(wallEntityStore.onAction(action));
            },
          }}
        />
      </div>
    </Page>
  );
};

export default WallTypeCreateScreen;
