import { STATUS_TYPE } from "taltech-styleguide";
import ErrorScreen, { ErrorScreenProps } from "./ErrorScreen";

const Error401Screen: React.FC<any> = () => {
  const props: ErrorScreenProps = {
    variant: STATUS_TYPE.WARNING,
    title: "Autentication required",
    message: "Please login to access this resource",
  };
  return <ErrorScreen {...props} />;
};

export default Error401Screen;
