import { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import { isExternalUrl, parseExternalUrl } from "taltech-styleguide";

const LinkBehaviour = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ to, children, ...rest }, ref) => {
    const url = typeof to === "string" ? to : to?.pathname || "";
    return isExternalUrl(url) ? (
      <a ref={ref} href={parseExternalUrl(url)} {...rest}>
        {children}
      </a>
    ) : (
      <Link ref={ref} to={to} {...rest}>
        {children}
      </Link>
    );
  }
);
LinkBehaviour.displayName = "LinkBehaviour";

export default LinkBehaviour;
