import { isNotNullOrEmptyString } from "@dirootie-extensions/javascript";
import { useNavigate } from "react-router-dom";
import { Header } from "taltech-styleguide";
import { LayoutConfiguration } from "../../domain/web-applications/AreaConfiguration";
import LinkBehaviour from "./LinkBehaviour";
import { observer } from "mobx-react";

export const NavBarTop: React.FC<any> = observer(
  (
    props: LayoutConfiguration & {
      profile: {
        firstName: string;
        lastName: string;
        photo: string;
        role: string;
      };
    }
  ) => {
    const navigator = useNavigate();
    const links: any = [];
    const showProfile = props.menuProfile?.items !== undefined;
    const headerClass = `edb-header` + (showProfile ? "" : " edb-header-basic");
    const logoRoute = isNotNullOrEmptyString(props.route) ? props.route : "";
    return (
      <Header
        linkAs={LinkBehaviour as any}
        className={headerClass}
        logoLink={{
          href: logoRoute,
          children: "Logo aria",
        }}
        links={links}
        profile={
          showProfile
            ? {
                profile: props.profile,
                links: props.menuProfile?.items.filter(
                  (x) =>
                    x.roles === undefined ||
                    x.roles.length === 0 ||
                    x.roles!.findIndex((x) => x === props.profile!.role) > -1
                ),
                onLogout: () => navigator("auth/logout"),
              }
            : undefined
        }
        translations={{
          logOut: "Logout",
        }}
      />
    );
  }
);
