import {
  DisabledTabComponent,
  ElementDataForm,
  Page,
  PageContentForm,
  createFormProperties,
} from "@components";
import React from "react";
import { floorEntityStore } from "./FloorTypeIndexScreen";
import { useNavigate } from "react-router-dom";
import { FloorBuildingComponent } from "@domain";

const FloorTypeCreateScreen: React.FC<any> = () => {
  const navigator = useNavigate();
  const formProps = createFormProperties<Partial<FloorBuildingComponent>>(
    "CREATE",
    {
      onAction: (action) => {
        return Promise.resolve(floorEntityStore.onAction(action));
      },
      schema: floorEntityStore.options.schema,
      element: { ...floorEntityStore.options.empty },
    },
    floorEntityStore.options,
    navigator
  );

  return (
    <Page title="Create new Floor type" showBreadcrumbs={true}>
      <div className={"container"}>
        <DisabledTabComponent
          elements={["Types", "Parameters"]}
          activeDisabled={0}
        />
        <PageContentForm
          form={{ Component: ElementDataForm, options: { ...formProps } }}
          mode="CREATE"
          dispatcher={{
            onAction: (action) => {
              return Promise.resolve(floorEntityStore.onAction(action));
            },
          }}
        />
      </div>
    </Page>
  );
};

export default FloorTypeCreateScreen;
