import { FC } from "react";

export const ComponentsScreen: FC<any> = ({...props}) => {

  return (
    <>
      <h3>Components screen</h3>
    </>
  );
};
