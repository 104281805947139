import { TableTalTech } from "@components";
import { PageTest } from "@components/testing";
import { TableProps } from "packages/web/components-types/src/tables/tables.types";
import { coursesData } from "@domain/courses";

const tableProps: TableProps = {
  columns: [
    {
      header: "Name",
      name: "name",
      type: "text",
      display: true,
    },
    {
      header: "State",
      name: "state",
      type: "text",
      display: true,
    },
    {
      header: "Created at",
      name: "createdDate",
      type: "date",
      display: true,
    },
  ],
  data: coursesData,
  onRowClickNavigate: true,
};

export const TableTalTechUitest = () => {
  return (
    <PageTest title="TalTech table - uitest">
      <p>Table from TalTech library that uses advanced table</p>
      <TableTalTech

       {...tableProps}
      ></TableTalTech>
    </PageTest>
  );
};
