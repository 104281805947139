import { RoleType } from './Users.types';

export const InvitationStateList = [
  'PENDING',
  'ACCEPTED',
  'REJECTED',
  'EXPIRED',
];

export type InvitationState = typeof InvitationStateList[number];

export type Invitation = {
  id?: string;
  name: string;
  email: string;
  message?: string;
  role: RoleType;
  state: InvitationState;
  createdDate: Date;
  modifiedDate: Date;
};
