import { IHttpClient, HttpResponse } from '@dirootie-core/http-types';
import { ILogger } from '@dirootie-core/logging-types';
import { PagedResult } from '@dirootie-core/core-types';
import {
  DefaultQueryService,
  IQuery,
  SearchStore,
} from '../../../web/components-types/src';
import _ from 'lodash';
import { buildPathFromRouteParams } from './routing.utils';
import { MainAppViewStore } from '../../../../configuration/MainAppViewStore';
export type ServiceFactory = {
  createApiService: <TC, TS>(endpoint: any) => ApiService<TC, TS>;
  createApiServiceFromPath: <TC, TS>(path: any) => ApiService<TC, TS>;
  createSearchStore: <T>(f: (q: IQuery) => PagedResult<T>) => SearchStore<T>;
  createSearchStoreFromPath: <T>(path: string) => SearchStore<T>;
};
export class ApiService<TClientType, TServerType> {
  private url: string = '';

  constructor(
    private httpClient: IHttpClient,
    private endpoint: any,
    private logger: ILogger
  ) {
    this.url =
      this.endpoint?.prefix !== undefined
        ? this.endpoint?.prefix + this.endpoint.collection
        : this.endpoint.collection;
    logger.info('[ApiService:constructor]: Called.', this.url);
  }

  getAll(query: any): Promise<PagedResult<TClientType>> {
    this.logger.trace('[ApiService:getAll]: Called.', query, this.url);
    const queryUrl = this.buildResourceUrl(this.url, query);
    return this.httpClient.get<TServerType[]>(queryUrl).then((x: any) => {
      this.logger.trace('[ApiService:getAll]: Response from http.', x);
      const result = this.mapServerToClientCollection(
        x.body,
        this.mapServerToClient
      ) as TClientType;
      return result as PagedResult<TClientType>;
    });
  }

  get(id: string): Promise<TClientType> {
    const idUrl = this.buildUrl(this.url, id);
    return this.httpClient.get<TServerType[]>(idUrl).then((x: any) => {
      this.logger.trace('[ApiService:get]: Response from http.', x);
      const result = this.mapServerToClient(x.body) as TClientType;
      return result;
    });
  }

  getUrl(url: string): Promise<TClientType> {
    const idUrl = this.buildUrl(this.url, url);
    return this.httpClient.get<TServerType[]>(idUrl).then((x: any) => {
      this.logger.trace('[ApiService:get]: Response from http.', x);
      const result = this.mapServerToClient(x.body) as TClientType;
      return result;
    });
  }

  create(entity: TClientType): Promise<TClientType> {
    const toServer: any = this.mapClientToServer(entity);
    toServer.id = undefined;
    toServer.createdDate = undefined;
    toServer.modifiedDate = undefined;
    return this.httpClient
      .post<TServerType>(this.url, toServer)
      .then((x: any) => {
        this.logger.trace('[UserService:create]: Response from http.', x);
        const result = this.mapServerToClient(x.body) as TClientType;
        return result;
      });
  }

  update(entity: TClientType): Promise<TClientType> {
    console.log(entity);
    const toServer = this.mapClientToServer(entity) as TServerType;
    const idUrl = this.buildUrl(this.url, (entity as any).id);
    console.log(idUrl, toServer);
    return this.httpClient
      .put<TServerType>(idUrl, toServer)
      .then((x: HttpResponse<TServerType>) => {
        this.logger.trace('[UserService:update]: Response from http.', x);
        const result = this.mapServerToClient(
          x.body as TServerType
        ) as TClientType;
        return result as unknown as TClientType;
      });
  }

  delete(id: string): Promise<TClientType> {
    const idUrl = this.buildUrl(this.url, id);
    return this.httpClient
      .delete(idUrl)
      .then((x: HttpResponse<TServerType>) => {
        this.logger.trace('[UserService:delete]: Response from http.', x);
        const result = this.mapServerToClient(
          x.body as TServerType
        ) as TClientType;
        return result as unknown as TClientType;
      });
  }

  buildUrl(base: string, id: string) {
    return base + '/' + id;
  }

  protected mapClientToServer(fromClient: TClientType): TServerType {
    return fromClient as unknown as TServerType;
  }

  protected mapServerToClient(fromServer: TServerType): TClientType {
    return fromServer as unknown as TClientType;
  }

  protected mapServerToClientCollection(
    fromServerCollection: PagedResult<TServerType>,
    typeMapper: (tserver: TServerType) => TClientType = (x: TServerType) =>
      x as any
  ) {
    return fromServerCollection;
  }

  protected mapClientQueryToServer(fromClientQuery: any = {}) {
    const {
      page = 1,
      size = 50,
      sortDirection,
      sortBy,
      ...rest
    } = fromClientQuery;
    const result = {
      page,
      size,
      ...rest,
    };
    if (!_.isUndefined(sortBy) && sortBy !== null) {
      result.sortBy = sortBy;
      if (!_.isUndefined(sortDirection)) {
        result.sortDirection = sortDirection;
      }
    }
    // if (!_.isUndefined(sortDirection) && !_.isUndefined(sortBy)) {
    //   result.sort = `${sortBy},${sortDirection}`;
    // }
    return result;
  }

  protected buildResourceUrl(template: string, fromClientQuery: any = {}) {
    const url = buildPathFromRouteParams(
      template,
      this.mapClientQueryToServer(fromClientQuery),
      true
    );
    return url;
  }

  static createService<TC, TS>(
    httpClient: IHttpClient,
    endpoint: any,
    logger: ILogger
  ) {
    return new ApiService<TC, TS>(httpClient, endpoint, logger);
  }

  static createFactory<TC, TS>(
    httpClient: IHttpClient,
    logger: ILogger,
    config: any,
    mainAppViewStore: MainAppViewStore
  ) {
    const createApiService = (endpoint: any) => {
      return this.createService<TC, TS>(httpClient, endpoint, logger);
    };

    const createApiServiceFromPath = <TC, TS>(
      path: any
    ): ApiService<TC, TS> => {
      return this.createService<TC, TS>(
        httpClient,
        _.get(config.api.endpoints, path),
        logger
      );
    };

    const createSearchStore = <T>(
      f: (q: IQuery) => PagedResult<T>
    ): SearchStore<T> => {
      return new SearchStore<T>(
        {
          getAll: (query) => {
            return Promise.resolve(f(query));
          },
        },
        mainAppViewStore
      );
    };
    const createSearchStoreFromPath = <T>(path: string): SearchStore<T> => {
      const service = createApiServiceFromPath(path) as any;
      return new SearchStore<T>(service, mainAppViewStore);
    };

    const createSearchStoreMock = <T>(data: T[]): SearchStore<T> => {
      const mockService = new DefaultQueryService(data, (c: any) => c.id);
      return new SearchStore(mockService, mainAppViewStore);
    };
    return {
      createApiService,
      createApiServiceFromPath,
      createSearchStore,
      createSearchStoreFromPath,
      createSearchStoreMock,
    };
  }
}
