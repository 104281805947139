import {FeedbackMessage, InputCheckbox, InputNumber, SelectWindowPosition,} from "@components";
import {FormInputExtendedProps,} from "@dirootie-web/components-types";
import {Col, Form, Row} from "taltech-styleguide";

export const InputWindowMeasure = ({
                                     id,
                                     formState,
                                     label,
                                     ...props
                                   }: FormInputExtendedProps & { showMaximum?: boolean }) => {
  const maximumContent = props.showMaximum ? (
    <InputCheckbox
      label="Maximum"
      parentClass="ebd-min-input-width mx-1 my-0"
      id={`${id}.maximum`}
      name={`${id}.maximum`}
      formState={formState}
    />
  ) : (
    <></>
  );

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Row>
        <Col sm={4}>
          <SelectWindowPosition
            parentClass="ebd-min-input-width mx-1 my-0"
            id={`${id}.position`}
            formState={formState}
            {...props}
          />
        </Col>

        <Col sm={8}>
          <InputNumber
            parentClass="ebd-min-input-width mx-1 my-0"
            id={`${id}.length`}
            name={`${id}.length`}
            formState={formState}
            {...props}
          />
        </Col>
      </Row>
      {maximumContent}
      <FeedbackMessage fieldId={id} formState={formState}></FeedbackMessage>
    </Form.Group>
  );
};
