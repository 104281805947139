export const IconCopy = ()  => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17.154 7H8.846C7.826 7 7 7.827 7 8.846v8.308C7 18.174 7.827 19 8.846 19h8.308c1.02 0 1.846-.827 1.846-1.846V8.846C19 7.826 18.173 7 17.154 7z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3.77 13h-.924A1.846 1.846 0 011 11.154V2.846A1.846 1.846 0 012.846 1h8.308A1.846 1.846 0 0113 2.846v.923"
    ></path>
  </svg>
);
