import {cols, createColActions, defaultProps, TableTalTech2,} from "@components";
import {spaceFunctionParametersData} from "@domain/building-components";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";

export const SpaceFunctionParametersTable: React.FC<any> = observer(
  (props: { elements: any }) => {
    const navigator = useNavigate();
    const columns = [
      cols.name(),
      cols.description(),
      cols.type(),
      cols.min(),
      cols.max(),
      cols.valueDefault(),
      {
        formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => createColActions(navigator, {
          showCopy: true,
          showDelete: true,
          showEdit: true,
          onEdit: () => navigator(row.id)
        }),
      },
    ];
    const tableProps: any = {
      ...defaultProps,

      columns,
    };
    return (
      <TableTalTech2
        source={{
          elements: spaceFunctionParametersData,
        }}
        table={tableProps}
      ></TableTalTech2>
    );
  }
);
