import {Field, useField} from "formik";
import React, {FC} from "react";
import ErrorComponent from "./ErrorComponent";

interface IShuffleRadioButtonType {
  name: string;
  disabled?: boolean;
  changeFn: () => void;
}

export const ShuffleRadioButton: FC<IShuffleRadioButtonType> = ({
                                                                  changeFn,
                                                                  name,
                                                                  disabled,
                                                                }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <>
      <Field isInvalid={meta.touched && meta.error} name={name}>
        {() => (
          <div
            style={disabled ? {pointerEvents: "none"} : {}}
            onClick={() => {
              helpers.setValue(!field.value);
              changeFn();
            }}
          >
            {field.value && (
              <img
                src={"/Random.svg"}
                alt={"success Shuffle"}
                className={"ebd-shuffle-btn"}
              />
            )}
            {!field.value && (
              <img
                src={"/Not_Random.svg"}
                alt={"success Shuffle"}
                className={"ebd-shuffle-btn"}
              />
            )}
          </div>
        )}
      </Field>
      <ErrorComponent fieldName={name}/>
    </>
  );
};

export default ShuffleRadioButton;
