import { Route } from "react-router-dom";
import LoginScreen from "./screens/auth/LoginScreen";
import LogoutScreen from "./screens/auth/LogoutScreen";
import Error404Screen from "./screens/errors/Error404Screen";
import HomeScreen from "./screens/home/HomeScreen";
import Error403Screen from "./screens/errors/Error403Screen";
import Error401Screen from "./screens/errors/Error401Screen";
import Error500Screen from "./screens/errors/Error500Screen";
import ErrorNoAccountScreen from "./screens/errors/ErrorNoAccount";

export const publicAreaRoutes = (
  <>
    <Route path="/">
      <Route index={true} element={<HomeScreen />} />
      <Route path="auth">
        <Route path="login" element={<LoginScreen />} />
        <Route path="logout" element={<LogoutScreen />} />
      </Route>
      <Route path="errors">
        <Route path="not-found" element={<Error404Screen />} />
        <Route path="forbidden" element={<Error403Screen />} />
        <Route path="authentication-required" element={<Error401Screen />} />
        <Route path="internal-server-error" element={<Error500Screen />} />
        <Route path="no-account" element={<ErrorNoAccountScreen />} />
      </Route>
    </Route>
    <Route path="*" element={<Error404Screen />} />
  </>
);
