import "primeflex/primeflex.css";

import "primeicons/primeicons.css";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useEffect, useState } from "react";
import "../../styles/tableStyles.css";
const productsAsJson = require("../services/products.json");

export const FigmaTable = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "country.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    balance: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });

  useEffect(() => {
    setProducts(productsAsJson.data);
  }, []);

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h5 className="m-0">From Courses</h5>
        <span className="p-input-icon-left d-flex justify-content-between w-25">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            aria-label="Server"
          />
          <i className="pi pi-search d-block w-100" />
        </span>

        <div>
          <i className="pi pi-chevron-down"></i>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <div>
      <div className="card">
        <DataTable
          size="small"
          value={products}
          filters={filters}
          globalFilterFields={[
            "name",
            "country.name",
            "representative.name",
            "balance",
            "status",
          ]}
          emptyMessage="No customers found."
          filterDisplay="menu"
          header={header}
          responsiveLayout="scroll"
        >
          <Column className="font-bold" field="name" header="Name" />
          <Column field="description" header="Description" />
          <Column field="seed" header="Seed" />
          <Column field="Created_On" header="Created On" />
          <Column field="Modified_On" header="Modified On" />
          <Column className="font-bold" field="Where" header="Where" />
          <Column className="font-bold" field=" " header="" />
        </DataTable>
      </div>
    </div>
  );
};
