import { inject, observer } from "mobx-react";
import { LayoutProps } from "./Layouts.types";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = inject("authorizationService")(
  ({ children, roles, authorizationService }: any) => {
    const element = authorizationService.hasPermission(roles) ? (
      children
    ) : (
      <Navigate to="/errors/forbidden" />
    );
    return (
      <>
        <AuthenticatedTemplate>{element}</AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Navigate to="/errors/forbidden" />
        </UnauthenticatedTemplate>
      </>
    );
  }
);

export const LayoutBase: React.FC<LayoutProps> = observer(
  (props: LayoutProps) => (
    <>
      {props.header}
      {props.children}
      {props.footer}
    </>
  )
);
