import React from "react";

const Add = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.914 1.111H3.086c-1.09 0-1.975.885-1.975 1.976v13.827c0 1.09.884 1.975 1.975 1.975h13.828c1.09 0 1.975-.884 1.975-1.975V3.087c0-1.091-.884-1.976-1.975-1.976zM10 5.556v8.889M5.556 10h8.888"
      ></path>
    </svg>
  );
};

export default Add;
