import { NavigateFunction } from 'react-router-dom';
import { mergeProps } from '@dirootie-extensions/javascript';
import _ from 'lodash';
import { IAction } from '../Actions.types';
import { CrudActionTypeList } from '../crud/Crud.types';

export const FormActionTypeList = [
  ...CrudActionTypeList,
  'FORM_CREATE_NEW',
  'FORM_CREATE_NEW_SHOW',
  'FORM_EDIT',
  'FORM_EDIT_SHOW',
  'FORM_VIEW',
  'FORM_VIEW_SHOW',
] as const;

export type FormActionType = typeof FormActionTypeList[number];

export interface IFormActionOptions<TType = any> {
  navigateTo?: string;
  navigator?: NavigateFunction;
  schema?: any;
  empty?: TType;
  path?: string;
}

export interface IFormAction<
  TData = any,
  TOptions extends IFormActionOptions = any
> extends IAction<FormActionType, TData, TOptions> {}
