import {InputSingleDatePicker, InputText, InputTextArea, SelectParameterType,} from "@components";
import {getParameterValidationSchema, Parameter, ParameterType,} from "@domain/building-components";
import {Form as FormikForm, Formik} from "formik";
import moment from "moment";
import React, {FC} from "react";
import {Col, Row,} from "taltech-styleguide";
import {createParameterTypeInput} from "./InputParameterType";

export type ParameterFormProps = {
  parameter: Parameter;
  onSubmit: (parameter: Parameter) => void;
  onCancel: (evt: any) => void;
  onReset?: () => void;
  visualization?: {
    showModifiedDate: boolean,
    showCreatedDate: boolean
  }
};

export const ParameterForm: FC<ParameterFormProps> = ({...props}) => {
  const formattedDate = moment(props.parameter.modifiedDate).format('DD/MM/YYYY'); // Convert to DD/MM/YYYY format
  return (
    <>
      <Formik
        validationSchema={getParameterValidationSchema()}
        initialValues={props.parameter}
        onSubmit={(values) => {
          props.onSubmit(values);
        }}
      >
        {(formik) => {
          return (
            <FormikForm>
              <InputText id="name" label="Name" formState={formik as any}/>
              <SelectParameterType
                id="type"
                label="Type"
                formState={formik as any}
              />
              <>
                {createParameterTypeInput(
                  formik.getFieldMeta("type").value as ParameterType,
                  formik as any,
                  {id: "valueDefault", label: "Value"}
                )}
              </>
              <InputTextArea
                id="description"
                label="Description"
                formState={formik as any}
              />
              {props.visualization?.showModifiedDate &&
                <InputSingleDatePicker id={'modifiedDate'} formState={formik as any} label={'Modified date'}/>}
              {props.visualization?.showCreatedDate &&
                <InputSingleDatePicker label={'Created date'} id={'createdDate'} formState={formik as any}/>}
              <Row>
                <Col sm={2}></Col>
                <Col sm={4}>
                  {/*<ButtonGroup className="mb-0">*/}
                  {/*  <SaveFormBtn*/}
                  {/*    className="me-3"*/}
                  {/*    type="submit"*/}
                  {/*    formik={formik}*/}
                  {/*    onClick={(evt: any) => {*/}
                  {/*      formik.submitForm();*/}
                  {/*    }}*/}
                  {/*    text="Save"*/}
                  {/*  ></SaveFormBtn>*/}
                  {/*  <CancelBtn*/}
                  {/*    className="me-3"*/}
                  {/*    text="Cancel"*/}
                  {/*    variant={BUTTON_COLOR.PRIMARY}*/}
                  {/*    onClick={(evt: any) => {*/}
                  {/*      props.onCancel(evt);*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    Cancel*/}
                  {/*  </CancelBtn>*/}
                  {/*</ButtonGroup>*/}
                </Col>
              </Row>
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};
