import _ from "lodash";
import { FC } from "react";

export type CodeViewerProps = {
  json?: any;
  jsonString?: string;
}

export const CodeViewer: FC<CodeViewerProps> = (props) => {

  const formatJson = (props: any) => {
    return JSON.stringify(props, null, 4);;
  }

  let jsonFormated =  { error: 'No string or props where passed'};
  if(!_.isUndefined(props.json)){
    jsonFormated = props.json;
  }

  if(!_.isUndefined(props.jsonString)){
    jsonFormated = props.json;
  }

  return (
    <div>
      <pre>{formatJson(jsonFormated)}</pre>
    </div>
  );
};

export default CodeViewer;
