import {
  ElementDataForm,
  Page,
  PageContentForm,
  createFormProperties,
  DisabledTabComponent,
} from "@components";
import React from "react";
import { RoofBuildingComponent } from "@domain";
import { entityStore } from "./RoofTypeIndexScreen";
import { useNavigate } from "react-router-dom";

const RoofTypeCreateScreen: React.FC<any> = () => {
  const navigator = useNavigate();
  const formProps = createFormProperties<Partial<RoofBuildingComponent>>(
    "CREATE",
    {
      onAction: (action) => {
        return Promise.resolve(entityStore.onAction(action));
      },
      schema: entityStore.options.schema,
      element: { ...entityStore.options.empty },
    },
    entityStore.options,
    navigator
  );

  return (
    <Page title="Create new roof type" showBreadcrumbs={true}>
      <div className={"container"}>
        <DisabledTabComponent
          elements={["Types", "Parameters"]}
          activeDisabled={0}
        />
        <PageContentForm
          form={{ Component: ElementDataForm, options: { ...formProps } }}
          mode="CREATE"
          dispatcher={{
            onAction: (action) => {
              return Promise.resolve(entityStore.onAction(action));
            },
          }}
        />
      </div>
    </Page>
  );
};

export default RoofTypeCreateScreen;
