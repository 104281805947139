import _ from "lodash";
import {makeAutoObservable} from "mobx";
import {
  BuildingParameter,
  FloorType,
  IWindowStructure,
  Materials,
  RoofType,
  Space,
  WallType,
  WindowsType
} from "./buildingTypes";

export class BuildingStore {
  floorTypes: FloorType[] = [
    {
      type: "Floor Types",
      id: "1",
      name: `Floor Type 1`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Floor Types",
      id: "2",
      name: `Floor Type 2`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Floor Types",
      id: "3",
      name: `Floor Type 3`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Floor Types",
      id: "4",
      name: `Floor Type 4`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Floor Types",
      id: "5",
      name: `Floor Type 5`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
  ];
  windowsTypes: WindowsType[] = [
    {
      type: "Window Types",
      id: "1",
      name: `Window Type 1`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure:
        {id: 0, maximumHeight: false, sillHeight: "200cm", WindowWidth: "150cm", windowStyle: 0, windowHeight: "150cm"},
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Window Types",
      id: "2",
      name: `Window Type 2`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure:
        {id: 0, maximumHeight: false, sillHeight: "200cm", WindowWidth: "150cm", windowStyle: 0, windowHeight: "150cm"},
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Window Types",
      id: "3",
      name: `Window Type 3`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure:
        {id: 0, maximumHeight: false, sillHeight: "200cm", WindowWidth: "150cm", windowStyle: 0, windowHeight: "150cm"},
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Window Types",
      id: "4",
      name: `Window Type 4`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        id: 0,
        maximumHeight: false,
        sillHeight: "200cm",
        WindowWidth: "150cm",
        windowStyle: 0,
        windowHeight: "150cm"
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Window Types",
      id: "5",
      name: `Window Type 5`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        id: 0,
        maximumHeight: false,
        sillHeight: "200cm",
        WindowWidth: "150cm",
        windowStyle: 0,
        windowHeight: "150cm"
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
  ];
  roofTypes: RoofType[] = [
    {
      type: "Roof Types",
      id: "1",
      name: `Roof Type 1`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Roof Types",
      id: "2",
      name: `Roof Type 2`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Roof Types",
      id: "3",
      name: `Roof Type 3`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Roof Types",
      id: "4",
      name: `Roof Type 4`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Roof Types",
      id: "5",
      name: `Roof Type 5`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
  ];
  wallTypes: WallType[] = [
    {
      type: "Wall Types",
      id: "1",
      name: `Wall Type 1`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Wall Types",
      id: "2",
      name: `Wall Type 2`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Wall Types",
      id: "3",
      name: `Wall Type 3`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Wall Types",
      id: "4",
      name: `Wall Type 4`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Wall Types",
      id: "5",
      name: `Wall Type 5`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
  ]
  buildingParameter: BuildingParameter[] = [
    {
      type: "Building Parameters",
      id: "1",
      name: `Building Parameter 1`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Building Parameters",
      id: "2",
      name: `Building Parameter 2`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Building Parameters",
      id: "3",
      name: `Building Parameter 3`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Building Parameters",
      id: "4",
      name: `Building Parameter 4`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
    {
      type: "Building Parameters",
      id: "5",
      name: `Building Parameter 5`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "Parameter 1",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    },
  ]
  materials: Materials[] = [
    {
      name: "Air Opening",
      id: "0",
      class: "glass",
      createdOn: new Date(),
      modifiedOn: new Date(),
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ..."
    },
    {
      name: "Aluminum",
      id: "1",
      class: "unassigned",
      createdOn: new Date(),
      modifiedOn: new Date(),
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ..."
    },
    {
      name: "Concrete",
      id: "2",
      class: "metal",
      createdOn: new Date(),
      modifiedOn: new Date(),
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ..."
    },
    {
      name: "Copper",
      id: "3",
      class: "concrete",
      createdOn: new Date(),
      modifiedOn: new Date(),
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ..."
    },
    {
      name: "Glass",
      id: "4",
      class: "glass",
      createdOn: new Date(),
      modifiedOn: new Date(),
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ..."
    }
  ]
  private rooms = ["Cabinet", "Hall", "Office 1", "Office 2", "Toilet", "WC", "XRoom", "YRoom"];
  spaceFunctions: Space = {
    rooms: this.rooms,
    spaceFunction: [
      {
        name: "Parameter 1", type: "Text", isShuffle: false, shuffleMax: -0.2, shuffleMin: 0.2, rooms:
          this.rooms.map(el => {
            return {name: el, value: ""}
          })
      },
      {
        name: "Parameter 2", type: "Text", isShuffle: false, shuffleMax: -0.2, shuffleMin: 0.2, rooms:
          this.rooms.map(el => {
            return {name: el, value: ""}
          })
      },
      {
        name: "Parameter 3", type: "Text", isShuffle: false, shuffleMax: -0.2, shuffleMin: 0.2, rooms:
          this.rooms.map(el => {
            return {name: el, value: ""}
          })
      },
      {
        name: "Parameter 4", type: "Text", isShuffle: false, shuffleMax: -0.2, shuffleMin: 0.2, rooms:
          this.rooms.map(el => {
            return {name: el, value: ""}
          })
      }
    ]
  }

  constructor() {
    makeAutoObservable(this);
  }

  getElementType(elementName: "Wall" | "Floor" | "Roof" | "BuildingParameter") {
    switch (elementName) {
      case "Wall":
        return this.wallTypes;
      case "Floor":
        return this.floorTypes;
      case "Roof":
        return this.roofTypes;
      case "BuildingParameter":
        return this.buildingParameter;
    }
  }

  addNewSpaceFunction() {
    this.spaceFunctions.spaceFunction.push({
      name: `Parameter ${this.spaceFunctions.spaceFunction.length + 1}`,
      type: "Text",
      isShuffle: false,
      shuffleMax: -0.2,
      shuffleMin: 0.2, rooms:
        this.rooms.map(el => {
          return {name: el, value: 1}
        })
    })
  }

  addNewRoom() {
    this.spaceFunctions.rooms.push(`Room ${this.spaceFunctions.rooms.length + 1}`);
    this.spaceFunctions.spaceFunction.map(s => {
      s.rooms.push({name: `Room ${this.spaceFunctions.rooms.length + 1}`, value: 1})
    })
  }

  deleteSpaceFunction(deletedIndex: number) {
    const foundElement = this.spaceFunctions.spaceFunction.find((ele: any) => this.spaceFunctions.spaceFunction.indexOf(ele) === deletedIndex)
    if (foundElement) {
      const index = this.spaceFunctions.spaceFunction.indexOf(foundElement);
      if (index > -1) {
        this.spaceFunctions.spaceFunction.splice(index, 1);
      }
    }
  }

  getWindowElementByIndex(id: number) {
    return this.windowsTypes?.find(element => +element.id === id);
  }

  createNewElement = (elementName: "Wall" | "Floor" | "Roof" | "BuildingParameter") => {
    const elementType = this.getElementType(elementName);
    elementType!.push({
      type: `${elementName} Types`,
      id: `${elementType!.length + 1}`,
      name: `${elementName} Type ${elementType!.length + 1}`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ...",
      isInternal: 0,
      createdOn: new Date(),
      modifiedOn: new Date(),
      structure: {
        exterior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},],
        core: [{material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1}],
        interior: [
          {material: "Material 01", thickness: 1, function: "Finish 01", isInterior: true, id: 0},
          {material: "Material 02", thickness: 1, function: "Finish 02", isInterior: true, id: 1},]
      },
      TypeParameters: [{
        id: 0,
        name: "New Parameter",
        type: "Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
        isShuffle: true,
        shuffleMax: 0.2,
        shuffleMin: -0.2,
        value: 11.2
      }]
    })
  }

  deleteElement = (elementName: "Wall" | "Floor" | "Roof" | "BuildingParameter", id: string) => {
    const elementType = this.getElementType(elementName);
    const found = elementType!.find(element => element.id === id);

    if (found) {
      const index = elementType!.indexOf(found);
      if (index > -1) {
        elementType!.splice(index, 1);
      }
    }
  }

  getElementNameFromURL(history: any): { elementName: "Wall" | "Floor" | "Roof" | "BuildingParameter", elementTypeNumber: string } {
    let nameArr = history.pathname.split('/');
    const [elementName, elementTypeNumber] = nameArr[nameArr.length - 1].split('-');
    return {
      elementName: elementName as any, elementTypeNumber
    }
  }

  getElementByIndex(elementName: "Wall" | "Floor" | "Roof" | "BuildingParameter", id: string) {
    const elementType = this.getElementType(elementName);
    return elementType?.find(element => +element.id === +id);
  }

  addNewStructureElement(elementName: "Wall" | "Floor" | "Roof" | "BuildingParameter", id: string, type: "exterior" | "interior" | "core") {
    const ele = this.getElementByIndex(elementName, id);
    ele && ele.structure[type].push({
      id: 0,
      material: "Material 01",
      thickness: 1,
      function: "Finish 01",
      isInterior: true
    })
  }

  addNewMaterials() {
    this.materials.push({
      name: "New Material",
      id: `${this.materials.length + 1}`,
      class: "glass",
      createdOn: new Date(),
      modifiedOn: new Date(),
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing ..."
    })
  }

  deleteMaterials(deletedIndex: number) {
    const foundElement = this.materials.find((ele: any) => this.materials.indexOf(ele) === deletedIndex)
    if (foundElement) {
      const index = this.materials.indexOf(foundElement);
      if (index > -1) {
        this.materials.splice(index, 1);
      }
    }
  }

  deleteStructureElement(elementName: "Wall" | "Floor" | "Roof" | "BuildingParameter", id: string, structureIndex: number, type: "exterior" | "interior" | "core") {
    const found = this.getElementByIndex(elementName, id);
    const structureFound = found!.structure[type].find((ele: any) => found!.structure[type].indexOf(ele) === structureIndex)
    if (structureFound) {
      const index = found!.structure[type].indexOf(structureFound);
      console.log(index)
      if (index > -1) {
        found!.structure[type].splice(index, 1);
      }
    }
  }

  addNewTypeParameter(elementName: "Wall" | "Floor" | "Roof" | "BuildingParameter" | "Window", id: string) {
    const ele = elementName === "Window" ? this.getWindowElementByIndex(+id) : this.getElementByIndex(elementName, id);
    ele && ele.TypeParameters.push({
      id: 0,
      name: `Parameter ${ele.TypeParameters.length + 1}`,
      type: "Text",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ...",
      isShuffle: true,
      shuffleMax: 0.2,
      shuffleMin: -0.2,
      value: 11.2
    })
  }

  deleteTypeParameter(elementName: "Wall" | "Floor" | "Roof" | "BuildingParameter", id: string, parameterIndex: number) {

    const found = this.getElementByIndex(elementName, id);
    const parameterFound = found!.TypeParameters.find(ele => found!.TypeParameters.indexOf(ele) === parameterIndex)

    if (parameterFound) {
      const index = found!.TypeParameters.indexOf(parameterFound);
      if (index > -1) {
        found!.TypeParameters.splice(index, 1);
      }
    }
  }

  setWindowStructure(id: string, values: any) {
    const element = this.getWindowElementByIndex(+id);

    const WindowWidth: any = _.filter(values, (v, k) => _.includes(k, 'WindowWidth'));
    const maximumHeight: any = _.filter(values, (v, k) => _.includes(k, 'maximumHeight'));
    const sillHeight: any = _.filter(values, (v, k) => _.includes(k, 'sillHeight'));
    const windowHeight: any = _.filter(values, (v, k) => _.includes(k, 'windowHeight'));
    const windowStyle: any = _.filter(values, (v, k) => _.includes(k, 'windowStyle'));

    const typeParameter: any = _.filter(values, (v, k) => _.includes(k, 'typeParameter'));
    const shuffleMin: any = _.filter(values, (v, k) => _.includes(k, 'shuffleMin'));
    const shuffle: any = _.filter(values, (v, k) => _.includes(k, 'shuffleBtn'));
    const shuffleMax: any = _.filter(values, (v, k) => _.includes(k, 'shuffleMax'));
    const isInternal: any = _.filter(values, (v, k) => _.includes(k, 'isInteriorToggle'));
    const name: any = _.filter(values, (v, k) => _.includes(k, 'componentTypeName'));
    const val: any = _.filter(values, (v, k) => _.includes(k, 'parameterValue'));
    const description: any = _.filter(values, (v, k) => _.includes(k, 'parameterDescription'));

    let newStructure: IWindowStructure = {windowHeight, WindowWidth, id: 0, maximumHeight, windowStyle, sillHeight};
    let newParameters = typeParameter.map((id: string, index: number) => {
      return {
        id: index + 1,
        name: element!.TypeParameters[index].name,
        type: typeParameter[index],
        description: description[index],
        isShuffle: shuffle[index],
        shuffleMax: shuffleMax[index],
        shuffleMin: shuffleMin[index],
        value: val[index]
      };
    });

    element!.structure = newStructure;
    element!.TypeParameters = [...newParameters];
    element!.isInternal = isInternal[0];
    element!.name = name[0];
  }

  setStructureElement(elementName: "Wall" | "Floor" | "Roof" | "BuildingParameter", id: string, values: any) {
    const element = this.getElementByIndex(elementName, id);
    const exteriorFirstMaterial: any = _.filter(values, (v, k) => _.includes(k, 'exteriorFirstMaterial'));
    const exteriorFunctionMaterial: any = _.filter(values, (v, k) => _.includes(k, 'exteriorSecondMaterial'));
    const exteriorThickness: any = _.filter(values, (v, k) => _.includes(k, 'exteriorThickness'));

    const coreFirstMaterial: any = _.filter(values, (v, k) => _.includes(k, 'coreFirstMaterial'));
    const coreFunctionMaterial: any = _.filter(values, (v, k) => _.includes(k, 'coreSecondMaterial'));
    const coreThickness: any = _.filter(values, (v, k) => _.includes(k, 'coreThickness'));

    const interiorFirstMaterial: any = _.filter(values, (v, k) => _.includes(k, 'interiorFirstMaterial'));
    const interiorFunctionMaterial: any = _.filter(values, (v, k) => _.includes(k, 'interiorSecondMaterial'));
    const interiorThickness: any = _.filter(values, (v, k) => _.includes(k, 'interiorThickness'));

    const typeParameter: any = _.filter(values, (v, k) => _.includes(k, 'typeParameter'));
    const shuffleMin: any = _.filter(values, (v, k) => _.includes(k, 'shuffleMin'));
    const shuffle: any = _.filter(values, (v, k) => _.includes(k, 'shuffleBtn'));
    const shuffleMax: any = _.filter(values, (v, k) => _.includes(k, 'shuffleMax'));
    const isInternal: any = _.filter(values, (v, k) => _.includes(k, 'isInteriorToggle'));
    const name: any = _.filter(values, (v, k) => _.includes(k, 'componentTypeName'));
    const val: any = _.filter(values, (v, k) => _.includes(k, 'parameterValue'));
    const description: any = _.filter(values, (v, k) => _.includes(k, 'parameterDescription'));
    let exteriorStructure = exteriorFirstMaterial.map((id: string, index_value: number) => {
      return {
        id: index_value,
        material: exteriorFirstMaterial[index_value],
        function: exteriorFunctionMaterial[index_value],
        thickness: exteriorThickness[index_value],
        isInterior: true
      };
    });
    let coreStructure = coreFirstMaterial.map((id: string, index_value: number) => {
      return {
        id: index_value,
        material: coreFirstMaterial[index_value],
        function: coreFunctionMaterial[index_value],
        thickness: coreThickness[index_value],
        isInterior: true
      };
    });
    let interiorStructure = interiorFirstMaterial.map((id: string, index_value: number) => {
      return {
        id: index_value,
        material: interiorFirstMaterial[index_value],
        function: interiorFunctionMaterial[index_value],
        thickness: interiorThickness[index_value],
        isInterior: true
      };
    });
    let newParameters = typeParameter.map((id: string, index: number) => {
      return {
        id: index + 1,
        name: element!.TypeParameters[index].name,
        type: typeParameter[index],
        description: description[index],
        isShuffle: shuffle[index],
        shuffleMax: shuffleMax[index],
        shuffleMin: shuffleMin[index],
        value: val[index]
      };
    });
    element!.structure = {exterior: exteriorStructure, core: coreStructure, interior: interiorStructure};
    element!.TypeParameters = [...newParameters];
    element!.isInternal = isInternal[0];
    element!.name = name[0]
  }

  setMaterials(values: any) {
    const materials: any = _.filter(values, (v, k) => _.includes(k, 'material'));
    let materialStructure = materials.map((el: any, index_value: number) => {
      return {
        name: `${this.materials[index_value].name}`,
        id: `${this.materials[index_value].id}`,
        class: `${materials[index_value]}`,
        createdOn: new Date(),
        modifiedOn: new Date(),
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing ..."
      };
    });
    this.materials = [...materialStructure]
  }

  setSpaceFunction(values: any) {
    const parameterName: any = _.filter(values, (v, k) => _.includes(k, `parameterName`));
    const parameterType: any = _.filter(values, (v, k) => _.includes(k, `parameterType`));
    const isShuffle: any = _.filter(values, (v, k) => _.includes(k, `shuffle_`));
    const minimumShuffle: any = _.filter(values, (v, k) => _.includes(k, `minimum_`));
    const maximumShuffle: any = _.filter(values, (v, k) => _.includes(k, `maximum_`));

    let rooms: any;
    rooms = this.spaceFunctions.spaceFunction.map((s, firstIndex) => {
      return s.rooms.map((r, index) => {
        return _.filter(values, (v, k) => _.includes(k, `${this.spaceFunctions.spaceFunction[firstIndex].rooms[index].name}_${firstIndex}`));
      })
    })
    this.spaceFunctions = {
      rooms: this.rooms,
      spaceFunction: parameterName.map((el: any, index_value: number) => {
        return {
          name: parameterName[index_value],
          type: parameterType[index_value],
          isShuffle: isShuffle[index_value],
          shuffleMax: maximumShuffle[index_value],
          shuffleMin: minimumShuffle[index_value],
          rooms:
            this.rooms.map((el, index) => {
              return {name: el, value: rooms[index_value][index]}
            })
        }
      })
    }
  }
}
