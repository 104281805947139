import {InputRandom, InputSingleDatePicker, InputText, InputTextArea, SelectParameterType,} from "@components";
import {getParameterValidationSchema, ParameterDescriptor,} from "@domain/building-components";
import {Form as FormikForm, Formik} from "formik";
import React, {FC} from "react";

export type BuildingParameterFormProps = {
  element: ParameterDescriptor;
  onSubmit: (element: ParameterDescriptor) => void;
  onCancel: (evt: any) => void;
  onReset?: () => void;
  visualization?: {
    showModifiedDate: boolean,
    showCreatedDate: boolean
  }
};

export const BuildingParameterForm: FC<BuildingParameterFormProps> = ({
                                                                        ...props
                                                                      }) => {
  return (
    <>
      <Formik
        validationSchema={getParameterValidationSchema()}
        initialValues={props.element}
        onSubmit={(values) => {
          props.onSubmit(values);
        }}
      >
        {(formik) => {
          return (
            <FormikForm>
              <InputText id="name" label="Name" formState={formik as any}/>

              <SelectParameterType
                id="type"
                label="Type"
                formState={formik as any}
              />
              {(formik.getFieldMeta("type").value as string).toLowerCase() ===
              "number" ? (
                <InputRandom id="valueDefault" formState={formik as any}/>
              ) : (
                <InputText
                  id="valueDefault"
                  label="Value"
                  formState={formik as any}
                />
              )}
              <InputTextArea
                id="description"
                label="Description"
                formState={formik as any}
              />
              {props.visualization?.showModifiedDate &&
                <InputSingleDatePicker id={'modifiedDate'} formState={formik as any} label={'Modified date'}/>}
              {props.visualization?.showCreatedDate &&
                <InputSingleDatePicker label={'Created date'} id={'createdDate'} formState={formik as any}/>}
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};
