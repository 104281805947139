import { Entity } from '@dirootie-core/core-types';
import { IFormBuildingGenerator } from '../../components/building-configurations/FormBuildingGenerator';

export const buildingConfiguration: (IFormBuildingGenerator & Entity)[] = [
  {
    id: '01',
    modifiedDate: new Date(Date.now()),
    createdDate: new Date(Date.now()),
    firstStep: {
      config: {
        isFormDirty: false,
        stepNumber: 0,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      name: 'Configuration 01',
      description: 'Some description',
      buildingOrientation: 1,
      buildingType: 'Commercial',
      coordinates: '36.427,57.74',
      buildingHeightType: 1,
      FloorHeight: 0,
      floorHeightMax: '55',
      floorHeightMin: '55',
      orientationTotalValue: '260',
      includeSurroundings: true,
      measureTypes: 'Modular',
      moduleSize: 5,
      numberOfFloor: 4,
      orientationMax: '22',
      orientationMin: '22',
      surroundingRadius: 5,
      floorHeightTotalValue: '33',
      buildingParameter: 1,
    },

    secondStep: {
      config: {
        isFormDirty: false,
        stepNumber: 1,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      firstRawWidth: 4,
      firstRawNumberOfRooms: 4,
      secondRawWidth: 4,
      secondRowNumberOfRooms: 4,
    },

    thirdStep: {
      config: {
        isFormDirty: false,
        stepNumber: 2,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      R_1_1_SpaceFunction: '2',
      R_1_1_Length: 4,
      R_1_1_shuffle: true,
      R_1_1_ShuffleMin: -1,
      R_1_1_ShuffleMax: 2,
      R_1_1_VariableLength: 1,

      R_1_2_SpaceFunction: '2',
      R_1_2_Length: 4,
      R_1_2_shuffle: true,
      R_1_2_ShuffleMin: -1,
      R_1_2_ShuffleMax: 2,
      R_1_2_VariableLength: 1,

      R_1_3_SpaceFunction: '2',
      R_1_3_Length: 4,
      R_1_3_shuffle: true,
      R_1_3_ShuffleMin: -1,
      R_1_3_ShuffleMax: 2,
      R_1_3_VariableLength: 1,

      R_1_4_SpaceFunction: '2',
      R_1_4_Length: 4,
      R_1_4_shuffle: true,
      R_1_4_ShuffleMin: -1,
      R_1_4_ShuffleMax: 2,
      R_1_4_VariableLength: 1,

      R_2_1_SpaceFunction: '2',
      R_2_1_Length: 4,
      R_2_1_shuffle: true,
      R_2_1_ShuffleMin: -1,
      R_2_1_ShuffleMax: 2,
      R_2_1_VariableLength: 1,

      R_2_2_SpaceFunction: '2',
      R_2_2_Length: 4,
      R_2_2_shuffle: true,
      R_2_2_ShuffleMin: -1,
      R_2_2_ShuffleMax: 2,
      R_2_2_VariableLength: 1,

      R_2_3_SpaceFunction: '2',
      R_2_3_Length: 4,
      R_2_3_shuffle: true,
      R_2_3_ShuffleMin: -1,
      R_2_3_ShuffleMax: 2,
      R_2_3_VariableLength: 1,

      R_2_4_SpaceFunction: '2',
      R_2_4_Length: 4,
      R_2_4_shuffle: true,
      R_2_4_ShuffleMin: -1,
      R_2_4_ShuffleMax: 2,
      R_2_4_VariableLength: 0,
    },

    sixthStep: {
      selectedIndex: 2,
      config: {
        isFormDirty: false,
        stepNumber: 4,
        disabled: false,
        isValid: false,
        stepName: 'Define Building Structure and Window components',
        setNextStep: () => {},
      },
      elements: [
        {
          key: 'Interior Walls',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Exterior Walls',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Ground Floor',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Roof',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Windows',
          selectedElement: 0,
          shuffle: false,
        },
        // {
        //   key: 'Building Parameters',
        //   selectedElement: 0,
        //   values: [
        //     {
        //       name: 'Building Parameters Type 1',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 2',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 3',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 4',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 5',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 6',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //   ],
        //   shuffle: false,
        // },
      ],
      setSelectedIndex: () => {},
    },
  },
  {
    id: '02',
    modifiedDate: new Date(Date.now()),
    createdDate: new Date(Date.now()),
    firstStep: {
      config: {
        isFormDirty: false,
        stepNumber: 0,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      name: 'Configuration 02',
      description: 'Some description',
      buildingOrientation: 1,
      buildingType: 'Commercial',
      coordinates: '36.427,57.74',
      buildingHeightType: 1,
      FloorHeight: 0,
      floorHeightMax: '55',
      floorHeightMin: '55',
      orientationTotalValue: '260',
      includeSurroundings: true,
      measureTypes: 'Modular',
      moduleSize: 5,
      numberOfFloor: 4,
      orientationMax: '22',
      orientationMin: '22',
      surroundingRadius: 5,
      floorHeightTotalValue: '33',
      buildingParameter: 1,
    },

    secondStep: {
      config: {
        isFormDirty: false,
        stepNumber: 1,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      firstRawWidth: 4,
      firstRawNumberOfRooms: 4,
      secondRawWidth: 4,
      secondRowNumberOfRooms: 4,
    },

    thirdStep: {
      config: {
        isFormDirty: false,
        stepNumber: 2,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      R_1_1_SpaceFunction: '2',
      R_1_1_Length: 4,
      R_1_1_shuffle: true,
      R_1_1_ShuffleMin: -1,
      R_1_1_ShuffleMax: 2,

      R_1_2_SpaceFunction: '2',
      R_1_2_Length: 4,
      R_1_2_shuffle: true,
      R_1_2_ShuffleMin: -1,
      R_1_2_ShuffleMax: 2,

      R_1_3_SpaceFunction: '2',
      R_1_3_Length: 4,
      R_1_3_shuffle: true,
      R_1_3_ShuffleMin: -1,
      R_1_3_ShuffleMax: 2,

      R_1_4_SpaceFunction: '2',
      R_1_4_Length: 4,
      R_1_4_shuffle: true,
      R_1_4_ShuffleMin: -1,
      R_1_4_ShuffleMax: 2,

      R_2_1_SpaceFunction: '2',
      R_2_1_Length: 4,
      R_2_1_shuffle: true,
      R_2_1_ShuffleMin: -1,
      R_2_1_ShuffleMax: 2,

      R_2_2_SpaceFunction: '2',
      R_2_2_Length: 4,
      R_2_2_shuffle: true,
      R_2_2_ShuffleMin: -1,
      R_2_2_ShuffleMax: 2,

      R_2_3_SpaceFunction: '2',
      R_2_3_Length: 4,
      R_2_3_shuffle: true,
      R_2_3_ShuffleMin: -1,
      R_2_3_ShuffleMax: 2,

      R_2_4_SpaceFunction: '2',
      R_2_4_Length: 4,
      R_2_4_shuffle: true,
      R_2_4_ShuffleMin: -1,
      R_2_4_ShuffleMax: 2,
    },

    sixthStep: {
      selectedIndex: 2,
      config: {
        isFormDirty: false,
        stepNumber: 4,
        disabled: false,
        isValid: false,
        stepName: 'Define Building Structure and Window components',
        setNextStep: () => {},
      },
      elements: [
        {
          key: 'Interior Walls',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Exterior Walls',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Ground Floor',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Roof',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Windows',
          selectedElement: 0,
          shuffle: false,
        },
        // {
        //   key: 'Building Parameters',
        //   selectedElement: 0,
        //   values: [
        //     {
        //       name: 'Building Parameters Type 1',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 2',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 3',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 4',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 5',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 6',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //   ],
        //   shuffle: false,
        // },
      ],
      setSelectedIndex: () => {},
    },
  },
  {
    id: '03',
    modifiedDate: new Date(Date.now()),
    createdDate: new Date(Date.now()),
    firstStep: {
      config: {
        isFormDirty: false,
        stepNumber: 0,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      name: 'Configuration 03',
      description: 'Some description',
      buildingOrientation: 1,
      buildingType: 'Commercial',
      coordinates: '36.427,57.74',
      buildingHeightType: 1,
      FloorHeight: 0,
      floorHeightMax: '55',
      floorHeightMin: '55',
      orientationTotalValue: '260',
      includeSurroundings: true,
      measureTypes: 'Modular',
      moduleSize: 5,
      numberOfFloor: 4,
      orientationMax: '22',
      orientationMin: '22',
      surroundingRadius: 5,
      floorHeightTotalValue: '33',
      buildingParameter: 1,
    },

    secondStep: {
      config: {
        isFormDirty: false,
        stepNumber: 1,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      firstRawWidth: 4,
      firstRawNumberOfRooms: 4,
      secondRawWidth: 4,
      secondRowNumberOfRooms: 4,
    },

    thirdStep: {
      config: {
        isFormDirty: false,
        stepNumber: 2,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      R_1_1_SpaceFunction: '2',
      R_1_1_Length: 4,
      R_1_1_shuffle: true,
      R_1_1_ShuffleMin: -1,
      R_1_1_ShuffleMax: 2,

      R_1_2_SpaceFunction: '2',
      R_1_2_Length: 4,
      R_1_2_shuffle: true,
      R_1_2_ShuffleMin: -1,
      R_1_2_ShuffleMax: 2,

      R_1_3_SpaceFunction: '2',
      R_1_3_Length: 4,
      R_1_3_shuffle: true,
      R_1_3_ShuffleMin: -1,
      R_1_3_ShuffleMax: 2,

      R_1_4_SpaceFunction: '2',
      R_1_4_Length: 4,
      R_1_4_shuffle: true,
      R_1_4_ShuffleMin: -1,
      R_1_4_ShuffleMax: 2,

      R_2_1_SpaceFunction: '2',
      R_2_1_Length: 4,
      R_2_1_shuffle: true,
      R_2_1_ShuffleMin: -1,
      R_2_1_ShuffleMax: 2,

      R_2_2_SpaceFunction: '2',
      R_2_2_Length: 4,
      R_2_2_shuffle: true,
      R_2_2_ShuffleMin: -1,
      R_2_2_ShuffleMax: 2,

      R_2_3_SpaceFunction: '2',
      R_2_3_Length: 4,
      R_2_3_shuffle: true,
      R_2_3_ShuffleMin: -1,
      R_2_3_ShuffleMax: 2,

      R_2_4_SpaceFunction: '2',
      R_2_4_Length: 4,
      R_2_4_shuffle: true,
      R_2_4_ShuffleMin: -1,
      R_2_4_ShuffleMax: 2,
    },

    sixthStep: {
      selectedIndex: 2,
      config: {
        isFormDirty: false,
        stepNumber: 4,
        disabled: false,
        isValid: false,
        stepName: 'Define Building Structure and Window components',
        setNextStep: () => {},
      },
      elements: [
        {
          key: 'Interior Walls',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Exterior Walls',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Ground Floor',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Roof',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Windows',
          selectedElement: 0,
          shuffle: false,
        },
        // {
        //   key: 'Building Parameters',
        //   selectedElement: 0,
        //   values: [
        //     {
        //       name: 'Building Parameters Type 1',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 2',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 3',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 4',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 5',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 6',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //   ],
        //   shuffle: false,
        // },
      ],
      setSelectedIndex: () => {},
    },
  },
  {
    id: '04',
    modifiedDate: new Date(Date.now()),
    createdDate: new Date(Date.now()),
    firstStep: {
      config: {
        isFormDirty: false,
        stepNumber: 0,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      name: 'Configuration 04',
      description: 'Some description',
      buildingOrientation: 1,
      buildingType: 'Commercial',
      coordinates: '36.427,57.74',
      buildingHeightType: 1,
      FloorHeight: 0,
      floorHeightMax: '55',
      floorHeightMin: '55',
      orientationTotalValue: '260',
      includeSurroundings: true,
      measureTypes: 'Modular',
      moduleSize: 5,
      numberOfFloor: 4,
      orientationMax: '22',
      orientationMin: '22',
      surroundingRadius: 5,
      floorHeightTotalValue: '33',
      buildingParameter: 1,
    },

    secondStep: {
      config: {
        isFormDirty: false,
        stepNumber: 1,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      firstRawWidth: 4,
      firstRawNumberOfRooms: 4,
      secondRawWidth: 4,
      secondRowNumberOfRooms: 4,
    },

    thirdStep: {
      config: {
        isFormDirty: false,
        stepNumber: 2,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      R_1_1_SpaceFunction: '2',
      R_1_1_Length: 4,
      R_1_1_shuffle: true,
      R_1_1_ShuffleMin: -1,
      R_1_1_ShuffleMax: 2,

      R_1_2_SpaceFunction: '2',
      R_1_2_Length: 4,
      R_1_2_shuffle: true,
      R_1_2_ShuffleMin: -1,
      R_1_2_ShuffleMax: 2,

      R_1_3_SpaceFunction: '2',
      R_1_3_Length: 4,
      R_1_3_shuffle: true,
      R_1_3_ShuffleMin: -1,
      R_1_3_ShuffleMax: 2,

      R_1_4_SpaceFunction: '2',
      R_1_4_Length: 4,
      R_1_4_shuffle: true,
      R_1_4_ShuffleMin: -1,
      R_1_4_ShuffleMax: 2,

      R_2_1_SpaceFunction: '2',
      R_2_1_Length: 4,
      R_2_1_shuffle: true,
      R_2_1_ShuffleMin: -1,
      R_2_1_ShuffleMax: 2,

      R_2_2_SpaceFunction: '2',
      R_2_2_Length: 4,
      R_2_2_shuffle: true,
      R_2_2_ShuffleMin: -1,
      R_2_2_ShuffleMax: 2,

      R_2_3_SpaceFunction: '2',
      R_2_3_Length: 4,
      R_2_3_shuffle: true,
      R_2_3_ShuffleMin: -1,
      R_2_3_ShuffleMax: 2,

      R_2_4_SpaceFunction: '2',
      R_2_4_Length: 4,
      R_2_4_shuffle: true,
      R_2_4_ShuffleMin: -1,
      R_2_4_ShuffleMax: 2,
    },

    sixthStep: {
      selectedIndex: 2,
      config: {
        isFormDirty: false,
        stepNumber: 4,
        disabled: false,
        isValid: false,
        stepName: 'Define Building Structure and Window components',
        setNextStep: () => {},
      },
      elements: [
        {
          key: 'Interior Walls',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Exterior Walls',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Ground Floor',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Roof',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Windows',
          selectedElement: 0,
          shuffle: false,
        },
        // {
        //   key: 'Building Parameters',
        //   selectedElement: 0,
        //   values: [
        //     {
        //       name: 'Building Parameters Type 1',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 2',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 3',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 4',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 5',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 6',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //   ],
        //   shuffle: false,
        // },
      ],
      setSelectedIndex: () => {},
    },
  },
  {
    id: '05',
    modifiedDate: new Date(Date.now()),
    createdDate: new Date(Date.now()),
    firstStep: {
      config: {
        isFormDirty: false,
        stepNumber: 0,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      name: 'Configuration 05',
      description: 'Some description',
      buildingOrientation: 1,
      buildingType: 'Commercial',
      coordinates: '36.427,57.74',
      buildingHeightType: 1,
      FloorHeight: 0,
      floorHeightMax: '55',
      floorHeightMin: '55',
      orientationTotalValue: '260',
      includeSurroundings: true,
      measureTypes: 'Modular',
      moduleSize: 5,
      numberOfFloor: 4,
      orientationMax: '22',
      orientationMin: '22',
      surroundingRadius: 5,
      floorHeightTotalValue: '33',
      buildingParameter: 1,
    },

    secondStep: {
      config: {
        isFormDirty: false,
        stepNumber: 1,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      firstRawWidth: 4,
      firstRawNumberOfRooms: 4,
      secondRawWidth: 4,
      secondRowNumberOfRooms: 4,
    },

    thirdStep: {
      config: {
        isFormDirty: false,
        stepNumber: 2,
        disabled: false,
        isValid: false,
        stepName: '',
        setNextStep: () => {},
      },
      R_1_1_SpaceFunction: '2',
      R_1_1_Length: 4,
      R_1_1_shuffle: true,
      R_1_1_ShuffleMin: -1,
      R_1_1_ShuffleMax: 2,

      R_1_2_SpaceFunction: '2',
      R_1_2_Length: 4,
      R_1_2_shuffle: true,
      R_1_2_ShuffleMin: -1,
      R_1_2_ShuffleMax: 2,

      R_1_3_SpaceFunction: '2',
      R_1_3_Length: 4,
      R_1_3_shuffle: true,
      R_1_3_ShuffleMin: -1,
      R_1_3_ShuffleMax: 2,

      R_1_4_SpaceFunction: '2',
      R_1_4_Length: 4,
      R_1_4_shuffle: true,
      R_1_4_ShuffleMin: -1,
      R_1_4_ShuffleMax: 2,

      R_2_1_SpaceFunction: '2',
      R_2_1_Length: 4,
      R_2_1_shuffle: true,
      R_2_1_ShuffleMin: -1,
      R_2_1_ShuffleMax: 2,

      R_2_2_SpaceFunction: '2',
      R_2_2_Length: 4,
      R_2_2_shuffle: true,
      R_2_2_ShuffleMin: -1,
      R_2_2_ShuffleMax: 2,

      R_2_3_SpaceFunction: '2',
      R_2_3_Length: 4,
      R_2_3_shuffle: true,
      R_2_3_ShuffleMin: -1,
      R_2_3_ShuffleMax: 2,

      R_2_4_SpaceFunction: '2',
      R_2_4_Length: 4,
      R_2_4_shuffle: true,
      R_2_4_ShuffleMin: -1,
      R_2_4_ShuffleMax: 2,
    },

    sixthStep: {
      selectedIndex: 2,
      config: {
        isFormDirty: false,
        stepNumber: 4,
        disabled: false,
        isValid: false,
        stepName: 'Define Building Structure and Window components',
        setNextStep: () => {},
      },
      elements: [
        {
          key: 'Interior Walls',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Exterior Walls',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Ground Floor',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Roof',
          selectedElement: 0,
          shuffle: false,
        },
        {
          key: 'Windows',
          selectedElement: 0,
          shuffle: false,
        },
        // {
        //   key: 'Building Parameters',
        //   selectedElement: 0,
        //   values: [
        //     {
        //       name: 'Building Parameters Type 1',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 2',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 3',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 4',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 5',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //     {
        //       name: 'Building Parameters Type 6',
        //       description:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
        //       isExternal: false,
        //       modifiedOn: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       createdDate: new Date().toLocaleDateString('en-us', {
        //         weekday: 'long',
        //         year: 'numeric',
        //         month: 'short',
        //         day: 'numeric',
        //       }),
        //       isSelected: false,
        //     },
        //   ],
        //   shuffle: false,
        // },
      ],
      setSelectedIndex: () => {},
    },
  },
];

export const buildingConfigurationTable: (Entity & {
  name: string;
  description: string;
})[] = [
  {
    id: '01',
    modifiedDate: new Date(Date.now()),
    createdDate: new Date(Date.now()),
    name: 'Building configuration 1',
    description:
      'Example of 6 floors building with wall to wall windows measured in relative lengths.',
  },
  {
    id: '02',
    modifiedDate: new Date(Date.now()),
    createdDate: new Date(Date.now()),
    name: 'Building configuration 2',
    description:
      'Example of 3 floors building with isolated windows measured in relative lengths and arranged in modules',
  },
  {
    id: '03',
    modifiedDate: new Date(Date.now()),
    createdDate: new Date(Date.now()),
    name: 'Building configuration 3',
    description:
      'Example of 10 floors building with wall to wall windows having maximum heights and absolute width.',
  },
  {
    id: '04',
    modifiedDate: new Date(Date.now()),
    createdDate: new Date(Date.now()),
    name: 'Building configuration 4',
    description: 'Building configuration 4 description',
  },
  {
    id: '05',
    modifiedDate: new Date(Date.now()),
    createdDate: new Date(Date.now()),
    name: 'Building configuration 5',
    description: 'Building configuration 5 description',
  },
];
