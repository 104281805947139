import { PageTabed, PageTabedProps } from "@components";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { useLocation, useParams } from "react-router-dom";
import { AssignmentsIndexScreen } from "../../../lecturers/screens/courses/assignments/AssignmentsIndexScreen";
import { StudentsIndexScreen } from "../../../lecturers/screens/courses/students/StudentsIndexScreen";
import { GroupsIndexScreen } from "../../../lecturers/screens/courses/groups/GroupsIndexScreen";
import { LecturersIndexScreen } from "../../../lecturers/screens/courses/lecturers/LecturersIndexScreen";
import { ResourcesIndexScreen } from "../../../lecturers/screens/courses/resources/ResourcesIndexScreen";
import { CoursesSummaryScreen } from "../../../lecturers/screens/courses/summary/CourseSummaryScreen";

const CourseDetailsScreen: React.FC<any> = inject("logger")(
  observer((props: any) => {
    let { courseId } = useParams();
    const location = useLocation();
    const segments = location.pathname.split("/");
    let firstUrlElement = "";
    if (segments[0] === "lecturers") firstUrlElement = "lecturers";
    else firstUrlElement = "students";

    props.logger.trace("[CourseDetailsScreen]", props, courseId);
    const newProps: PageTabedProps = {
      title: "Course details",
      pathBase: `/${firstUrlElement}/courses/` + courseId,
      tabs: [
        {
          label: "Summary",
          component: <CoursesSummaryScreen />,
          index: 0,
          path: "summary",
        },
        {
          label: "Lecturers",
          component: <LecturersIndexScreen />,
          index: 1,
          path: "lecturers",
        },
        {
          label: "Students",
          component: <StudentsIndexScreen />,
          index: 2,
          path: "students",
        },
        {
          label: "Groups",
          component: <GroupsIndexScreen />,
          index: 3,
          path: "groups",
        },
        {
          label: "Resources",
          component: <ResourcesIndexScreen />,
          index: 4,
          path: "resources",
        },
        {
          label: "Assignments",
          component: <AssignmentsIndexScreen />,
          index: 5,
          path: "assignments",
        },
      ],
    };
    return <PageTabed {...newProps}></PageTabed>;
  })
);

export default CourseDetailsScreen;
