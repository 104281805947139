import {Button, ButtonProps} from "@components";
import React, {FC} from "react";
import {Modal} from "taltech-styleguide";

type IConfirmationComponent = {
  modalState: boolean
  modalStateAction: () => void
  confirmationHeader: string,
  confirmationBody: string,
  closeBtn: ButtonProps,
  confirmationBtn: ButtonProps
}

export const ConfirmationComponent: FC<IConfirmationComponent> = ({
                                                                    ...props
                                                                  }) => {

  return (
    <div>
      <Modal show={props.modalState} onHide={props.modalStateAction}>
        <Modal.Header closeButton>{props.confirmationHeader}</Modal.Header>
        <Modal.Body>
          {props.confirmationBody}
        </Modal.Body>
        <Modal.Footer>
          <Button kind={props.confirmationBtn.kind} onClick={(e) => {
            props.modalStateAction();
            props.confirmationBtn.onClick && props.confirmationBtn.onClick(e)
          }}/>{" "}

          <Button kind={props.closeBtn.kind} onClick={(e) => {
            props.modalStateAction();
            props.closeBtn.onClick && props.closeBtn.onClick(e)
          }}/>

        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmationComponent;
