import {ToolbarCreate} from "@components/navigation";
import {useLocation} from "react-router-dom";
import {groupsData} from "./Groups.samples";
import {GroupsTable} from "./GroupsTable";

export const GroupsIndexScreen: React.FC<any> = () => {
  const location = useLocation();
  const segments = location.pathname.split("/");
  return (
    <>
      <ToolbarCreate disabled={segments[1] === "students"}/>

      <GroupsTable
        isEditable={segments[1] === "lecturers"}
        elements={groupsData}
      />
    </>
  );
};
