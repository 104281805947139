import { defaultProps, TableTalTech } from "@components";
import { convertToTitleCase } from "@dirootie-extensions/javascript";
import { structureLayerFunctionMapper } from "@domain/building-components";
import { observer } from "mobx-react";

export const StructureLayerFunctionTable: React.FC<any> = observer(
  (props: { elements: any }) => {
    const columns = [
      {
        classes: "test-column",
        dataField: "hierarchy",
        text: "Hierarchy",
      },
      {
        classes: "test-column",
        dataField: "function",
        text: "Function",
      },
      {
        classes: "test-column",
        dataField: "type",
        text: "Type",
        formatter: (col: string) => convertToTitleCase(col),
      },
    ];
    const data = Array.from(structureLayerFunctionMapper.values());
    const prop: any = { ...defaultProps, data, columns };
    prop.pagination = undefined;
    return <TableTalTech tableProps={prop}></TableTalTech>;
  }
);
