import {
  colDisplayName,
  colEmail,
  defaultProps,
  TableTalTech2,
  ToolbarCreate,
} from "@components";
import { observer } from "mobx-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Initials } from "taltech-styleguide";

export const UsersAzureAdSearchTable: React.FC<any> = observer(
  (props: { elements: any }) => {
    const navigator = useNavigate();
    const [selected, setSelected] = useState<string[]>([]);
    const columns = [
      {
        classes: "ebd-initials-first",
        dataField: "pictureUrl",
        formatter: (col: any, row: any, index: any, extraData: any) => {
          return <Initials key={row.id} name={row.displayName} size="xs" />;
        },
        sort: false,
        text: "",
      },
      colDisplayName,
      {
        dataField: "firstName",
        text: "First name",
      },
      {
        dataField: "lastName",
        text: "Last name",
      },
      colEmail,
      {
        dataField: "memberId",
        text: "TalTech id",
      },
      {
        dataField: "id",
        text: "Azure id",
      },
      //colRole,
    ];
    const tableProps = {
      ...defaultProps,
      columns,
      selectRow: {
        mode: "checkbox",
        selected: selected,
        selectColumnPosition: "left",

        className: "ebd-initials-first",
      },
    } as any;
    return (
      <>
        <ToolbarCreate />
        <TableTalTech2
          source={{
            path: "users.azuread",
          }}
          table={tableProps}
        ></TableTalTech2>
      </>
    );
  }
);
