import React, {FC, useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";

type IFileUploadComponent = {
  id?: string,
  setFileUrl: (url: string) => void,
  setFileName: (name: string) => void
}

export const FileUploadComponent: FC<IFileUploadComponent> = ({...props}) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [base64String, setBase64String] = useState<string>('');

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0] || null;
    setSelectedImage(file);
    convertToBase64String(file);
  }, []);

  const convertToBase64String = (file: File | null) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          const base64String = arrayBufferToBase64(reader.result);
          setBase64String(base64String);
          props.setFileUrl(base64String);
          // acceptedFiles[0] && props.setFileName(acceptedFiles[0].name)
          console.log(base64String, acceptedFiles[0])
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      setBase64String('');
    }
  };

  const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };
  const {acceptedFiles, getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: onDrop,
    multiple: true,
    onDragOver: () => console.log("A")
  });
  const files = acceptedFiles.map(file => {
    props.setFileName(file.name)
    return <div className={'ebd-file-element'} key={file.name}>
      {file.name}
    </div>
  });
  return (
    <section className={isDragActive ? "ebd-active-drag" : "container ebd-file-container"}>


      <div {...getRootProps({className: 'dropzone disabled py-5 d-flex justify-content-center ebd-drop-area'})}>
        <input {...getInputProps()} />
        <p>Drag & drop some files here, or click to select files</p>
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
    </section>
  );
};

export default FileUploadComponent;
