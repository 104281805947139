import {observer} from "mobx-react";
import React, {FC} from "react";
import Add from "../../../../icons/Add";

type IBuildingTableHeader = {
  componentName?: "spaceFunction"
  headerValues: string[],
  addTo?: "interior" | "exterior" | "core",
  showAddNew: boolean,
  addNewFn: () => void
};

const BuildingTableHeader: FC<IBuildingTableHeader> = observer(({
                                                                  componentName,
                                                                  addNewFn,
                                                                  showAddNew,
                                                                  addTo,
                                                                  headerValues,
                                                                  ...props
                                                                }) => {
  return (
    <>
      <div className={'row-element'}>
        {componentName === "spaceFunction" &&
          <>
            <div className={'cell Parameter-header'}>Parameters Name</div>
            <div className={'cell Parameter-header'}>Parameters Type</div>
          </>
        }
        {headerValues.map((headerEl: string, index: number) =>
          <div className={'cell Parameter-header'} key={index}>{headerEl}</div>)}
        {showAddNew ? <div className={'d-flex justify-content-end cell ebd-icon'} onClick={() => addNewFn()}>
          <Add/>
        </div> : <div className={'cell'}></div>}
      </div>
    </>
  );
});

export default BuildingTableHeader;
