import {observer} from "mobx-react";
import React, {FC} from "react";
import {useStore} from "../../../domain/store";
import EmptyTable from "../../forms/EmptyTable";
import SpaceConfigurationTable from "./SpaceConfigurationTable";

const SpaceConfigurationForm: FC<{ [key: string]: any }> = observer(
  ({config, ...props}) => {
    const {buildingConfigurationFormStore} = useStore();

    return (
      <>
        {!config.disabled || buildingConfigurationFormStore.thirdStep.config.isFormDirty || buildingConfigurationFormStore.isReadOnly ? (
          <SpaceConfigurationTable
            rowOneRooms={buildingConfigurationFormStore.secondStep.firstRawNumberOfRooms}
            rowTwoRooms={buildingConfigurationFormStore.secondStep.secondRowNumberOfRooms}
            config={config}/>
        ) : (
          <EmptyTable headerElements={["Room no.", "Room space function", 'Length', 'Variable length']}/>)}
      </>
    );
  }
);

export default SpaceConfigurationForm;
