import { formatDate } from "@dirootie-extensions/javascript";
import {
  FormInputExtendedProps,
  isFormFieldValid,
} from "@dirootie-web/components-types";
import { Field } from "formik";
import { useEffect } from "react";
import { Form, Input } from "taltech-styleguide";
import FeedbackMessage from "./FeedbackMessage";

export const InputText = ({
  outsideValue,
  id,
  formState,
  disabled,
  ...props
}: FormInputExtendedProps & { outsideValue?: any }) => {
  if (props.formatter !== undefined) {
    formState.setFieldValue(
      id,
      props.formatter(formState.getFieldMeta(id).value)
    );
  }
  useEffect(() => {
    if (outsideValue) {
      formState.setFieldValue(id, outsideValue);
    }
  }, [outsideValue]);

  return (
    <Form.Group className={props.elementClass}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <Field
        name={id}
        component={Input}
        isInvalid={!isFormFieldValid(id, formState)}
        disabled={disabled}
      />
      <FeedbackMessage fieldId={id} formState={formState}></FeedbackMessage>
    </Form.Group>
  );
};

export const InputTextDate = ({
  id,
  disabled = true,
  formState,
  ...props
}: FormInputExtendedProps) => {
  return (
    <Form.Group className={props.elementClass}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <Field
        name={id}
        component={Input}
        disabled={disabled}
        value={formatDate(formState.getFieldMeta(id).value)}
      />
      <FeedbackMessage fieldId={id} formState={formState}></FeedbackMessage>
    </Form.Group>
  );
};
