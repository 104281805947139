import {
  FormBuildingGenerator,
  IFormBuildingGenerator,
  Page,
  ToolbarBack,
  ToolbarCreate,
} from "@components";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { emptyBuildingConfigurationProps } from "../../../../domain/building/EmptyBuildingConfigurationProps";
import { useStore } from "../../../../domain/store";

const ConfigurationCreateScreen: React.FC<any> = observer(() => {
  const { buildingConfigurationFormStore } = useStore();
  useEffect(() => {
    buildingConfigurationFormStore.isReadOnly = false;
    buildingConfigurationFormStore.firstStep =
      emptyBuildingConfigurationProps.firstStep;
    buildingConfigurationFormStore.secondStep =
      emptyBuildingConfigurationProps.secondStep;
    buildingConfigurationFormStore.thirdStep =
      emptyBuildingConfigurationProps.thirdStep;
    buildingConfigurationFormStore.sixthStep =
      emptyBuildingConfigurationProps.sixthStep;
  }, []);
  const buildingConfigurationProps: IFormBuildingGenerator = {
    firstStep: buildingConfigurationFormStore.firstStep,
    secondStep: buildingConfigurationFormStore.secondStep,
    sixthStep: buildingConfigurationFormStore.sixthStep,
    thirdStep: buildingConfigurationFormStore.thirdStep,
  };

  return (
    <Page title="Create new Building Configuration" showBreadcrumbs={true}>
      <ToolbarBack />
      <FormBuildingGenerator {...buildingConfigurationProps} />
    </Page>
  );
});

export default ConfigurationCreateScreen;
