import {PagedResult} from '@dirootie-core/core-types';
import {Assignment} from "./Assignment";

export const assignmentsData: PagedResult<Assignment> = {
  page: 1,
  size: 20,
  total: 13,
  items: [
    {
      id: 'id_course_1',
      number: 1,
      title: 'Assignment 01',
      description: 'string 1',
      state: 'ACTIVE',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      assignmentGroup: "Group 01"
    },
    {
      id: 'id_course_2',
      number: 2,
      title: 'name',
      description: 'string',
      state: 'ACTIVE',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      assignmentGroup: "Group 01"
    },
    {
      id: 'id_course_3',
      number: 3,
      title: 'name',
      description: 'string',
      state: 'ACTIVE',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
        {
          id: 'idl2',
          displayName: 'lect1',
          description: 'Blabla',
        },
        {
          id: 'idl3',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      assignmentGroup: "Group 01"
    },
    {
      id: 'id_course_4',
      number: 4,
      title: 'name',
      description: 'string',
      state: 'ACTIVE',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      assignmentGroup: "Group 01"
    },
    {
      id: 'id_course_5',
      number: 5,
      title: 'name',
      description: 'string',
      state: 'ACTIVE',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      assignmentGroup: "Group 01"
    },
    {
      id: 'id_course_6',
      number: 6,
      title: 'name',
      description: 'string',
      state: 'ARCHIVED',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      assignmentGroup: "Group 01"
    },
    {
      id: 'id_course_7',
      number: 7,
      title: 'name',
      description: 'string',
      state: 'ACTIVE',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      assignmentGroup: "Group 01"
    },
    {
      id: 'id_course_8',
      number: 8,
      title: 'name',
      description: 'string',
      state: 'ACTIVE',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      assignmentGroup: "Group 01"
    },
    {
      id: 'id_course_9',
      number: 9,
      title: 'name',
      description: 'string',
      state: 'ACTIVE',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      assignmentGroup: "Group 01"
    },
    {
      id: 'id_course_21',
      number: 21,
      title: 'name',
      description: 'string',
      state: 'ACTIVE',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      assignmentGroup: "Group 01"
    },
    {
      id: 'id_course_22',
      number: 22,
      title: 'name',
      description: 'string',
      state: 'COMPLETED',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      assignmentGroup: "Group 01"
    },
    {
      id: 'id_course_24',
      number: 24,
      title: 'name',
      description: 'string',
      state: 'ACTIVE',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      assignmentGroup: "Group 01"
    },
    {
      id: 'id_course_25',
      number: 25,
      title: 'name',
      description: 'string',
      state: 'ACTIVE',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      assignmentGroup: "Group 01"
    },
  ],
};
