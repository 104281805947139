import { apiEndpoints } from './ApiEndpoints';
import { ApplicationConfig } from './Configuration.types';
import localConfig from '../config.local.json';
import productionConfig from '../config.production.json';
import testingConfig from '../config.testing.json';

// const environment = process.env.REACT_APP_ENV || 'development';
// var configFile = require(`../../config.${environment}.json`);

const environment = process.env.REACT_APP_ENV ?? process.env.NODE_ENV;
let configFile =
environment === 'local'
    ? localConfig
    : environment === 'production'
    ? productionConfig
    : testingConfig;
console.log('Process env', process.env.NODE_ENV, process.env.REACT_APP_ENV);
export const configSettings: ApplicationConfig = {
  api: { ...configFile.api, endpoints: apiEndpoints },
  authentication: configFile.authentication,
  diStellar: configFile.diStellar,
};
