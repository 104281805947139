import { Page, WidgetShortcuts, WidgetListProps } from "@components";
import { Col, Row } from "taltech-styleguide";

const buildLecturersUrl = (path: string) =>
  `/lecturers/building-components/system/${path}`;

const dataLinks: WidgetListProps = {
  title: "Building data",
  items: [
    {
      name: "Structure layer functions",
      route: buildLecturersUrl("data/structure-layer-functions"),
      title: "Structure layer functions",
    },
    {
      name: "Materials",
      route: buildLecturersUrl("data/materials"),
      title: "Materials",
    },
    {
      name: "Building parameters",
      route: buildLecturersUrl("data/building-parameters"),
      title: "Building parameters",
    },
    {
      name: "Space functions",
      route: buildLecturersUrl("data/space-functions/types"),
      title: "Space functions",
    },
  ],
};

const structureComponentsLinks: WidgetListProps = {
  title: "Structure components",
  items: [
    {
      name: "Floors",
      route: buildLecturersUrl("components/floors/types"),
      title: "Floors",
    },
    {
      name: "Windows",
      route: buildLecturersUrl("components/windows/types"),
      title: "Windows",
    },
    {
      name: "Walls",
      route: buildLecturersUrl("components/walls/types"),
      title: "Walls",
    },
    {
      name: "Roofs",
      route: buildLecturersUrl("components/roofs/types"),
      title: "Roofs",
    },
  ],
};

const presetsLinks: WidgetListProps = {
  title: "Presets",
  items: [
    {
      name: "Building parameters presets",
      route: buildLecturersUrl("presets/building-parameters"),
      title: "Building parameters presets",
    },
    {
      name: "Space functions presets",
      route: buildLecturersUrl("presets/space-functions"),
      title: "Space functions presets",
    },
    {
      name: "Structure components presets",
      route: buildLecturersUrl("presets/components"),
      title: "Structure components presets",
    },
  ],
};

const BuildingComponentsIndexScreen: React.FC<any> = () => (
  <Page title="Building components">
    <Row>
      <Col>
        <WidgetShortcuts {...dataLinks} />
      </Col>
      <Col>
        <WidgetShortcuts {...structureComponentsLinks} />
      </Col>
      <Col>
        <WidgetShortcuts {...presetsLinks} />
      </Col>
    </Row>
  </Page>
);

export default BuildingComponentsIndexScreen;
