import { Col, Row } from "taltech-styleguide";
import { Page, WidgetShortcuts, WidgetListProps } from "@components";

const studentsShortcuts: WidgetListProps = {
  title: "Students shortcuts",
  subTitle: "Shortcuts for common operations",
  items: [
    {
      name: "Active courses",
      route: "/erros/not-found",
      title: "Active courses",
    },
    {
      name: "Ongoing assignments",
      route: "/erros/not-found",
      title: "Ongoing assignments",
    },
    {
      name: "Submit assignment",
      route: "/erros/not-found",
      title: "Submit assignment",
    },
    {
      name: "Create building configuration",
      route: "/erros/not-found",
      title: "Create building configuration",
    },
    {
      name: "Download Revit",
      route: "/erros/not-found",
      title: "Download Revit plugin",
    },
  ],
};

const DashboardScreen: React.FC<any> = () => (
  <Page title="Dashboard">
    <Row>
      <Col>
        <WidgetShortcuts {...studentsShortcuts}></WidgetShortcuts>
      </Col>
      <Col></Col>
    </Row>
    <Row>
      <Col></Col>
      <Col></Col>
    </Row>
  </Page>
);

export default DashboardScreen;
