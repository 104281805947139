import {Field, useField} from "formik";
import React, {FC} from "react";
import {Form, Input} from "taltech-styleguide";
import ErrorComponent from "./ErrorComponent";

interface ISelectComponent {
  customClassName?: string,
  size?: 'lg' | 'sm',
  options: any,
  type?: string,
  name: string,
  disabled?: boolean,
  label?: string,
  changeFn?: () => void
}

export const SelectComponent: FC<ISelectComponent> = ({
                                                        changeFn,
                                                        customClassName,
                                                        size,
                                                        options,
                                                        type,
                                                        name,
                                                        disabled,
                                                        label,
                                                        ...props
                                                      }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      <Field {...field} isInvalid={meta.touched && meta.error} name={name}>
        {() => (
          <Input
            onChangeCapture={() => {
              changeFn!();
            }}
            className={customClassName && customClassName}
            size={size && size}
            onChange={(e) => helpers.setValue(e.target.value)}
            disabled={disabled}
            value={options[options.indexOf(type) > -1 ? options.indexOf(type) : (options.indexOf(field.value) > -1 ? options.indexOf(field.value) : field.value)]}
            as="select">
            {options.map((el: string, index: number) => {
              return <option key={index}>{el}</option>;
            })}
          </Input>
        )}
      </Field>
      <ErrorComponent fieldName={name}/>
    </>
  );
};

export default SelectComponent;
