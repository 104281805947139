import {cols, createColActions, defaultProps, TableTalTech2,} from "@components";
import { UserProfile } from "@domain";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import { Initials } from "taltech-styleguide";

export const PeopleTable: React.FC<any> = observer(
  (props: { source: any; isEditable: boolean }) => {
    const navigator = useNavigate();
    const {source, isEditable, ...rest} = props;
    const columns = [
      cols.id(),
      {
        classes: "ebd-initials-first",
        dataField: "picture",
        formatter: (col: any, row: { id:string, user: UserProfile} & any, index: any, extraData: any) => {
          return (
            <>
              <Initials
                key={row.id}
                imageUrl={(row.picture === undefined ? "" : (row.picture as any).path) }
                name={row.displayName}
                size="xs"
              />
            </>
          );
        }},
      cols.name({dataField:"displayName"}),
      cols.email(),
      cols.createdDate(),
      {
        formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => createColActions(navigator, {
          showCopy: false,
          showDelete: props.isEditable,
          showEdit: false,
          showView: !props.isEditable,
          onEdit: () => navigator(row.id)
        }),
      },
    ];
    const tableProps = {...defaultProps, columns, ...rest} as any;
    return (
      <TableTalTech2 table={tableProps} source={props.source}></TableTalTech2>
    );
  }
);
