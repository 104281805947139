import { createRequiredMessage } from '@domain/validations';
import * as Yup from 'yup';

export const getPresetValidationSchema = () => {
  const presetValidationSchema = Yup.object<any>().shape({
    name: Yup.string().required(createRequiredMessage('name')),
    description: Yup.string().required(createRequiredMessage('description')),
    elementIds: Yup.array()
      .min(1, 'Must be at least one element.')
      .required(createRequiredMessage('elements')),
  });

  return presetValidationSchema;
};
