import {
  MaterialForm,
  Page,
  PageContentForm,
  createFormProperties,
} from "@components";
import { Material } from "@domain/building-components";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { entityStore } from "./MaterialIndexScreen";

const MaterialDetailsScreen: React.FC<any> = inject("materialStore")(
  observer((props) => {
    const [element, setElement] = useState<Material | undefined>(undefined);
    const { materialId } = useParams();
    const navigator = useNavigate();
    useEffect(() => {
      if (materialId !== undefined) {
        props.materialStore?.getMaterial(materialId!).then((x: any) => {
          setElement(x);
        });
      }
    });
    const formProps = createFormProperties(
      "EDIT",
      {
        onAction: (action) => {
          return Promise.resolve(entityStore.onAction(action));
        },
        schema: entityStore.options.schema,
        element: element,
      },
      entityStore.options,
      navigator
    );
    return (
      <Page title="Material details">
        <PageContentForm
          form={{ Component: MaterialForm, options: { ...formProps } }}
          mode="EDIT"
          dispatcher={{
            onAction: (action) => {
              return Promise.resolve(entityStore.onAction(action));
            },
          }}
        />
      </Page>
    );
  })
);

export default MaterialDetailsScreen;
