import { Page } from "@components";
import {CodeViewer} from "@components/testing";
import { application } from "../../../../configuration/Application.bootstrap";

export const ApplicationConfigurationScreen: React.FC<any> = () => {

  const applicationSettings = application.getSettings()?.authentication;
  applicationSettings!.options.auth = "HIDDEN for now" as any;
  return <Page title="Application configuration">
    <CodeViewer json={applicationSettings}/>
  </Page>
};

export default ApplicationConfigurationScreen;
