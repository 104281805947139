import {createMaxMessage, createMinMessage, createRequiredMessage} from "@domain/validations";
import * as Yup from 'yup';

export const getConfigurationValidationSchema = () => {
  return Yup.object<any>().shape({
    name: Yup.string().min(1, createMinMessage('name', 1)).max(100, createMaxMessage('name', 100)).required(createRequiredMessage('Name')),
    buildingType: Yup.string().required(createRequiredMessage('Building Type')),
    description: Yup.string().min(1, createMinMessage('description', 1)).max(100, createMaxMessage('description', 10000)).required(createRequiredMessage('description')),
    coordinates: Yup.string()
      .required(createRequiredMessage('Coordinates'))
      .matches(
        /^([-+]?\d{1,2}[.]\d+),\s*([-+]?\d{1,3}[.]\d+)$/,
        'Coordinates must be in format of number(latitude),number(longitude)'
      ),
    buildingOrientation: Yup.number().required(
      createRequiredMessage('Building orientation')
    ),
    orientationMin: Yup.number().when("buildingOrientation", {
      is: 1,
      then: Yup.number().required(createRequiredMessage('Building orientation')).test(
        'Is positive?',
        'ERROR: The number must be greater than 0!',
        (value) => +value! >= 0
      )
    }),
    orientationMax: Yup.number().when("buildingOrientation", {
      is: 1,
      then: Yup.number().required(createRequiredMessage('Building orientation')).test(
        'Is positive?',
        'ERROR: The number must be greater than 0!',
        (value) => +value! >= 0
      )
    }),
    orientationTotalValue: Yup.number().when("buildingOrientation", {
      is: 0,
      then: Yup.number().required(createRequiredMessage('Building orientation')).test(
        'Is positive?',
        'ERROR: The number must be greater than 0!',
        (value) => +value! >= 0
      )
    }),
    measureTypes: Yup.string().required(createRequiredMessage('Measure Types')),
    moduleSize: Yup.number().required(createRequiredMessage('Module Size')).test(
      'Is positive?',
      'ERROR: The number must be greater than 0!',
      (value) => value! > 0
    ),
    buildingHeightType: Yup.string().required(
      createRequiredMessage('Building height type')
    ),
    numberOfFloor: Yup.number().required(createRequiredMessage('Number of floors')).integer("Must be integer number").test(
      'Is positive?',
      'ERROR: The number must be greater than 0!',
      (value) => +value! > 0
    ),
    FloorHeight: Yup.number().required(createRequiredMessage('Floor height')),
    floorHeightMin: Yup.number().when("FloorHeight", {
      is: 1,
      then: Yup.number().required(createRequiredMessage('Floor height')).test(
        'Is positive?',
        'ERROR: The number must be greater than 0!',
        (value) => +value! > 0
      )
    }),
    floorHeightMax: Yup.number().when("FloorHeight", {
      is: 1,
      then: Yup.number().required(createRequiredMessage('Floor height')).test(
        'Is positive?',
        'ERROR: The number must be greater than 0!',
        (value) => +value! > 0
      )
    }),
    floorHeightTotalValue: Yup.number().when("FloorHeight", {
      is: 0,
      then: Yup.number().required(createRequiredMessage('Floor height')).test(
        'Is positive?',
        'ERROR: The number must be greater than 0!',
        (value) => +value! > 0
      )
    }),
  });
};
