import React, {FC} from "react";
import "../../styles/form.css"

interface IImageWithFade {
  url: string
}

export const ImageWithFade: FC<IImageWithFade> = ({...props}) => {

  return (
    <div style={{position: "relative"}}>
      <div className={'overlay-div'}>Edit</div>
      <img style={{width: "100%"}} src={props.url} alt={"alt"}/>
    </div>
  );
};

export default ImageWithFade;
