//import { Application } from '@dirootie-apps/apps-types';
import {
  HandlerDefinition,
  HandlerProps,
  HandlersBuildOptions,
  IAction,
  IActionHandler,
  IDispatcher,
} from './Actions.types';
import { activator } from '@dirootie-extensions/javascript';
import { DispatcherBase } from './DispatcherBase';
import { navigationHandlers } from './navigation/NavigationHandlers.definitions';
import { Application } from '@dirootie-apps/apps-types';
//import { Application } from '@dirootie-apps/apps-types';

export class HandlerFactory {
  private options: HandlersBuildOptions = {} as any;

  // constructor(private application: Application<any>) {
  //   this.options = {
  //     viewNavigator: application.getNavigator(),
  //     serviceFactory: application.getStores()['serviceFactory'],
  //     userNotificationService: application.getUserNotificationService(),
  //   };
  // }

  initialize(application: Application<any>) {
    this.options = {
      viewNavigator: application.getNavigator(),
      serviceFactory: application.getStores()['serviceFactory'],
      userNotificationService: application.getUserNotificationService(),
    };
  }

  createHandler(
    constructor: { new (props: any): IActionHandler },
    props: any = {}
  ): IActionHandler {
    const handler = activator(constructor, { ...this.options, ...props });
    return handler;
  }

  createHandlerProps(definition: HandlerDefinition): HandlerProps {
    const { action, handler, type, converter, options } = definition;
    let resultHandler: any;
    if (handler !== undefined) {
      resultHandler = handler;
    }
    if (type !== undefined) {
      resultHandler = this.createHandler(type as any, options);
    }
    return { handler: resultHandler!, action, converter };
  }

  executeAction(
    action: IAction,
    handler: IActionHandler | { new (props: any): IActionHandler },
    props: any = {}
  ) {
    const resultHandler =
      (handler as IActionHandler).handle !== undefined
        ? (handler as IActionHandler)
        : this.createHandler(handler as any, props);
    return resultHandler.handle(action);
  }

  createDispatcher(
    constructor: { new (props: any): IDispatcher },
    definitions: HandlerDefinition[]
  ): IDispatcher {
    const dispatcher = activator(constructor, this.options);
    definitions.forEach((x) => {
      const handler = this.createHandlerProps(x);
      dispatcher.registerHandler(handler);
    });
    return dispatcher;
  }

  createDispatcherBasic() {
    return this.createDispatcher(DispatcherBase, navigationHandlers);
  }
}
