import {SpaceFunctionParametersTable, ToolbarList} from "@components";
import React from "react";

const SpaceParametersScreen: React.FC<any> = () => (
  <div>
    <ToolbarList/>

    <SpaceFunctionParametersTable/>
  </div>
);

export default SpaceParametersScreen;
