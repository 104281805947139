import {
  Page,
  WidgetListProps,
  WidgetList,
} from "@components";

const testingShortcutsWidgetList: WidgetListProps = {
  title: "Areas shortcuts",
  items: [
    { name: "Administation", route: "/management", title: "Management area" },
    { name: "Lecturers", route: "/lecturers", title: "Lecturers area" },
    { name: "Students", route: "/students", title: "Students area" },
    { name: "Testing", route: "/testing", title: "Testing area" },
  ],
};

const DashboardScreen: React.FC<any> = () => (
  <Page title="Lecturers dashboard screen">
    <WidgetList {...testingShortcutsWidgetList}></WidgetList>
  </Page>
);

export default DashboardScreen;
