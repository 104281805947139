import {ActionButtonProps, Button} from '@components/buttons';
import {ToolbarProps, ToolbarWithActionsProps} from './Toolbars.types';

const buttonComponent = Button;

export const btnBackDefaultProps: ActionButtonProps = {
  action: {action: 'NAVIGATE_BACK', options: {navigateTo: -1}},
  kind: 'back',
  Component: buttonComponent,
};

export const btnCreateNewDefaultProps: ActionButtonProps = {
  action: {
    action: 'NAVIGATE_TO_NEW',
    options: {navigateTo: 'new', relative: true},
  },
  kind: 'create',
  Component: buttonComponent,
};

export const btnCreateDefaultProps: ActionButtonProps = {
  action: 'TOOLBAR_CREATE',
  kind: 'create',
  Component: buttonComponent,
};

export const btnSaveDefaultProps: ActionButtonProps = {
  action: 'TOOLBAR_SAVE',
  kind: 'save',
  Component: buttonComponent,
};

export const btnDeleteDefaultProps: ActionButtonProps = {
  class: "mx-3",
  activeClass: "mx-3",
  visibleClass: "mx-3",
  action: {action: 'TOOLBAR_DELETE', options: {navigateTo: -1}},
  kind: 'delete',
  Component: buttonComponent,
};

export const toolbarEditDefaultProps: ToolbarProps = {
  left: [{...btnSaveDefaultProps, action: 'SAVE'}],
  right: [
    {...btnDeleteDefaultProps, action: 'DELETE'},
    {...btnBackDefaultProps, action: 'BACK'},
  ],
};

export const toolbarExtendedDefaultProps: ToolbarWithActionsProps = {
  title: '',
  buttons: {
    btnCreateNew: btnCreateNewDefaultProps,
    btnCreate: btnCreateDefaultProps,
    btnSave: btnSaveDefaultProps,
    btnDelete: btnDeleteDefaultProps,
    btnBack: btnBackDefaultProps,
  },
  createVisible: false,
  createDisable: false,
  createNewVisible: false,
  createNewDisable: false,
  saveVisible: false,
  saveDisable: false,
  deleteVisible: false,
  deleteDisable: false,
  backVisible: true,
  backDisable: false,
};
