import { FC } from "react";
import {
  PageTest,
  PageTestProps,
  useOutputWindowService
} from "@components/testing";
import { WindowDetails } from "@components";

export const WindowComponentUitest: FC<any> = () => {

  const props: PageTestProps = {
    title: "Window component - uitest",
    demo: {
      outputWindow: true,
      Component: WindowDetails
  }};

  return (
    <PageTest {...props}/>
  );
};
