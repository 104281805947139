export const buttonKindList = [
  'accept',
  'add',
  'approve',
  'back',
  'cancel',
  'change',
  'clear',
  'close',
  'confirmation',
  'continue',
  'create',
  'create_form',
  'create_field',
  'create_new',
  'delete',
  'details',
  'download',
  'edit',
  'expand',
  'finish',
  'generate',
  'hide',
  'login',
  'logout',
  'new',
  'no',
  'preview',
  'refuse',
  'register',
  'reject',
  'reload',
  'replace',
  'resend',
  'resend_email',
  'save',
  'search',
  'send',
  'show',
  'show_more',
  'show_less',
  'start',
  'subscribe',
  'unsubscribe',
  'upload',
  'upload_replace',
  'view',
  'view_more',
  'view_less',
  'yes',
] as const;
export type ButtonKind = typeof buttonKindList[number];
