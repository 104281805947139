import { PresetDetails, PresetDetailsProps } from "@components";
import { presetsData, buildingParametersData } from "@domain";
import { useParams } from "react-router-dom";

const BuildingParametersPresetDetailsScreen: React.FC<any> = () => {
  const { presetId } = useParams();
  const props: PresetDetailsProps = {
    elementId: presetId!,
    getElement: (id: string) =>
      Promise.resolve(presetsData.filter((x) => x.id === id)[0]),
    getElements: () => Promise.resolve(buildingParametersData),
    title: "Building parameter preset details",
    onSave: (elem) => Promise.resolve(console.log("Saved", elem)),
  };
  return <PresetDetails {...props} />;
};

export default BuildingParametersPresetDetailsScreen;
