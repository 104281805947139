import { Logger } from './logger';
import { LoggingConfig } from './logging.config';
import { ILogger } from './logging.types';

export class ConsoleLogger extends Logger implements ILogger {
  configure(loggingConfig: LoggingConfig): void {}

  writeLog(message: string, ...data: any[]): void {
    console.log(message, ...data);
  }
}
