import {
  Page
} from "@components";

export const TablesScreen = () => {
  return (
    <Page title="Tables components testing">
    </Page>
  );
};
