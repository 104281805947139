import { ButtonProps, ButtonVisualType } from './Buttons.types';
import { ButtonKind, buttonKindList } from './Buttons.kind';
import _ from 'lodash';

type ButtonConfiguration = {
  visual: ButtonVisualType;
  visuals: { [key: string]: ButtonProps };
};

export const defaultButtonDefinitions: ButtonConfiguration = {
  visual: 'text',
  visuals: {
    text: {
      kind: undefined,
      visual: 'text',
      text: undefined,
    },
    icon: {
      kind: undefined,
      visual: 'text',
      text: undefined,
    },
    both: {
      kind: undefined,
      visual: 'text',
      text: undefined,
    },
  },
};

const primaryColor = 'primary';
const basicColor = 'basic';
const secondaryColor = 'secondary';
const warnColor = 'warn';
const successsColor = 'success';
const infoColor = 'info';

export const defaultButtonKindDefinition: {
  [key in ButtonKind]: ButtonProps;
} = {
  accept: { color: successsColor },
  add: { color: primaryColor },
  approve: { color: successsColor },
  back: { color: basicColor },
  cancel: { color: basicColor },
  change: { color: basicColor },
  clear: { color: basicColor },
  close: { color: basicColor },
  confirmation: { color: successsColor },
  continue: { color: successsColor },
  create: { color: primaryColor },
  create_field: { color: primaryColor },
  create_form: { color: primaryColor },
  create_new: { color: primaryColor },
  delete: { color: warnColor },
  details: { color: infoColor },
  download: { color: primaryColor },
  edit: { color: infoColor },
  expand: { color: infoColor },
  finish: { color: infoColor },
  generate: { color: infoColor },
  hide: { color: infoColor },
  login: { color: primaryColor },
  logout: { color: primaryColor },
  new: { color: primaryColor },
  no: { color: basicColor },
  preview: { color: infoColor },
  refuse: { color: warnColor },
  register: { color: primaryColor },
  reject: { color: warnColor },
  reload: { color: primaryColor },
  replace: { color: primaryColor },
  resend: { color: primaryColor },
  resend_email: { color: primaryColor },
  save: { color: primaryColor },
  search: { color: infoColor },
  send: { color: primaryColor },
  show: { color: infoColor },
  show_more: { color: infoColor },
  show_less: { color: infoColor },
  start: { color: successsColor },
  subscribe: { color: primaryColor },
  unsubscribe: { color: primaryColor },
  upload: { color: primaryColor },
  upload_replace: { color: primaryColor },
  view: { color: infoColor },
  view_more: { color: infoColor },
  view_less: { color: infoColor },
  yes: { color: basicColor },
};

buttonKindList.forEach(
  (x) => (defaultButtonKindDefinition[x].text = _.startCase(x))
);
