import {
  DisabledTabComponent,
  createFormProperties,
  Page,
  PageContentForm,
} from "@components";
import { Assignment } from "domain/courses/Assignment";
import { inject } from "mobx-react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AssignmentGeneralForm,
  IAssignmentGeneralForm,
} from "../../../../../components/assignments/AssignmentGeneralForm";
import { entityStore } from "./AssignmentsIndexScreen";

const emptyParameter: IAssignmentGeneralForm = {
  parameters: {
    id: "id_course_1",
    number: 1,
    title: "",
    description: "",
    state: "ACTIVE",
    lecturers: [
      {
        id: "idl1",
        displayName: "lect1",
        description: "Blabla",
      },
    ],
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    assignmentGroup: "Group 01",
  },
  actions: {
    onSubmit: (values) => console.log(values),
  },
  visualization: {
    isReadOnly: false,
    showCreatedDate: false,
    showModifiedDate: false,
  },
};

export const AssignmentCreateScreen: React.FC<any> = inject("coursesStore")(
  (props) => {
    const navigator = useNavigate();
    const { courseId } = useParams();
    const formProps = createFormProperties<Partial<Assignment>>(
      "CREATE",
      {
        onAction: (action) => {
          return Promise.resolve(entityStore.onAction(action));
        },
        schema: entityStore.options.schema,
        element: { ...entityStore.options.empty, courseId },
      },
      entityStore.options,
      navigator
    );
    return (
      <>
        <Page title="New Assignment" showBreadcrumbs={true}>
          <div className={"container"}>
            <DisabledTabComponent
              elements={[
                "Summary",
                "Lecturers",
                "Students",
                "Groups",
                "Resources",
                "Assignments",
              ]}
              activeDisabled={5}
            />
            <DisabledTabComponent
              size={"sm"}
              elements={[
                "General",
                "Resources",
                "Submissions",
                "Building Configurations",
              ]}
              activeIndex={0}
            />
            <PageContentForm
              form={{
                Component: AssignmentGeneralForm,
                options: { ...formProps },
              }}
              mode="CREATE"
              dispatcher={{
                onAction: (action) => {
                  return Promise.resolve(entityStore.onAction(action));
                },
              }}
            />
          </div>
        </Page>
      </>
    );
  }
);
