import { EntityKey, IEntity } from './../entities/entities.types';
import { IKeyedObject } from '@dirootie-extensions/typescript';

export type PagedResult<T> = {
  page: number;
  size: number;
  total: number;
  items: T[];
};

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type RepositoryQuery = {
  page?: number;
  size?: number;
  sorDirection?: SortDirection;
  sorBy?: string;
  [key: string]: any;
};

export interface IRepository<T, K> {
  create(element: T, options?: IKeyedObject<string>): Promise<T>;
  createOrSave(element: T, options?: IKeyedObject<string>): Promise<T>;

  save(element: T, options?: IKeyedObject<string>): Promise<T>;
  saveAll(elements: T[], options?: IKeyedObject<string>): Promise<T[]>;

  delete(element: T | K, options?: IKeyedObject<string>): Promise<void>;

  has(id: K, options?: IKeyedObject<string>): Promise<boolean>;
  getOne(id: K, options?: IKeyedObject<string>): Promise<T | undefined>;
  getAll(query?: RepositoryQuery): Promise<PagedResult<T>>;
}

export interface IInMemoryRepository<T extends IEntity<K>, K extends EntityKey>
  extends IRepository<T, K> {
  size: Promise<number>;
  clear(): Promise<void>;
}
