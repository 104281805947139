export const StructureLayerFunctionTypeList = [
  'STRUCTURE',
  'SUBSTRATE',
  'THERMAL/AIR',
  'FINISH1',
  'FINISH2',
  'MEMBRANE',
] as const;

export type StructureLayerFunctionType =
  typeof StructureLayerFunctionTypeList[number];

export const StructureLayerTypeList = ['EXTERIOR', 'INTERIOR', 'CORE'] as const;

export type StructureLayerType = typeof StructureLayerTypeList[number];
