import {FormInputExtendedProps, isFormFieldValid,} from "@dirootie-web/components-types";
import {Field} from "formik";
import {useState} from "react";
import {Form, Input} from "taltech-styleguide";
import FeedbackMessage from "./FeedbackMessage";

export type InputNumberProps = FormInputExtendedProps & {};


export const InputNumber = ({
                              id,
                              formState,
                              disabled,
                              ...props
                            }: InputNumberProps) => {
  const handleInputChange = (e: any) => {
    const input = e.target.value;
    const key = e.key;
    const decimalIndex = input.indexOf('.');
    console.log(input, key, decimalIndex)

    if (decimalIndex !== -1 && key === '.') {
      // If decimal point already exists and user tries to add another
      e.preventDefault();
    } else if (decimalIndex !== -1 && input.split('.')[1].length >= 2) {
      // If decimal point exists and there are already two decimal places
      e.preventDefault();
    } else if (key !== '.' && !/[\d.]/.test(key)) {
      // Allow only digits and a single decimal point
      e.preventDefault();
    }
  };
  const validateNumber = (value: any) => {
    if (!value) {
      return 'Field is required';
    }

    if (!/^\d+(\.\d{1,2})?$/.test(value)) {
      return 'Invalid number format. Please enter up to two decimal places.';
    }

    return undefined;
  };
  return (
    <Form.Group className={props.elementClass}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <Field
        validate={validateNumber}
        onKeyPress={handleInputChange}
        name={id}
        disabled={disabled}
        component={Input}
        isInvalid={!isFormFieldValid(id, formState)}
        type="number"
      />
      <FeedbackMessage fieldId={id} formState={formState}></FeedbackMessage>
    </Form.Group>
  );
};


export const InputIntegerNumber = ({
                                     value,
                                     step,
                                     id,
                                     formState,
                                     disabled,
                                     ...props
                                   }: InputNumberProps & { step?: number, value: string }) => {
  const [initialValue, setInitialValue] = useState(value)
  const handleInput = (event: any) => {
    const {value} = event.target;
    event.target.value = value.replace(/\D/g, '');
  };
  return (
    <Form.Group className={props.elementClass}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <Field
        onChange={(e: any) => setInitialValue(e.target.value)}
        value={initialValue}
        step={step}
        name={id}
        disabled={disabled}
        component={Input}
        onInput={handleInput}
        isInvalid={!isFormFieldValid(id, formState)}
        type="number"
      />
      <FeedbackMessage fieldId={id} formState={formState}></FeedbackMessage>
    </Form.Group>
  );
};
