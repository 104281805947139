import React from "react";


type IDisabledTabComponent = {
  elements: string[],
  activeDisabled?: number,
  activeIndex?: number,
  size?: "lg" | "sm"
}

export const DisabledTabComponent: React.FC<IDisabledTabComponent> = ({activeDisabled, activeIndex, ...props}) => {

  const fontSize = props.size === "sm" ? 14 : 24;
  const padding = props.size === "sm" ? "4px 12px" : "14px 24px";
  const lineHeight = props.size === "sm" ? "20px" : "36px"
  const displayClassName = (index: number, elementPosition: "first" | "last" | "middle") => {
    if (elementPosition === "first") return activeDisabled === index ? "ebd-types pl-0" :
      activeIndex === index ? "ebd-types-active pl-0" : "ebd-parameters pl-0"

    else if (elementPosition === "last") return activeDisabled === index ? "ebd-types pl-4" :
      activeIndex === index ? "ebd-types-active pl-4" : "ebd-parameters"

    else return activeDisabled === index ?
        "ebd-types" : activeIndex === index ?
          "ebd-types-active" : "ebd-parameters"
  }

  return <>
    <div className={'d-flex ebd-tabs-margin'}>
      {props.elements.map((item, index) => {
        if (index === 0)
          return <h2
            className={displayClassName(index, "first")}
            style={{fontSize: fontSize, padding: padding, lineHeight: lineHeight}}>{item}</h2>;
        else if (index === props.elements.length - 1)
          return <h2
            className={displayClassName(index, "last")}
            style={{fontSize: fontSize, padding: padding, lineHeight: lineHeight}}>{item}</h2>;
        else
          return <h2
            className={displayClassName(index, "middle")}
            style={{fontSize: fontSize, padding: padding, lineHeight: lineHeight}}>{item}</h2>;
      })}
    </div>
  </>
};

