import { FloorParameterIndexScreen } from "./FloorParameterIndexScreen";
import { FloorTypeIndexScreen } from "./FloorTypeIndexScreen";

import {
  PageTabed,
  PageTabedProps
} from "@components";

export const FloorsScreen: React.FC<any> = (props: any) => {
  const newProps: PageTabedProps = {
    title: "Floors",
    pathBase: "/lecturers/building-components/system/components/floors",
    tabs: [
      {
        label: "Types",
        component: <FloorTypeIndexScreen />,
        index: 0,
        path: "types",
      },
      {
        label: "Parameters",
        component: <FloorParameterIndexScreen />,
        index: 1,
        path: "parameters"
      }
    ]
  };
  return <PageTabed {...newProps}></PageTabed>;
};
