import { STATUS_TYPE } from "taltech-styleguide";
import ErrorScreen, { ErrorScreenProps } from "./ErrorScreen";

const Error405Screen: React.FC<any> = () => {
  const props: ErrorScreenProps = {
    variant: STATUS_TYPE.DANGER,
    title: "Internal server error",
    message: "An unexpected error has occurred",
  };
  return <ErrorScreen {...props} />;
};

export default Error405Screen;
