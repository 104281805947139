import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Page } from "@components";

import { application } from "../../../configuration/Application.bootstrap";
import UserInfo from "../../../components/users/UserInfo";

const UserProfileScreen: React.FC<any> = inject("coursesStore")(
  observer((props: any) => {
    const [profile, setProfile] = useState<any>(undefined);

    useEffect(() => {
      application
        .getHttpClient()
        .get("/usersms/users/self")
        .then((x) => {
          setProfile(x.body);
        });
    }, []);

    return (
      <Page title="User information" showBreadcrumbs={true}>
        <UserInfo account={profile} profile={profile} />
      </Page>
    );
  })
);

export default UserProfileScreen;
