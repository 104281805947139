import {Material, StructurelLayer} from "@domain";

import React, {FC} from "react";
import MaterialStructureElementTable from "./MaterialStructureElementTable";

export type ParameterStructure = {
  interior: StructurelLayer[];
  core: StructurelLayer[];
  exterior: StructurelLayer[];
}
export type ParameterStructureView = {
  materialOptions: Material[],
  functionOptions: string[],
}
export type typeStructureActions = {
  onsubmitAll: (values: any) => void,
  onElementSubmit: (values: any) => void,
  oncancel: () => void,
  onAddNew: (type: "core" | "interior" | "exterior") => void,
  onDeleteElement: (index: number, type: "core" | "interior" | "exterior") => void
}
export type ITypesMaterialStructure = {
  parameters: ParameterStructure,
  viewModel: ParameterStructureView,
  actions: typeStructureActions
}

const TypesMaterialStructure: FC<ITypesMaterialStructure> = ({
                                                               viewModel,
                                                               actions,
                                                               ...props
                                                             }) => {

  return (
    <>
      <MaterialStructureElementTable
        {...viewModel}
        {...actions}
        heading={"exterior"}
        elementStructure={props.parameters.exterior || []}/>
      <MaterialStructureElementTable
        {...viewModel}
        {...actions}
        heading={"core"}
        elementStructure={props.parameters.core || []}/>
      <MaterialStructureElementTable
        {...viewModel}
        {...actions}
        heading={"interior"}
        elementStructure={props.parameters.interior || []}/>
    </>
  );
};

export default TypesMaterialStructure;
