import { STATUS_TYPE } from "taltech-styleguide";
import ErrorScreen, { ErrorScreenProps } from "./ErrorScreen";

const Error404Screen: React.FC<any> = () => {
  const props: ErrorScreenProps = {
    variant: STATUS_TYPE.WARNING,
    title: "Resource not found",
    message: "The page you are looking for was not found",
  };
  return <ErrorScreen {...props} />;
};

export default Error404Screen;
