import { ILogger } from '@dirootie-core/logging-types';
import {
  IUserNotificationService,
  NotificationSeverity,
  UserNotification,
} from './notifications.types';

export class UserNotificationService implements IUserNotificationService {
  protected defaults = {
    duration: 5000,
    closable: true,
    horizontalPosition: 'end',
    verticalPosition: 'top',
  };

  constructor(private logger: ILogger) {}

  protected showNotification(
    severity: NotificationSeverity,
    notification: UserNotification
  ) {}

  error(notification: UserNotification): void {
    this.showNotification('error', notification);
  }

  warning(notification: UserNotification): void {
    this.showNotification('warning', notification);
  }

  info(notification: UserNotification): void {
    this.showNotification('info', notification);
  }

  success(notification: UserNotification): void {
    this.showNotification('success', notification);
  }

  notify(
    notification: UserNotification,
    severity?: NotificationSeverity
  ): void {
    const resultSeverity =
      notification.type !== undefined ? notification.type : severity;
    if (resultSeverity === undefined) {
      this.logger.error(
        `[UserNotificationService:notify]: Notification and severity are undefined. Cannot show notification with both set to undefined.`
      );
    }
    this.showNotification(resultSeverity!, notification);
  }
}
