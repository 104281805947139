import { IPresetElement } from "@domain/building-components";
import {
  colDescription,
  colId,
  colName,
  defaultProps,
  TableTalTech2,
} from "@components";
import { observer } from "mobx-react";
import { useState } from "react";
import { CollectionDataSource } from "../../../components/tables/temp/data.source";
import { IDataSource } from "components/tables/temp/tables.types";

export const PresetsElementSelector: React.FC<any> = observer(
  (props: {
    elements: IPresetElement[];
    selected: string[];
    submitSelectedBinder: any;
    service?: IDataSource<IPresetElement>;
  }) => {
    const [selected, setSelected] = useState<string[]>(props.selected || []);
    const columns = [colId, colName, colDescription];
    const getSelected = () => selected;
    props.submitSelectedBinder(getSelected);
    const tableProps: any = {
      ...defaultProps,
      selectRow: {
        mode: "checkbox",
        selected: selected,
        onSelect: (row: any, isSelect: boolean, rowIndex: number, e: any) => {
          if (isSelect) {
            setSelected([...selected, row.id]);
          } else {
            setSelected([...selected.filter((x) => x !== row.id)]);
          }
        },

        onSelectAll: (isSelect: boolean, rows: string[], e: any) => {
          if (isSelect) {
            setSelected(rows.map((x: any) => x.id));
          } else {
            setSelected([]);
          }
        },
        selectColumnPosition: "left",
      },

      columns,
    };

    return (
      <TableTalTech2
        source={{
          tableService: props.service ? props.service : (undefined as any),
          elements: props.service === undefined ? props.elements : undefined,
        }}
        table={tableProps}
      ></TableTalTech2>
    );
  }
);
