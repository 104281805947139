import {
  DisabledTabComponent,
  ElementDataForm,
  Page,
  PageContentForm,
  createFormProperties,
} from "@components";
import { SpaceFunction } from "@domain";
import React from "react";
import { useNavigate } from "react-router-dom";
import { spaceEntityStore } from "./SpaceFunctionTypesScreen";

const SpaceFunctionTypeCreateScreen: React.FC<any> = () => {
  const navigator = useNavigate();
  const formProps = createFormProperties<Partial<SpaceFunction>>(
    "CREATE",
    {
      onAction: (action) => {
        return Promise.resolve(spaceEntityStore.onAction(action));
      },
      schema: spaceEntityStore.options.schema,
      element: { ...spaceEntityStore.options.empty },
    },
    spaceEntityStore.options,
    navigator
  );
  return (
    <Page title="Create new Space Function" showBreadcrumbs={true}>
      <div className={"container"}>
        <DisabledTabComponent
          elements={["Functions", "Parameters"]}
          activeDisabled={0}
        />
        <PageContentForm
          form={{ Component: ElementDataForm, options: { ...formProps } }}
          mode="CREATE"
          dispatcher={{
            onAction: (action) => {
              return Promise.resolve(spaceEntityStore.onAction(action));
            },
          }}
        />
      </div>
    </Page>
  );
};

export default SpaceFunctionTypeCreateScreen;
