import { StructureLayerFunctionType } from './BuildingData.types';

export type StructureLayerFunction = {
  type: StructureLayerFunctionType;
  hierarchy: number;
  function: string;
};

const _structureLayerFunctionMapper = new Map<
  StructureLayerFunctionType,
  StructureLayerFunction
>();

const structure: StructureLayerFunction = {
  type: 'STRUCTURE',
  hierarchy: 1,
  function: 'Structure',
};

const substrate: StructureLayerFunction = {
  type: 'SUBSTRATE',
  hierarchy: 2,
  function: 'Substrate',
};

const thermal: StructureLayerFunction = {
  type: 'THERMAL/AIR',
  hierarchy: 3,
  function: 'Thermal/Air layer',
};

const finish1: StructureLayerFunction = {
  type: 'FINISH1',
  hierarchy: 4,
  function: 'Finish 1',
};

const finish2: StructureLayerFunction = {
  type: 'FINISH2',
  hierarchy: 5,
  function: 'Finish 2',
};

const membrane: StructureLayerFunction = {
  type: 'MEMBRANE',
  hierarchy: 6,
  function: 'Membrabe layer',
};

_structureLayerFunctionMapper.set('STRUCTURE', structure);
_structureLayerFunctionMapper.set('SUBSTRATE', substrate);
_structureLayerFunctionMapper.set('THERMAL/AIR', thermal);
_structureLayerFunctionMapper.set('FINISH1', finish1);
_structureLayerFunctionMapper.set('FINISH2', finish2);
_structureLayerFunctionMapper.set('MEMBRANE', membrane);

export const structureLayerFunctionMapper = _structureLayerFunctionMapper;
