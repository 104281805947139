import {colCreateDate, colId, colModifiedDate, defaultProps, TableTalTech2,} from "@components";
import {convertToTitleCase, isEmptyArray,} from "@dirootie-extensions/javascript";
import {UserProfile} from "@domain";
import {observer} from "mobx-react";
import React, {FC} from "react";
import {useNavigate} from "react-router-dom";
import {FILTER_TYPES, Initials, STATUS_TYPE, StatusTag,} from "taltech-styleguide";

const colNumber = {
  text: "Number",
  dataField: "memberId",
};
const colName = {
  text: "Name",
  dataField: "displayName",
  sort: true,
  filterObject: {
    defaultValue: undefined,
    minChars: 3,
    placeholder: "Name",
    type: FILTER_TYPES.TEXT,
  },
};
const colEmail = {
  text: "Email",
  dataField: "email",
};
const colRole = {
  text: "Role",
  dataField: "role",
  formatter: (col: string) => convertToTitleCase(col),
};
const colState = {
  dataField: "accountState",
  formatter: (col: any, row: UserProfile, index: any, extraData: any) => {
    const states = Object.keys(extraData.states).filter(
      (x) => x.toUpperCase() === col.toUpperCase()
    );
    let stateType = STATUS_TYPE.DANGER;
    let stateName = "UNKNOWN";
    if (!isEmptyArray(states)) {
      const state = extraData.states[states[0]];
      stateType = state.type;
      stateName = state.name;
    }
    return (
      <StatusTag type={stateType}>{convertToTitleCase(stateName)}</StatusTag>
    );
  },
  formatExtraData: {
    states: {
      active: {
        name: "Active",
        state: "Active",
        type: STATUS_TYPE.SUCCESS,
      },
      archived: {
        name: "Archived",
        state: "Archived",
        type: STATUS_TYPE.INFO,
      },
      finished: {
        name: "Finished",
        state: "Finished",
        type: STATUS_TYPE.LIGHT,
      },
    },
  },
  sort: true,
  text: "Account state",
};

export const UserRegisteredTable: FC<{ service: any }> = observer(
  ({...props}) => {
    const navigator = useNavigate();
    const columns = [
      colId,
      {
        classes: "ebd-initials-first",
        dataField: "profile.picture.url",
        formatter: (col: any, row: UserProfile, index: any, extraData: any) => {
          return (
            <>
              <Initials
                key={row.id}
                imageUrl={row.picture.url}
                name={row.displayName}
                size="xs"
              />
            </>
          );
        },
        sort: false,
        text: "",
      },
      // colNumber,
      colName,
      colEmail,
      colRole,
      colState,
      colCreateDate,
      colModifiedDate,
    ];
    const tableProps = {
      ...defaultProps,
      columns,
    } as any;
    return (
      <TableTalTech2
        source={{
          path: "users.registered",
        }}
        table={tableProps}
      ></TableTalTech2>
    );
  }
);
