import { isActiveLevel, LogLevel } from './level';
import { LoggingConfig } from './logging.config';
import { ILogger } from './logging.types';

export abstract class Logger implements ILogger {
  loggingConfig;

  get level() {
    return this.loggingConfig.level;
  }

  constructor(loggingConfig: LoggingConfig) {
    this.loggingConfig = loggingConfig;
  }

  private shouldLog(currentLevel: LogLevel, messageLevel: LogLevel) {
    return isActiveLevel(currentLevel, messageLevel);
  }

  private internalWriteLog(
    messageLogLevel: LogLevel,
    message: string,
    ...data: any[]
  ) {
    if (this.shouldLog(this.level, messageLogLevel)) {
      this.writeLog(message, ...data);
    }
  }

  fatal(message: string, ...data: any[]): void {
    this.internalWriteLog(LogLevel.FATAL, message, ...data);
  }

  error(message: string, ...data: any[]) {
    this.internalWriteLog(LogLevel.ERROR, message, ...data);
  }

  warning(message: string, ...data: any[]) {
    this.internalWriteLog(LogLevel.WARN, message, ...data);
  }

  info(message: string, ...data: any[]) {
    this.internalWriteLog(LogLevel.INFO, message, ...data);
  }

  debug(message: string, ...data: any[]) {
    this.internalWriteLog(LogLevel.DEBUG, message, ...data);
  }

  trace(message: string, ...data: any[]): void {
    this.internalWriteLog(LogLevel.TRACE, message, ...data);
  }

  log(logLevel: LogLevel, message: string, ...data: any[]) {
    this.internalWriteLog(logLevel, message, ...data);
  }

  abstract configure(loggingConfig: LoggingConfig): void;
  abstract writeLog(message: string, ...data: any[]): void;
}
