import {cols, createColActions, defaultProps, TableTalTech2,} from "@components";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";

export const AssignmentsTable: React.FC<any> = observer(
  (props: { elements: any; isEditable: boolean; source: any }) => {
    console.log(props.elements)
    const navigator = useNavigate();
    const columns = [
      cols.id(),
      cols.number(),
      cols.title(),
      cols.description(),
      cols.state2(),
      cols.endDate(),
      {
        formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => createColActions(navigator, {
          showCopy: props.isEditable,
          showDelete: props.isEditable,
          showEdit: props.isEditable,
          showView: !props.isEditable,
          onEdit: () => navigator(row.id)
        }),
      },
    ];
    const prop = {...defaultProps, columns} as any;
    return (
      <TableTalTech2
        source={props.source}
        // source={{
        //   elements: props.elements, // path: "buildingComponents.materials",
        //   // query: { componentType: "1" },
        // }}
        table={prop}
      ></TableTalTech2>
    );
  }
);
