import {observer} from "mobx-react";
import {TabPanel, Tabs} from "taltech-styleguide";
import {BuildingComponentParametersTable} from "../_common/BuildingComponentParametersTable";
import {WindowDataForm} from "./WindowDataForm";
import { ElementDataForm, PageContentForm, createFormProperties } from "@components";
import { useNavigate } from "react-router-dom";

export const WindowDetails: React.FC<any> = observer(
  (props: { element: any, entityStore: any }) => {
    const navigator = useNavigate();
    const formProps = createFormProperties(
      "EDIT" ,
       {
         onAction: (action) => { return Promise.resolve(props.entityStore.onAction(action))},
         schema: props.entityStore.options.schema,
         element: props.element
       },
       props.entityStore.options,
       navigator
     );
     formProps.showLayer = true;
    const content = (
      <Tabs id="page-tabs-window" navSize="sm" labels={["Data", "Parameters"]}>
        <TabPanel key="Data">
        <PageContentForm
        form={{ Component: WindowDataForm, options: formProps  }}
        mode="EDIT"
        dispatcher={{onAction: (action) => { return Promise.resolve(props.entityStore.onAction(action));}}}
      />
        </TabPanel>
        <TabPanel key="Parameters">
          <BuildingComponentParametersTable element={props.element}/>
        </TabPanel>
      </Tabs>
    );
    return content;
  }
);
