export const ProfileStateList = [
  'UNKNOWN',
  'INVITED',
  'NOT_COMPLETED',
  'ACTIVE',
  'BLOCKED',
  'SUSPENDED',
  'FINISHED',
];

export type ProfileState = typeof ProfileStateList[number];

export const RoleTypeList = ['STUDENT', 'LECTURER', 'STUDY_DIRECTOR'];

export type RoleType = typeof RoleTypeList[number];

export const AccountStateList = [
  'UNKNOWN',
  'INVITED',
  'REGISTERED',
  'ACTIVE',
  'BLOCKED',
  'SUSPENDED',
  'FINISHED',
];

export type AccountState = typeof AccountStateList[number];
