import { Col, Container, Row } from "taltech-styleguide";
import { Page } from "@components";
import { Chart } from "primereact/chart";

const data = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "First Dataset",
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      borderColor: "#4bc0c0",
    },
    {
      label: "Second Dataset",
      data: [28, 48, 40, 19, 86, 27, 90],
      fill: false,
      borderColor: "#565656",
    },
  ],
};

const AnalyticsScreen: React.FC<any> = () => (
  <Page title="Analytics">
    <Container body className="layout-example">
      <Row>
        <Col>
          <Chart type="line" data={data} />
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col></Col>
      </Row>
    </Container>
  </Page>
);

export default AnalyticsScreen;
