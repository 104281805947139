import {InputCheckbox,} from "@components";
import {FormState,} from "@dirootie-web/components-types";
import {ParameterInstance, ParameterType} from "@domain/building-components";
import {Form as FormikForm, Formik} from "formik";
import React, {FC} from "react";
import {Form} from "taltech-styleguide";
import {createParameterTypeInput} from "../../parameters/InputParameterType";

export type ParameterInstanceFormProps = {
  parameter: ParameterInstance;
};

export const ParameterInstanceForm: FC<ParameterInstanceFormProps> = ({
                                                                        ...props
                                                                      }) => {
  const initialValues = {...props.parameter};
  if (!initialValues.override) {
    initialValues.overrideValue = initialValues.valueDefault;
  }
  return (
    <>
      <Formik
        // validationSchema={getParameterValidationSchema()}
        initialValues={props.parameter}
        onSubmit={(values) => {
          //props.onSubmit(values);
        }}
      >
        {(formik) => {
          const formState: FormState = formik as any;
          const overrideValue = (
            <div className={'d-flex'}>
              <InputCheckbox elementClass={'mr-3'} id="override" formState={formState}/>
              {createParameterTypeInput(
                formik.getFieldMeta("type").value as ParameterType,
                formik as any,
                {disabled: false, id: "overrideValue"}
              )}
            </div>
          );
          const defaultValue = (
            <div className={'d-flex'}>
              <InputCheckbox elementClass={'mr-3'} id="override" formState={formState}/>
              {createParameterTypeInput(
                formik.getFieldMeta("type").value as ParameterType,
                formik as any,
                {disabled: true}
              )}
            </div>
          );
          console.log("ASDAS", formik.initialValues, props.parameter);
          const override = formState.getFieldMeta("override").value;

          const content = override ? overrideValue : defaultValue;

          return (
            <FormikForm>
              <Form.Group>{content}</Form.Group>
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};
