import {PagedResult} from '@dirootie-core/core-types';
import {IQuery} from '@dirootie-web/components-types';
import {
  ComponentType,
  Parameter,
  ParameterApiService,
  ParameterCategory,
  parametersPerType,
} from '@domain/building-components';
import {ServiceFactory} from 'packages/core/api-types/src';
import {BuildingComponent} from './BuildingComponent';
import {buildingComponentsData} from './BuildingComponents.samples';
import {BuildingComponentsApiService} from './BuildingComponentsApiService';

export class BuildingComponentsStore {
  // searchSore;
  private componentsService: BuildingComponentsApiService;

  constructor(
    private apiService: ParameterApiService,
    private serviceFactory: ServiceFactory
  ) {
    //this.searchSore = this.serviceFactory.createSearchStoreFromPath('courses');
    this.componentsService = serviceFactory.createApiServiceFromPath(
      'buildingComponents.components'
    );
  }

  getParameters(
    category: ParameterCategory,
    query: IQuery
  ): Promise<PagedResult<Parameter>> {
    const queryServer = {...query, category};
    const data = parametersPerType.filter((x) => x.category === category);
    return Promise.resolve({
      items: data,
      total: data.length,
      page: 1,
      size: 10,
    }); //this.apiService.getAll(queryServer);
  }

  getParameter(id: string): Promise<Parameter> {
    return Promise.resolve(parametersPerType.filter((x) => x.id === id)[0]); // this.apiService.get(id);
  }

  createParameter(parameter: Parameter): Promise<Parameter> {
    return this.apiService.create(parameter);
  }

  saveParameter(parameter: Parameter): Promise<Parameter> {
    return this.apiService.update(parameter);
  }

  getComponents(
    type: ComponentType,
    query: IQuery
  ): Promise<PagedResult<BuildingComponent>> {
    const queryServer = {...query, type};
    const data = buildingComponentsData.filter((x) => x.type === type);
    return Promise.resolve({
      items: data,
      total: data.length,
      page: 1,
      size: 10,
    }); // this.componentsService.getAll(queryServer);
  }

  getComponent(id: string): Promise<BuildingComponent> {
    return Promise.resolve(
      buildingComponentsData.filter((x) => x.id === id)[0]
    );
  } // this.componentsService.getAll(queryServer);

}
