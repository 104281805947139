import { FC } from "react";
import {
  PageTest,
  PageTestProps,
  useOutputWindowService,
} from "@components/testing";
import { RoofDetails } from "@components/building-components";

export const RoofComponentUitest: FC<any> = () => {
  const props: PageTestProps = {
    title: "Roof component - uitest",
    demo: {
      outputWindow: true,
      Component: RoofDetails,
    },
  };

  return <PageTest {...props} />;
};
