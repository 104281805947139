import {createContext, useContext} from 'react';
import {BuildingStore} from "./building/buildingStore";
import {BuildingConfigurationFormStore} from './forms/FormsStore';

interface Store {
  buildingConfigurationFormStore: BuildingConfigurationFormStore;
  buildingStore: BuildingStore;
}

export const store: Store = {
  buildingConfigurationFormStore: new BuildingConfigurationFormStore(),
  buildingStore: new BuildingStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
