import { IQuery, TableProps } from './tables.types';

export class TableSearchStore {
  searchSore;
  tableProps: TableProps;

  constructor(
    private serviceFactory: any,
    private path: string,
    tableProps: TableProps,
    data?: any[]
  ) {
    this.searchSore =
      data !== undefined
        ? this.serviceFactory.createSearchStoreMock(data)
        : this.serviceFactory.createSearchStoreFromPath(this.path);
    this.tableProps = this.buildTableProps(tableProps);
  }

  search(query?: IQuery) {
    this.searchSore.search(query);
  }

  getTableProps(): TableProps {
    const { data, totalResults, ...rest } = this.tableProps;
    this.tableProps.data = this.searchSore.getResults().items;
    this.tableProps.totalResults = this.searchSore.getResults().total;
    this.tableProps.loading = this.searchSore.loading;
    return this.tableProps;
  }

  buildTableProps(partialTableProps: Partial<TableProps>) {
    const tableProps: TableProps = {
      totalResults: this.searchSore.getResults().total,
      columns: partialTableProps.columns!,
      data: this.searchSore.getResults().items,
      onRowClickNavigate: partialTableProps.onRowClickNavigate,
      pageable: partialTableProps.pageable,
      pageableProps:
        partialTableProps.pageableProps === undefined
          ? {
              rows: 10,
              rowsPerPageOptions: [2, 5, 10],
              lazy: true,
            }
          : (partialTableProps.pageableProps as any),
      loading: this.searchSore.loading,
      changePage: (a: any, b: any) => this.searchSore.changePage(a, b),
      changeSort: (a: any, b: any) => this.searchSore.changeSort(a, b),
    };
    return tableProps;
  }
}

export class TableSearchStoreFactory {
  stores = new Map<string, TableSearchStore>();

  constructor(private serviceFactory: any) {}

  createTableStore(props: TableProps, path: string, id: string, data?: any[]) {
    if (this.stores.get(id) === undefined) {
      const store = new TableSearchStore(
        this.serviceFactory,
        path,
        props,
        data
      );
      this.stores.set(id, store);
      return store;
    } else {
      return this.stores.get(id)!;
    }
  }

  getFactory(id: string) {
    return this.stores.get(id)!;
  }
}
