import Add from './Add';
import { IconEye } from './IconEye';
import { IconSquareEdit } from './IconSquareEdit';
import { IconXmark } from './IconXmark';
import { IconActionType } from './Icons.types';

export const iconMapper = new Map<IconActionType, (props?: any) => JSX.Element>(
  [
    ['add', Add],
    ['cancel', IconXmark],
    ['clear', IconXmark],
    ['close', IconXmark],
    // ['confirm', 'done'],
    // ['print', 'print'],
    // ['refresh', 'refresh'],
    ['edit', IconSquareEdit],
    // ['done', 'done'],
    // ['destroy', 'delete'],
    // ['play', 'play_arrow'],
    // ['touch', 'touch_app'],
    // ['redo', 'redo'],
    // ['delete', 'delete'],
    // ['remove', 'remove'],
    // ['reset', 'close'],
    // ['restart', 'restart_alt'],
    // ['select', 'touch_app'],
    // ['send', 'send'],
    // ['pause', 'pause'],
    // ['transfer', 'swap_horiz'],
    // ['warning', 'erroroutline'],
    // ['local_shipping', 'local_shipping_outline'],
    // ['event_note', 'event_note'],
    ['view', IconEye],
    // ['view_more', 'expand_more'],
    // ['view_less', 'expand_less'],
  ]
);
