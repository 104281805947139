import { Toolbar } from "./Toolbar";
import { ToolbarWithActionsProps } from "./Toolbars.types";
import { createToolbarExtendedProps } from "./Toolbars.utils";
import { application } from "../../../configuration/Application.bootstrap";

export const ToolbarWithActions: React.FC<ToolbarWithActionsProps> = (
  props: ToolbarWithActionsProps
) => {
  const resultProps = createToolbarExtendedProps(props);
  const onAction =
    props.onAction !== undefined
      ? props.onAction
      : !props.defaultDispatcher
      ? (a: any) =>
          application.getHandlerFactory().createDispatcherBasic().dispatch(a)
      : () => {};
  return (
    <Toolbar
      title={resultProps.title}
      left={[
        resultProps.buttons!.btnCreateNew,
        resultProps.buttons!.btnCreate,
        resultProps.buttons!.btnSave,
      ].filter((x) => x!.visible)}
      right={[
        resultProps.buttons!.btnDelete,
        resultProps.buttons!.btnBack,
      ].filter((x) => x!.visible)}
      onAction={(ev: any) => {
        onAction!(ev);
      }}
    />
  );
};

export const ToolbarList: React.FC<any> = (props: any) => {
  return <ToolbarWithActions createNewVisible={true} {...props} />;
};

export const ToolbarCreate: React.FC<any> = (props: any) => {
  const { createVisible = true, ...rest } = props;
  return <ToolbarWithActions {...rest} createVisible={createVisible} />;
};

export const ToolbarEdit: React.FC<any> = (props: any) => {
  const { saveVisible = true, deleteVisible = true, ...rest } = props;
  return (
    <ToolbarWithActions
      saveVisible={saveVisible}
      deleteVisible={deleteVisible}
      {...rest}
    />
  );
};

export const ToolbarBack: React.FC<any> = (props: any) => {
  return <ToolbarWithActions {...props} />;
};
