import { AreaConfiguration } from 'domain/web-applications/AreaConfiguration';
import { Router } from '@remix-run/router';
import _ from 'lodash';

export type ViewNavigatorOptions = {
  loginRoute: string;
  logoutRoute: string;
  forbiddenRoute: string;
  notFoundRoute: string;
  internalServerErrorRoute: string;
  areas: AreaConfiguration[];
  home: string;
};

const navigatorOptionsDefaults: ViewNavigatorOptions = {
  loginRoute: 'auth/login',
  logoutRoute: '/auth/logout',
  forbiddenRoute: '/errors/unauthorized',
  notFoundRoute: '/errors/not-found',
  internalServerErrorRoute: '/errors/internal-server-error',
  areas: [],
  home: '/',
};

export interface IViewNavigator {
  navigateToExternalUrl: (url: string) => Promise<void>;
  navigateToPath: (
    path: string | number,
    options: { relative: boolean }
  ) => Promise<void>;
  navigateToNotFound(): Promise<void>;
  navigateToUnauthorized(): Promise<void>;
  navigateToLogin(): Promise<void>;
  navigateToLogout(): Promise<void>;
  navigate(route: string | number, options: { relative: boolean }): void;
  getCurrentArea(): AreaConfiguration;
  getCurrentUrl(): string;
  getCurrentUrlExcept(): string | undefined;
  isLogoutRoute(): boolean;
}

export class ViewNavigator implements IViewNavigator {
  options: ViewNavigatorOptions = navigatorOptionsDefaults;
  private router: Router | undefined = undefined;
  constructor(options?: Partial<ViewNavigatorOptions>) {
    this.options =
      options === undefined
        ? this.options
        : Object.assign(this.options, options);
  }
  setRouter(router: Router) {
    this.router = router;
  }
  setAreas(areas: AreaConfiguration[]) {
    this.options.areas = areas;
  }
  navigateToExternalUrl(url: string): Promise<void> {
    window.open(url);
    return Promise.resolve();
  }
  navigateToPath(
    path: string | number,
    options: { relative: boolean } = { relative: false }
  ): Promise<void> {
    this.navigate(path, options);
    return Promise.resolve();
  }
  navigateToInternalServerError(): Promise<void> {
    this.navigate(this.options.internalServerErrorRoute);
    return Promise.resolve();
  }
  navigateToNotFound(): Promise<void> {
    this.navigate(this.options.notFoundRoute);
    return Promise.resolve();
  }
  navigateToUnauthorized(): Promise<void> {
    this.navigate(this.options.forbiddenRoute);
    return Promise.resolve();
  }
  navigateToLogin(): Promise<void> {
    this.navigate(this.options.loginRoute);
    return Promise.resolve();
  }
  navigateToLogout(): Promise<void> {
    this.navigate(this.options.logoutRoute);
    return Promise.resolve();
  }

  navigate(
    route: string | number,
    options: { relative: boolean } = { relative: false }
  ) {
    if (_.isInteger(route)) {
      return this.router!.navigate(route as number);
    }
    if (route === window.location.pathname) {
      return Promise.reject();
    }
    if (options.relative) {
      const relativeRoute = `${this.getCurrentUrl()}/${route}`;
      return this.router!.navigate(relativeRoute);
    }
    return this.router!.navigate(route as string);
  }

  getCurrentArea(): AreaConfiguration {
    const firstPath = window.location.pathname
      .split('/')
      .filter((x) => x !== '')[0];
    let area = this.options.areas.filter((x) => x.name === firstPath);
    if (area === undefined || area.length === 0) {
      return this.options.areas.filter((x) => x.name === 'public')[0];
    } else {
      return area[0];
    }
  }

  getCurrentUrl(): string {
    const path = window.location.pathname;
    return path;
  }

  getCurrentUrlExcept(): string | undefined {
    const path = this.getCurrentUrl();
    const nonRedirectableRoutes = [this.options.loginRoute, this.options.home];
    const result =
      nonRedirectableRoutes.findIndex((x) => x === path) > -1
        ? undefined
        : path;
    return result;
  }

  isLogoutRoute(): boolean {
    const path = this.getCurrentUrl();
    const result = this.options.logoutRoute === path;
    return result;
  }
}
