import { ServiceFactory } from "@dirootie-core/api-types";
import { IUserNotificationService, badRequestNotification, notAuthorizedNotification } from "../../../packages/web/components-types/src/notifications";
import { IFormAction } from "./FormActions.types";
import { createdSuccessNotification, deleteErrorNotification, deleteSuccessNotification, formHasErrorsNotification, saveSuccessNotification } from "../../../packages/web/components-types/src/notifications/forms.notifications";
import { IEntity } from "@dirootie-core/core-types";
import { FormState } from "@dirootie-web/components-types";
import { IAction, IActionHandler } from "../Actions.types";
export interface IFormStateProvider {
  setState(s: FormState): void;
  getState(): FormState;
}
export abstract class FormActionHandlerBase implements IActionHandler {

  constructor(
    protected userNotificationService: IUserNotificationService,
    protected serviceFactory: ServiceFactory
  ) {}

  public abstract handle(action: any): Promise<any>;
}
