import { Entity, PagedResult } from "@dirootie-core/core-types";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { UserInvitationTable } from "./UserInvitationTable";
import { buildingConfigurationTable } from "../../../../domain/building/SampleBuildingConfiguration";
import { TableSearchStoreFactory } from "../../../../packages/web/components-types/src/tables/table-search-store";
import { ToolbarList } from "@components";

const InvitationsScreen: React.FC<any> = inject("tableSearchStoreFactory")(
  observer((props: { tableSearchStoreFactory: TableSearchStoreFactory }) => {
    const [parameter, setParameter] = useState<
      PagedResult<Entity & { name: string; description: string }>
    >({
      total: 0,
      page: 0,
      size: 0,
      items: [],
    });
    useEffect(() => {
      setParameter({
        items: buildingConfigurationTable,
        total: buildingConfigurationTable.length,
        page: 1,
        size: 10,
      }); //this.apiService.getAll(queryServer);
    }, []);
    return (
      <div>
        <ToolbarList />
        <UserInvitationTable />
      </div>
    );
  })
);

export default InvitationsScreen;
