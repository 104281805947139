import { observer } from "mobx-react";
import { Component } from "react";
import {
  AdvancedTable,
  FILTER_TYPES,
  IAdvancedTableProps,
  Initials,
  ITableState,
  StatusTag,
  STATUS_TYPE,
} from "taltech-styleguide";
//import { coursesData } from "../../areas/lecturers/screens/courses/Courses.samples";
import { isEmptyArray } from "formik";
import { SummaryResource } from "domain/courses/Course";
import { SelectParameterType } from "components/forms/common/SelectParameterType";
import { SelectFromArray } from "components/forms";
import {
  DefaultDataService,
  TaltechTableProps,
  TalTechTableService,
} from "./temp/tables.types";
import { DataSourceFactory } from "./temp/data.source";

const column = {
  data: {
    states: {
      active: {
        name: "Active",
        state: "Active",
        type: STATUS_TYPE.SUCCESS,
      },
      archived: {
        name: "Archived",
        state: "Archived",
        type: STATUS_TYPE.INFO,
      },
      finished: {
        name: "Finished",
        state: "Finished",
        type: STATUS_TYPE.LIGHT,
      },
    },
  },
};

@observer
export class TableTalTech extends Component<
  any & { tableProps?: any; dataStore?: any },
  any
> {
  //navigator =  useNavigate();

  componentDidMount() {
    if (this.props.dataStore !== undefined) {
      //this.props.dataStore.search();
    }
  }

  render() {
    // const columns = buildColumns(this.props);
    const tProps: IAdvancedTableProps = this.props.tableProps; //this.props.dataStore.getTableProps this.getTableProps(this.props);
    return <AdvancedTable {...tProps}></AdvancedTable>;
  }
}

@observer
export class TableTalTech2 extends Component<TaltechTableProps, any> {
  //navigator =  useNavigate();
  private service;

  constructor(props: TaltechTableProps) {
    super(props);
    const { source } = props;
    this.service = source.tableService
      ? source.tableService
      : new TalTechTableService(DataSourceFactory.createDataSource(source)!);
  }

  // componentDidMount() {
  //   if (this.service !== undefined) {
  //     this.service.search();
  //     console.log("Service search");
  //   }
  // }

  handleTableChange(type: string, state: ITableState) {
    this.service.search(state);
  }

  render() {
    const tProps: IAdvancedTableProps = this.service.getProps(this.props.table); //this.props.dataStore.getTableProps this.getTableProps(this.props);
    return (
      <AdvancedTable
        remote={{ pagination: true, sort: true, filter: true }}
        callOnTableChangeOnMount
        onTableChange={(type, st) => {
          this.handleTableChange(type, st);
        }}
        {...tProps}
      ></AdvancedTable>
    );
  }
}
