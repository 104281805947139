import { observer } from "mobx-react-lite";
import { FC } from "react";
import PageHeader from "./PageHeader";

export type PageProps = {
  title: string;
  showBreadcrumbs?: boolean;
  children?: any;
};

export const Page: FC<PageProps> = observer((props: PageProps) => {
  const { showBreadcrumbs = true, title } = props;
  return (
    <>
      <PageHeader {...{ showBreadcrumbs, title }}></PageHeader>
      <div>{props.children}</div>
    </>
  );
});
