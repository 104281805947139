import React, {FC} from "react";
import {Button} from "taltech-styleguide";
import ConfirmationComponent from "../../../../components/forms/ConfirmationComponent";
import UploadComponent from "../../../../components/forms/UploadComponent";
import '../../../../styles/upload.css'

export const UploadScreen: FC<any> = ({...props}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);
  return <>
    <UploadComponent/>
    {/*<InputSearch users={usersSample}/>*/}
    <Button onClick={toggleModal}/>
    <ConfirmationComponent modalState={modalOpen}
                           modalStateAction={toggleModal}
                           confirmationHeader={"Confirm"}
                           confirmationBody={"Are you sure you want to delete?"}
                           closeBtn={{kind: "cancel", onClick: () => console.log("Confirmation Clicked")}}
                           confirmationBtn={{
                             kind: "approve",
                             text: "Submit",
                             onClick: () => console.log("Confirmation Clicked")
                           }}/>
  </>
};
