import {createMaxMessage, createMinMessage, createRequiredMessage} from '@domain/validations';
import * as Yup from 'yup';

export const getCourseValidationSchema = () => {
  const courseValidationSchema = Yup.object<any>().shape({
    number: Yup.string().required(createRequiredMessage('number')).nullable().matches(/^[A-Za-z]{3}\d{4}$/, 'Field must start with 3 characters and be followed by 4 numbers'),
    name: Yup.string().min(1, createMinMessage('name', 1)).max(100, createMaxMessage('name', 100)).required(createRequiredMessage('name')),
    description: Yup.string().min(1, createMinMessage('description', 1)).max(100, createMaxMessage('description', 10000)).required(createRequiredMessage('description')),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable().min(
      Yup.ref('startDate'),
      'End Date should be after the Start Date'
    ),
  });

  return courseValidationSchema;
};
