export enum LogLevel {
  OFF = 0,
  FATAL = 100,
  ERROR = 200,
  WARN = 300,
  INFO = 400,
  DEBUG = 500,
  TRACE = 600,
  ALL = Number.MAX_VALUE,
}

export const isActiveLevel = (
  currentLevel: LogLevel,
  messageLevel: LogLevel
): boolean => {
  if (messageLevel === LogLevel.ALL || messageLevel === LogLevel.OFF) {
    throw new Error(
      `Level: ${LogLevel[messageLevel]} is not a message valid level!`
    );
  }
  if (currentLevel === LogLevel.OFF) {
    return false;
  }
  if (currentLevel === LogLevel.ALL) {
    return true;
  }
  return messageLevel <= currentLevel;
};
