import { STATUS_TYPE } from "taltech-styleguide";
import ErrorScreen, { ErrorScreenProps } from "./ErrorScreen";

const Error403Screen: React.FC<any> = () => {
  const props: ErrorScreenProps = {
    variant: STATUS_TYPE.DANGER,
    title: "Forbiden",
    message: "You don't have the necessary permission to access this resource",
  };
  return <ErrorScreen {...props} />;
};

export default Error403Screen;
