import {InputNumber, InputSelect} from "@components";
import {StructurelLayer} from "@domain";
import {createRequiredMessage} from "@domain/validations";
import {Form as FormikForm, Formik} from "formik";
import React, {FC} from "react";
import {Button, BUTTON_COLOR, Modal} from "taltech-styleguide";
import * as Yup from 'yup';
import "../../../../../styles/building.css"
import Delete from "../../../../icons/Delete";
import IconSave from "../../../../icons/IconSave";
import BuildingTypesHeader from "./BuildingTypesHeader";
import {ParameterStructureView, typeStructureActions} from "./TypesMaterialStructure";

type IMaterialStructureElementTable = typeStructureActions & ParameterStructureView & {
  heading: "interior" | "exterior" | "core",
  elementStructure: StructurelLayer[],
}

const MaterialStructureElementTable: FC<IMaterialStructureElementTable> = ({
                                                                             ...props
                                                                           }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);
  const validationSchema = Yup.object().shape({
    thickness: Yup.number().required(createRequiredMessage('Thickness')).min(1, 'Field must be greater than 0'),
  });

  return (
    <>
      <Modal show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>Confirmation</Modal.Header>
        <Modal.Body>
          Are you sure you want to Delete?
        </Modal.Body>
        <Modal.Footer>
          <Button variant={BUTTON_COLOR.PRIMARY} onClick={() => {
            toggleModal()
            props.onDeleteElement(0, props.heading);
          }}>
            Confirm
          </Button>{' '}
          <Button variant={BUTTON_COLOR.LINK} onClick={toggleModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <h6 className={'ebd-material-header'}>{props.heading}</h6>
      <div className={'table-element'}>
        <BuildingTypesHeader
          addNewFn={() => props.onAddNew(props.heading)}
          showAddNew={true}
          headerValues={["Material", "Function", "Thickness"]}
          addTo={`${props.heading}`}/>
        {props.elementStructure.map((parameters: any, index: number) => {
          return (
            <Formik
              enableReinitialize
              initialValues={parameters}
              validationSchema={validationSchema}
              onSubmit={(values) => props.onElementSubmit(values)}>
              {(formik) => {
                return (
                  <FormikForm>
                    <div className={'row-element'} key={index}>
                      <div className={'cell'}>
                        <InputSelect
                          id={`material`}
                          formState={formik as any}
                          items={props.materialOptions.map(e => e.name)}/>
                      </div>
                      <div className={'cell'}>
                        <InputSelect
                          id={`function`}
                          formState={formik as any}
                          items={[...props.functionOptions]}/>
                      </div>
                      <div className={'cell'}>
                        <InputNumber id={`thickness`} formState={formik as any} elementClass={'ebd-min-input-width'}/>
                      </div>

                      <div className={'cell d-flex justify-content-end'}>
                        <div onClick={() => formik.submitForm()}
                             className={'mr-1 ebd-icon'}>
                          <IconSave/>
                        </div>
                        <div onClick={toggleModal}
                             className={'ebd-icon'}>
                          <Delete/>
                        </div>
                      </div>
                    </div>
                  </FormikForm>
                );
              }}
            </Formik>
          )
        })}
      </div>
    </>
  );
};

export default MaterialStructureElementTable;
