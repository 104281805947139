import {cols, createColActions, defaultProps, TableTalTech2,} from "@components";
import _ from "lodash";
import {observer} from "mobx-react";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import ConfirmationComponent from "../forms/ConfirmationComponent";

export const MediaItemsTable: React.FC<any> = observer((props: any) => {
  const [id, setId] = useState("");
  const navigator = useNavigate();
  const columns = [
    cols.id(),
    cols.name(),
    cols.description(),
    cols.type(),
    cols.createdDate(),
    {
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => createColActions(navigator, {
        showCopy: false,
        showDelete: props.isEdiStable,
        showEdit: props.isEdiStable,
        showDownload: row.type === "FILE",
        showView: true,
        onDelete: () => {
          toggleModal()
          setId(row.id)
        },
        onDownload: async () => {
          console.log(_.cloneDeep(row))
          const outsideRes = await fetch(row.url);

          const blob = await outsideRes.blob();
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.download = row.fileName;
          link.click();
        },
        onViewClick: () => {
          window.open(row.url, "_blank");
        },
        onEdit: () => navigator(row.id)
      }),
    },
  ];
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);
  const tableProps = {
    ...defaultProps,
    columns,
  } as any;
  return (
    <>
      <TableTalTech2 source={props.source} table={tableProps}></TableTalTech2>
      <ConfirmationComponent modalState={modalOpen}
                             modalStateAction={toggleModal}
                             confirmationHeader={"Modal"}
                             confirmationBody={"Are you sure you want to delete?"}
                             closeBtn={{
                               kind: "cancel",
                               onClick: () => setModalOpen(false)
                             }}
                             confirmationBtn={{
                               kind: "approve",
                               text: "Submit",
                               onClick: () => console.log(id)
                             }}/>
    </>
  );
});
