import {FormInputExtendedProps, isFormFieldValid,} from "@dirootie-web/components-types";
import {ParameterCreationType} from "@domain";
import {Field} from "formik";
import {observer} from "mobx-react";
import {Form, Input} from "taltech-styleguide";
import {InputNumber} from "./InputNumber";

export type RandomChipProps = {
  random: boolean;
  onClick: (t: ParameterCreationType) => void;
};

export const RandomChip = observer((props: RandomChipProps) => {
  const image = props.random ? "/Random.svg" : "/Not_Random.svg";
  const result = (
    <img
      src={image}
      alt={"success Shuffle"}
      className={"ebd-shuffle-btn"}
      onClick={() => props.onClick(props.random ? "CONSTANT" : "RANDOM")}
    />
  );
  return result;
});

export type InputRandomProps = FormInputExtendedProps & {};

export const InputRandom = ({
                              id,
                              formState,
                              disabled,
                              ...props
                            }: InputRandomProps) => {
  return (
    <Form.Group className={props.elementClass + " mb-3 d-flex w-100 my-0"}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <div className={'d-flex align-items-end'}>
        <InputNumber
          parentClass="ebd-min-input-width mx-1 my-0"
          id="valueDefault"
          label="Value"
          formState={formState as any}
        />
        <RandomChip
          random={formState.getFieldMeta("creationType").value === "RANDOM"}
          onClick={(t) => {
            formState.setFieldValue("creationType", t);
          }}
        />
      </div>
      {formState.getFieldMeta("creationType").value === "RANDOM" ? (
        <div className={"d-flex align-items-end"}>
          <Field
            className="ebd-min-input-width mx-1 my-0"
            name={"creationOptions.min"}
            component={Input}
            isInvalid={!isFormFieldValid("creationOptions.min", formState)}
            disabled={disabled}
            type="number"
          />
          <Field
            className="ebd-min-input-width mx-1 my-0"
            name={"creationOptions.max"}
            component={Input}
            isInvalid={!isFormFieldValid("creationOptions.max", formState)}
            disabled={disabled}
            type="number"
          />{" "}
        </div>
      ) : (
        <></>
      )}
    </Form.Group>
  );
};
