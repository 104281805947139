import {BuildingConfigurationTable, ToolbarList} from "@components";
import {Entity, PagedResult} from "@dirootie-core/core-types";
import {useEffect, useState} from "react";
import {buildingConfigurationTable} from "../../../../../../domain/building/SampleBuildingConfiguration";

export const ConfigurationIndexScreen: React.FC<any> = () => {
  const [parameter, setParameter] = useState<PagedResult<Entity & { name: string; description: string }>>({
    total: 0,
    page: 0,
    size: 0,
    items: [],
  });
  useEffect(() => {
    setParameter({
      items: buildingConfigurationTable,
      total: buildingConfigurationTable.length,
      page: 1,
      size: 10,
    }); //this.apiService.getAll(queryServer);
  }, []);
  return (
    <>
      <ToolbarList/>

      <BuildingConfigurationTable elements={parameter}/>
    </>
  );
};
