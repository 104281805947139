import {
  InputComponent,
  RadioButtonComponent,
  ReactToastifyUserNotificationService,
  SelectComponent,
  ShuffleRadioButton
} from "@components";
import {Form as FormikForm, Formik, FormikValues} from "formik";
import _ from "lodash";
import React, {FC, useEffect, useState} from "react";
import {Button, Form, Table} from "taltech-styleguide";
import {application} from "../../../configuration/Application.bootstrap";
import {useStore} from "../../../domain/store";
import {getSpaceValidationSchema} from "./SpaceValidationSchema";

const validateBeforeSubmit = (values: FormikValues): boolean => {
  let firstSum = 0;
  let secondSum = 0;
  _.filter(values, (v, k) => _.includes(k, 'R_1_')).filter(x => typeof x === "number").forEach(item => {
    firstSum += item;
    return firstSum;
  });
  _.filter(values, (v, k) => _.includes(k, 'R_2_')).filter(x => typeof x === "number").forEach(item => {
    secondSum += item;
    return secondSum;
  });
  const smallerRow: "First" | "Second" = firstSum < secondSum ? "First" : "Second";
  const x: "First" | "Second" = _.findKey(values, (o) => o === '...')?.includes('R_1_') ? "First" : "Second";
  const notificationService = new ReactToastifyUserNotificationService(application.getLogger());
  if (x !== smallerRow) {
    notificationService.notify({
      title: `Change Variable Length to be on the ${smallerRow} row`,
      className: "ebd-toast-custom-styling",
    }, "error");
  }
  return x === smallerRow;
}

const SpaceConfigurationTable: FC<any> = ({
                                            rowOneRooms,
                                            rowTwoRooms,
                                            config,
                                            ...props
                                          }) => {
  const {buildingConfigurationFormStore, buildingStore} = useStore();


  const initialValues = _.cloneDeep(buildingConfigurationFormStore.thirdStep);
  const validationSchema = getSpaceValidationSchema(rowOneRooms, rowTwoRooms);
  const [firstRowDisabledInput, setFirstRowDisabledInput] = useState<number | undefined>(_.cloneDeep(buildingConfigurationFormStore.thirdStep.firstRowVariableIndex));
  const [secondRowDisabledInput, setSecondRowDisabledInput] = useState<number | undefined>(_.cloneDeep(buildingConfigurationFormStore.thirdStep.secondRowVariableIndex));
  const [successElements, setSuccessElements] = useState<number[]>([]);
  const [secondSuccessElements, setSecondSuccessElements] = useState<number[]>([]);

  useEffect(() => {
    setFirstRowDisabledInput(firstRowDisabledInput)
    setSecondSuccessElements(secondSuccessElements)
  }, [])

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (!validateBeforeSubmit(values)) return
          buildingConfigurationFormStore.setSpaceConfigurationState(values);
          _.set(buildingConfigurationFormStore.thirdStep, `secondRowVariableIndex`, secondRowDisabledInput);
          _.set(buildingConfigurationFormStore.thirdStep, `firstRowVariableIndex`, firstRowDisabledInput);
          buildingConfigurationFormStore.thirdStep.config.isFormDirty = true;
          buildingConfigurationFormStore.currentStep = config.stepNumber + 1;

        }}
      >
        {(formik) => {
          return (
            <FormikForm className={!config.disabled ? "active" : ""}>
              <Table className={'ebd-configuration-table-height'}>
                <thead>
                <tr>
                  <th>Room no.</th>
                  <th>Room space function</th>
                  <th>Length</th>
                  <th>Variable length</th>
                </tr>
                </thead>
                <tbody>
                {_.times(rowOneRooms || 0, (index) => (
                  <tr key={index}>
                    <td className={'align-middle'}>{`R 1.${index + 1}`}</td>
                    <td className={'align-middle'}>
                      <Form.Group>
                        <SelectComponent
                          changeFn={() => console.log("Changed")}
                          disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                          options={buildingStore.spaceFunctions.rooms}
                          name={`R_1_${index + 1}_SpaceFunction`}/>
                      </Form.Group>
                    </td>
                    <td className={"w-50 align-middle"}>
                      <div className={"d-flex align-items-center"}>
                        <Form.Group>
                          <InputComponent
                            isInteger={true}
                            customClassName={'ebd-input-width'}
                            step={!config.disabled ? "space Configuration" : "Other"}
                            disabled={buildingConfigurationFormStore.isReadOnly || firstRowDisabledInput === index || config.disabled}
                            type={"number"}
                            min={0}
                            placeholder={"ex. 4"}
                            name={`R_1_${index + 1}_Length`}
                          />
                        </Form.Group>
                        <Form.Group className="mx-1">
                          <ShuffleRadioButton
                            disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                            changeFn={() => setSuccessElements(_.xor(successElements, [index]))}
                            name={`R_1_${index + 1}_shuffle`}/>
                        </Form.Group>
                        {successElements.includes(index) || buildingConfigurationFormStore.thirdStep[`R_1_${index + 1}_shuffle`] ? (
                          <div className={"d-flex w-100"}>
                            <Form.Group className="mx-2">
                              <InputComponent
                                isInteger={true}
                                disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                                type={"number"}
                                placeholder={"ex. 2.0"}
                                name={`R_1_${index + 1}_Min`}/>
                            </Form.Group>
                            <Form.Group>
                              <InputComponent
                                isInteger={true}
                                disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                                type={"number"}
                                placeholder={"ex. -2.0"}
                                name={`R_1_${index + 1}_Max`}/>
                            </Form.Group>
                            <div/>
                          </div>
                        ) : (<div className={"ebd-full-width w-100"}></div>)}
                      </div>
                    </td>
                    <td className={'align-middle'}>
                      <Form.Group className="">
                        <RadioButtonComponent
                          step={'buildingSpaceFunction'}
                          changeFn={(e) => {
                            setFirstRowDisabledInput(index);
                            setSecondRowDisabledInput(undefined);
                          }}
                          value={firstRowDisabledInput === index ? 0 : undefined}
                          disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                          isInline={true}
                          name={`R_1_${index + 1}_VariableLength`}
                          options={[""]}
                        />
                      </Form.Group>
                    </td>
                  </tr>
                ))}
                <tr className={"ebd-corridor-col"}>
                  <td scope="row">C 1.0</td>
                  <td>
                    <Form.Group className="mb-3">
                      <InputComponent
                        disabled={buildingConfigurationFormStore.isReadOnly || true}
                        type={"text"}
                        name={`corridorFunction`}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group className="mb-3">
                      <InputComponent
                        customClassName={'ebd-input-width'}
                        disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                        type={"number"}
                        min={0}
                        placeholder={"ex. 4"}
                        name={`corridorLength`}
                      />
                    </Form.Group>
                  </td>
                  <td></td>
                </tr>
                {_.times(rowTwoRooms || 0, (index) => (
                  <tr key={index}>
                    <td className={'align-middle'}>{`R 2.${index + 1}`}</td>
                    <td className={'align-middle'}>
                      <Form.Group>
                        <SelectComponent
                          changeFn={() => console.log("Changed")}
                          disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                          options={buildingStore.spaceFunctions.rooms}
                          name={`R_2_${index + 1}_SpaceFunction`}/>
                      </Form.Group>
                    </td>
                    <td className={"w-50 align-middle"}>
                      <div className={"d-flex align-items-center"}>
                        <Form.Group>
                          <InputComponent
                            isInteger={true}
                            step={!config.disabled ? "space Configuration" : "Other"}
                            disabled={buildingConfigurationFormStore.isReadOnly || secondRowDisabledInput === index || config.disabled}
                            type={"number"}
                            min={0}
                            placeholder={"ex. 4"}
                            name={`R_2_${index + 1}_Length`}
                          />
                        </Form.Group>
                        <Form.Group className="mx-1">
                          <ShuffleRadioButton
                            changeFn={() => setSecondSuccessElements(_.xor(secondSuccessElements, [index]))}
                            disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                            name={`R_2_${index + 1}_shuffle`}
                          />
                        </Form.Group>
                        {secondSuccessElements.includes(index) || buildingConfigurationFormStore.thirdStep[`R_2_${index + 1}_shuffle`] ? (
                          <div className={"d-flex w-100"}>
                            <Form.Group className="mx-2 ">
                              <InputComponent
                                isInteger={true}
                                disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                                type={"number"}
                                placeholder={"ex. 2.0"}
                                name={`R_2_${index + 1}_Min`}/>
                            </Form.Group>
                            <Form.Group>
                              <InputComponent
                                isInteger={true}
                                disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                                type={"number"}
                                placeholder={"ex. -2.0"}
                                name={`R_2_${index + 1}_Max`}/>
                            </Form.Group>
                          </div>
                        ) : (<div className={"ebd-full-width w-100"}></div>)}
                      </div>
                    </td>
                    <td className={'align-middle'}>
                      <Form.Group>
                        <RadioButtonComponent
                          step={'buildingSpaceFunction'}
                          changeFn={(e) => {
                            setSecondRowDisabledInput(index);
                            setFirstRowDisabledInput(undefined);
                          }}
                          disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                          value={secondRowDisabledInput === index ? 0 : undefined}
                          isInline={true}
                          name={`R_2_${index + 1}_VariableLength`}
                          options={[""]}
                        />
                      </Form.Group>
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
              {!config.disabled && <Button type="submit" className={'position-fixed invisible'}>Next</Button>}
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};

export default SpaceConfigurationTable;
