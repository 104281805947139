import {createFormProperties, DisabledTabComponent, MediaItemEditForm, Page, PageContentForm,} from "@components";
import {observer} from "mobx-react";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {mediaItemStore as entityStore} from "./MediaItem.store";

export const MediaItemCreateScreen: React.FC<any> = observer(
  (props: { subjectId: string }) => {
    const navigator = useNavigate();
    const location = useLocation();

    const formProps = createFormProperties(
      "CREATE",
      {
        onAction: (action) => {
          return Promise.resolve(entityStore.onAction(action));
        },
        schema: entityStore.options.schema,
        element: entityStore.getEmpty({subjectId: props.subjectId}),
      },
      entityStore.options,
      navigator
    );
    return (
      <Page title="Create new resource">
        <div className={'container'}>
          {/*component <DisabledTabComponent/> is the component responsible for rendering disabled tabs*/}
          {/*elements property is array of strings which will be for specific tab element*/}
          {/*activeDisabled property to choose which tab is active to change its color to pink and put underline beneath it*/}
          {/*size property is size of the tab "lg"=>for large tabs   "sm"=>for small tabs*/}
          <DisabledTabComponent elements={["Summary", "Lecturers", "Students", "Groups", "Resources", "Assignments"]} activeDisabled={location.pathname.includes("assignments") ? 5 : 4}/>

          {location.pathname.includes("assignments") &&
            <DisabledTabComponent elements={["Summary", "Students", "Resources", "Building configurations"]} activeDisabled={2} size={"sm"}/>}


          <PageContentForm
            form={{Component: MediaItemEditForm, options: {...formProps}}}
            mode="CREATE"
            dispatcher={{
              onAction: (action) => {
                return Promise.resolve(entityStore.onAction(action));
              },
            }}
          />
        </div>
      </Page>
    );
  }
);
