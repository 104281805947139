import { IKeyedObject } from '@dirootie-extensions/typescript';

export type TemplatePlaceHolder = {
  begin?: string;
  end?: string;
};

export const buildUrlPathFromPathTemplate = (
  pathTemplate: string,
  substitutionParameters: any,
  addQueryString = false,
  placeHolder?: TemplatePlaceHolder
): string => {
  let urlPath = pathTemplate;
  const queryString = [];
  const resultPlaceHolder: TemplatePlaceHolder = { begin: '{', end: '}' };
  if (placeHolder !== undefined) {
    const { begin = '', end = '' } = placeHolder;
    resultPlaceHolder.begin = begin;
    resultPlaceHolder.end = end;
  }

  for (var param in substitutionParameters) {
    if (
      substitutionParameters.hasOwnProperty(param) &&
      substitutionParameters[param] !== undefined
    ) {
      const parameterKey = `${resultPlaceHolder.begin}${param}${resultPlaceHolder.end}`;
      if (urlPath.includes(parameterKey)) {
        urlPath = urlPath.replace(parameterKey, substitutionParameters[param]);
      } else {
        if (addQueryString) {
          queryString.push(`${param}=${substitutionParameters[param]}`);
        }
      }
    }
  }
  if (queryString.length > 0) {
    urlPath = `${urlPath}?${queryString.join('&')}`;
  }
  return urlPath;
};

export const buildPathFromRouteParams = (
  pathTemplate: string,
  params?: IKeyedObject<string>,
  addQueryString: boolean = false
) => {
  if (params === undefined) {
    return pathTemplate;
  }
  const placeHolder = { begin: ':' };
  let substitutionParameters = {} as any;
  const keys: string[] =
    params.keys !== undefined ? (params as any).keys : Object.keys(params);
  keys.forEach((x) => {
    substitutionParameters[x] =
      params['get'] !== undefined ? (params as any).get(x) : (params as any)[x];
  });
  return buildUrlPathFromPathTemplate(
    pathTemplate,
    substitutionParameters,
    addQueryString,
    placeHolder
  );
};

export const getParamsFromObjectNeedForPathTemplate = (
  entity: any,
  template: any
) => {
  const templateParametersNames = template
    .split('/')
    .filter((x: string) => x.startsWith(':'))
    .map((x: string) => x.slice(1));
  let result: IKeyedObject<string> = {};
  templateParametersNames.forEach((name: string) => {
    if (entity[name] !== undefined) {
      result[name] = entity[name].toString();
    }
  });
  return result;
};

export const buildPathFromObject = (
  sourceObj: any,
  template: any,
  options?: IKeyedObject<string>
) => {
  const templateParameters = getParamsFromObjectNeedForPathTemplate(
    sourceObj,
    template
  );
  const resOptions =
    options !== undefined
      ? { ...templateParameters, ...options }
      : templateParameters;
  return buildPathFromRouteParams(template, resOptions);
};
