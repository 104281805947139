// index.ts
// select.types.ts
// forms.utils.ts

import { observable } from "mobx";
import React from "react";
import { Input } from "taltech-styleguide";
import { isNotNullOrEmptyString } from "@dirootie-extensions/javascript";
import { SelectOptions } from "../../packages/web/components-types/src/forms";
import { getArrayAsOptions } from "../../packages/web/components-types/src/forms/forms.utils";

export class SelectFromArray extends React.Component<any, any> {
  @observable items: SelectOptions = [];
  @observable selected: string | undefined = undefined;

  constructor(
    props: { onClick: any; items: any[]; selected: string } & {
      select: string | undefined;
    }
  ) {
    super(props);
    if (props.select) {
      this.selected = props.select;
    }
    this.setItems(props);
  }

  setItems(props: any) {
    this.items = getArrayAsOptions(props.items as any);
    if (isNotNullOrEmptyString(props.translationKey)) {
      this.items.forEach((x) => {
        x.text = `${props.translationKey}.${x.text}`;
      });
    }
  }

  render() {
    return (
      <>
        <Input
          as="select"
          size="sm"
          value={this.selected}
          onChange={(evt) => {
            this.selected = evt.currentTarget.value;
          }}
        >
          {this.items.map((x) => (
            <option key={x.value} value={x.value}>
              {x.text.toString()}
            </option>
          ))}
        </Input>
      </>
    );
  }
}
export default SelectFromArray;
