import {cols, createColActions, defaultProps, TableTalTech,} from "@components";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";

export const ParametersTable: React.FC<any> = observer(
  (props: { elements: any }) => {
    const navigator = useNavigate();
    const columns = [
      cols.id(),
      cols.name(),
      cols.type(),
      cols.valueDefault(),
      cols.description(),
      cols.createdDate(),
      cols.modifiedDate(),
      {
        formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => createColActions(navigator, {
          showCopy: true,
          showDelete: true,
          showEdit: true,
          onEdit: () => navigator(row.id)
        }),
      },
    ];
    const prop = {...defaultProps, data: props.elements.items, columns};
    return <TableTalTech tableProps={prop}></TableTalTech>;
  }
);
