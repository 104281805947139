import {useField} from "formik";
import React, {FC} from "react";
import {FormFeedback, STATUS_TYPE} from "taltech-styleguide";


type IErrorComponent = {
  fieldName: string
}

export const ErrorComponent: FC<IErrorComponent> = (props: {
  fieldName: string;
}) => {
  const [field, meta, helpers] = useField(props.fieldName);
  return (
    <>
      {meta.touched && meta.error ? (
        <FormFeedback
          style={{
            color: "red",
            fontSize: 12,
            opacity: 0.65,
          }}
          type={STATUS_TYPE.DANGER}
        >
          {meta.error}
        </FormFeedback>
      ) : null}
    </>
  );
};

export default ErrorComponent;
