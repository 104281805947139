import { BuildingConfigurationTable, Page, ToolbarCreate } from "@components";
import { Entity, PagedResult } from "@dirootie-core/core-types";
import { useEffect, useState } from "react";
import { Container } from "taltech-styleguide";
import { buildingConfigurationTable } from "../../../../domain/building/SampleBuildingConfiguration";

const BuildingConfigurationsScreen: React.FC<any> = () => {
  const [parameter, setParameter] = useState<
    PagedResult<Entity & { name: string; description: string }>
  >({
    total: 0,
    page: 0,
    size: 0,
    items: [],
  });
  useEffect(() => {
    setParameter({
      items: buildingConfigurationTable,
      total: buildingConfigurationTable.length,
      page: 1,
      size: 10,
    }); //this.apiService.getAll(queryServer);
  }, []);

  return (
    <Page title="Building configurations">
      <ToolbarCreate />
      <BuildingConfigurationTable elements={parameter} />
    </Page>
  );
};

export default BuildingConfigurationsScreen;
