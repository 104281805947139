import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import React, { useEffect, useState } from "react";
import "../../styles/advancedTable.css";
import { IconXmark } from "../icons";

let tableData = require("../services/data.json");

export const AdvancedTable = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [renderedElements, setRenderedElements] = useState<String[]>([]);
  const [filters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    Esitaja: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    Tüüp: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    Periood: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    Tekitatud: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    Osakond: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    Omab_14p_perioodi: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    Asendaja: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    Kooskõlastaja: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    Jääk_peale_kinnitamist: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const [filterItems, setFilterItems] = useState<
    { key: string; value: string }[]
  >([]);

  useEffect(() => {
    setProducts(tableData.data);
    products.length > 0 && setRenderedElements(Object.keys(products[0]));
  }, [products]);

  const removeFromFilterItems = (el: { key: string; value: string }) => {
    setFilterItems(filterItems.filter((ele) => ele !== el));
  };

  const renderHeader = () => {
    return (
      <div className="flex align-items-center justify-content-between w-100">
        <div className="d-flex">
          {filterItems.map((el) => {
            return (
              <div className={"ebd-tag d-flex align-items-center"}>
                <h6 className="ebd-label-head">
                  {el.key}:{" "}
                  <span className={"mr-3 ebd-label-head-bold"}>{el.value}</span>
                  <div
                    className="d-inline-block cursor-pointer"
                    onClick={() => removeFromFilterItems(el)}
                  >
                    <IconXmark />
                  </div>
                </h6>
              </div>
            );
          })}
        </div>
        <h6 className="edb-heading-light">Eemalda Koik filtrid</h6>
      </div>
    );
  };

  const customFilterFn = (e: any) => {
    setFilterItems([
      ...filterItems,
      { key: e.field, value: e.constraints.constraints[0].value },
    ]);
  };

  const header = filterItems.length > 0 && renderHeader();

  return (
    <div>
      <div className="card">
        <DataTable
          onValueChange={(filteredData) => console.log(filteredData)}
          size={"small"}
          value={products}
          paginator
          className="p-datatable-customers"
          header={header}
          rows={10}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          rowsPerPageOptions={[10, 25, 50]}
          dataKey="id"
          rowHover
          filters={filters}
          filterDisplay="menu"
          responsiveLayout="scroll"
          globalFilterFields={[
            "name",
            "country.name",
            "representative.name",
            "balance",
            "status",
          ]}
          emptyMessage="No customers found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          {renderedElements.map((el) => {
            return (
              <Column
                field={el as any}
                header={el}
                style={{ width: "12rem" }}
                sortable
                onFilterApplyClick={(e) => customFilterFn(e)}
                filter
              />
            );
          })}
        </DataTable>
      </div>
    </div>
  );
};
