import {EntityOptions, EntityStore, MaterialTable, Page, ToolbarList,} from "@components";
import {PagedResult} from "@dirootie-core/core-types";
import {getMaterialValidationSchema, Material} from "@domain";
import {inject, observer} from "mobx-react";
import {useEffect, useState} from "react";
import {configSettings} from "../../../../../../../configuration/ConfigurationSettings";
//JUST to provoke a build

export const materialEntityOptions: EntityOptions<Material> = {
  schema: getMaterialValidationSchema,
  empty: {
    class: "CONCRETE",
    name: "",
    description: undefined,
    sourceType: "SYSTEM",
    sourceId: "ad51df0b-d350-4fd6-a7cb-904a938e7bbb",
  },
  path: "buildingComponents.materials",
};

export const entityStore = new EntityStore(materialEntityOptions);

export const MaterialIndexScreen: React.FC<any> = inject(
  "materialStore",
  "serviceFactory"
)(
  observer((props) => {
    const [elements, setElements] = useState<PagedResult<Material>>({
      total: 0,
      page: 0,
      size: 0,
      items: [],
    });
    const service = props.serviceFactory.createApiService(
      configSettings.api.endpoints.buildingComponents.materials
    );
    useEffect(() => {
      props.materialStore
        ?.getMaterials({page: 1, size: 10})
        .then((x: any) => {
          setElements(x);
        });
    }, [props.materialStore]);

    return (
      <Page title="Materials">
        <div>
          <ToolbarList/>

          <MaterialTable service={service}/>
        </div>
      </Page>
    );
  })
);
