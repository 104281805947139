import {convertToTitleCase, formatDate, isEmptyArray,} from "@dirootie-extensions/javascript";
import {SummaryResource} from "@domain";
import _ from "lodash";
import {FILTER_TYPES, Initials, STATUS_TYPE, StatusTag} from "taltech-styleguide";
import {createColumnProps} from "./Col.utils";

export const columnDefaultProps = {
  classes: "test-column",
  sort: false,
};

export const colIdDefaults = {
  dataField: "id",
  hidden: true,
  text: "ID",
};

export const colMinDefaults = {
  classes: "w-10",
  dataField: "creationOptions.min",
  text: "Min",
}
export const colMaxDefaults = {
  classes: "w-10",
  dataField: "creationOptions.max",
  text: "Max",
}

export const colCourseLogoDefaults = {
  classes: "ebd-initials-first",
  formatter: (col: any, row: any, index: any, extraData: any) => {
    return (
      <>
        {row.picture?.url ===
        "https://taltechedubimtesteufugst.blob.core.windows.net/" ? (
          <Initials className={"m-1"} name={row.name} key={row.id} size="xs"/>
        ) : (
          <Initials
            className={"m-1"}
            imageUrl={row.picture.url}
            key={row.id}
            size="xs"
          />
        )}
      </>
    );
  },
}

export const colLecturerDefaults = {
  classes: "ebd-initials-column",
  dataField: "Lecturers",
  formatter: (col: any, row: any, index: any, extraData: any) => {
    console.log(row, col);
    if (!row.lecturers) {
      return (
        <>
          {_.times(Math.floor(Math.random() * 3) + 1, (index2) => {
            return (
              <Initials
                className={"m-1"}
                key={row.id}
                name={
                  row.name === "Course demo 1" && index2 === 0
                    ? "M"
                    : String.fromCharCode(97 + Math.floor(Math.random() * 26))
                }
                size="xs"
              />
            );
          })}
        </>
      );
    }
    if (row.lecturers && row.lecturers.length > 4) {
      const displayedItems = row.lecturers.slice(0, 4); // Extract the first 5 items
      return (
        <>
          {displayedItems.map((x: SummaryResource) => {
            return (
              <div className={"ebd-initials"}>
                <Initials
                  className={"m-1"}
                  key={x.id}
                  name={x.displayName}
                  imageUrl={x.pictureUrl}
                  size="xs"
                />
              </div>
            );
          })}
          <div className={"ebd-last-initial"}>
            <div>
              <Initials
                className={"m-1"}
                key={row.lecturers[5].id}
                name={row.lecturers[5].displayName}
                imageUrl={row.lecturers[5].pictureUrl}
                size="xs"
              />
            </div>
            <div className={"ebd-remaining-initials"}>
              +{row.lecturers.length - 4}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {row.lecturers.map((x: SummaryResource) => {
            return (
              <div className={"ebd-initials"}>
                <Initials
                  className={"m-1"}
                  key={x.id}
                  name={x.displayName}
                  imageUrl={x.pictureUrl}
                  size="xs"
                />
              </div>
            );
          })}
        </>
      );
    }
  },
  sort: true,
  text: "Lecturers",
}

export const colNumberDefaults = {
  dataField: "number",
  text: "Number",
};

export const colSubmissionsDefaults = {
  classes: "w-10",
  dataField: "submissionDate",
  sort: true,
  text: "Submitted",
}

export const colTitleDefaults = {
  dataField: "title",
  sort: true,
  formatter: (col: any, row: any, index: any, extraData: any) => {
    return <div className={"test-column-name"}>{col}</div>;
  },
  text: "Title",
  filterObject: {
    defaultValue: undefined,
    minChars: 3,
    placeholder: "Title",
    type: FILTER_TYPES.TEXT,
  },
};

export const colValueDefaultDefaults = {
  classes: "w-10",
  dataField: "valueDefault",
  text: "Value",
}

export const colStateDefaults = {
  dataField: "state",
  // filterObject: {
  //   options: ,
  //   placeholder: '´State',
  //   type: FILTER_TYPES.MULTISELECT
  // },
  formatter: (col: any, row: any, index: any, extraData: any) => {
    const states = Object.keys(extraData.states).filter(
      (x) => true //   (x) => x.toUpperCase() === col.toUpperCase()
    );
    let stateType = STATUS_TYPE.DANGER;
    let stateName = "UNKNOWN";
    if (!isEmptyArray(states)) {
      const state = extraData.states[states[0]];
      stateType = state.type;
      stateName = state.name;
    }
    console.log("Args", col, row, index, extraData);
    return <StatusTag type={stateType}>{stateName}</StatusTag>;
  },
  formatExtraData: {
    states: {
      active: {
        name: "Active",
        state: "Active",
        type: STATUS_TYPE.SUCCESS,
      },
      archived: {
        name: "Archived",
        state: "Archived",
        type: STATUS_TYPE.INFO,
      },
      finished: {
        name: "Finished",
        state: "Finished",
        type: STATUS_TYPE.LIGHT,
      },
    },
  },
  sort: true,
  text: "State",

}

export const colEmailDefaults = {
  classes: "w-20",
  dataField: "email",
  sort: true,
  text: "Email",
  filterObject: {
    defaultValue: undefined,
    minChars: 3,
    placeholder: "Email",
    type: FILTER_TYPES.TEXT,
  },
}

export const colNameDefaults = {
  classes: "w-20",
  dataField: "name",
  sort: true,
  text: "Name",
  formatter: (col: any, row: any, index: any, extraData: any) => {
    return <div className={"test-column-name"}>{col}</div>;
  },
  filterObject: {
    defaultValue: undefined,
    minChars: 3,
    placeholder: "Name",
    type: FILTER_TYPES.TEXT,
  },
};

export const colDescriptionDefaults = {

  dataField: "description",
  formatter: (col: any, row: any, index: any, extraData: any) => {
    return <div className={"test-column-description"}>{col}</div>;
  },
  text: "Description",
};

export const colDateDefaults = createColumnProps(columnDefaultProps, {
  // filterObject: {
  //   defaultValue: undefined,
  //   type: FILTER_TYPES.DATE,
  // },
  formatter: (col: Date) => formatDate(col),
  sort: true,
});

export const colCreatedDateDefaults = createColumnProps(colDateDefaults, {
  classes: "w-5 ml-auto",
  dataField: "createdDate",
  text: "Created at",
});

export const colModifiedDateDefaults = createColumnProps(colDateDefaults, {
  classes: "w-5 ml-auto",
  dataField: "modifiedDate",
  text: "Modified at",
});

export const colStartDateDefaults = createColumnProps(colDateDefaults, {
  classes: "w-5",
  dataField: "startDate",
  text: "Start date",
});

export const colEndDateDefaults = createColumnProps(colDateDefaults, {
  classes: "w-5",
  dataField: "endDate",
  text: "End date",
});

export const colEnumDefaults = {
  classes: "w-10",
  dataField: "type",
  sort: true,
  text: "Type",
  formatter: (col: string) => convertToTitleCase(col),
  // filterObject: {
  //   defaultValue: undefined,
  //   minChars: 3,
  //   placeholder: "Name",
  //   type: FILTER_TYPES.MULTISELECT,
  // },
};
