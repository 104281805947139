import {mergeProps,} from "@dirootie-extensions/javascript";
import _ from "lodash";
import {IVisualProperties} from "packages/web/components-types/src/components.types";
import React from "react";
import {toolbarExtendedDefaultProps} from "./Toolbars.defaults";
import {ToolbarElement, ToolbarElementProps, ToolbarProps, ToolbarWithActionsProps,} from "./Toolbars.types";

export const getElementsAsArray = (
  props: ToolbarElement | ToolbarElement[] | undefined
): ToolbarElement[] => {
  if (_.isUndefined(props)) {
    return [];
  }
  const result = _.isArray(props) ? props : [props];
  return result;
};

export const elementMapper = (
  toolbarProps: ToolbarProps,
  props: ToolbarElement
) => {
  if (React.isValidElement(props)) {
    return props;
  }
  const elemProps = props as ToolbarElementProps;
  let {Component, onClick, action, ...rest} = elemProps;
  let resultProps: any = {...rest, action};
  if (onClick === undefined) {
    onClick = (ev: any) => {
      const res = _.isString(action)
        ? {
          action: action as any,
          payload: undefined,
          options: {evt: ev},
        }
        : action;

      toolbarProps!.onAction!(res);
    };
    resultProps.onClick = onClick;
  }
  const result = <Component className={"mr-2"} {...resultProps} />;
  return result;
};

export const mapChildrenElements = (
  toolbarProps: ToolbarProps,
  props: ToolbarElement | ToolbarElement[] | undefined,
  mapper: (
    toolbarProps: ToolbarProps,
    props: ToolbarElement
  ) => JSX.Element = elementMapper
): JSX.Element => {
  const elems = getElementsAsArray(props);
  const result = (
    <>
      {" "}
      {elems.map((x) => {
        return mapper(toolbarProps, x);
      })}
    </>
  );
  return result;
};

export const setElementVisibility = (
  props: ToolbarWithActionsProps,
  name: string
): IVisualProperties => {
  let resultName = name[0].toUpperCase() + name.substring(1, name.length);

  const propertyButtons = `btn${resultName}`;
  const propsIndex = props as any;
  const element = propsIndex.buttons[propertyButtons];
  element.visible = propsIndex[`${name}Visible`];
  element.disabled = propsIndex[`${name}Disabled`];
  return element;
};

export const createToolbarExtendedProps = (
  props: ToolbarWithActionsProps
): ToolbarWithActionsProps => {
  const defaultProps = _.cloneDeep(toolbarExtendedDefaultProps);
  const resultProps = mergeProps(defaultProps, props as any);
  setElementVisibility(resultProps, "createNew");
  setElementVisibility(resultProps, "create");
  setElementVisibility(resultProps, "save");
  setElementVisibility(resultProps, "delete");
  setElementVisibility(resultProps, "back");
  return resultProps;
};
