import { PublicClientApplication } from '@azure/msal-browser';
import { Application, ApplicationOptions } from '@dirootie-apps/apps-types';
import {
  AxiosHttpClient,
  AxiosHttpClientOptions,
} from '@dirootie-core/http-extensions-axios';
import {
  BuildingComponentsStore,
  MaterialApiService,
  MaterialStore,
  ParameterApiService,
} from '@domain/building-components';
import { ReactToastifyUserNotificationService } from '../components/notifications/ReactToastifyUserNotificationService';
import { CourseApiService } from '../domain/courses/CourseApiService';
import { CoursesStore } from '../domain/courses/CoursesStore';
import { ApiService } from '../packages/core/api-types/src/api.service';
import {
  ConsoleLogger,
  LoggingConfig,
  LogLevel,
} from '../packages/core/logging-types/src';
import { TableSearchStoreFactory } from '../packages/web/components-types/src/tables/table-search-store';
import { ApplicationConfig } from './Configuration.types';
import { configSettings } from './ConfigurationSettings';
import { MainAppViewStore } from './MainAppViewStore';
import { SessionStore } from '../domain/access-control/Session.store';
import {
  AuthenticationService,
  AuthorizationService,
} from '../domain/access-control/Authentication.service';
import { ViewNavigator } from './ViewNavigator';
import { AreaConfiguration } from '../domain/web-applications/AreaConfiguration';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { serverConnectionFailedNotification } from '@dirootie-web/components-types';
import { HandlerFactory } from '../components/actions/Handler.factory';

const areas: AreaConfiguration[] = [];
const viewNavigator = new ViewNavigator({ areas: areas });

const msalConfig = configSettings.authentication.options;
export const msalInstance = new PublicClientApplication(msalConfig);
export const loginApiRequest = {
  scopes: msalConfig.auth.scopes,
};

const loggingConfig: LoggingConfig = {
  level: LogLevel.ALL,
  provider: 'Console',
};
const logger = new ConsoleLogger(loggingConfig);

const userNofificationService = new ReactToastifyUserNotificationService(
  logger
);

const axiosMsalInterceptor = async (config: any) => {
  const account = msalInstance.getAllAccounts()[0];
  const msalResponse = await msalInstance.acquireTokenSilent({
    ...loginApiRequest,
    account: account,
  });
  config.headers!['Authorization'] = `Bearer ${msalResponse.accessToken}`;
  return config;
};

const axiosApiErrorInterceptor = {
  response: (response: any) => response,
  error: (error: AxiosError) => {
    if (_.isUndefined(error)) {
      console.log('Axior error undefined');
      return Promise.reject();
    }
    if (_.isUndefined(error.response)) {
      if (_.isUndefined(error.code)) {
        console.log('Axior error code undefined');
        return Promise.reject();
      }
      if (error.code === 'ERR_NETWORK') {
        userNofificationService.error(serverConnectionFailedNotification);
        return Promise.reject();
      }
      console.log('Axior error code undefined');
      return Promise.reject();
    }
    if (_.isUndefined(error.response) || _.isUndefined(error.response.status)) {
      console.log('Axior error undefined');
      return Promise.reject();
    }
    if (error!.response!.status === 400) {
      viewNavigator.navigateToNotFound();
      return Promise.reject();
    }
    if (error!.response!.status === 401) {
      viewNavigator.navigateToLogin();
      return Promise.reject();
    }
    if (error!.response!.status === 403) {
      viewNavigator.navigateToUnauthorized();
      return Promise.reject();
    }
    if (error!.response!.status === 404) {
      viewNavigator.navigateToNotFound();
      return Promise.reject();
    }
    return Promise.reject(error!);
  },
};

const axiosOptions: AxiosHttpClientOptions = {
  baseUrl: configSettings.api.baseUrl,
  interceptors: {
    request: [axiosMsalInterceptor],
    response: [axiosApiErrorInterceptor],
  },
};

const axiosHttpClient = new AxiosHttpClient(axiosOptions);

const mainAppViewStore = new MainAppViewStore(userNofificationService, logger);
const serviceFactory = ApiService.createFactory(
  axiosHttpClient,
  logger,
  configSettings,
  mainAppViewStore
);
const tableSearchStoreFactory = new TableSearchStoreFactory(serviceFactory);
const courseApiService = new CourseApiService(
  axiosHttpClient,
  configSettings.api.endpoints.courses,
  logger
);

const paramterApiService = new ParameterApiService(
  axiosHttpClient,
  configSettings.api.endpoints.buildingComponents.parameters,
  logger
);

const materialApiService = new MaterialApiService(
  axiosHttpClient,
  configSettings.api.endpoints.buildingComponents.materials,
  logger
);
const sessionStore = new SessionStore();
const authorizationService = new AuthorizationService(
  sessionStore,
  viewNavigator
);
const authenticationService = new AuthenticationService(
  serviceFactory as any,
  sessionStore,
  msalInstance,
  authorizationService,
  viewNavigator
);
const stores = {
  coursesStore: new CoursesStore(courseApiService, serviceFactory as any),
  buildingComponentsStore: new BuildingComponentsStore(
    paramterApiService,
    serviceFactory as any
  ),
  materialStore: new MaterialStore(materialApiService, serviceFactory as any),
  mainAppViewStore,
  serviceFactory: serviceFactory,
  tableSearchStoreFactory: tableSearchStoreFactory,
  logger: logger,
  sessionStore: sessionStore,
  authorizationService: authorizationService,
  authenticationService: authenticationService,
  viewNavigator: viewNavigator,
  handlerFactory: new HandlerFactory(),
};

const applicationOptions: ApplicationOptions<ApplicationConfig> = {
  httpClient: axiosHttpClient,
  name: 'TalTech EduBIM - Website',
  settings: configSettings,
  userNofificationService,
  stores: stores,
  areas: areas,
  logger: logger,
};

export const application = new Application(applicationOptions);
