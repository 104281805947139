import { STATUS_TYPE } from "taltech-styleguide";
import ErrorScreen, { ErrorScreenProps } from "./ErrorScreen";

const ErrorNoAccountScreen: React.FC<any> = () => {
  const props: ErrorScreenProps = {
    variant: STATUS_TYPE.WARNING,
    title: "No acount created",
    message:
      "You don't an acount yet. Only authorized university accounts can access this platform",
  };
  return <ErrorScreen {...props} />;
};

export default ErrorNoAccountScreen;
