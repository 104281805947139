import { Breadcrumbs, BuildingParameterForm, ToolbarEdit } from "@components";
import { ParameterDescriptor } from "@domain";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { buildingParametersData } from "../../../../../../../domain/building-components/building-data/building-parameters/BuildingParameters.samples";

const BuildingParameterDetailsScreen: React.FC<any> = (props) => {
  const { parameterId } = useParams();
  const [parameter, setParameter] = useState<ParameterDescriptor | undefined>(
    undefined
  );

  useEffect(() => {
    if (parameterId !== undefined) {
      const param = buildingParametersData.filter(
        (x) => x.id === parameterId
      )[0];
      setParameter(param);
    } else {
      setParameter(undefined);
    }
  });
  if (parameter === undefined) {
    return <></>;
  }
  return (
    <div>
      <h1>Edit Building Parameters</h1>
      <Breadcrumbs />
      <ToolbarEdit />
      <BuildingParameterForm
        element={parameter}
        onCancel={() => {}}
        onSubmit={(values) => {
          console.log(values);
        }}
        visualization={{
          showModifiedDate: true,
          showCreatedDate: false,
        }}
      />
    </div>
  );
};

export default BuildingParameterDetailsScreen;
