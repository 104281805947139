import { FormState } from "@dirootie-web/components-types";
import { IFormAction } from "./FormActions.types";
import { FormActionHandlerBase, IFormStateProvider } from "./Forms.handlers";
import { ServiceFactory } from "@dirootie-core/api-types";
import { IUserNotificationService, badRequestNotification, notAuthorizedNotification } from "../../../packages/web/components-types/src/notifications";
import { formHasErrorsNotification, saveSuccessNotification } from "../../../packages/web/components-types/src/notifications/forms.notifications";
import { IEntity } from "@dirootie-core/core-types";
import { IActionHandler } from "../Actions.types";

export class FormSubmitActionHandler extends FormActionHandlerBase {
  constructor(
    userNotificationService: IUserNotificationService,
    serviceFactory: ServiceFactory,
    private formStateProvider: IFormStateProvider
  ) {
    super(userNotificationService, serviceFactory);
  }

  public handle<T extends IEntity>(action: IFormAction<FormState>) {
    const state = this.formStateProvider.getState();
    return state.validateForm().then((x: any) => {
      if (state.isValid) {
        state.submitForm();
      } else {
        this.userNotificationService.error(formHasErrorsNotification);
      }
    });
  }
}

export class FormRenderActionHandler implements IActionHandler {
  constructor(
    private formStateProvider: IFormStateProvider
  ) {
  }

  public handle(action: IFormAction<FormState>) {
    this.formStateProvider.setState(action.payload!);
    return Promise.resolve();
  }
}
