import {
  RoleTypeList,
  ProfileStateList,
} from "../../../../domain/users/Users.types";
import { Page } from "@components";
import {
  WidgetEnumerationSection,
  WidgetEnumerationSectionProps,
} from "./WidgetEnumeration";
import { InvitationStateList } from "../../../../domain/users/Invitation";
import { CourseStateList } from "../../../../domain/courses/Courses.types";

const usersEnumerations: WidgetEnumerationSectionProps = {
  title: "Users management system",
  enumerations: [
    {
      title: "User roles",
      description: "Available user roles in the system",
      values: RoleTypeList,
    },
    {
      title: "User invitation states",
      description: "User Azure AD invitation states",
      values: InvitationStateList,
    },
    {
      title: "Profile states",
      description: "User profile states in the system",
      values: ProfileStateList,
    },
  ],
};

const coursesEnumerations: WidgetEnumerationSectionProps = {
  title: "Courses management system",
  enumerations: [
    {
      title: "Course states",
      description: "Course states in the system",
      values: CourseStateList,
    },
  ],
};

const EnumerationsScreen = () => {
  return (
    <Page title="System enumerations">
      <WidgetEnumerationSection {...usersEnumerations} />
      <WidgetEnumerationSection {...coursesEnumerations} />
    </Page>
  );
};

export default EnumerationsScreen;
