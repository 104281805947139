import {MediaItemsTable, ToolbarList} from "@components";

export const MediaItemIndexScreen: React.FC<any> = (props: {
  subjectId: string;
}) => {
  return (
    <>
      <ToolbarList/>

      <MediaItemsTable
        source={{
          path: "media",
          query: {subjectId: props.subjectId},
        }}
        isEditable={true}
      />
    </>
  );
};
