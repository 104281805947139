import {PeopleTable, ToolbarList} from "@components";
import {useLocation, useParams} from "react-router-dom";
import {application} from "../../../../../configuration/Application.bootstrap";

export const StudentsIndexScreen: React.FC<any> = () => {
  const location = useLocation();
  const { courseId } = useParams();
  const segments = location.pathname.split("/");
  return (
    <>
      <ToolbarList
        disabled={segments[1] === "students"}
        onAction={(a: any) =>
          application.getHandlerFactory().createDispatcherBasic().dispatch(a)
        }
      />

      <PeopleTable
        isEditable={segments[1] === "lecturers"}
        source={{
          path: "courses.students.summary",
          query: {
            courseId
          }
        }}
      ></PeopleTable>
    </>
  );
};
