import {Preset, PresetBuildingParameter, PresetComponent, PresetSpaceFunction,} from './Preset';

export const presetsPerSpaceFunction: PresetSpaceFunction[] = [
  {
    id: 'id_space_preset_1',
    name: 'Floor parameter 1',
    description: 'Floor parameter description 1',
    type: 'SPACE_FUNCTION',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    elementIds: [],
  },
  {
    id: 'id_space_preset_2',
    name: 'Floor parameter 2',
    description: 'Floor parameter description 2',
    type: 'SPACE_FUNCTION',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    elementIds: [],
  },
  {
    id: 'id_space_preset_3',
    name: 'Floor parameter 3',
    description: 'Floor parameter description 3',
    type: 'SPACE_FUNCTION',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    elementIds: [],
  },
];

export const presetsPerBuildingParameter: PresetBuildingParameter[] = [
  {
    id: 'id_build_preset_1',
    name: 'Floor parameter 1',
    description: 'Floor parameter description 1',
    type: 'BUILDING',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    elementIds: [],
  },
  {
    id: 'id_build_preset_2',
    name: 'Floor parameter 2',
    description: 'Floor parameter description 2',
    type: 'BUILDING',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    elementIds: [],
  },
  {
    id: 'id_build_preset_3',
    name: 'Floor parameter 3',
    description: 'Floor parameter description 3',
    type: 'BUILDING',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    elementIds: [],
  },
];

export const presetPerComponent: PresetComponent[] = [
  {
    id: 'id_comp_preset_1',
    name: 'Floor parameter 1',
    description: 'Floor parameter description 1',
    type: 'COMPONENT',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    elementIds: [],
    floors: {type: 'FLOOR', creationType: 'CONSTANT', elementIds: []},
    wallsExterior: {
      type: 'WALL_EXTERIOR',
      creationType: 'CONSTANT',
      elementIds: [],
    },
    wallsInterior: {
      type: 'WALL_INTERIOR',
      creationType: 'CONSTANT',
      elementIds: [],
    },
    roofs: {type: 'ROOF', creationType: 'CONSTANT', elementIds: []},
    windows: {type: 'WINDOW', creationType: 'CONSTANT', elementIds: []},
    buildingParameters: {
      type: 'BUILDING_PARAMETERS',
      creationType: 'CONSTANT',
      elementIds: [],
    },
  },
  {
    id: 'id_comp_preset_2',
    name: 'Floor parameter 2',
    description: 'Floor parameter description 2',
    type: 'COMPONENT',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    elementIds: [],
    floors: {type: 'FLOOR', creationType: 'CONSTANT', elementIds: []},
    wallsExterior: {
      type: 'WALL_EXTERIOR',
      creationType: 'CONSTANT',
      elementIds: [],
    },
    wallsInterior: {
      type: 'WALL_INTERIOR',
      creationType: 'CONSTANT',
      elementIds: [],
    },
    roofs: {type: 'ROOF', creationType: 'CONSTANT', elementIds: []},
    windows: {type: 'WINDOW', creationType: 'CONSTANT', elementIds: []},
    buildingParameters: {
      type: 'BUILDING_PARAMETERS',
      creationType: 'CONSTANT',
      elementIds: [],
    },
  },
  {
    id: 'id_comp_preset_3',
    name: 'Floor parameter 3',
    description: 'Floor parameter description 3',
    type: 'COMPONENT',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    elementIds: [],
    floors: {type: 'FLOOR', creationType: 'CONSTANT', elementIds: []},
    wallsExterior: {
      type: 'WALL_EXTERIOR',
      creationType: 'CONSTANT',
      elementIds: [],
    },
    wallsInterior: {
      type: 'WALL_INTERIOR',
      creationType: 'CONSTANT',
      elementIds: [],
    },
    roofs: {type: 'ROOF', creationType: 'CONSTANT', elementIds: []},
    windows: {type: 'WINDOW', creationType: 'CONSTANT', elementIds: []},
    buildingParameters: {
      type: 'BUILDING_PARAMETERS',
      creationType: 'CONSTANT',
      elementIds: [],
    },
  },
];

export const presetsData: Preset<any>[] = [
  ...presetsPerSpaceFunction,
  ...presetsPerBuildingParameter,
  ...presetPerComponent,
];
