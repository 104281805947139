import { Col, Row } from "taltech-styleguide";
import { Page, WidgetShortcuts, WidgetListProps } from "@components";

const lectureShortcuts: WidgetListProps = {
  title: "Lecturer shortcuts",
  subTitle: "Shortcuts for common operations",
  items: [
    {
      name: "Create new course",
      route: "/lecturers/courses/new",
      title: "Create new course",
    },
    {
      name: "Invite students",
      route: "/erros/not-found",
      title: "Invite students",
    },
    {
      name: "Create new resource",
      route: "/lecturers/resources/nen",
      title: "Create new resource",
    },
    { name: "Testing", route: "/testing", title: "Testing area" },
    {
      name: "Download Revit",
      route: "/erros/not-found",
      title: "Download Revit plugin",
    },
  ],
};

const DashboardScreen: React.FC<any> = () => (
  <Page title="Dashboard">
    <Row>
      <Col>
        <WidgetShortcuts {...lectureShortcuts}></WidgetShortcuts>
      </Col>
      <Col></Col>
    </Row>
    <Row>
      <Col></Col>
      <Col></Col>
    </Row>
  </Page>
);

export default DashboardScreen;
