import {
  FloorDetails,
  ToolbarEdit,
  DisabledTabComponent,
  Breadcrumbs,
} from "@components";
import { BuildingComponent, FloorBuildingComponent } from "@domain";
import { inject } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { floorEntityStore } from "./FloorTypeIndexScreen";

const FloorTypeDetailsScreen: React.FC<any> = inject("buildingComponentsStore")(
  (props) => {
    const { typeId } = useParams();
    const [element, setElement] = useState<BuildingComponent | undefined>(
      undefined
    );

    useEffect(() => {
      if (typeId !== undefined) {
        floorEntityStore
          .executeAction<FloorBuildingComponent>({
            action: "FORM_GET",
            payload: typeId,
            options: {
              path: "buildingComponents.components",
            },
          })
          .then((x) => {
            setElement(x);
          });
      }
    }, [setElement, typeId]);
    if (element === undefined) {
      return <></>;
    }
    return (
      <div>
        <h1>Floor</h1>
        <Breadcrumbs />
        <div className={"container"}>
          <DisabledTabComponent
            elements={["Types", "Parameters"]}
            activeDisabled={0}
          />
          <FloorDetails element={element} entityStore={floorEntityStore} />
        </div>
      </div>
    );
  }
);

export default FloorTypeDetailsScreen;
