import {InputComponent, SelectComponent, useWindowSize} from "@components";
import {Form as FormikForm, Formik} from "formik";
import {observer} from "mobx-react";
import React, {FC} from "react";
import {Button, Form} from "taltech-styleguide";
import {useStore} from "../../../domain/store";
import {BuildingFunctionFormTypes} from "../../../packages/web/components-types/src/forms";
import {getFunctionValidationSchema} from "./functionValidationSchema";

export const BuildingFunctionForm: FC<BuildingFunctionFormTypes> = observer(
  ({config, ...props}) => {
    const {buildingConfigurationFormStore} = useStore();
    const validationSchema = getFunctionValidationSchema();
    const {width} = useWindowSize();

    return (
      <>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={buildingConfigurationFormStore.secondStep}
          onSubmit={(values) => {
            buildingConfigurationFormStore.setFunctionConfigurationState(
              values as BuildingFunctionFormTypes
            );
            buildingConfigurationFormStore.setNextStep(config.stepNumber + 1);
            console.log(config.stepNumber)
          }}
        >
          {(formik) => {
            return (
              <FormikForm className={!config.disabled ? "active" : ""}>
                <div className={width > 1200 ? "d-flex align-items-end" : ""}>
                  <Form.Group className="mb-3 w-75 mx-2">
                    <SelectComponent
                      changeFn={() => console.log("S")}
                      disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                      type={"text"}
                      label={"Building spaces (Functions)"}
                      name={"buildingSpaceFunction"}
                      options={[
                        "\<None\>",
                        "Building Function 1",
                        "Building Function 2",
                        "Building Function 3",
                      ]}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 mx-2">
                    <div className={"d-flex"}>
                      <div className={"w-50"}>
                        <Form.Label>Row 1</Form.Label>
                        <div className={"d-flex"}>
                          <div className={"mx-2"}>
                            <InputComponent
                              isInteger={true}
                              disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                              type={"number"}
                              min={1}
                              label={"No. rooms"}
                              name={"firstRawNumberOfRooms"}
                            />
                          </div>
                          <div className={"mx-2"}>
                            <InputComponent
                              isInteger={true}
                              disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                              type={"number"}
                              label={"Width"}
                              min={1}
                              name={"firstRawWidth"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={"w-50"}>
                        <Form.Label>Row 2</Form.Label>
                        <div className={"d-flex"}>
                          <div className={"mx-2"}>
                            <InputComponent
                              isInteger={true}
                              disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                              type={"number"}
                              label={"No. rooms"}
                              min={1}
                              name={"secondRowNumberOfRooms"}
                            />
                          </div>
                          <div className={"mx-2"}>
                            <InputComponent
                              isInteger={true}
                              disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                              type={"number"}
                              label={"Width"}
                              min={1}
                              name={"secondRawWidth"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                </div>
                {!config.disabled && <Button type="submit" className={'position-fixed invisible'}>Next</Button>}
              </FormikForm>
            );
          }}
        </Formik>
      </>
    );
  }
);
