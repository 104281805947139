import {ToolbarList} from "@components/navigation";
import {PagedResult} from "@dirootie-core/core-types";
import {BuildingComponentsStore, ComponentType, Parameter} from "@domain/building-components";
import {inject, observer} from "mobx-react";
import {useEffect, useState} from "react";
import {ParametersTable} from "./ParametersTable";

export type ParametersBaseScreenProps = {
  componentType?: ComponentType;
  componentId?: string;
  buildingComponentsStore?: BuildingComponentsStore
};


export const ParametersIndexBaseScreen: React.FC<ParametersBaseScreenProps> = inject("buildingComponentsStore")(
  observer((props) => {
      const [parameters, setParameters] = useState<PagedResult<Parameter>>({total: 0, page: 0, size: 0, items: []});

      useEffect(() => {
        if (props.componentType !== undefined) {
          props.buildingComponentsStore?.getParameters(props.componentType!, {}).then(x => {
            setParameters(x)
          })
        }

      }, [props.buildingComponentsStore, props.componentType]);
      return <div>
        <ToolbarList/>

        <ParametersTable elements={parameters}/>
      </div>;
    }
  ));

export default ParametersIndexBaseScreen;
