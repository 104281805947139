import {
  colId,
  colModifiedDate,
  defaultProps,
  TableTalTech2,
} from "@components";
import {
  convertToTitleCase,
  formatDate,
  isEmptyArray,
} from "@dirootie-extensions/javascript";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { FILTER_TYPES, STATUS_TYPE, StatusTag } from "taltech-styleguide";

const colName = {
  text: "Name",
  dataField: "name",
};
const colEmail = {
  text: "Email",
  dataField: "email",
};
const colRole = {
  text: "Role",
  dataField: "role",
  formatter: (col: string) => convertToTitleCase(col),
};
const colState = {
  dataField: "state",
  formatter: (col: any, row: any, index: any, extraData: any) => {
    const states = Object.keys(extraData.states).filter(
      (x) => x.toUpperCase() === col.toUpperCase()
    );
    let stateType = STATUS_TYPE.DANGER;
    let stateName = "UNKNOWN";
    if (!isEmptyArray(states)) {
      const state = extraData.states[states[0]];
      stateType = state.type;
      stateName = state.name;
    }
    return (
      <StatusTag type={stateType}>{convertToTitleCase(stateName)}</StatusTag>
    );
  },
  formatExtraData: {
    states: {
      unknown: {
        name: "Unknown",
        state: "UNKNOWN",
        type: STATUS_TYPE.DANGER,
      },
      pending: {
        name: "Pending",
        state: "PENDING",
        type: STATUS_TYPE.WARNING,
      },
      accepted: {
        name: "Accepted",
        state: "Finished",
        type: STATUS_TYPE.SUCCESS,
      },
      refused: {
        name: "Refused",
        state: "REFUSED",
        type: STATUS_TYPE.DANGER,
      },
      expired: {
        name: "Expired",
        state: "EXPIRED",
        type: STATUS_TYPE.DANGER,
      },
    },
  },
  sort: true,
  text: "State",
};
const colSentDate = {
  dataField: "createdDate",
  filterObject: {
    defaultValue: undefined,
    type: FILTER_TYPES.DATE,
  },
  formatter: (col: Date) => formatDate(col),
  sort: true,
  text: "Sent Date",
};

export const UserInvitationTable: FC<any> = observer(({ ...props }) => {
  const navigator = useNavigate();
  const columns = [
    colId,
    //   colName,
    colEmail,
    colRole,
    //  colState,
    colSentDate,
    colModifiedDate,
  ];
  const tableProps = {
    ...defaultProps,
    columns,
  } as any;
  return (
    <TableTalTech2
      source={{
        path: "users.invitations",
      }}
      table={tableProps}
    ></TableTalTech2>
  );
});
