import {Entity} from '@dirootie-core/core-types';

export const MaterialClassList = [
  'WOOD',
  'STONE',
  'STEEL',
  'CONCRETE',
  'IRON',
  'CERAMIC',
  'EARTH',
  'GAS',
  'GLASS',
  'LIQUID',
  'MASONRY',
  'METAL',
  'PAINT',
  'PLASTIC',
] as const;

export type MaterialClass = typeof MaterialClassList[number];

export type Material = Entity & {
  name: string;
  class: MaterialClass;
  description: string;
  sourceId: string;
  sourceType: "SYSTEM" | "STUDENT" | "COURSE";
};
