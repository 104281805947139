import {PageTest, PageTestProps} from "@components/testing";
import {StructureLayerFunctionTypeList} from "@domain/building-components";
import {FC, useState} from "react";
import TypesMaterialStructure, {
  ITypesMaterialStructure,
  ParameterStructure
} from "../../../../../../../components/building-components/components/types/_common/TypesMaterialStructure";
import {useOutputWindowService} from "../../../../../components/OutputWindow.service";

export const MaterialStructureTableUitest: FC<any> = () => {
  const service = useOutputWindowService();
  const [elements, setElements] = useState<ParameterStructure>({
    exterior: [
      {material: "Material 01", thickness: 1, function: "STRUCTURE", type: "EXTERIOR", id: "Some_id"},
      {material: "Material 02", thickness: 1, function: "STRUCTURE", type: "EXTERIOR", id: "Some_id"}],
    core: [{material: "Material 02", thickness: 1, function: "STRUCTURE", type: "CORE", id: "Some_id"}],
    interior: [
      {material: "Material 01", thickness: 1, function: "STRUCTURE", type: "INTERIOR", id: "Some_id"},
      {material: "Material 02", thickness: 1, function: "STRUCTURE", type: "INTERIOR", id: "Some_id"}]
  });
  const materialStructureProps: ITypesMaterialStructure = {
    parameters: elements,
    viewModel: {
      materialOptions: [
        {
          id: "0",
          name: "Material 01",
          class: "IRON",
          description: "description",
          createdDate: new Date(Date.now()),
          modifiedDate: new Date(Date.now())
        },
        {
          id: "0",
          name: "Material 01",
          class: "IRON",
          description: "description",
          createdDate: new Date(Date.now()),
          modifiedDate: new Date(Date.now())
        },
        {
          id: "0",
          name: "Material 01",
          class: "IRON",
          description: "description",
          createdDate: new Date(Date.now()),
          modifiedDate: new Date(Date.now())
        }
      ] as any,
      functionOptions: [...StructureLayerFunctionTypeList],
    },
    actions: {
      onAddNew: (type: "core" | "interior" | "exterior") => {
        elements[type].push({
          id: `${elements[type].length + 1}`,
          type: type as any,
          material: "Material 01",
          thickness: 1,
          function: "STRUCTURE"
        })
        setElements({...elements})
      },
      oncancel: () => console.log("Cancel"),
      onsubmitAll: (values) => service.log("onSubmit", values),
      onElementSubmit: (values) => service.log("onSubmit", values),
      onDeleteElement: (index: number, type: "core" | "interior" | "exterior") => elements[type].splice(index, 1)
    }
  }

  const props: PageTestProps = {
    title: "Structure Material form - uitest",
    demo: {
      outputWindow: true,
      outputTracker: ["onSubmit", "onCancel"],
      Component: TypesMaterialStructure,
      properties: materialStructureProps,
    },
  };

  return <PageTest {...props} />;
};
