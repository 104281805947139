import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Col, Container, Loader, Row } from "taltech-styleguide";
import { MainAppViewStore } from "../../configuration/MainAppViewStore";
import {
  LayoutConfiguration,
  MenuItem,
} from "../../domain/web-applications/AreaConfiguration";
import { useWindowSize } from "../hooks/useWindowResize";
import { MenuToogle } from "../navigation/MenuToogle";

import { NavBarLeft } from "@components/navigation";
import { IconMenu } from "../icons";
import { LayoutPublic } from "./LayoutPublic";

export const LayoutWorkspace: React.FC<LayoutConfiguration> = inject(
  "mainAppViewStore"
)(
  observer(
    (
      props: LayoutConfiguration & {
        children?: JSX.Element[];
        mainAppViewStore?: MainAppViewStore;
      }
    ) => {
      const [open, setOpen] = useState<boolean>(true);
      const [scrolledUp, setScrolledUp] = useState<boolean>(true);
      const [scrollHeight, setScrollHeight] = useState<number>(0);
      const menuItem: MenuItem = {
        children: props.menuMain?.items,
        to: "",
      };
      const { width } = useWindowSize();
      const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

      useEffect(() => {
        const handleScroll = () => {
          if (Math.abs(window.pageYOffset - scrollHeight) > 0) {
            setScrollHeight(window.pageYOffset);
            setScrolledUp(false);
          } else if (window.pageYOffset - scrollHeight < -100)
            setScrollHeight(window.pageYOffset);
          if (window.pageYOffset - scrollHeight <= 0) setScrolledUp(true);
          else setScrolledUp(false);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, [window.pageYOffset]);

      const loader = props.mainAppViewStore?.isLoading ? (
        <Loader overlay />
      ) : (
        <></>
      );
      return (
        <LayoutPublic {...props}>
          <Container>
            {loader}
            <Row
              className={
                width > 1200 ? (open ? "ebd-row-open" : "ebd-row-closed") : ""
              }
            >
              {width > 1200 && (
                <Col
                  className={
                    !scrolledUp ? "ebd-move-nav-bar" : "ebd-stick-nav-bar"
                  }
                  xs={"2"}
                >
                  <NavBarLeft {...menuItem} isOpenFn={setOpen} />
                </Col>
              )}
              <Col
                className={
                  width > 1200
                    ? open
                      ? "ebd-col-width-open"
                      : "ebd-col-width-closed"
                    : ""
                }
                xs={width > 1200 ? "10" : "12"}
              >
                {width < 1200 && (
                  <button
                    onClick={() => setMenuIsOpen(!menuIsOpen)}
                    className="ebd-button-up top-0 mb-2"
                  >
                    <IconMenu />
                  </button>
                )}
                <Outlet />
                {width < 1200 ? (
                  menuIsOpen && (
                    <MenuToogle
                      menuState={menuIsOpen}
                      closeFn={setMenuIsOpen}
                      {...menuItem}
                    />
                  )
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Container>
        </LayoutPublic>
      );
    }
  )
);
