import React from "react";
import {Col, Row, Table} from "taltech-styleguide";


export const SpaceFunctionSpecifiactions: React.FC<{ jsonData: any }> = ({jsonData}) => {

  return <>
    <section>
      <h5>Space function:</h5>
      {jsonData.spaceFunctions.map((space: any, index: any) => {
        return <>
          <Row>
            <Col sm={2}><h6>Space Function {index + 1}:</h6></Col></Row>
          <Row>
            <Col sm={2}><h6>Id:</h6></Col>
            <Col sm={10}><h6>{jsonData.spaceFunctions[index].id}</h6></Col>

            <Col sm={2}><h6>Name:</h6></Col>
            <Col sm={10}><h6>{jsonData.spaceFunctions[index].name}</h6></Col>

            <Col sm={2}><h6>Description:</h6></Col>
            <Col sm={10}><h6>{jsonData.spaceFunctions[index].description}</h6></Col>
          </Row>

          <Row>
            <Col sm={2}><h6>Parameters:</h6></Col>
            <Col sm={10}><Table>
              <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Value</th>
              </tr>
              </thead>
              <tbody>
              {jsonData.spaceFunctions[index].parameters.map((para: any, index: any) => {
                return <tr>
                  <td>{jsonData.buildingParameters[index].name}</td>
                  <td>{jsonData.buildingParameters[index].type}</td>
                  <td>{jsonData.buildingParameters[index].value}</td>
                </tr>
              })}
              </tbody>
            </Table></Col>
          </Row>
        </>
      })}

    </section>
  </>
};
