import "taltech-styleguide/build/css/main.css";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { appRouter } from "./AppRouter";
import { configSettings } from "./configuration/ConfigurationSettings";
import {
  ConsoleLogger,
  LoggingConfig,
  LogLevel,
} from "@dirootie-core/logging-types";
import { inject, observer } from "mobx-react";
import { Loader } from "taltech-styleguide";

const loggingConfig: LoggingConfig = {
  level: LogLevel.ALL,
  provider: "Console",
};
const logger = new ConsoleLogger(loggingConfig);

export const App = inject("mainAppViewStore")(
  observer((props: any) => {
    logger.info(
      "[AppComponent]: Application started. Configuration used: ",
      configSettings
    );
    return props.mainAppViewStore.isLoading ? (
      <Loader fullScreen></Loader>
    ) : (
      <RouterProvider router={appRouter}></RouterProvider>
    );
  })
);

export default App;
