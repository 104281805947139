import { defaultProps, TableTalTech } from "@components";
import { convertToTitleCase } from "@dirootie-extensions/javascript";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Initials } from "taltech-styleguide";

const colFirstName = {
  dataField: "displayName",
  sort: true,
  text: "Name",
};
const colRole = {
  dataField: "role",
  sort: true,
  text: "Role",
  formatter: (col: string) => convertToTitleCase(col),
};
const colEmail = {
  dataField: "email",
  sort: true,
  text: "E-mail",
};

export const SearchResultTable: React.FC<any> = observer(
  (props: { elements: any }) => {
    useEffect(() => {
      console.log(props.elements);
    }, [props.elements]);
    const navigator = useNavigate();
    const [selected, setSelected] = useState<string[]>([]);
    const columns = [
      {
        classes: "ebd-initials-first",
        dataField: "owner",
        formatter: (col: any, row: any, index: any, extraData: any) => {
          return (
            <>
              {row.owner.map((x: any) => {
                return <Initials key={x.id} name={x.displayName} size="xs" />;
              })}
            </>
          );
        },
        sort: false,
        text: "",
      },
      colFirstName,
      colEmail,
      colRole,
    ];
    const prop = {
      ...defaultProps,
      selectRow: {
        mode: "checkbox",
        selected: selected,
        selectColumnPosition: "left",

        className: "ebd-initials-first",
      },
      data: props.elements,
      columns,
    };
    return <TableTalTech tableProps={prop}></TableTalTech>;
  }
);
