import {
  Page,
  PageContentForm,
  TabItem,
  ToolbarEdit,
  createFormProperties,
  getSelectedTabByPath,
  DisabledTabComponent,
} from "@components";
import {
  isNullOrEmptyString,
  notEmptyArray,
} from "@dirootie-extensions/javascript";
import { inject } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TabPanel, Tabs } from "taltech-styleguide";
import { AssignmentGeneralForm } from "../../../../../components/assignments/AssignmentGeneralForm";
import { Assignment } from "../../../../../domain/courses/Assignment";
import { ConfigurationIndexScreen } from "./configurations/ConfigurationIndexScreen";
import { ResourcesIndexScreen } from "./resourses/ResourcesIndexScreen";
import { SubmissionIndexScreen } from "./submission/SubmissionIndexScreen";
import { entityStore } from "./AssignmentsIndexScreen";
import { ViewNavigator } from "configuration/ViewNavigator";

type AssignmentRouteProps = {
  area: string;
  courseId: string;
  assignmentId: string;
  tabId: string;
  tabItemId?: string;
};

const buildAssignmentRouteUrl = ({
  area,
  courseId,
  assignmentId,
  tabId,
  tabItemId,
}: AssignmentRouteProps) => {
  const baseAssignmentRouteTemplate = `${area}/courses/${courseId}/assignments/${assignmentId}`;
  const paths: string[] = [baseAssignmentRouteTemplate];
  if (isNullOrEmptyString(tabId)) {
    return paths[0];
  }
  paths.push(tabId.toLowerCase());
  if (isNullOrEmptyString(tabItemId)) {
    return paths.join("/");
  }
  paths.push(tabItemId!);
  return paths.join("/");
};

export const AssignmentEditScreen: React.FC<any> = inject("viewNavigator")(
  (props: { viewNavigator?: ViewNavigator }) => {
    const [element, setElement] = useState<Assignment | undefined>(undefined);
    const { assignmentId, courseId, assignmentTabId: tabId } = useParams();
    const currentArea = props.viewNavigator?.getCurrentArea()!;
    const navigator = useNavigate();
    useEffect(() => {
      if (assignmentId !== undefined) {
        entityStore
          .executeAction<Assignment>({
            action: "FORM_GET",
            payload: assignmentId,
            options: {
              path: "assignments",
            },
          })
          .then((x) => {
            setElement(x);
          });
      }
    }, [setElement, assignmentId]);
    const mode = currentArea.name === "lecturers" ? "EDIT" : "VIEW";
    const formProps = createFormProperties(
      mode,
      {
        onAction: (action) => {
          return Promise.resolve(entityStore.onAction(action));
        },
        schema: entityStore.options.schema,
        element: element,
      },
      entityStore.options,
      navigator
    );

    const generalTab = {
      label: "Summary",
      component: (
        <PageContentForm
          form={{
            Component: AssignmentGeneralForm,
            options: { ...formProps },
          }}
          mode={mode}
          dispatcher={{
            onAction: (action) => {
              return Promise.resolve(entityStore.onAction(action));
            },
          }}
        />
      ),
      index: 0,
      path: "summary",
    };

    const studentsTab = {
      label: "Students",
      component: <SubmissionIndexScreen />,
      index: 1,
      path: "students",
    };

    const resourcesTab = {
      label: "Resources",
      component: <ResourcesIndexScreen isReadOnly={mode === "VIEW"} />,
      index: 2,
      path: "resources",
    };

    const buildingConfigurationsTab = {
      label: "Building configurations",
      component: <ConfigurationIndexScreen />,
      index: 3,
      path: "bulding-configurations",
    };

    const tabs: TabItem[] = [
      generalTab,
      studentsTab,
      resourcesTab,
      buildingConfigurationsTab,
    ];

    const disabledTabElements = [
      "Summary",
      "Lecturers",
      "Students",
      "Groups",
      "Resources",
      "Assignments",
    ];

    const selectedTab = getSelectedTabByPath(tabs, tabId!);
    const selectedIndex = notEmptyArray(selectedTab) ? selectedTab[0].index : 0;
    return (
      <>
        <Page title={element?.title || ""} showBreadcrumbs={true}>
          <div className={"container"}>
            <DisabledTabComponent
              elements={disabledTabElements}
              activeDisabled={5}
            />
            <Tabs
              id="page-tabs-floor"
              navSize="sm"
              selectedIndex={selectedIndex}
              onSelect={(
                index: number,
                last: undefined | number,
                evt: Event | undefined
              ) => {
                const selectedTab = tabs.filter((x) => x.index === index);
                if (notEmptyArray(selectedTab)) {
                  const routeProps: AssignmentRouteProps = {
                    area: currentArea.name,
                    courseId: courseId!,
                    assignmentId: assignmentId!,
                    tabId: selectedTab[0].path,
                  };
                  const routeUrl = buildAssignmentRouteUrl(routeProps);
                  props.viewNavigator!.navigateToPath(routeUrl);
                }
                return true;
              }}
              labels={tabs.map((x) => x.label)}
            >
              {tabs.map((x) => (
                <TabPanel key={x.index + x.path}>{x.component}</TabPanel>
              ))}
            </Tabs>
          </div>
        </Page>
      </>
    );
  }
);
