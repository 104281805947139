import React from "react";
import {Col, Row, Table} from "taltech-styleguide";


export const FloorSpecifications: React.FC<{ jsonData: any }> = ({jsonData}) => {

  return <>
    <section>
      <h5>Floor:</h5>
      <Row>
        <Col sm={2}><h6>Name</h6></Col>
        <Col sm={10}><h6>{jsonData.elementTypes.floor.name}</h6></Col>
      </Row>
      <Row>
        <Col sm={2}><h6>Layers:</h6></Col>
        <Col sm={10}><Table>
          <thead>
          <tr>
            <th>Value</th>
            <th>Order</th>
          </tr>
          </thead>
          <tbody>
          {jsonData.elementTypes.floor.layers.map((layer: any, index: any) => {
            return <tr>
              <td>{layer.value.function}</td>
              <td>{layer.order}</td>
            </tr>
          })}
          </tbody>
        </Table></Col>
      </Row>
      <Row>
        <Col sm={2}><h6>Parameters:</h6></Col>
        <Col sm={10}><Table>
          <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Value</th>
          </tr>
          </thead>
          <tbody>
          {jsonData.elementTypes.floor.parameters.map((para: any, index: any) => {
            return <tr>
              <td>{para.name}</td>
              <td>{para.type}</td>
              <td>{para.value}</td>
            </tr>
          })}
          </tbody>
        </Table></Col>
      </Row>
    </section>
  </>
};
