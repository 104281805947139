import {Link} from "react-router-dom";
import {Card, CustomCard} from "taltech-styleguide";
import {WidgetListProps, WidgetProps} from "./Widgets.types";

export const Widget: React.FC<any> = (props: WidgetProps) => {
  return (
    <Link to={props.route} id={props.name}>
      {props.title}
    </Link>
  );
};

export const WidgetList: React.FC<any> = (props: WidgetListProps) => {
  return (
    <>
      <CustomCard backgroundColor="blue" className="mt-3" title="Quick actions">
        {props.items.map((x) => (
          <Widget {...x} />
        ))}
      </CustomCard>
    </>
  );
};

export const WidgetShortcut: React.FC<any> = (props: WidgetProps) => {
  return (
    <Link to={props.route} id={props.name}>
      {props.title}
    </Link>
  );
};

export const WidgetShortcuts: React.FC<any> = (props: WidgetListProps) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Subtitle>{props.subTitle}</Card.Subtitle>
        <Card.Text className="mt-3">
          <ul className="list-styled" style={props.title === "Presets" ? {paddingBottom: 32} : {}}>
            {props.items.map((x, i) => (
              <li key={`${i}-${x.name}`}>
                <WidgetShortcut {...x} />
              </li>
            ))}
          </ul>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
