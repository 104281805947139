export const IconDDownload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 13v4a2 2 0 01-2 2H3a2 2 0 01-2-2v-4M5 8l5 5 5-5M10 13V1"
    ></path>
  </svg>
);
