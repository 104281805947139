import {createMaxMessage, createMinMessage, createRequiredMessage} from '@domain/validations';
import * as Yup from 'yup';

export const getParameterValidationSchema = () => {
  const parameterValidationSchema = Yup.object<any>().shape({
    name: Yup.string().min(1, createMinMessage('name', 1)).max(100, createMaxMessage('name', 100)).required(createRequiredMessage('name')),
    description: Yup.string().min(1, createMinMessage('description', 1)).max(100, createMaxMessage('description', 10000)).required(createRequiredMessage('description')),
    valueDefault: Yup.string()
      .required(createRequiredMessage('value')),
  });

  return parameterValidationSchema;
};
