import {InvitationState, RoleType} from "@domain";
import {FC, useState} from "react";
import {Form, Input} from "taltech-styleguide";
import {SearchResultTable} from "./SearchResultTable";

type SearchedElement = {
  id: string,
  number: number,
  displayName: string,
  state: InvitationState,
  email: string,
  role: RoleType,
  owner: [
    {
      id: string,
      displayName: string,
      description: string,
    },
  ],
  createdDate: Date,
}

export const InputSearch: FC<{ users: SearchedElement[] }> = ({users}) => {
  const [searchedUser, setSearchedUser] = useState('');
  const [searchResults, setSearchResults] = useState<SearchedElement[]>(users);

  const searchUsers = (val: string) => {
    setSearchedUser(val)
    const matchingUsers = users.filter((user) =>
      user.displayName.toLowerCase().includes(val.toLowerCase()) ||
      user.email.toLowerCase().includes(val.toLowerCase())
    );

    setSearchResults(matchingUsers);
  };

  return (
    <div>
      <div>
        <Form.Group controlId="id-7" className="mt-4">
          <Form.Label>Search</Form.Label>
          <Input placeholder="Enter user name" value={searchedUser} onChange={(e) => searchUsers(e.target.value)} type="search"/>
        </Form.Group>
      </div>
      {searchResults.length > 0 && (
        <div>
          <SearchResultTable elements={searchResults}/>
        </div>
      )}
    </div>
  );
};
