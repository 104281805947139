export const IconSquareEdit = (props:{onClick?:any})  => (
  <svg onClick={() => {props.onClick()}}
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.946 3.103H3.76A1.767 1.767 0 001.994 4.87v12.369a1.767 1.767 0 001.767 1.767h12.37a1.767 1.767 0 001.767-1.767v-6.185"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16.572 1.683a1.941 1.941 0 012.745 2.745l-8.693 8.693-3.66.915.915-3.66 8.693-8.693z"
    ></path>
  </svg>
);
