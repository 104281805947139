import React, {FC} from "react";
import {useStore} from "../../domain/store";
import "../../styles/form.css";
import {BuildingFunctionForm} from "./second-step";
import SpaceConfigurationForm from "./third-step/SpaceConfigurationForm";

export const BuildingSpaceFunctionWrapper: FC<any> = ({...props}) => {

  const {buildingConfigurationFormStore} = useStore();

  return (
    <>
      <BuildingFunctionForm
        {...buildingConfigurationFormStore.secondStep}
        config={{
          ...buildingConfigurationFormStore.secondStep.config,
          disabled: !(buildingConfigurationFormStore.currentStep === 1),
        }}
      />
      <SpaceConfigurationForm
        {...buildingConfigurationFormStore.thirdStep}
        config={{
          ...buildingConfigurationFormStore.thirdStep.config,
          disabled: !(buildingConfigurationFormStore.currentStep === 2),
        }}
      />
      <br/>
      <div>
        <img
          className={"ebd-model-image"}
          src={"/ExampleModel.png"}
          alt={"Model"}
        />
      </div>
    </>
  );
};
