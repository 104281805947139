import { mergeBuilder } from '@dirootie-extensions/javascript';
import { IColumnProps } from 'taltech-styleguide';

export const createColumnProps = (
  systemDefaultProps: Partial<IColumnProps>,
  singleDefaultProps: Partial<IColumnProps> | Partial<IColumnProps>[]
) => {
  const result = mergeBuilder<IColumnProps>(systemDefaultProps, true)
    .merge(singleDefaultProps)
    .build();
  return result;
};
