import {IconCircleXmark, IconCopy, IconEye, IconSquareEdit} from "@components/icons";
import {FC} from "react";
import '../../styles/icons.css'
import {IconDDownload} from "../icons/IconDDownload";

export type TableEditBtnProps = {
  url?: string;
  onClick?: () => void;
  id?: string;
};

export const TableEditBtn: FC<any> = (props: { onClick?: any }) => {
  return (
    <div className={'ebd-icon mx-3'}>
      <IconSquareEdit onClick={(evt: any) => props.onClick(evt)}/>
    </div>);
};

export const TableViewBtn: FC<any> = (props: { onClick?: any }) => {
  return (
    <div className={'ebd-icon'} onClick={(evt: any) => props.onClick(evt)}>
      <IconEye/>
    </div>);
};

export const TableDownloadBtn: FC<any> = (props: { onClick?: any }) => {
  return (
    <div className={'ebd-icon mx-3'} onClick={(evt: any) => props.onClick(evt)}>
      <IconDDownload/>
    </div>);
};

export const TableCopyBtn: FC<any> = (props: { onClick?: any }) => {
  return (
    <div className={'ebd-icon'} onClick={(evt: any) => props.onClick(evt)}>
      <IconCopy/>
    </div>);
};

export const TableDeleteBtn: FC<any> = (props: { onClick?: any }) => {
  return (
    <div className={'ebd-icon'} onClick={(evt: any) => props.onClick(evt)}>
      <IconCircleXmark/>
    </div>);
};
