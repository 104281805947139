export const auditData: any[] = [
  {
    id: 'id1',
    category: 'Accesses',
    type: 'Login',
    actorId: 'userId',
    appId: 'web',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id2',
    category: 'Accesses',
    type: 'Logout',
    actorId: 'userId',
    appId: 'web',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id3',
    category: 'Accesses',
    type: 'Login',
    actorId: 'userId',
    appId: 'revit',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id4',
    category: 'Courses',
    type: 'Created',
    actorId: 'userId',
    appId: 'web',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id5',
    category: 'Assignments',
    type: 'Submited',
    actorId: 'userId',
    appId: 'web',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
];
