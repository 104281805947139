import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import { adminAreaConfiguration } from "./areas/admin/AdminAreaConfiguration";
import { adminAreaRoutes } from "./areas/admin/AdminAreaRouting";
import { lecturersAreaConfiguration } from "./areas/lecturers/LecturersAreaConfiguration";
import { lecturersAreaRoutes } from "./areas/lecturers/LecturersAreaRouting";
import { publicAreaConfiguration } from "./areas/public/PublicAreaConfiguration";
import { publicAreaRoutes } from "./areas/public/PublicAreaRouting";
import { studentsAreaConfiguration } from "./areas/students/StudentsAreaConfiguration";
import { studentsAreaRoutes } from "./areas/students/StudentsAreaRouting";
import { testingAreaConfiguration } from "./areas/testing/TestingAreaConfiguration";
import { testingAreaRoutes } from "./areas/testing/TestingAreaRouting";
import { usersAreaConfiguration } from "./areas/users/UsersAreaConfiguration";
import { usersAreaRoutes } from "./areas/users/UsersAreaRouting";
import { AreaConfiguration } from "./domain/web-applications/AreaConfiguration";
import { supportAreaRoutes } from "./areas/support/SupportAreaRouting";
import { supportAreaConfiguration } from "./areas/support/SupportAreaConfiguration";
import { ProtectedRoute } from "@components";
import { application } from "./configuration/Application.bootstrap";
import { DiStellarViewer } from "./areas/distellar/DiStellarViewer";

// const createRoutesFromAreaConfiguration = (areaConfiguration: AreaConfiguration) => {
//     const  LayoutComponent = areaConfiguration.layout?.component as any;
//     const result = <LayoutComponent {...areaConfiguration.layout} />;
//     const routes = <Route element={result}>{ areaConfiguration.routes?.map(x => (
//         <Route key={x.path} path={x.path} element={x.element}></Route>
//     ))}</Route>;
//     console.log(areaConfiguration, routes, createRoutesFromElements(<>{routes!}</>))
//     console.log(areaConfiguration, createRoutesFromElements(<>{routes!}</>))
//     return createRoutesFromElements(routes!);
// };
export const withLayout = (
  areaConfiguration: AreaConfiguration,
  routes: JSX.Element
) => {
  const LayoutComponent = areaConfiguration.layout?.component as any;
  const result = <LayoutComponent {...areaConfiguration.layout} />;
  const props = { breadcrumb: "lasl" };
  const element =
    areaConfiguration.protected === true ? (
      <ProtectedRoute roles={areaConfiguration.roles}>{result}</ProtectedRoute>
    ) : (
      //result
      result
    );
  return (
    <Route element={element} {...props}>
      {routes}
    </Route>
  );
};

export const routes = createRoutesFromElements([
  withLayout(adminAreaConfiguration, adminAreaRoutes),
  withLayout(lecturersAreaConfiguration, lecturersAreaRoutes),
  withLayout(studentsAreaConfiguration, studentsAreaRoutes),
  withLayout(publicAreaConfiguration, publicAreaRoutes),
  withLayout(testingAreaConfiguration, testingAreaRoutes),
  withLayout(usersAreaConfiguration, usersAreaRoutes),
  withLayout(supportAreaConfiguration, supportAreaRoutes),
  <Route path="viewers">
    <Route path="distellar" element={<DiStellarViewer />} />
  </Route>,
]);

export const appRouter = createBrowserRouter([...routes], { window: window });

const areas = [
  adminAreaConfiguration,
  lecturersAreaConfiguration,
  studentsAreaConfiguration,
  publicAreaConfiguration,
  testingAreaConfiguration,
  usersAreaConfiguration,
  supportAreaConfiguration,
];
application.setAreas(areas);
application.setRouter(appRouter);
