import React from "react";
import {Col, Row, Table} from "taltech-styleguide";


export const WindowTypeSpecifiactions: React.FC<{ jsonData: any }> = ({jsonData}) => {

  return <>
    <section>
      <h5>Window Type:</h5>
      <Row>
        <Col sm={2}>
          <h6>Absolute length unit:</h6>
        </Col>
        <Col sm={10}>
          {jsonData.windowType.absoluteLengthUnit}
        </Col>
      </Row>
      <Row>
        <Col sm={2}><h6>Style:</h6></Col>
        <Col sm={10}>{jsonData.windowType.style}</Col>
      </Row>

      <Row>
        <Col sm={2}><h6>Height:</h6></Col>
        <Col sm={10}><Table>
          <thead>
          <tr>
            <th>Position</th>
            <th>Length</th>
            <th>Maximum</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{jsonData.windowType.height.position}</td>
            <td>{jsonData.windowType.height.length}</td>
            <td>{jsonData.windowType.height.maximum}</td>
          </tr>
          </tbody>
        </Table></Col>
      </Row>

      <Row>
        <Col sm={2}><h6>Width:</h6></Col>
        <Col sm={10}><Table>
          <thead>
          <tr>
            <th>Position</th>
            <th>Length</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{jsonData.windowType.width.position}</td>
            <td>{jsonData.windowType.width.length}</td>
          </tr>
          </tbody>
        </Table></Col>
      </Row>
      <Row>
        <Col sm={2}><h6>Sill height:</h6></Col>
        <Col sm={10}><Table>
          <thead>
          <tr>
            <th>Position</th>
            <th>Length</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{jsonData.windowType.SillHeight.position}</td>
            <td>{jsonData.windowType.SillHeight.length}</td>
          </tr>
          </tbody>
        </Table></Col>
      </Row>

      <Row>
        <Col sm={2}><h6>Parameters:</h6></Col>
        <Col sm={10}><Table>
          <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Value</th>
          </tr>
          </thead>
          <tbody>
          {jsonData.windowType.parameters.map((para: any, index: any) => {
            return <tr>
              <td>{jsonData.windowType.parameters[index].name}</td>
              <td>{jsonData.windowType.parameters[index].type}</td>
              <td>{jsonData.windowType.parameters[index].value}</td>
            </tr>
          })}
          </tbody>
        </Table></Col>
      </Row>
    </section>
  </>
};
