import { Route } from "react-router-dom";
import { RevitPluginScreen } from "./screens/RevitPluginScreen";
import { HelpScreen } from "./screens/HelpScreen";

export const supportAreaRoutes = (
  <Route path="/support">
    <Route index={true} element={<RevitPluginScreen />} />
    <Route path="revit-plugin" element={<RevitPluginScreen />} />
    <Route path="help" element={<HelpScreen />} />
  </Route>
);
