import {
  EntityOptions,
  EntityStore,
  IAction,
  Page,
  ToolbarAction,
  ToolbarList,
} from "@components";
import { PagedResult } from "@dirootie-core/core-types";
import {
  Course,
  CoursesStore,
  getCourseValidationSchema,
} from "@domain/courses";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CoursesTable } from "./CoursesTable";

export const courseEntityOptions: EntityOptions<Course> = {
  schema: getCourseValidationSchema,
  empty: {
    name: "",
    number: undefined,
    state: "Active",
    description: undefined,
    lecturers: [],
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  path: "courses",
  actions: {
    FORM_CREATE: {
      converter: (action: IAction) => {
        action.options!.path = "courses";
        action.options!.navigateTo = "../:id/summary";
        return action;
      },
    },
  },
};

export const entityStore = new EntityStore(courseEntityOptions);

const CoursesIndexScreen: React.FC<any> = inject("coursesStore")(
  observer((props: { coursesStore: CoursesStore }) => {
    const location = useLocation();
    const segments = location.pathname.split("/");
    return (
      <Page title="Courses">
        <ToolbarList
          onAction={(action: ToolbarAction) => {
            entityStore.onAction(action);
          }}
          backDisabled={true}
        />

        <CoursesTable
          isEditable={segments[1] === "lecturers"}
          source={{
            path: "courses",
          }}
        />
      </Page>
    );
  })
);

export default CoursesIndexScreen;
