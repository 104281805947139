import {Page, PresetsTable, ToolbarList} from "@components";

const BuildingParametersPresetScreen: React.FC<any> = (props: any) => {
  return (
    <Page title="Building parameters preset">
      <div>
        <ToolbarList/>

        <PresetsTable type="BUILDING"/>
      </div>
    </Page>
  );
};

export default BuildingParametersPresetScreen;
