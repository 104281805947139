import { ServiceFactory } from '@dirootie-core/api-types';
import { PagedResult } from '@dirootie-core/core-types';
import { activator } from '@dirootie-extensions/javascript';
import { makeAutoObservable } from 'mobx';
import { application } from '../../../configuration/Application.bootstrap';
import { IDataSource, IQuery, ISearch, TableSourceProps } from './tables.types';

export abstract class DataSource<TType> implements IDataSource<TType> {
  constructor(protected source: TableSourceProps) {}

  getAll(search: ISearch): Promise<PagedResult<TType>> {
    console.log('getAllElements', search);
    const s = this.beforeGetAll(search);
    return this.getAllElements(s).then((x) => {
      return this.afterGetAll(x);
    });
  }

  abstract getAllElements(search: ISearch): Promise<PagedResult<TType>>;

  beforeGetAll(search: ISearch): ISearch {
    if (this.source.query !== undefined) {
      search = { ...search, ...this.source.query };
    }
    return search;
  }

  afterGetAll(elems: PagedResult<TType>): PagedResult<TType> {
    return elems;
  }
}

export class CollectionDataSource<TType> extends DataSource<TType> {
  constructor(source: TableSourceProps) {
    super(source);
    console.log('Datasource', source);
  }

  getAllElements(search: ISearch): Promise<PagedResult<TType>> {
    console.log('getAllElements', search);
    const q = search.query;
    const elements = this.source.elements!;
    const result =
      q !== undefined
        ? elements.filter(
            (x: any) =>
              Object.keys(q)
                .map((y: any) => x[y] === q[y])
                .filter((z) => z === false).length === 0
          )
        : elements;
    return Promise.resolve({
      items: result,
      page: 1,
      size: result.length,
      total: result.length,
    } as PagedResult<any>);
  }

  updateElements(elems: any[]) {
    this.source.elements = elems;
  }
}

export class ApiDataSource<TType> extends DataSource<TType> {
  constructor(source: TableSourceProps) {
    super(source);
  }
  getAllElements(search: ISearch): Promise<PagedResult<TType>> {
    const serviceFactory = application.getStores()
      .serviceFactory as ServiceFactory;
    const apiService = serviceFactory.createApiServiceFromPath<TType, TType>(
      this.source.path
    );
    const result = apiService.getAll(search);
    return result;
  }
}

export class DataSourceFactory {
  static createDataSource<TType>(
    sourceProps: TableSourceProps
  ): IDataSource<TType> {
    if (sourceProps.service !== undefined) {
      return sourceProps.service;
    }
    if (sourceProps.serviceConstructor !== undefined) {
      return activator(sourceProps.serviceConstructor as any, sourceProps);
    }
    if (sourceProps.path !== undefined) {
      return new ApiDataSource(sourceProps);
    }
    if (sourceProps.elements !== undefined) {
      return new CollectionDataSource(sourceProps);
    }
    return new CollectionDataSource(sourceProps);
  }
}
