import {Material} from "./Material";

export const materialData: Material[] = [
  {
    id: "id_material_1",
    name: "Material 1",
    class: "CONCRETE",
    description: "Material description 1",
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now())
  },
  {
    id: "id_material_2",
    name: "Material 2",
    class: "STEEL",
    description: "Material description 2",
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now())
  },
  {
    id: "id_material_3",
    name: "Material 3",
    class: "STONE",
    description: "Material description 3",
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now())
  }
] as any;
