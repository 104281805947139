import {FormInputExtendedProps,} from "@dirootie-web/components-types";
import {Field} from "formik";
import {CustomInput, Form} from "taltech-styleguide";

export type InputRadioGroupProps = FormInputExtendedProps & {
  isInline?: boolean,
  options: {
    id: string;
    label: string;
    value: string;
  }[];
};

export const InputRadioGroup = ({
                                  isInline = false,
                                  id,
                                  formState,
                                  disabled,
                                  ...props
                                }: InputRadioGroupProps) => {
  return (
    <Form.Group className="mb-3">
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <div className={isInline ? "d-flex" : ""}>
        {props.options.map((x) => {
          return (
            <Field
              key={x.id}
              inline
              type="radio"
              id={x.id}
              name={id}
              role="group"
              value={x.value}
              label={x.label}
              component={CustomInput}
            />
          );
        })}
      </div>
    </Form.Group>
  );
};
