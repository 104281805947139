import InvitationsScreen from "./InvitationsScreen";
import UserDetailsScreen from "./UserDetailsScreen";
import UsersRegisteredScreen from "./UsersRegisteredScreen";
import {
  PageTabed,
  PageTabedProps
} from "@components";

const UsersScreen: React.FC<any> = (props: any) => {
  const newProps: PageTabedProps = {
    title: "User management",
    pathBase: "/management/users",
    tabs: [
      {
        label: "Registered",
        component: <UsersRegisteredScreen />,
        index: 0,
        path: "registered",
      },
      {
        label: "",
        component: <UserDetailsScreen />,
        index: 1,
        path: "details",
      },
      {
        label: "Invitations",
        component: <InvitationsScreen />,
        index: 2,
        path: "invitations",
      },
    ],
  };
  return <PageTabed {...newProps}></PageTabed>;
};

export default UsersScreen;
