import { Entity, IRepository } from "@dirootie-core/core-types";
import { ICrudAction, ICrudActionHandler } from "./Crud.types";
import { IActionResult } from "../Actions.types";

export abstract class CrudActionHandlerBase implements ICrudActionHandler {

  handle(action: ICrudAction): Promise<IActionResult> {
    const repository = this.createRepository();
    return this.handleAction(action,repository);
    // const beforeActionResult = this.beforeAction(action);
    // const result = this.executeAction(action);
    // const afterActionResult = this.afterAction(action);
  }

  protected createRepository(): IRepository<any,string> {
    return "a" as any;
  }

  // abstract beforeAction(action: ICrudAction): Promise<any>;

  abstract handleAction(action: ICrudAction, repository: IRepository<any,string>): Promise<IActionResult>;

  // abstract afterAction(action: ICrudAction): TType;
}

export class GetActionHandler extends CrudActionHandlerBase {
  handleAction(action: ICrudAction<any, any, undefined>, repository: IRepository<any, string>): Promise<IActionResult<void>> {
    return repository.getOne(action.id!, action.options);
  }
}

export class CreateActionHandler extends CrudActionHandlerBase {
  handleAction(action: ICrudAction<any, any, undefined>, repository: IRepository<any, string>): Promise<IActionResult<void>> {
    return repository.getOne(action.payload, action.options);
  }
}

export class UpdateActionHandler extends CrudActionHandlerBase {
  handleAction(action: ICrudAction<any, any, undefined>, repository: IRepository<any, string>)
    : Promise<IActionResult<void>> {
      return repository.save(action.payload, action.options);
  }
}

export class DeleteActionHandler extends CrudActionHandlerBase {
  async handleAction(action: ICrudAction<any, any, undefined>, repository: IRepository<any, string>): Promise<IActionResult<void>> {
    const result = await repository.delete(action.id!);
    return Promise.resolve({ payload:result, isSuccess: true} );
  }
}


