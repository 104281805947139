import React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { MediaItemCreateScreen } from "../../resources/MediaItemCreateScreen";

export const AssignmentResourceCreateScreen: React.FC<any> = observer(
  (props) => {
    const { assignmentId } = useParams();
    return <MediaItemCreateScreen subjectId={assignmentId} />;
  }
);
