import { FC } from "react";
import { Card, Separator } from "taltech-styleguide";
import { SectionTestProps } from "./Components.types";

export const Section: FC<SectionTestProps> = (props) => {
  return (
    <div>
          <Card>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>

        <Card.Subtitle>{props.description}</Card.Subtitle>
        <Card.Text className="mt-3">
        <Separator size="xl" />
          {props.component}
        </Card.Text>
      </Card.Body>
    </Card>

      {/* <div className="card">
      <div className="card-body">
      <h5>{props.title}</h5>
      <h6>{props.description}</h6>
      <div>
      {props.children}
      </div>

      </div>
    </div> */}
    </div>

  );
};

export const SectionSamples: FC<SectionTestProps> = (props) => {
  return <Section {...props}/>
};

SectionSamples.defaultProps = {
  title:"Samples",
  description:"Samples of the present component",
}

export const SectionConfiguration: FC<SectionTestProps> = (props) => (<Section {...props}/>);

SectionConfiguration.defaultProps = {
  title:"Configuration",
  description:"Configure component",
}

export const SectionResults: FC<SectionTestProps> = (props) => (<Section {...props}/>);

SectionResults.defaultProps = {
  title:"Result"
}
