import React, {FC} from "react";
import {Col, Row} from "taltech-styleguide";
import '../../styles/specification.css'
import {BuildingParameterSpecifications} from "./building-specifications/BuildingParameterSpecifications";
import {FloorSpecifications} from "./building-specifications/FloorSpecifications";
import {GroundFloorSpecifications} from "./building-specifications/GroundFloorSpecifications";
import {InfoComponent} from "./building-specifications/InfoComponent";
import {LayoutSpecifications} from "./building-specifications/LayoutSpecifications";
import {MaterialsSpecifications} from "./building-specifications/MaterialsSpecifications";
import {RoofSpecifications} from "./building-specifications/RoofSpecifications";
import {SpaceFunctionSpecifiactions} from "./building-specifications/SpaceFunctionSpecifiactions";
import {WallExteriorSpecifications} from "./building-specifications/WallExteriorSpecifications";
import {WallInteriorSpecification} from "./building-specifications/WallInteriorSpecification";
import {WindowTypeSpecifiactions} from "./building-specifications/WindowTypeSpecifiactions";
import jsonData from './Building.specification.json';


// type IBuildingSpecification = {}

const BuildingSpecification: React.FC<any> = ({data}) => {

  return <>
    <InfoComponent jsonData={jsonData}/>
    <hr/>

    <section>
      <Row>
        <Col sm={2}><h5>Length unit:</h5></Col>
        <Col sm={10}><h6>{jsonData.lengthUnit}</h6></Col>
      </Row>
    </section>
    <hr/>

    <section>
      <Row>
        <Col sm={2}><h5>Height:</h5></Col>
        <Col sm={10}><h6>{jsonData.height}</h6></Col>
      </Row>
    </section>
    <hr/>

    <section>
      <Row>
        <Col sm={2}><h5>Number of levels:</h5></Col>
        <Col sm={10}><h6>{jsonData.numberOfLevels}</h6></Col>
      </Row>
    </section>
    <hr/>

    <MaterialsSpecifications jsonData={jsonData}/>
    <hr/>

    <WallInteriorSpecification jsonData={jsonData}/>
    <hr/>

    <WallExteriorSpecifications jsonData={jsonData}/>
    <hr/>

    <FloorSpecifications jsonData={jsonData}/>
    <hr/>

    <GroundFloorSpecifications jsonData={jsonData}/>
    <hr/>

    <RoofSpecifications jsonData={jsonData}/>
    <hr/>

    <WindowTypeSpecifiactions jsonData={jsonData}/>
    <hr/>

    <BuildingParameterSpecifications jsonData={jsonData}/>
    <hr/>

    <SpaceFunctionSpecifiactions jsonData={jsonData}/>
    <hr/>

    <LayoutSpecifications jsonData={jsonData}/>
    <hr/>

  </>
}

export const JsonViewer: FC<any> = () => {

  return (
    <div>
      <BuildingSpecification data={jsonData}/>
    </div>
  );
}
