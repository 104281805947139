import { UserNotification } from './../notifications/notifications.types';

const translationBaseKey = 'NOTIFICATION.FORMS';

const createTranslations = (notificationKey: string) => {
  return {
    title: { key: `${translationBaseKey}.${notificationKey}.TITLE` },
    message: { key: `${translationBaseKey}.${notificationKey}.MESSAGE` },
  };
};

export const formHasErrorsNotification: UserNotification = {
  //...createTranslations('HAS_ERRORS'),
  title: "Form contains errors",
  message: "Current form has errors. Please correct them to continue.",
  type: 'error',
};

export const saveSuccessNotification: UserNotification = {
  //...createTranslations('SAVE_SUCCESS'),
  title:"Successfully saved",
  message:"Changes were successfully saved!",
  type: 'success',
};

export const saveErrorNotification: UserNotification = {
  //...createTranslations('SAVE_ERROR'),
  title:"An error has occurred",
  message:"An error occurred during save operation. Changes were not successfully saved.",
  type: 'error',
};

export const createdSuccessNotification: UserNotification = {
  //...createTranslations('CREATE_SUCCESS'),
  title: "Successfully created",
  message: "Element was successfully created!",
  type: 'success',
};

export const createdErrorNotification = (error:any): UserNotification => {
  return {
  //...createTranslations('NOT_FOUND'),
  title: "An error has occurred",
  message: error !== undefined ? error : "An error occurred during create operation. Element was not successfully created.",
  type: 'error',
  component: true
}};

export const deleteSuccessNotification: UserNotification = {
  //...createTranslations('DELETE_SUCCESS'),
  title:"Successfully deleted",
  message:"Element was successfully deleted!",
  type: 'success',
};

export const deleteErrorNotification: UserNotification = {
  //...createTranslations('DELETE_ERROR'),
  title:"An error has occurred",
  message:"An error occurred during delete operation. Element was not successfully deleted.",
  type: 'error',
};

export const notFoundNotification: UserNotification = {
  //...createTranslations('NOT_FOUND'),
  title:"Resource not found",
  message:"The resource you are looking for was not found. It was either deleted or does not exist. You will be redirected to a new page.",
  type: 'warning',
};
