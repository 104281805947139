import { InputSelectForm, RandomChip } from "@components";
import { ToolbarEdit } from "@components/navigation";
import { SelectOption } from "@dirootie-web/components-types";
import {
  IPresetElement,
  PresetComponent,
  PresetComponentItem,
  PresetComponentProps,
  PresetComponentType,
  presetComponentTypeOptions,
} from "@domain";
import { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "taltech-styleguide";
import { CollectionDataSource } from "../../../components/tables/temp/data.source";
import { TalTechTableService } from "../../../components/tables/temp/tables.types";
import { PageLoader } from "./PresetDetails";
import { PresetsElementSelector } from "./PresetElementSelector";
import { PresetForm } from "./PresetForm";

export type PresetComponentDetailsProps = {
  title: string;
  elementId: string;
  getElement: (elemId: string) => Promise<PresetComponent>;
  onSave: (elem: PresetComponent) => Promise<void>;
  getElements: (type: PresetComponentType) => Promise<IPresetElement[]>;
};

const initialState: PresetComponentProps = {
  floors: { type: "FLOOR", creationType: "CONSTANT", elementIds: [] },
  wallsExterior: {
    type: "WALL_EXTERIOR",
    creationType: "CONSTANT",
    elementIds: [],
  },
  wallsInterior: {
    type: "WALL_INTERIOR",
    creationType: "CONSTANT",
    elementIds: [],
  },
  roofs: { type: "ROOF", creationType: "CONSTANT", elementIds: [] },
  windows: { type: "WINDOW", creationType: "CONSTANT", elementIds: [] },
  buildingParameters: {
    type: "BUILDING_PARAMETERS",
    creationType: "CONSTANT",
    elementIds: [],
  },
};

const service = new CollectionDataSource({ elements: [] });
const tableService = new TalTechTableService(service!);
export const PresetComponentDetails: React.FC<any> = ({
  title,
  elementId,
  getElement,
  onSave,
  getElements,
  ...rest
}: PresetComponentDetailsProps) => {
  const [element, setElement] = useState<PresetComponent | undefined>(
    undefined
  );

  const [elements, setElements] = useState<IPresetElement[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (elementId !== undefined) {
      getElement(elementId).then((x) => {
        setElement(x);
        setLoading(false);
      });
    }
  }, [elementId, getElement, getElements]);

  const onSelectionChange = (type: PresetComponentType) => {
    getElements(type).then((x) => {
      setElements(x);
    });
  };

  const onItemChanged = (value: any) => {
    const { data, item } = value;
    const newITem: any = {};
    newITem[data.property] = item;
    setElement({ ...element!, ...newITem! });
    if (item.creationType === "RANDOM") {
      getElements(item.type).then((x) => {
        setElements(x);
        service.updateElements(elements);
        tableService.search();
      });
    }
  };

  const createContent = () => {
    let submitSelected: any = null;
    let submitForm: any = null;
    const onSubmitSelectedHandler = (e: any) => {
      if (submitForm) {
        submitForm(e).then((x: any) => console.log(x));
      }
      if (submitSelected) {
        element!.elementIds = submitSelected!(e);
      }
    };

    const submitSelectedBinder = (submitFormParam: any) => {
      submitSelected = submitFormParam;
    };

    const submitFormBinder = (submitFormParam: any) => {
      submitForm = submitFormParam;
    };

    return (
      <>
        <ToolbarEdit onSave={onSubmitSelectedHandler} />
        <PresetForm
          element={element!}
          submiFormBinder={submitFormBinder}
          onSubmit={(val) => onSave(val)}
          visualization={{ showModifiedDate: true, showCreatedDate: false }}
        />
        <Row>
          <Col>
            <ComponentList
              element={element!}
              onSelectedChange={(type) => {
                onSelectionChange(type);
              }}
              onChange={(item: {
                item: PresetComponentItem;
                data: SelectOption & { property: string };
              }) => onItemChanged(item)}
              getElements={getElements}
            />
          </Col>
          <Col>
            <PresetsElementSelector
              service={tableService}
              selected={[]}
              submitSelectedBinder={submitSelectedBinder}
            />
          </Col>
        </Row>
      </>
    );
  };
  return <PageLoader loading={loading} content={createContent} title={title} />;
};

type ComponentLineProps = {
  selectedType: PresetComponentType;
  type: PresetComponentType;
  selected: PresetComponentType;
  label: string;
  element: PresetComponentItem;
  onClick: (type: PresetComponentType) => void;
  elements: IPresetElement[];
  onChange: (item: PresetComponentItem) => void;
  onSelectedChange: (id: string) => void;
  getElements: () => Promise<IPresetElement[]>;
};

const ComponentLine = ({
  selectedType,
  label,
  element,
  type,
  onClick,
  selected,
  onChange,
  onSelectedChange,
  getElements,
}: ComponentLineProps) => {
  const [elements, setElements] = useState<IPresetElement[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (type !== undefined) {
      getElements().then((x) => {
        setElements(x);
        setLoading(false);
      });
    }
  }, [getElements]);
  return (
    <tr key={type} onClick={() => onClick(type)}>
      <td
        className={
          selected === type
            ? "ebd-component-elements-selected w-25"
            : "ebd-component-elements w-25"
        }
      >
        {label}
      </td>
      <td>
        <div className={"d-flex align-items-center"}>
          <Form.Group className={"w-50"}>
            <InputSelectForm
              options={{
                elements,
                selector: (elem) => ({ value: elem.id, text: elem.name }),
              }}
              selected={
                element.creationType === "CONSTANT" &&
                element.elementIds.length === 1
                  ? element.elementIds[0]
                  : selected
              }
              disabled={element.creationType === "RANDOM"}
              onClick={(val) => {
                onSelectedChange(val);
              }}
              id={selectedType}
            />
          </Form.Group>
          <Form.Group>
            <RandomChip
              random={element.creationType === "RANDOM"}
              onClick={() => {
                const elem = { ...element };
                elem.creationType =
                  elem.creationType === "RANDOM" ? "CONSTANT" : "RANDOM";
                onChange({
                  ...elem,
                });
              }}
            />
          </Form.Group>
        </div>
      </td>
    </tr>
  );
};

type ComponentListProps = {
  element: PresetComponent;
  onSelectedChange: (id: any) => void;
  onChange: (value: {
    item: PresetComponentItem;
    data: SelectOption & { property: string };
  }) => void;
  getElements: (type: PresetComponentType) => Promise<IPresetElement[]>;
};

const ComponentList = ({
  element,
  onSelectedChange,
  onChange,
  getElements,
}: ComponentListProps) => {
  const [selected, setSelected] =
    useState<PresetComponentType>("WALL_INTERIOR");
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Component</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {presetComponentTypeOptions.map((x) => {
            const props: ComponentLineProps = {
              type: x.value,
              label: x.text,
              element: (element as any)[x.property],
              elements: [],
              onClick: (type) => {
                onSelectedChange(type);
                setSelected(type);
              },
              onChange: (item) => {
                const newItem = {
                  data: x,
                  item,
                };
                onChange(newItem);
              },
              onSelectedChange: onSelectedChange,
              selectedType: "BUILDING_PARAMETERS",
              selected: selected,
              getElements: () => getElements(x.value),
            };
            return <ComponentLine key={x.value} {...props} />;
          })}
          ;
        </tbody>
      </Table>
    </>
  );
};
