import { PagedResult, SortDirection } from '@dirootie-core/core-types';
import _ from 'lodash';
import { action, makeAutoObservable, observable } from 'mobx';
import { IDataSource, IQuery, ISearch } from './tables.types';
export type SearchResuls<T = any> = {
  items: T[];
  total: number;
};
export class SearchStore<T> {
  private _currentSearch: ISearch = {
    page: 1,
    size: 10,
  };

  @observable get currentSearch() {
    return this._currentSearch;
  }

  _loading = false;
  @observable get loading() {
    return this._loading;
  }

  @action
  startLoading() {
    this._loading = true;
  }

  @action
  stopLoading() {
    this._loading = false;
  }

  @observable currentResults: SearchResuls<T> = { items: [], total: 0 };
  //@observable loading = false;
  constructor(private dataService: IDataSource<T>) {
    makeAutoObservable(this);
    //this.search(this._currentSearch);
  }

  private handleSearchResult = (results: PagedResult<T>) => {
    this.currentResults.items = [...results.items];
    this.currentResults.total = results.total;
  };

  search(quer?: IQuery): Promise<SearchResuls<T>> {
    if (this.loading) {
      return Promise.resolve(this.currentResults);
    }
    const query = _.isUndefined(quer) ? this._currentSearch : quer;
    this.startLoading();

    return this.dataService.getAll(query).then(
      (x: any) => {
        this.stopLoading();
        this.handleSearchResult(x);

        console.log('P', this.currentResults, this, x);
        return Promise.resolve(x);
      }
      // (err: Error)=>{
      //   this.mainAppViewStore.reportError(err);
      // }
    );
  }

  changePage(page: number, size: number): void {
    this._currentSearch.page = page;
    this._currentSearch.size = size;
    this.search({ ...this._currentSearch });
  }

  changeSort(direction: SortDirection, field: string): void {
    this._currentSearch.sortBy = field;
    this._currentSearch.sortDirection = direction;
    this._currentSearch.page = 0;
    this.search({ ...this._currentSearch });
  }

  getResults(): { items: T[]; total: number } {
    return this.currentResults;
  }
}
