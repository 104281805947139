import {Field, useField} from "formik";
import React, {FC, useEffect} from "react";
import {CustomInput} from "taltech-styleguide";
import ErrorComponent from "./ErrorComponent";

type ICheckboxType = {
  changeFn?: () => void,
  type: string,
  name: string,
  label?: string,
  disabled: boolean,
  value?: boolean,

}

export const CheckboxComponent: FC<ICheckboxType> = ({
                                                value,
                                                changeFn,
                                                type,
                                                name,
                                                label,
                                                disabled,
                                                ...props
                                              }) => {
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    helpers.setValue(value)
  }, [value])
  return (
    <div>
      <Field isInvalid={meta.touched && meta.error} name={name}>
        {() => (
          <CustomInput
            disabled={disabled}
            {...field}
            type="checkbox"
            id="exampleCheckbox"
            label={label && label}
            checked={field.value}
            onChange={(e) => {
              changeFn && changeFn();
              field.onChange(e);
            }}
          />
        )}
      </Field>
      <ErrorComponent fieldName={name}/>
    </div>
  );
};

export default CheckboxComponent;
