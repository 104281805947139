import { DisabledTabComponent, Breadcrumbs, WindowDetails } from "@components";
import {
  BuildingComponent,
  WindowBuildingComponent,
} from "@domain/building-components";
import { inject } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { windowEntityStore } from "./WindowTypeIndexScreen";

export const WindowTypeDetailsScreen: React.FC<any> = inject(
  "buildingComponentsStore"
)((props) => {
  const { typeId } = useParams();
  const [element, setElement] = useState<BuildingComponent | undefined>(
    undefined
  );

  useEffect(() => {
    if (typeId !== undefined) {
      windowEntityStore
        .executeAction<WindowBuildingComponent>({
          action: "FORM_GET",
          payload: typeId,
          options: {
            path: "buildingComponents.components",
          },
        })
        .then((x) => {
          x.parameters = [];
          setElement(x);
        });
    }
  }, [setElement, typeId]);
  if (element === undefined) {
    return <></>;
  }
  return (
    <div>
      <h1>Windows</h1>
      <Breadcrumbs />
      <div className={"container"}>
        <DisabledTabComponent
          elements={["Types", "Parameters"]}
          activeDisabled={0}
        />
        <WindowDetails element={element} entityStore={windowEntityStore} />
      </div>
    </div>
  );
});
