import {Course} from "./Course";

export const coursesData: Course[] = [
  {
    id: 'id_course_1',
    number: 1,
    name: 'name 1',
    description: 'string 1',
    state: 'ACTIVE',
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
    lecturers: [
      {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
    ],
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_course_2',
    number: 2,
    name: 'name',
    description: 'string',
    state: 'ACTIVE',
    lecturers: [
      {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
    ],
    modifiedDate: new Date(Date.now()),
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    id: 'id_course_3',
    number: 3,
    name: 'name',
    description: 'string',
    state: 'ACTIVE',
    lecturers: [
      {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
      {
        id: 'idl2',
        displayName: 'lect1',
        description: 'Blabla',
      },
      {
        id: 'idl3',
        displayName: 'lect1',
        description: 'Blabla',
      }, {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
      {
        id: 'idl2',
        displayName: 'lect1',
        description: 'Blabla',
      },
      {
        id: 'idl3',
        displayName: 'lect1',
        description: 'Blabla',
      },
    ],
    modifiedDate: new Date(Date.now()),
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    id: 'id_course_4',
    number: 4,
    name: 'name',
    description: 'string',
    state: 'ACTIVE',
    lecturers: [
      {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
    ],
    modifiedDate: new Date(Date.now()),
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    id: 'id_course_5',
    number: 5,
    name: 'name',
    description: 'string',
    state: 'ACTIVE',
    lecturers: [
      {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
    ],
    modifiedDate: new Date(Date.now()),
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    id: 'id_course_6',
    number: 6,
    name: 'name',
    description: 'string',
    state: 'ARCHIVED',
    lecturers: [
      {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
    ],
    modifiedDate: new Date(Date.now()),
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    id: 'id_course_7',
    number: 7,
    name: 'name',
    description: 'string',
    state: 'ACTIVE',
    lecturers: [
      {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
    ],
    modifiedDate: new Date(Date.now()),
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    id: 'id_course_8',
    number: 8,
    name: 'name',
    description: 'string',
    state: 'ACTIVE',
    lecturers: [
      {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
    ],
    modifiedDate: new Date(Date.now()),
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    id: 'id_course_9',
    number: 9,
    name: 'name',
    description: 'string',
    state: 'ACTIVE',
    lecturers: [
      {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
    ],
    modifiedDate: new Date(Date.now()),
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    id: 'id_course_21',
    number: 21,
    name: 'name',
    description: 'string',
    state: 'ACTIVE',
    lecturers: [
      {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
    ],
    modifiedDate: new Date(Date.now()),
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    id: 'id_course_22',
    number: 22,
    name: 'name',
    description: 'string',
    state: 'COMPLETED',
    lecturers: [
      {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
    ],
    modifiedDate: new Date(Date.now()),
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    id: 'id_course_24',
    number: 24,
    name: 'name',
    description: 'string',
    state: 'ACTIVE',
    lecturers: [
      {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
    ],
    modifiedDate: new Date(Date.now()),
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    id: 'id_course_25',
    number: 25,
    name: 'name',
    description: 'string',
    state: 'ACTIVE',
    lecturers: [
      {
        id: 'idl1',
        displayName: 'lect1',
        description: 'Blabla',
      },
    ],
    modifiedDate: new Date(Date.now()),
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
];
