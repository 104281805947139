import {
  colDisplayName,
  colEmail,
  defaultProps,
  TableTalTech2,
} from "@components";
import { observer } from "mobx-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Initials } from "taltech-styleguide";

export const UsersSearchTable: React.FC<any> = observer(
  (props: { elements: any }) => {
    const navigator = useNavigate();
    const [selected, setSelected] = useState<string[]>([]);
    const columns = [
      {
        classes: "ebd-initials-first",
        dataField: "pictureUrl",
        formatter: (col: any, row: any, index: any, extraData: any) => {
          return <Initials key={row.id} name={row.displayName} size="xs" />;
        },
        sort: false,
        text: "",
      },
      colDisplayName,
      colEmail,
      //colRole,
    ];
    const tableProps = {
      ...defaultProps,
      columns,
      selectRow: {
        mode: "checkbox",
        selected: selected,
        selectColumnPosition: "left",

        className: "ebd-initials-first",
      },
    } as any;
    return (
      <TableTalTech2
        source={{
          path: "users.azuread",
        }}
        table={tableProps}
      ></TableTalTech2>
    );
  }
);
