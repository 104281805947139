import { Page, StructureLayerFunctionTable, ToolbarBack }  from "@components";
import { Alert, STATUS_TYPE } from "taltech-styleguide";

const StructuralLayerFunctionsScreen: React.FC<any> = (props: any) => {
  return <Page title="Structural layer functions">
    <ToolbarBack />
    <Alert variant={STATUS_TYPE.INFO}>
      Structural layer functions are static. They cannot be changed.
    </Alert>
    <StructureLayerFunctionTable />
  </Page>
};

export default StructuralLayerFunctionsScreen;
