import { action, makeAutoObservable, observable } from 'mobx';
import { ILogger } from '../packages/core/logging-types/src';
import {
  internalServerErrorNotification,
  UserNotificationService,
} from '../packages/web/components-types/src/notifications';

export class MainAppViewStore {
  @observable loading = false;

  private errors: Error | any = [];
  constructor(
    private userNofificationService: UserNotificationService,
    private logger: ILogger
  ) {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this.loading;
  }

  @action
  startLoading() {
    this.loading = true;
  }

  @action
  stopLoading() {
    this.loading = false;
  }

  // reportError(error: any) {
  //   if (this.loading.isMainAreaLoading) {
  //     this.stopLoading();
  //   }
  //   this.errors.push(error);
  //   // this.logger.error(error.message, error);
  //   // this.userNofificationService.error(internalServerErrorNotification);
  // }
}
