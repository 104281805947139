import {
  FormBase,
  FormProps,
  InputSingleDatePicker,
  InputText,
} from "@components";
import { UserProfile } from "@domain";
import { FC } from "react";
import { Form as FormikForm } from "formik";
import { observer } from "mobx-react";

export const ProfileEditForm: FC<FormProps<UserProfile>> = observer(
  ({ ...props }) => {
    const render = (formik: any) => {
      return (
        <FormikForm>
          <InputText
            disabled={props.visualization?.isReadOnly}
            id="memberId"
            label="University id"
            formState={formik as any}
          />
          <InputText
            disabled={props.visualization?.isReadOnly}
            id="firstName"
            label="First name"
            formState={formik as any}
          />
          <InputText
            disabled={props.visualization?.isReadOnly}
            id="lastName"
            label="Last name"
            formState={formik as any}
          />
          <InputText
            disabled={props.visualization?.isReadOnly}
            id="displayName"
            label="Display name"
            formState={formik as any}
          />
          <InputText
            disabled={props.visualization?.isReadOnly}
            id="email"
            label="Email"
            formState={formik as any}
          />
          <InputSingleDatePicker
            id={"modifiedDate"}
            formState={formik as any}
            label={"Modified at"}
          />
        </FormikForm>
      );
    };
    return <FormBase {...props} render={render} />;
  }
);

export default ProfileEditForm;
