import { ServiceFactory } from '@dirootie-core/api-types';
import {
  IUserNotificationService,
  badRequestNotification,
  notAuthorizedNotification,
} from '../../../packages/web/components-types/src/notifications';
import { IFormAction } from './FormActions.types';
import {
  createdErrorNotification,
  createdSuccessNotification,
  deleteErrorNotification,
  deleteSuccessNotification,
  formHasErrorsNotification,
  saveSuccessNotification,
} from '../../../packages/web/components-types/src/notifications/forms.notifications';
import { IEntity } from '@dirootie-core/core-types';
import { FormActionHandlerBase } from './Forms.handlers';

const convertError = (error: any) => {
  let responseErrors = error?.response?.data?.extensions?.errors;
  let result: string | undefined = undefined;
  if (responseErrors) {
    //se ha erros nos inputs
    result = '';
    let errorsKeys = Object.keys(responseErrors);
    errorsKeys.forEach((key) => {
      let errorMsg = responseErrors[key][0].message;
      result = result + errorMsg + '\n';
    });
  }
  return result;
};

export class FormCreateNewActionHandler extends FormActionHandlerBase {
  constructor(
    userNotificationService: IUserNotificationService,
    serviceFactory: ServiceFactory
  ) {
    super(userNotificationService, serviceFactory);
  }

  public handle<T extends IEntity>(action: IFormAction<T>) {
    const apiService = this.serviceFactory.createApiServiceFromPath<T, T>(
      action.options.path
    );
    return apiService?.create(action.payload!).then(
      (x) => {
        this.userNotificationService.success(createdSuccessNotification);
        const editPath: string = action.options!.navigateTo
          ? action.options!.navigateTo
          : '../:id';
        //  const editPath: string = action.options!.navigateTo ? action.options!.navigateTo : ':id';
        action.options.navigator!(editPath.replace(':id', x.id + ''), {
          relative: 'path',
        });
      },
      (x) => {
        this.userNotificationService.error(
          createdErrorNotification(convertError(x))
        );
      }
    );
  }
}
