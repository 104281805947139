import { EntityOptions, EntityStore } from "@components";
import { BuildingComponentsIndexBaseScreen } from "@components/building-components";
import { WindowBuildingComponent, getBuildingComponentValidationSchema } from "@domain";


const componentEntityOptions: EntityOptions<WindowBuildingComponent> = {
  schema: getBuildingComponentValidationSchema,
  empty: {
    name: '',
    description: '',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    category: "WINDOWS"
  },
  path: 'buildingComponents.components.windows',
  actions: {
    FORM_CREATE: {
      converter: (action: any) => {
        action.options.path = "buildingComponents.components.windows"; return action;
      }
    }
  }
};

export const windowEntityStore = new EntityStore(componentEntityOptions)

export const WindowTypeIndexScreen: React.FC<any> = () => (
  <BuildingComponentsIndexBaseScreen componentType={"WINDOWS" as any} />
);
