import React, {FC} from "react";
import {Button, BUTTON_COLOR, ICONS} from "taltech-styleguide";
import {useStore} from "../../domain/store";


export const PreviousBtn: FC<any> = ({...props}) => {
  const {buildingConfigurationFormStore} = useStore();

  return (
    <>
      <div className={'d-flex justify-content-center'}>
        <Button
          disabled={buildingConfigurationFormStore.currentStep === 0}
          className={'w-100 mr-4'}
          onClick={(e) => {
            buildingConfigurationFormStore.currentStep === 0 ?
              buildingConfigurationFormStore.setPreviousStep(0) :
              buildingConfigurationFormStore.setPreviousStep(buildingConfigurationFormStore.currentStep - 1)
          }}
          variant={BUTTON_COLOR.OUTLINE_PRIMARY}
          iconLeft={ICONS.ARROW_OPEN_LEFT}
          size="sm">
          PREVIOUS
        </Button>
      </div>
    </>
  );
};

export default PreviousBtn;
