import {
  AuthenticationResult,
  PublicClientApplication,
} from '@azure/msal-browser';
import { ServiceFactory } from '@dirootie-core/api-types';
import { SessionStore } from './Session.store';
import { UserProfile, RoleType } from '@domain/users';
import { ViewNavigator } from 'configuration/ViewNavigator';
import _ from 'lodash';

export class AuthenticationService {
  apiService;
  constructor(
    serviceFactory: ServiceFactory,
    sessionStore: SessionStore,
    private msalInstance: PublicClientApplication,
    private authorizationService: AuthorizationService,
    private viewNavigator: ViewNavigator
  ) {
    this.apiService =
      serviceFactory.createApiServiceFromPath('users.registered');
  }

  isAuthenticated(): boolean {
    const isLoggedIn = this.msalInstance.getAllAccounts().length > 0;
    return isLoggedIn;
  }

  getAccountInfo(from?: string) {
    return this.apiService
      .getUrl('self/verification')
      .then((x: any) => {
        return this.handleAccount(x, from);
      })
      .catch((e) => {
        return this.handleAccountError(e);
      });
  }

  loginSuccess(result: AuthenticationResult): Promise<void> {
    return this.getAccountInfo();
  }

  handleAccount(profile: UserProfile, from?: string): Promise<void> {
    if (profile !== undefined && profile.accountState !== undefined) {
      if (
        profile.accountState === 'REGISTERED' ||
        profile.accountState === 'ACTIVE'
      ) {
        this.authorizationService.handleActiveAccount(profile, from);
        return Promise.resolve();
      }
      if (profile.accountState === 'INVITED') {
        this.authorizationService.handleInvitedAccount(profile, from);
        return Promise.resolve();
      }

      if (
        profile.accountState === 'BLOCKED' ||
        profile.accountState === 'SUSPENDED'
      ) {
        this.authorizationService.handleBlockedAccount(profile);
        return Promise.resolve();
      }
    }
    return Promise.resolve();
  }

  handleAccountError(x: any): Promise<void> {
    if (
      _.isUndefined(x) ||
      _.isUndefined(x.response) ||
      _.isUndefined(x.response.status)
    ) {
      this.authorizationService.handleAccountError();
      return Promise.reject();
    }
    const { response } = x;
    if (
      response.status === 422 &&
      x.response.data.detail === 'Account does not exist!'
    ) {
      this.authorizationService.handleNonExistingAccount(x);
      return Promise.reject();
    }
    if (
      (response.status === 422 &&
        x.response.data.detail === 'Account is blocked!') ||
      response.data.detail === 'Account is suspended!'
    ) {
      this.authorizationService.handleBlockedAccount(x);
      return Promise.reject();
    }
    return Promise.reject();
  }

  loginUnsuccess(result: AuthenticationResult): Promise<void> {
    return Promise.resolve();
  }

  logout(): Promise<void> {
    return Promise.resolve();
  }
}

export class AuthorizationService {
  private _isAuthorized: boolean = false;

  constructor(
    private sessionStore: SessionStore,
    private viewNavigator: ViewNavigator
  ) {}
  getStartPageForRole(role: RoleType): string {
    if (role === 'STUDY_DIRECTOR') {
      return '/management';
    }
    if (role === 'LECTURER') {
      return '/lecturers';
    }
    if (role === 'STUDENT') {
      return '/students';
    }
    return '/errors/not-found';
  }

  handleActiveAccount(profile: UserProfile, from?: string) {
    this.handleInvitedAccount(profile, from);
  }
  handleInvitedAccount(profile: UserProfile, from?: string) {
    this.sessionStore.setUserProfile(profile);
    this._isAuthorized = true;
    if (from === undefined) {
      this.navigateToRoleHomePage(profile.role);
    }
  }

  handleBlockedAccount(profile: UserProfile) {
    this.sessionStore.setUserProfile(profile);
    this._isAuthorized = false;
    this.viewNavigator.navigateToPath('errors/blocked-account');
  }

  handleNonExistingAccount(profile: UserProfile) {
    this._isAuthorized = false;
    if (!this.viewNavigator.isLogoutRoute()) {
      this.viewNavigator.navigateToPath('errors/no-account');
    }
  }

  handleAccountError(): Promise<void> {
    this._isAuthorized = false;
    this.viewNavigator.navigateToPath('errors/internal-server-error');
    return Promise.reject();
  }

  navigateToRoleHomePage(role: RoleType) {
    const home = this.getStartPageForRole(role);
    this.viewNavigator.navigateToPath(home);
  }

  hasPermission(roles: RoleType[]) {
    return (
      roles.findIndex((x) => x === this.sessionStore.userProfile?.role!) > -1
    );
  }

  isAuthorized() {
    return this._isAuthorized;
  }
}
