import {FormBase, FormProps, InputSingleDatePicker, InputText, InputTextArea,} from "@components";
import {Course} from "@domain";
import {Form as FormikForm,} from "formik";
import 'moment/locale/en-gb';
import React, {FC, useEffect} from "react";
// import {IDateRangeFormat, SingleDatePicker} from "taltech-styleguide";
import 'react-dates/lib/css/_datepicker.css';
import {Col, Row} from "taltech-styleguide";
import ImageWithFade from "../../../../../components/forms/ImageWithFade";
import UploadComponent from "../../../../../components/forms/UploadComponent";
import {IconCourse} from "../../../../../components/icons/IconCourse";
import "../../../../../styles/form.css";

export type CourseFormProps = {
  course: Course;
  onSubmit: (course: Course) => void;
  onCancel: (evt: any) => void;
  onReset?: () => void;
  visualization?: {
    isReadOnly: boolean,
    showModifiedDate: boolean,
    showCreatedDate: boolean
  }
}


export const CourseEditForm: FC<FormProps<Course>> = ({...props}) => {
  const [singleFocus, setSingleFocus] = React.useState<boolean>(false);
  const [imageUrl, setImageUrl] = React.useState<string>(props.element?.picture?.url || "");
  useEffect(() => {
    console.log(props.element?.picture?.url)
    setImageUrl(props.element?.picture?.url || "")
  }, [props.element?.picture])

  const render = (formik: any) => {
    return (
      (
        <FormikForm>
          <div className={"uploader-wrapper mt-5"} style={{position: "relative", width: 200, cursor: "pointer"}}>
            <div className={'uploader'}>
              <UploadComponent setImageUrl={setImageUrl} id={props.element.id}/>
            </div>
            <div>
              {imageUrl !== "https://taltechedubimtesteufugst.blob.core.windows.net/" && props.mode === "EDIT" ?
                <ImageWithFade url={imageUrl}/>
                : <IconCourse/>}
            </div>
          </div>
          <InputText disabled={props.visualization?.isReadOnly}
                     id="number"
                     label="Number"
                     formState={formik as any}/>
          <InputText disabled={props.visualization?.isReadOnly}
                     id="name"
                     label="Name"
                     formState={formik as any}/>
          <InputTextArea disabled={props.visualization?.isReadOnly}
                         id="description"
                         label="Description"
                         formState={formik as any}/>
          <Row>
            <Col sm={6}>
              <InputSingleDatePicker id={'startDate'}
                                     formState={formik as any}
                                     label={"Start date"}
                                     disabled={false}/>
            </Col>
            <Col sm={6}>
              <InputSingleDatePicker id={'endDate'}
                                     formState={formik as any}
                                     label={"End date"} {...{startId: 'startDate'}}
                                     disabled={false}/>
            </Col>
          </Row>
        </FormikForm>
      )
    );
  };
  return <FormBase {...props} render={render}/>;
};

export default CourseEditForm;
