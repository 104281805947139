import { FC } from "react";
import {
  PageTest,
  PageTestProps,
  useOutputWindowService
} from "@components/testing";
import { WallDetails } from "@components";

export const WallComponentUitest: FC<any> = () => {

  const props: PageTestProps = {
    title: "Wall component - uitest",
    demo: {
      outputWindow: true,
      Component: WallDetails
  }};

  return (
    <PageTest {...props}/>
  );
};
