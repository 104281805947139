import {BuildingComponentsWrapper, NavigationBtnGroup, PreviousBtn} from "@components";
import {
  BuildingComponentsFormType,
  BuildingConfigurationStateTypes,
  BuildingFunctionFormTypes
} from "@dirootie-web/components-types";
import {observer} from "mobx-react";
import React, {FC} from "react";
import {Container, Progress} from "taltech-styleguide";
import {useStore} from "../../domain/store";
import "../../styles/form.css";
import {BuildingConfigurationWrapper} from "./BuildingConfigurationFormWrapper";
import {BuildingSpaceFunctionWrapper} from "./BuildingSpaceFunctionWrapper";


export type IFormBuildingGenerator = {
  firstStep: BuildingConfigurationStateTypes,
  secondStep: BuildingFunctionFormTypes,
  thirdStep: any,
  sixthStep: BuildingComponentsFormType
}

export const FormBuildingGenerator: FC<IFormBuildingGenerator> = observer(({...props}) => {

  const {buildingConfigurationFormStore} = useStore();

  return (
    <>
      <div className={'d-flex align-items-start justify-content-between mb-5 mt-5'}>
        <PreviousBtn/>
        <Progress
          aria-label="Progress"
          steps={[
            "Building information",

            "Building layout",
            "Space functions",
            // "Preview Model",
            // "Building Spaces Function",
            // "Building components"
            "Building components",
          ]}
          currentStep={buildingConfigurationFormStore.currentStep}
          lastCurrentStep={buildingConfigurationFormStore.lastCurrentStep}
          onStepChange={buildingConfigurationFormStore.handleStepChange}
          position={"center"}
        />
        <NavigationBtnGroup stepName={"Next"}/>
      </div>
      <hr/>
      <Container>

        {buildingConfigurationFormStore.currentStep === 0 ? <BuildingConfigurationWrapper/> :
          (buildingConfigurationFormStore.currentStep === 1 || buildingConfigurationFormStore.currentStep === 2) ?
            <BuildingSpaceFunctionWrapper/>
            : <BuildingComponentsWrapper/>}
      </Container>
    </>
  );
});
