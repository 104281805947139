import {IFormBuildingGenerator} from '../../components/building-configurations/FormBuildingGenerator';
import {ComponentTypes} from "../../packages/web/components-types/src/forms/BuildingConfigurationTypes";

export const emptyBuildingConfigurationProps: IFormBuildingGenerator = {
  firstStep: {
    config: {
      isFormDirty: false,
      stepNumber: 0,
      disabled: false,
      isValid: false,
      stepName: '',
      setNextStep: () => {
      },
    },
    name: '',
    description: '',
    buildingOrientation: 1,
    buildingType: 'Commercial',
    coordinates: '',
    buildingHeightType: 1,
    FloorHeight: 0,
    floorHeightMax: '',
    floorHeightMin: '',
    orientationTotalValue: '',
    includeSurroundings: true,
    measureTypes: 'Modular',
    moduleSize: undefined,
    numberOfFloor: undefined,
    orientationMax: '',
    orientationMin: '',
    surroundingRadius: undefined,
    floorHeightTotalValue: '',
    buildingParameter: 1
  },

  secondStep: {
    config: {
      isFormDirty: false,
      stepNumber: 1,
      disabled: false,
      isValid: false,
      stepName: '',
      setNextStep: () => {
      },
    },
    firstRawWidth: undefined,
    firstRawNumberOfRooms: undefined,
    secondRawWidth: undefined,
    secondRowNumberOfRooms: undefined,
  },

  thirdStep: {
    config: {
      isFormDirty: false,
      stepNumber: 2,
      disabled: false,
      isValid: false,
      stepName: '',
      setNextStep: () => {
      },
    },
  },

  sixthStep: {
    selectedIndex: 2,
    config: {
      isFormDirty: false,
      stepNumber: 3,
      disabled: false,
      isValid: false,
      stepName: 'Define Building Structure and Window components',
      setNextStep: () => {
      },
    },
    elements: [
      {
        key: 'Interior walls',
        selectedElement: 0,
        shuffle: false,
      },
      {
        key: 'Exterior walls',
        selectedElement: 0,
        shuffle: false,
      },
      {
        key: 'Ground floor',
        selectedElement: 0,
        shuffle: false,
      },
      {
        key: 'Floor',
        selectedElement: 0,
        shuffle: false,
      },
      {
        key: 'Roof',
        selectedElement: 0,
        shuffle: false,
      },
      {
        key: 'Windows',
        selectedElement: 0,
        shuffle: false,
      },
      // {
      //   key: 'Building parameters',
      //   selectedElement: 0,
      //   values: [
      //     {
      //       name: 'Building parameters type 1',
      //       description:
      //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
      //       isExternal: false,
      //       modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
      //       createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
      //       isSelected: false,
      //     },
      //     {
      //       name: 'Building parameters type 2',
      //       description:
      //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
      //       isExternal: false,
      //       modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
      //       createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
      //       isSelected: false,
      //     },
      //     {
      //       name: 'Building parameters type 3',
      //       description:
      //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
      //       isExternal: false,
      //       modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
      //       createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
      //       isSelected: false,
      //     },
      //     {
      //       name: 'Building parameters type 4',
      //       description:
      //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
      //       isExternal: false,
      //       modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
      //       createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
      //       isSelected: false,
      //     },
      //     {
      //       name: 'Building parameters type 5',
      //       description:
      //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
      //       isExternal: false,
      //       modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
      //       createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
      //       isSelected: false,
      //     },
      //     {
      //       name: 'Building parameters type 6',
      //       description:
      //         'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
      //       isExternal: false,
      //       modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
      //       createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
      //       isSelected: false,
      //     },
      //   ],
      //   shuffle: false,
      // },
    ],
    setSelectedIndex: () => {
    },
  },
};


export const exteriorWallTypes: ComponentTypes[] = [
  {
    name: 'Exterior wall Type 1',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Exterior wall Type 2',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Exterior wall Type 3',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Exterior wall Type 4',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Exterior wall Type 5',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Exterior wall Type 6',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
]
export const interiorWallTypes: ComponentTypes[] = [
  {
    name: 'Interior wall type 1',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Interior wall type 2',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Interior wall type 3',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Interior wall type 4',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Interior wall type 5',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Interior wall type 6',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
]
export const groundFloorTypes: ComponentTypes[] = [
  {
    name: 'Ground floor type 1',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Ground floor type 2',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Ground floor type 3',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Ground floor type 4',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Ground floor type 5',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Ground floor type 6',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
]
export const roofTypes: ComponentTypes[] = [
  {
    name: 'Roof type 1',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Roof type 2',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Roof type 3',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Roof type 4',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Roof type 5',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Roof type 6',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
]
export const floorTypes: ComponentTypes[] = [
  {
    name: 'Floor type 1',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Floor type 2',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Floor type 3',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Floor type 4',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Floor type 5',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Floor type 6',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
]
export const windowTypes: ComponentTypes[] = [
  {
    name: 'Windows type 1',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Windows type 2',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Windows type 3',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Windows type 4',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Windows type 5',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Windows type 6',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
]
export const buildingParameterTypes: ComponentTypes[] = [
  {
    name: 'Building parameters type 1',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Building parameters type 2',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Building parameters type 3',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Building parameters type 4',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Building parameters type 5',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
  {
    name: 'Building parameters type 6',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing ...',
    isExternal: false,
    modifiedOn: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    createdDate: new Date().toLocaleDateString(('en-GB').split('/').reverse().join('/')),
    isSelected: false,
  },
]
