import { Entity, IEntity } from '@dirootie-core/core-types';
import { SpaceFunction } from '../building-data';
import { ParameterCreationType, ParameterDescriptor } from '../shared';

export const presetTypeOptions = [
  { text: 'Building', value: 'BUILDING' },
  { text: 'Space function', value: 'SPACE_FUNCTION' },
  { text: 'Component', value: 'COMPONENT' },
] as const;
export const PresetTypeList = presetTypeOptions.map((x) => x.value);
export type PresetType = typeof presetTypeOptions[number]['value'];

export interface IPresetElement extends IEntity<string> {
  name: string;
  description: string;
}

export interface IPreset extends IPresetElement {
  type: PresetType;
  elementIds: string[];
}

export type Preset<T> = IPreset &
  Entity & {
    name: string;
    description: string;
    type: PresetType;
    elements?: T[];
    elementIds?: string[];
  };

export type PresetBuildingParameter = Preset<ParameterDescriptor> & {};

export type PresetSpaceFunction = Preset<SpaceFunction> & {};

export const presetComponentTypeOptions = [
  { text: 'Wall interior', value: 'WALL_INTERIOR', property: 'wallsInterior' },
  { text: 'Wall exterior', value: 'WALL_EXTERIOR', property: 'wallsExterior' },
  { text: 'Window', value: 'WINDOW', property: 'windows' },
  { text: 'Floor', value: 'FLOOR', property: 'floors' },
  { text: 'Roof', value: 'ROOF', property: 'roofs' },
  {
    text: 'Building parameters',
    value: 'BUILDING_PARAMETERS',
    property: 'buildingParameters',
  },
] as const;
export const PresetComponentTypelist = presetComponentTypeOptions.map(
  (x) => x.value
);
export type PresetComponentType =
  typeof presetComponentTypeOptions[number]['value'];

export type PresetComponentItem = {
  type: PresetComponentType;
  creationType: ParameterCreationType;
  elementIds: string[];
};

export type PresetComponentProps = {
  floors: PresetComponentItem;
  wallsInterior: PresetComponentItem;
  wallsExterior: PresetComponentItem;
  windows: PresetComponentItem;
  roofs: PresetComponentItem;
  buildingParameters: PresetComponentItem;
};

export type PresetComponent = Preset<PresetComponentItem> &
  Partial<PresetComponentProps>;
