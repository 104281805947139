import {
  IActionHandler,
  IActionResult,
  IAction,
  HandlersBuildOptions,
} from '../Actions.types';
import { IViewNavigator } from 'configuration/ViewNavigator';

export type NavigationAction = 2;

export const NavigationActionTypeList = [
  'NAVIGATE_TO',
  'NAVIGATE_TO_NEW',
  'NAVIGATE_TO_EDIT',
  'NAVIGATE_BACK',
  'NAVIGATE_PREVIOUS',
] as const;

export type NavigationActionType = typeof NavigationActionTypeList[number];

export interface INavigationActionOptions {
  navigateTo: string | number;
  relative?: boolean;
}

export interface INavigationAction<
  TActionType extends NavigationActionType = any,
  TData = any,
  TOptions extends INavigationActionOptions = INavigationActionOptions
> extends IAction<TActionType, TData, TOptions> {}

export class NavigationActionHandlerBase implements IActionHandler {
  protected viewNavigator: IViewNavigator;
  constructor(options: HandlersBuildOptions) {
    this.viewNavigator = options.viewNavigator;
  }

  handle(action: INavigationAction): Promise<IActionResult> {
    const { navigateTo, relative = false } = action.options!;
    this.viewNavigator!.navigateToPath(navigateTo, { relative });
    return Promise.resolve({ payload: undefined, isSuccess: true });
  }
}
