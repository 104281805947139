export const IconCircleXmark = ()  => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10 19a9 9 0 100-18 9 9 0 000 18zM13 7l-6 6M7 7l6 6"
    ></path>
  </svg>
);
