import {TableCopyBtn, TableDeleteBtn, TableDownloadBtn, TableEditBtn, TableViewBtn,} from "@components";
import {convertToTitleCase, formatDate,} from "@dirootie-extensions/javascript";
import {FILTER_TYPES} from "taltech-styleguide";
import {IconSubmitted} from "../icons/IconSubmitted";

export const colId = {
  dataField: "id",
  hidden: true,
  text: "ID",
};

export const colNumber = {
  dataField: "number",
  text: "Number",
};

export const colName = {
  classes: "test-column",
  dataField: "name",
  sort: true,
  text: "Name",
  formatter: (col: any, row: any, index: any, extraData: any) => {
    return <div className={"test-column-name"}>{col}</div>;
  },
  filterObject: {
    defaultValue: undefined,
    minChars: 3,
    placeholder: "Name",
    type: FILTER_TYPES.TEXT,
  },
};

export const colDisplayName = {
  classes: "test-column",
  dataField: "displayName",
  sort: true,
  text: "Display name",
  filterObject: {
    defaultValue: undefined,
    minChars: 3,
    placeholder: "Name",
    type: FILTER_TYPES.TEXT,
  },
};

export const colRole = {
  dataField: "role",
  sort: true,
  text: "Role",
  formatter: (col: string) => convertToTitleCase(col),
};

export const colEmail = {
  dataField: "email",
  sort: true,
  text: "E-mail",
};

export const colTitle = {
  classes: "test-column",
  dataField: "title",
  sort: true,
  formatter: (col: any, row: any, index: any, extraData: any) => {
    return <div className={"test-column-name"}>{col}</div>;
  },
  text: "Title",
  filterObject: {
    defaultValue: undefined,
    minChars: 3,
    placeholder: "Title",
    type: FILTER_TYPES.TEXT,
  },
};

export const colDescription = {
  classes: "test-column",
  dataField: "description",
  sort: true,
  formatter: (col: any, row: any, index: any, extraData: any) => {
    return <div className={"test-column-description"}>{col}</div>;
  },
  text: "Description",
};

export const colCreateDate = {
  dataField: "createdDate",
  filterObject: {
    defaultValue: undefined,
    type: FILTER_TYPES.DATE,
  },
  formatter: (col: Date) => formatDate(col),
  sort: true,
  text: "Created at",
};

export const colModifiedDate = {
  dataField: "modifiedDate",
  filterObject: {
    defaultValue: undefined,
    type: FILTER_TYPES.DATE,
  },
  formatter: (col: Date) => formatDate(col),
  sort: true,
  text: "Modified at",
};

export const colStartDate = {
  dataField: "startDate",
  filterObject: {
    defaultValue: undefined,
    type: FILTER_TYPES.DATE,
  },
  formatter: (col: Date) => formatDate(col),
  sort: true,
  text: "Start date",
};

export const colEndDate = {
  dataField: "endDate",
  filterObject: {
    defaultValue: undefined,
    type: FILTER_TYPES.DATE,
  },
  formatter: (col: Date) => formatDate(col),
  sort: true,
  text: "End date",
};

type ColActionProps = {
  showEdit?: boolean;
  showCopy?: boolean;
  showDelete?: boolean;
  showView?: boolean;
  showDownload?: boolean;
  showSubmitted?: boolean;
  onDelete?: () => void,
  onDownload?: () => void,
  onEdit?: () => void,
  onViewClick?: () => void
};

export const createColActions = (
  navigator: any,
  {
    showEdit = true,
    showCopy = true,
    showDelete = true,
    showView = false,
    showDownload = false,
    showSubmitted = false,
    onDelete,
    onDownload,
    onEdit,
    onViewClick
  }: ColActionProps
) => {
  const onEditClick = (id: string) => {
    navigator(id);
  };
  return (
    <div style={{width: 50}} className={"d-flex ml-auto justify-content-end"}>
      {showCopy && <TableCopyBtn/>}
      {showDownload &&
        <TableDownloadBtn onClick={onDownload}/>}
      {showSubmitted && <IconSubmitted/>}
      {showView && <TableViewBtn onClick={onViewClick}/>}
      {showEdit && <TableEditBtn onClick={onEdit}/>}
      {showDelete && <div onClick={onDelete}><TableDeleteBtn/></div>}
    </div>
  );
};

const defaultPagination = {
  sizePerPage: 10,
  sizePerPageList: [
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ],
};

const defaultTranslations = {
  clearFilters: "Clear filters",
  clearRadioFilter: "Clear radio filter",
  datePlaceholder: "Placeholder",
  dateRangeEndPlaceholder: "End date",
  dateRangeStartPlaceholder: "Start date",
  filterColumn: "Filter column",
  moreFiltersSelected: "More filters selected",
  noMultiselectOptions: "No multi select options",
  noResults: "No results found",
  pageSize: "Page size",
  removeTag: "Remove tag",
};

export const defaultProps = {
  keyField: "id",
  pagination: defaultPagination,
  translations: defaultTranslations,
};
