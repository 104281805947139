import { mergeProps } from "@dirootie-extensions/javascript";
import _ from "lodash";
import { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { Button as ButtonTT, BUTTON_COLOR } from "taltech-styleguide";
import { ButtonProps } from "./Buttons.types";
import { IconTalTech } from "@components/icons";
import { defaultButtonKindDefinition } from "./Buttons.defaults";
import { ButtonKind } from "./Buttons.kind";

const defaultButtonProps: ButtonProps = {
  kind: undefined,
  text: "Text missing",
  visual: "text",
  disabled: false,
  color: BUTTON_COLOR.SECONDARY,
  onClick: (data?: any, ev?: any) => ({}),
};

const createProps = (
  defaultProps: ButtonProps,
  props: Partial<ButtonProps>
): ButtonProps => {
  const deftProps = _.cloneDeep(defaultProps);
  const { kind } = props;
  if (_.isUndefined(kind)) {
    const result = mergeProps(deftProps, props! as any);
    return result;
  }
  const kindDefaults = defaultButtonKindDefinition[kind as ButtonKind];
  if (_.isUndefined(kindDefaults)) {
    return mergeProps(deftProps, props! as any);
  }
  const kindFinalDefaults = mergeProps(deftProps, [
    props!,
    kindDefaults as any,
  ]);
  return kindFinalDefaults;
};

const colorMapper = (() => {
  const mapper = {
    primary: BUTTON_COLOR.SECONDARY,
    secondary: BUTTON_COLOR.PRIMARY,
    basic: BUTTON_COLOR.OUTLINE_PRIMARY,
    warn: BUTTON_COLOR.OUTLINE_SECONDARY,
    info: BUTTON_COLOR.OUTLINE_SECONDARY,
    success: BUTTON_COLOR.OUTLINE_SECONDARY,
  };

  const getColor = (value: string | undefined) => {
    if (value === undefined) {
      return mapper.primary;
    }
    if ((mapper as any)[value] === undefined) {
      return value;
    }
    return (mapper as any)[value];
  };

  return getColor;
})();

const ButtonInner: React.FC<any> = (props: ButtonProps) => {
  // const navigator = useNavigate();
  // const onClick = props.onClickUrl
  //   ? (evt: any) => {
  //       navigator(props.onClickUrl!);
  //     }
  //   : props.onClick!;
  const { color, style, text, ...rest } = props;
  return (
    <ButtonTT
      {...rest}
      variant={colorMapper(color) as BUTTON_COLOR}
      style={style as CSSProperties}
    >
      {text}
    </ButtonTT>
  );
};

// export const Button = ButtonInner;

// export const Button2: React.FC<ButtonProps> = (props) => {
//   const btnProps = createProps(defaultButtonProps, props);
//   const component =
//     btnProps.visual === "icon" ? (
//       <IconTalTech {...(btnProps as any)} />
//     ) : (
//       <ButtonInner {...btnProps} />
//     );
//   return component;
// };
export const Button2: React.FC<ButtonProps> = (props) => {
  const btnProps = createProps(defaultButtonProps, props);
  const component =
    btnProps.visual === "icon" ? (
      <IconTalTech {...(btnProps as any)} />
    ) : (
      <ButtonInner {...btnProps} />
    );
  return component;
};
export const Button = Button2;
