import { SummaryResource } from "domain/courses/Course";
import _ from "lodash";
import {
  ColumnType,
  TableColumn,
  TableProps,
} from "packages/web/components-types/src/tables/tables.types";
import { Column } from "primereact/column";
import {
  DataTable,
  DataTablePageParams,
  DataTableSortParams,
} from "primereact/datatable";
import { Icon, Initials, StatusTag, STATUS_TYPE } from "taltech-styleguide";
import { SortDirection } from "../../packages/core/core-types/src";
import dayjs from "dayjs";
import { isEmptyArray } from "formik";
import SelectFromArray from "../forms/SelectFromArray";
import { observer } from "mobx-react";
import { Component } from "react";
import { IconTalTech } from "@components/icons";

const columnMapper = new Map<ColumnType, (props: TableColumn) => JSX.Element>();
columnMapper.set("text", (col: TableColumn) => (
  <Column
    key={"col-" + col.name}
    sortable={col.sortable}
    field={col.name}
    header={col.header}
    body={
      col.bodyFormatter !== undefined
        ? (d, c) => col.bodyFormatter!(d, c)
        : undefined
    }
  />
));
columnMapper.set("image", (col: TableColumn) => (
  <Column
    key={"col-" + col.name}
    sortable={col.sortable}
    field={col.name}
    header={col.header}
  />
));
columnMapper.set("avatar", (col: TableColumn) => (
  <Column
    key={"col-" + col.name}
    sortable={col.sortable}
    field={col.name}
    header={col.header}
    body={(d, c) => {
      const names = ["name", "displayName", "firstName", "lastName"]
        .map((x) => d[x])
        .filter((x) => x !== undefined);
      const result = !isEmptyArray(names) ? names[0] : "";
      return <Initials name={result} imageUrl={d["pictureUrl"]} size="xs" />;
    }}
  />
));
columnMapper.set("date", (col: TableColumn) => (
  <Column
    key={"col-" + col.name}
    sortable={col.sortable}
    field={col.name}
    body={(d, c) => dayjs(d[c.field]).format("DD/MM/YYYY")}
    header={col.header}
  />
));
columnMapper.set("state", (col: TableColumn) => (
  <Column
    key={"col-" + col.name}
    field={col.name}
    body={(row, d) => {
      const states = Object.keys(col.data.states).filter(
        (x) => x.toUpperCase() === (row[d.field] as string).toUpperCase()
      );
      let stateType = STATUS_TYPE.DANGER;
      let stateName = "UNKNOWN";
      if (!isEmptyArray(states)) {
        const state = col.data.states[states[0]];
        stateType = state.type;
        stateName = state.name;
      }
      return <StatusTag type={stateType}>{stateName}</StatusTag>;
    }}
    header={col.header}
  />
));
columnMapper.set("list", (col: TableColumn) => (
  <Column
    key={"col-" + col.name}
    className="font-bold"
    field={col.name}
    body={(row, d) => {
      return (
        <>
          {row[d.field].map((x: SummaryResource) => {
            return (
              <Initials
                key={x.id}
                name={x.displayName}
                imageUrl={x.pictureUrl}
                size="xs"
              />
            );
          })}
        </>
      );
    }}
    header={col.header}
  />
));
columnMapper.set("actions", (col: TableColumn) => (
  <Column
    key={"col-" + col.name}
    className="font-bold"
    field={col.name}
    body={(row, d) => {
      return (
        <>
          {col.data.actions.map((x: any) => {
            return <IconTalTech action={x.iconName} />;
          })}
        </>
      );
    }}
    header={col.header}
  />
));
columnMapper.set("select", (col: TableColumn) => (
  <Column
    key={"col-" + col.name}
    className="font-bold"
    field={col.name}
    body={(row, d) => {
      const props = { items: col.data.items, selected: row[d.field] };
      return (
        <SelectFromArray
          {...props}
          onClick={(ev: any) => {
            ev.stopImmediatePropagation();
          }}
        />
      );
    }}
    header={col.header}
  />
));

const buildPaginator = (props: TableProps) => {
  const { pageable = false, pageableProps = undefined } = props;
  if (!pageable || pageableProps === undefined) {
    return { paginator: false };
  }
  const result = {
    paginator: true,
    paginatorTemplate:
      "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    currentPageReportTemplate: `Showing {first} to {last} From {totalRecords}`,
    rows: pageableProps.rows,
    rowsPerPageOptions: pageableProps.rowsPerPageOptions,
    lazy: pageableProps.lazy,
    first: 1,
  };
  return result;
};

const buildColumns = (props: TableProps) => {
  const result = props.columns
    .filter((x) => x.display)
    .map((x) => columnMapper.get(x.type!)!(x));
  return result;
};

@observer
export class TableDi extends Component<TableProps & { dataStore?: any }, any> {
  //navigator =  useNavigate();

  componentDidMount() {
    if (this.props.dataStore !== undefined) {
      this.props.dataStore.search();
    }
  }

  onRowClick(evt: any) {
    if (!_.isUndefined(this.props.onRowClick)) {
      this.props.onRowClick(evt.data, evt);
    }
    if (this.props.onRowClickNavigate) {
      //navigator(evt.data.id,{relative: "route"});
    }
  }

  onPage(pageEvent: DataTablePageParams) {
    const pag = pageEvent.first / pageEvent.rows;
    console.log("Page", pageEvent);
    this.props.changePage!(pag, pageEvent.rows);
  }

  onSort(sortEvent: DataTableSortParams) {
    console.log("Sort", sortEvent);
    const direction =
      sortEvent.sortOrder !== -1 ? SortDirection.ASC : SortDirection.DESC;
    const field = sortEvent.sortField;
    console.log("Sort", direction, field, sortEvent);
    this.props.changeSort!(direction, field);
  }

  getTableProps(props: TableProps) {
    return {
      value: props.data ? props.data : [],
      onRowClick: (evt: any) => this.onRowClick(evt),
      ...buildPaginator(props),
      onPage: (pageEvent: DataTablePageParams) => this.onPage(pageEvent),
      onSort: (sortEvent: DataTableSortParams) => this.onSort(sortEvent),

      sortField: "",
      sortOrder: "" as any,
      totalRecords: props.totalResults,
      loading: props.loading,
    };
  }

  render() {
    const columns = buildColumns(this.props);
    const tableProps = this.getTableProps(this.props);
    return (
      <DataTable responsiveLayout="scroll" {...tableProps}>
        {columns}
      </DataTable>
    );
  }
}
