import React from "react";
import {Col, Row, Table} from "taltech-styleguide";


export const MaterialsSpecifications: React.FC<{ jsonData: any }> = ({jsonData}) => {

  return <>
    <section>
      {/*<h5>Materials:-</h5>*/}
      <Row>
        <Col sm={2}><h5>Materials:</h5></Col>
        <Col sm={10}><Table>
          <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Class</th>
          </tr>
          </thead>
          <tbody>
          {jsonData.materials.map((mat: any, index: any) => {
            return <tr>
              <td>{jsonData.materials[index].id}</td>
              <td>{jsonData.materials[index].name}</td>
              <td>{jsonData.materials[index].class}</td>
            </tr>
          })}
          </tbody>
        </Table></Col>
      </Row>
    </section>
  </>
};
