import _ from "lodash";
import {makeAutoObservable} from 'mobx';
import {
  BuildingComponentsFormType,
  BuildingConfigurationStateTypes,
  BuildingFunctionFormTypes,
} from '../../packages/web/components-types/src/forms';
import {
  ConfigurationServerType,
  RowType,
  WizardType
} from '../../packages/web/components-types/src/forms/BuildingConfigurationTypes';
import {
  buildingParameterTypes,
  emptyBuildingConfigurationProps,
  exteriorWallTypes,
  floorTypes,
  groundFloorTypes,
  interiorWallTypes,
  roofTypes,
  windowTypes
} from "../building/EmptyBuildingConfigurationProps";

export class BuildingConfigurationFormStore {
  currentStep = 0
  lastCurrentStep = 3
  isReadOnly: boolean = false

  firstStep: BuildingConfigurationStateTypes = emptyBuildingConfigurationProps.firstStep;

  secondStep: BuildingFunctionFormTypes = emptyBuildingConfigurationProps.secondStep;

  thirdStep: { [key: string]: any } = emptyBuildingConfigurationProps.thirdStep;

  sixthStep: BuildingComponentsFormType = emptyBuildingConfigurationProps.sixthStep

  wizardState: WizardType = {
    firstStep: this.firstStep,
    secondStep: this.secondStep,
    thirdStep: this.thirdStep,
    sixthStep: this.sixthStep
  };

  constructor() {
    makeAutoObservable(this);
  }

  mapWizardToServer(wizard: WizardType): ConfigurationServerType {
    const firstRowRoomElements: RowType[] = []
    const secondRowRoomElements: RowType[] = []
    const corridorLength: any = _.filter(wizard.thirdStep, (v, k) => _.includes(k, "corridorLength"));

    _.times(wizard.secondStep.firstRawNumberOfRooms!, (index) => {
      const firstRowRoomsLength2: any = _.filter(wizard.thirdStep, (v, k) => _.includes(k, `R_1_${index + 1}`));
      firstRowRoomElements.push({
        Name: `R.1.${index}`,
        spaceFunction: firstRowRoomsLength2[0],
        length: firstRowRoomsLength2[1],
        isShuffle: firstRowRoomsLength2[2],
        variableLength: firstRowRoomsLength2[3],
        shuffleMin: firstRowRoomsLength2[4],
        shuffleMax: firstRowRoomsLength2[5]
      })
    })
    _.times(wizard.secondStep.secondRowNumberOfRooms!, (index) => {
      const secondRowRoomsLength2: any = _.filter(wizard.thirdStep, (v, k) => _.includes(k, `R_2_${index + 1}`));
      secondRowRoomElements.push({
        Name: `R.2.${index}`,
        spaceFunction: secondRowRoomsLength2[0],
        length: secondRowRoomsLength2[1],
        isShuffle: secondRowRoomsLength2[2],
        variableLength: secondRowRoomsLength2[3],
        shuffleMin: secondRowRoomsLength2[4],
        shuffleMax: secondRowRoomsLength2[5]
      })
    })

    const firstRowRooms = firstRowRoomElements.map((el, index: number) => {
      return {
        number: el.Name,
        width: wizard.secondStep.firstRawWidth!,
        length: {
          creationType: el.isShuffle ? "RANDOM" : "CONSTANT" as any,
          type: "NUMBER" as any,
          options: el.isShuffle ? {
            min: el.shuffleMin,
            max: el.shuffleMax
          } : undefined,
          value: el.length
        },
        spaceFunction: el.spaceFunction,
        isVariableLength: el.length as any === "..."
      }
    })

    const secondRowRooms = secondRowRoomElements.map((el, index: number) => {
      return {
        number: el.Name,
        width: wizard.secondStep.secondRawWidth!,
        length: {
          creationType: el.isShuffle ? "RANDOM" : "CONSTANT" as any,
          type: "NUMBER" as any,
          options: el.isShuffle ? {
            min: el.shuffleMin,
            max: el.shuffleMax
          } : undefined,
          value: el.length
        },
        spaceFunction: el.spaceFunction,
        isVariableLength: el.length as any === "..."
      }
    })
    return {
      info: {
        id: "building id",
        name: wizard.firstStep.name,
        description: wizard.firstStep.description,
        location: {
          coordinates: {
            latitude: typeof (wizard.firstStep.coordinates) !== "string" ? wizard.firstStep.coordinates.lat : 0,
            longitude: typeof (wizard.firstStep.coordinates) !== "string" ? wizard.firstStep.coordinates.lng : 0
          },
          radius: 12,
          surroundingRadius: 12
        },
        rotation: {
          type: "ProjectNorth",
          angle: {
            creationType: wizard.firstStep.buildingOrientation === 0 ? "CONSTANT" : "RANDOM",
            type: "NUMBER",
            options: wizard.firstStep.buildingOrientation === 1 ? {
              min: +wizard.firstStep.orientationMin,
              max: +wizard.firstStep.orientationMax
            } : undefined,
            value: wizard.firstStep.buildingOrientation === 0 ? +wizard.firstStep.orientationTotalValue : undefined
          }
        }
      },
      height: {
        type: wizard.firstStep.buildingHeightType === 0 ? "FLOOR" : "BUILDING", //other option is BUILDING
        value: {
          creationType: wizard.firstStep.FloorHeight === 0 ? "CONSTANT" : "RANDOM",
          options: wizard.firstStep.FloorHeight === 1 ? {
            min: wizard.firstStep.floorHeightMin.toString(),
            max: wizard.firstStep.floorHeightMax.toString()
          } : undefined,
          value: wizard.firstStep.FloorHeight === 0 ? wizard.firstStep.floorHeightTotalValue.toString() : undefined
        }
      },
      numberOfLevels: wizard.firstStep.numberOfFloor || 0,
      elementTypes: {
        wallInterior: {
          creationType: wizard.sixthStep.elements[0].shuffle ? "RANDOM" : "CONSTANT",
          type: "list",
          options: {
            elements: wizard.sixthStep.elements[0].shuffle && Object.keys(wizard.sixthStep.elements[0].shuffledElements as any) || undefined
          },
          value: wizard.sixthStep.elements[0].shuffle ? undefined : wizard.sixthStep.elements[0].selectedElement as string
        },
        wallExterior: {
          creationType: wizard.sixthStep.elements[1].shuffle ? "RANDOM" : "CONSTANT",
          type: "list",
          options: {
            elements: wizard.sixthStep.elements[1].shuffle && Object.keys(wizard.sixthStep.elements[1].shuffledElements as any) || undefined
          },
          value: wizard.sixthStep.elements[1].shuffle ? undefined : wizard.sixthStep.elements[1].selectedElement as string
        },
        floor: {
          creationType: wizard.sixthStep.elements[3].shuffle ? "RANDOM" : "CONSTANT",
          type: "list",
          options: {
            elements: wizard.sixthStep.elements[3].shuffle && Object.keys(wizard.sixthStep.elements[3].shuffledElements as any) || undefined
          },
          value: wizard.sixthStep.elements[3].shuffle ? undefined : wizard.sixthStep.elements[3].selectedElement as string
        },
        floorGround: {
          creationType: wizard.sixthStep.elements[2].shuffle ? "RANDOM" : "CONSTANT",
          type: "list",
          options: {
            elements: wizard.sixthStep.elements[2].shuffle && Object.keys(wizard.sixthStep.elements[2].shuffledElements as any) || undefined
          },
          value: wizard.sixthStep.elements[2].shuffle ? undefined : wizard.sixthStep.elements[2].selectedElement as string
        },
        roof: {
          creationType: wizard.sixthStep.elements[4].shuffle ? "RANDOM" : "CONSTANT",
          type: "list",
          options: {
            elements: wizard.sixthStep.elements[4].shuffle && Object.keys(wizard.sixthStep.elements[4].shuffledElements as any) || undefined
          },
          value: wizard.sixthStep.elements[4].shuffle ? undefined : wizard.sixthStep.elements[4].selectedElement as string
        },
        windowType: {
          creationType: wizard.sixthStep.elements[5].shuffle ? "RANDOM" : "CONSTANT",
          type: "list",
          options: {
            elements: wizard.sixthStep.elements[5].shuffle && Object.keys(wizard.sixthStep.elements[5].shuffledElements as any) || undefined
          },
          value: wizard.sixthStep.elements[5].shuffle ? undefined : wizard.sixthStep.elements[5].selectedElement as string
        },
        buildingParameters: {
          creationType: "CONSTANT",
          type: "list",
          options: undefined,
          value: wizard.firstStep.buildingParameter as string
        }
      },
      layout: {
        rowFirst: {
          numberOfRooms: wizard.secondStep.firstRawNumberOfRooms || 0,
          width: wizard.secondStep.firstRawWidth || 0,
          rooms: firstRowRooms
        },
        corridor: {
          width: corridorLength[0]
        },
        rowSecond: {
          numberOfRooms: wizard.secondStep.secondRowNumberOfRooms || 0,
          width: wizard.secondStep.secondRawWidth || 0,
          rooms: secondRowRooms
        }
      }
    }
  }

  getInteriorWallRandomTypes(): Promise<any[]> {
    return Promise.resolve(interiorWallTypes)
  }

  getExteriorWallRandomTypes(): Promise<any[]> {
    return Promise.resolve(exteriorWallTypes)
  }

  getGroundFloorRandomTypes(): Promise<any[]> {
    return Promise.resolve(groundFloorTypes)
  }

  getFloorRandomTypes(): Promise<any[]> {
    return Promise.resolve(floorTypes)
  }

  getRoofRandomTypes(): Promise<any[]> {
    return Promise.resolve(roofTypes)
  }

  getWindowRandomTypes(): Promise<any[]> {
    return Promise.resolve(windowTypes)
  }

  getBuildingParameterTypes(): Promise<any[]> {
    return Promise.resolve(buildingParameterTypes)
  }

  handleStepChange = (index: number, _prevIndex: number) => {
    console.log(index, _prevIndex)
    if (index - _prevIndex > 1) return
    this.currentStep = index;
    if (index > this.lastCurrentStep) {
      this.lastCurrentStep = index
    }
  };

  setBuildingConfigurationState(values: BuildingConfigurationStateTypes) {
    if (typeof (values.coordinates) === 'string') {
      console.log("HERE")
      this.firstStep = {
        ...this.firstStep,
        config: {...this.firstStep.config, isFormDirty: true},
        coordinates: {
          lat: +values.coordinates.toString().split(',')[0],
          lng: +values.coordinates.toString().split(',')[1],
        },
      };
      this.firstStep = {
        ...this.firstStep, ...values,
        coordinates: this.firstStep.coordinates,
        config: {...this.firstStep.config, isFormDirty: true}
      };
    }
  }

  setFunctionConfigurationState(values: BuildingFunctionFormTypes) {
    this.secondStep = {...this.secondStep, ...values, config: {...this.secondStep.config, isFormDirty: true}};
    this.thirdStep.initialHasValue = true;
    _.times(this.secondStep.firstRawNumberOfRooms!, (index) => {
      _.set(this.thirdStep, `R_1_${index + 1}_SpaceFunction`, "");
      _.set(this.thirdStep, `R_1_${index + 1}_Length`, "");
      _.set(this.thirdStep, `R_1_${index + 1}_shuffle`, false);
      _.set(this.thirdStep, `R_1_${index + 1}_VariableLength`, undefined);
    })

    _.times(this.secondStep.secondRowNumberOfRooms!, (index) => {
      _.set(this.thirdStep, `R_2_${index + 1}_SpaceFunction`, "");
      _.set(this.thirdStep, `R_2_${index + 1}_Length`, "");
      _.set(this.thirdStep, `R_2_${index + 1}_shuffle`, false);
      _.set(this.thirdStep, `R_2_${index + 1}_VariableLength`, index === (this.secondStep.secondRowNumberOfRooms! - 1) ? 0 : undefined);
    })
    _.set(this.thirdStep, `secondRowVariableIndex`, this.secondStep.secondRowNumberOfRooms! - 1);
    this.thirdStep.config.isFormDirty = true;
  }

  setSpaceConfigurationState(values: { [key: string]: any }) {
    this.thirdStep = {...this.thirdStep, ...values, config: {...this.thirdStep.config, isFormDirty: true}};
    console.log(_.cloneDeep(this.thirdStep))
  }

  setComponentsFunctionState(values: any) {
    // this.sixthStep = {...this.sixthStep, ...values, config: {...this.sixthStep.config, isFormDirty: true}};
    // const selectedBuildingParameter: any = _.filter(values, (v, k) => _.isEqual(k, `Building parameters`));
    const selectedInteriorWalls: any = _.filter(values, (v, k) => _.isEqual(k, `Interior walls`));
    const selectedExteriorWalls: any = _.filter(values, (v, k) => _.isEqual(k, `Exterior walls`));
    const selectedGroundFloor: any = _.filter(values, (v, k) => _.isEqual(k, `Ground floor`));
    const selectedFloor: any = _.filter(values, (v, k) => _.isEqual(k, `Floor`));
    const selectedRoof: any = _.filter(values, (v, k) => _.isEqual(k, `Roof`));
    const selectedWindows: any = _.filter(values, (v, k) => _.isEqual(k, `Windows`));

    const InteriorWallsShuffle: any = _.filter(values, (v, k) => _.includes(k, `Interior wallsShuffle`));
    const ExteriorWallsShuffle: any = _.filter(values, (v, k) => _.includes(k, `Exterior wallsShuffle`));
    const GroundFloorShuffle: any = _.filter(values, (v, k) => _.includes(k, `Ground floorShuffle`));
    const FloorShuffle: any = _.filter(values, (v, k) => _.includes(k, `FloorShuffle`));
    const RoofShuffle: any = _.filter(values, (v, k) => _.includes(k, `RoofShuffle`));
    const WindowsShuffle: any = _.filter(values, (v, k) => _.includes(k, `WindowsShuffle`));

    let allValues: any = _.pickBy(values, (val, key) => val === true && _.includes(key, "_Checked"));
    const InteriorWallsComponents: any = _.pickBy(allValues, (v, k) => _.includes(k, "Interior wall"))
    const ExteriorWallsComponents: any = _.pickBy(allValues, (v, k) => _.includes(k, `Exterior wall`));
    const GroundFloorComponents: any = _.pickBy(allValues, (v, k) => _.includes(k, `Ground floor`));
    const FloorComponents: any = _.pickBy(allValues, (v, k) => _.includes(k, `Floor`));
    const RoofComponents: any = _.pickBy(allValues, (v, k) => _.includes(k, `Roof`));
    const WindowsComponents: any = _.pickBy(allValues, (v, k) => _.includes(k, `Windows`));

    let ele = selectedInteriorWalls.map((id: string, index_value: number) => {
      return [
        {
          key: "Interior walls",
          selectedElement: selectedInteriorWalls[index_value],
          shuffle: InteriorWallsShuffle[index_value],
          shuffledElements: Object.keys(InteriorWallsComponents).length > 0 ? InteriorWallsComponents : this.sixthStep.elements[0].shuffledElements
        },
        {
          key: "Exterior walls",
          selectedElement: selectedExteriorWalls[index_value],
          shuffle: ExteriorWallsShuffle[index_value],
          shuffledElements: Object.keys(ExteriorWallsComponents).length > 0 ? ExteriorWallsComponents : this.sixthStep.elements[1].shuffledElements
        },
        {
          key: "Ground floor",
          selectedElement: selectedGroundFloor[index_value],
          shuffle: GroundFloorShuffle[index_value],
          shuffledElements: Object.keys(GroundFloorComponents).length > 0 ? GroundFloorComponents : this.sixthStep.elements[2].shuffledElements
        },
        {
          key: "Floor",
          selectedElement: selectedFloor[index_value],
          shuffle: FloorShuffle[index_value],
          shuffledElements: Object.keys(FloorComponents).length > 0 ? FloorComponents : this.sixthStep.elements[3].shuffledElements
        },
        {
          key: "Roof",
          selectedElement: selectedRoof[index_value],
          shuffle: RoofShuffle[index_value],
          shuffledElements: Object.keys(RoofComponents).length > 0 ? RoofComponents : this.sixthStep.elements[4].shuffledElements
        },
        {
          key: "Windows",
          selectedElement: selectedWindows[index_value],
          shuffle: WindowsShuffle[index_value],
          shuffledElements: Object.keys(WindowsComponents).length > 0 ? WindowsComponents : this.sixthStep.elements[5].shuffledElements
        },
      ];
    });
    this.sixthStep.elements = [].concat(...ele);
    this.sixthStep = {...this.sixthStep, config: {...this.sixthStep.config, isFormDirty: true}};
    console.log(_.cloneDeep(this.sixthStep))
  }

  setNextStep(nextStep: number) {
    this.currentStep = nextStep
  }

  setPreviousStep(nextStep: number) {
    this.currentStep = nextStep
  }

  getSpaceConfigurationState(): { [key: string]: any } {
    return this.thirdStep;
  }

  setWizardState() {
    this.wizardState = {
      firstStep: this.firstStep,
      secondStep: this.secondStep,
      thirdStep: this.thirdStep,
      sixthStep: this.sixthStep
    };
    const serverConfiguration = this.mapWizardToServer(this.wizardState)
    console.log(_.cloneDeep(this.wizardState), serverConfiguration);
  }
}
