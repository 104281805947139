import {FC} from "react";
import {Link} from "react-router-dom";
import {MenuItem} from "../../domain/web-applications/AreaConfiguration";
import { IconXmark } from "../icons";
import {INavBarLeft} from "./NavBarLeft";

export interface IHamburgerMenuNavigation {
  menuState: boolean
  closeFn: (x: boolean) => void
}

export const MenuToogle: FC<MenuItem & INavBarLeft & IHamburgerMenuNavigation> = (props: MenuItem & INavBarLeft & IHamburgerMenuNavigation) => {

  return (
    <>
      <div onClick={() => props.closeFn(!props.menuState)} className={'ebd-navigation-close'}>
        <IconXmark/>
      </div>
      <div className={'ebd-hamburger-menu'}>
        {props.children?.map((el, index) => (
          <div onClick={() => props.closeFn(!props.menuState)} key={el.to} className={'my-3'}>
            <Link to={el.to}>
              <h2 className={'ebd-hamburger-element'}>{el.label}</h2>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};
