import {cols, createColActions, defaultProps, TableTalTech,} from "@components";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";


export const GroupsTable: React.FC<any> = observer(
  (props: { elements: any; isEditable: boolean }) => {
    const navigator = useNavigate();
    const columns = [
      cols.id(),
      cols.name(),
      cols.number(),
      cols.createdDate(),
      {
        formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => createColActions(navigator, {
          showCopy: false,
          showDelete: props.isEditable,
          showEdit: props.isEditable,
          showView: !props.isEditable,
          onEdit: () => navigator(row.id)
        }),
      },
    ];
    const prop = {...defaultProps, data: props.elements.items, columns};
    return <TableTalTech tableProps={prop}></TableTalTech>;
  }
);
