import { LayoutPublic } from "@components";
import { AreaConfiguration } from "../../domain/web-applications/AreaConfiguration";

export const publicAreaConfiguration: AreaConfiguration = {
  name: "public",
  roles: [],
  protected: false,
  layout: {
    name: "",
    route: "/",
    component: LayoutPublic,
  },
};
