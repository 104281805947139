import { Page, ToolbarList } from "@components";
import { PagedResult } from "@dirootie-core/core-types";
import { Course } from "@domain";
import { inject, observer } from "mobx-react";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CoursesTable } from "../../../lecturers/screens/courses/CoursesTable";

const CourseIndexScreen: React.FC<any> = inject("coursesStore")(
  observer((props) => {
    const [elements, setElements] = useState<PagedResult<Course>>({
      total: 0,
      page: 0,
      size: 0,
      items: [],
    });
    const location = useLocation();
    const segments = location.pathname.split("/");
    useEffect(() => {
      props.coursesStore?.getStudentCurses("lecturerId", {}).then((x: any) => {
        setElements(x);
      });
    }, [props.coursesStore]);
    return (
      <Page title="Courses" showBreadcrumbs={true}>
        <ToolbarList disabled={segments[1] === "students"} />
        <CoursesTable
          isEditable={segments[1] === "lecturers"}
          elements={elements}
        />
      </Page>
    );
  })
);

export default CourseIndexScreen;
