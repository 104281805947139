import { Route } from "react-router-dom";
import AnalyticsScreen from "./screens/analytics/AnalyticsScreen";
import AuditingScreen from "./screens/auditing/AuditingScreen";
import DashboardScreen from "./screens/dashboard/DashboardScreen";
import NotificationsScreen from "./screens/notifications/NotificationsScreen";
import UserDetailsScreen from "./screens/users/UserDetailsScreen";
import UsersScreen from "./screens/users/UsersScreen";
import InvitationCreateScreen from "./screens/users/InvitationCreateScreen";
import { ProtectedRoute } from "@components";

export const adminAreaRoutes: any = (
  <Route path="/management">
    <Route index={true} element={<DashboardScreen />} />
    <Route path="users">
      <Route index={true} element={<UsersScreen />} />
      <Route path=":tabId" element={<UsersScreen />} />
      <Route path="registered/:userId" element={<UsersScreen />} />
      <Route path="details/:userId" element={<UserDetailsScreen />} />
      <Route path="invitations/new" element={<InvitationCreateScreen />} />
    </Route>
    <Route path="auditing">
      <Route index={true} element={<AuditingScreen />} />
    </Route>
    <Route path="analytics">
      <Route index={true} element={<AnalyticsScreen />} />
    </Route>
    <Route path="notifications">
      <Route index={true} element={<NotificationsScreen />} />
    </Route>
  </Route>
);
