import { IKeyedObject } from '@dirootie-extensions/typescript';
import { PagedResult, SortDirection } from '@dirootie-core/core-types';
import {
  IAdvancedTableProps,
  IFilterDefaultValue,
  IFilterState,
  ITableState,
} from 'taltech-styleguide';
import { SearchResuls, SearchStore } from './search.store';
import { defaultProps } from '../Tables.utils';
import { Type } from 'typescript';
import _ from 'lodash';

export type RowAction = any & {
  confirmEnabled?: boolean;
};

export type ColumnType =
  | 'text'
  | 'actions'
  | 'image'
  | 'order'
  | 'date'
  | 'date_time'
  | 'template'
  | 'state'
  | 'list'
  | 'avatar'
  | 'select';

export type TableColumn = {
  name: string;
  header: string;
  width?: number;
  display: boolean;
  actions?: RowAction[];
  type?: ColumnType;
  translate?: boolean;
  sortable?: boolean;
  bodyFormatter?: (rowData: any, cellData: any) => any;
  data?: any;
};

// export type DraggableProps = {
//   visual:"icon" | "number";
//   icon?:Icon;
//   column?:string;
//   onDrop?:(data:{
//       prevIndex:number,
//       prevRowData:any,
//       targetIndex:number,
//       targetRowData:any}) => void
// };

// export type TableProps<T = any> = {
//   columns: TableColumn[];
//   actions?: RowAction[];
//   data?: any[];
//   onRowClick?: (row: any, evt: any) => void;
//   onRowClickNavigate?: boolean;
//   pageable?: boolean;
//   pageableProps?: PageableProps;
//   dataStore?: SearchStore<T>;
//   totalResults?: number;
//   loading?: boolean;
//   changeSort?: (direction: SortDirection, filed: string) => void;
//   changePage?: (page: number, size: number) => void;
// };

// export type PageableProps = {
//   lazy: boolean;
//   rows: number;
//   rowsPerPageOptions: number[];
//   totalRecords?: number;
// };

export interface IPageProps {
  size?: number;
  page?: number;
}

export interface ISortProps {
  sortBy?: string;
  sortDirection?: SortDirection;
}

export interface IQuery {
  query?: IKeyedObject<any>;
}

export interface ISearch extends IPageProps, ISortProps, IQuery {
  size?: number;
  page?: number;
  params?: IKeyedObject<string>;
}

// export type IQsuery = {
//   search?: IKeyedObject<any>;
//   page?: number;
//   size?: number;
//   sortDirection?: SortDirection;
//   sortBy?: string;
// } & IKeyedObject<string> &
//   any;

export interface IDataSource<T> {
  getAll(query: IQuery): Promise<PagedResult<T>>;
}

export interface ISearchStore<TType> {}

export interface ITableService<TTableProps, TSearch> {
  search<TType>(query?: TSearch): Promise<SearchResuls<TType>>;
  getProps(props: any): TTableProps;
}

export interface ITableAdapter<TPage, TSort, TQuey> {
  fromPage(page: IPageProps): TPage;
  toPage(page: TPage): IPageProps;
}

export abstract class TableService<TTableProps, TSearch>
  implements ITableService<TTableProps, TSearch>
{
  protected dataStore;

  constructor(service: IDataSource<any>) {
    this.dataStore = new SearchStore<any>(service);
  }

  abstract getProps(props: any): TTableProps;

  search<TType>(query?: TSearch | undefined): Promise<SearchResuls<TType>> {
    const q = this.convertSearchQuery(query);
    console.log('search');
    return this.dataStore.search(q);
  }

  abstract convertSearchQuery(query?: TSearch | undefined): ISearch;
}

export type TableSourceProps = {
  path?: string;
  elements?: any[];
  query?: IKeyedObject<string>;
  service?: IDataSource<any>;
  serviceConstructor?: Type;
  tableService?: any;
};

export type TaltechTableProps = {
  table: Partial<IAdvancedTableProps>;
  source: TableSourceProps;
};

export const createService = (source: TableSourceProps) => {};

export class TalTechTableService extends TableService<
  IAdvancedTableProps,
  ITableState
> {
  getProps(props: any): IAdvancedTableProps {
    console.log('props', this.dataStore.getResults().items);
    return {
      ...defaultProps,
      columns: props.columns,
      data: this.dataStore.getResults().items,
      loading: this.dataStore.loading,
      selectRow: props.selectRow,
    };
  }
  constructor(service: IDataSource<any>) {
    super(service);
  }

  operatorMapper: IKeyedObject<string> = {
    EQ: '=',
    GE: '>=',
    GT: '>',
    LE: '<=',
    LIKE: '=',
    LT: '<',
    NE: '!=',
  };

  convertSearchQuery(query?: ITableState | undefined): ISearch {
    const {
      page,
      sizePerPage,
      sortField = undefined,
      sortOrder = undefined,
    } = query!;
    const result: ISearch = {
      page: page + 1,
      size: sizePerPage,
      sortBy: sortField,
      sortDirection: sortOrder as any,
      ...this.converFilters(query),
    };
    return result;
  }

  converFilterToQueryString(
    property: string,
    filter?: IFilterState<IFilterDefaultValue>
  ) {
    return `${property}${this.operatorMapper[filter?.comparator!]}${
      filter?.filterVal
    }`;
  }

  converFilters(state?: ITableState) {
    let result: IKeyedObject<string> = {};
    if (!_.isUndefined(state?.filters)) {
      const { filters } = state!;
      Object.keys(filters).forEach((x) => {
        result[x] = filters[x].filterVal as string;
      });
    }
    return result;
  }

  convertResults(query?: ITableState | undefined): ISearch {
    throw new Error('Method not implemented.');
  }
}

export class DefaultDataService implements IDataSource<any> {
  constructor(private data: any[]) {}

  getAll(query: IQuery): Promise<PagedResult<any>> {
    const q = query.query;
    const result =
      q !== undefined
        ? this.data.filter(
            (x) =>
              Object.keys(q)
                .map((y) => x[y] === q[y])
                .filter((z) => z == false).length === 0
          )
        : this.data;
    return Promise.resolve({
      items: result,
      page: 1,
      size: result.length,
      total: result.length,
    } as PagedResult<any>);
  }
}

export class DefaultPathDataService implements IDataSource<any> {
  constructor(private data: any[]) {}

  getAll(query: IQuery): Promise<PagedResult<any>> {
    const q = query.query;
    const result =
      q !== undefined
        ? this.data.filter(
            (x) =>
              Object.keys(q)
                .map((y) => x[y] === q[y])
                .filter((z) => z == false).length === 0
          )
        : this.data;
    return Promise.resolve({
      items: result,
      page: 1,
      size: result.length,
      total: result.length,
    } as PagedResult<any>);
  }
}
