import {
  PageContentForm,
  MaterialForm,
  Page,
  createFormProperties,
} from "@components";
import { entityStore } from "./MaterialIndexScreen";
import { Material } from "@domain";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";

const MaterialCreateScreen: React.FC<any> = inject("materialStore")(
  observer((props) => {
    const navigator = useNavigate();
    const formProps = createFormProperties(
      "CREATE",
      {
        onAction: (action) => {
          return Promise.resolve(entityStore.onAction(action));
        },
        schema: entityStore.options.schema,
        element: entityStore.options.empty,
      },
      entityStore.options,
      navigator
    );
    return (
      <Page title="Material details">
        <PageContentForm
          form={{ Component: MaterialForm, options: { ...formProps } }}
          mode="CREATE"
          dispatcher={{
            onAction: (action) => {
              return Promise.resolve(entityStore.onAction(action));
            },
          }}
        />
      </Page>
    );
  })
);

export default MaterialCreateScreen;
