import { Parameter } from './Parameter';

export const parametersPerTypeFloor: Parameter[] = [
  {
    id: 'id_floor_param_1',
    category: 'FLOOR',
    name: 'Floor parameter 1',
    description: 'Floor parameter description 1',
    type: 'NUMBER',
    valueDefault: 12.2,
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_floor_param_2',
    category: 'FLOOR',
    name: 'Floor parameter 2',
    description: 'Floor parameter description 2',
    type: 'TEXT',
    valueDefault: 'text 2',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_floor_param_3',
    category: 'FLOOR',
    name: 'Floor parameter 3',
    description: 'Floor parameter description 3',
    type: 'TEXT',
    valueDefault: 'asd 1',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
];

export const parametersPerTypeWindow: Parameter[] = [
  {
    id: 'id_window_param_1',
    category: 'WINDOW',
    name: 'Window parameter 1',
    description: 'Window parameter description 1',
    type: 'NUMBER',
    valueDefault: 12.2,
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_window_param_2',
    category: 'WINDOW',
    name: 'Window parameter 2',
    description: 'Window parameter description 2',
    type: 'TEXT',
    valueDefault: 'text 2',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_window_param_3',
    category: 'WINDOW',
    name: 'Window parameter 3',
    description: 'Window parameter description 3',
    type: 'TEXT',
    valueDefault: 'asd 1',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
];

export const parametersPerTypeWall: Parameter[] = [
  {
    id: 'id_wall_param_1',
    category: 'WALL',
    name: 'Wall parameter 1',
    description: 'Wall parameter description 1',
    type: 'NUMBER',
    valueDefault: 12.2,
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_wall_param_2',
    category: 'WALL',
    name: 'Wall parameter 2',
    description: 'Wall parameter description 2',
    type: 'TEXT',
    valueDefault: 'text 2',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_wall_param_3',
    category: 'WALL',
    name: 'Wall parameter 3',
    description: 'Wall parameter description 3',
    type: 'TEXT',
    valueDefault: 'asd 1',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
];

export const parametersPerTypeRoof: Parameter[] = [
  {
    id: 'id_roof_param_1',
    category: 'ROOF',
    name: 'Roof parameter 1',
    description: 'Roof parameter description 1',
    type: 'NUMBER',
    valueDefault: 12.2,
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_roof_param_2',
    category: 'ROOF',
    name: 'Roof parameter 2',
    description: 'Roof parameter description 2',
    type: 'TEXT',
    valueDefault: 'text 2',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_roof_param_3',
    category: 'ROOF',
    name: 'Roof parameter 3',
    description: 'Roof parameter description 3',
    type: 'TEXT',
    valueDefault: 'asd 1',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
];

export const parametersPerType: Parameter[] = [
  ...parametersPerTypeFloor,
  ...parametersPerTypeWindow,
  ...parametersPerTypeWall,
  ...parametersPerTypeRoof,
];
