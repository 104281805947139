import { Page } from "@components/layouts";
import { IPreset, IPresetElement } from "@domain";
import { useEffect, useState } from "react";
import { Loader } from "taltech-styleguide";
import { PresetsElementSelector } from "./PresetElementSelector";
import { PresetForm } from "./PresetForm";
import { ToolbarEdit } from "@components";

export type PresetDetailsProps = {
  title: string;
  elementId: string;
  getElement: (elemId: string) => Promise<IPreset>;
  onSave: (elem: IPresetElement) => Promise<void>;
  getElements: () => Promise<IPresetElement[]>;
};

export const PageLoader = ({
  title,
  loading,
  header,
  content,
  footer,
}: any) => {
  return (
    <Page title={title}>
      <div>
        {header}
        {loading ? <Loader /> : content()}
        {footer}
      </div>
    </Page>
  );
};

export const PresetDetails: React.FC<any> = ({
  title,
  elementId,
  getElement,
  onSave,
  getElements,
  ...rest
}: PresetDetailsProps) => {
  const [element, setElement] = useState<IPreset | undefined>(undefined);

  const [elements, setElements] = useState<IPresetElement[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (elementId !== undefined) {
      Promise.all([getElement(elementId), getElements()]).then((x) => {
        setElement(x[0]);
        setElements(x[1]);
        setLoading(false);
      });
    }
  }, [elementId, getElement, getElements]);

  const createContent = () => {
    let submitSelected: any = null;
    let submitForm: any = null;
    const onSubmitSelectedHandler = (e: any) => {
      if (submitForm) {
        submitForm(e).then((x: any) => console.log(x));
      }
      if (submitSelected) {
        element!.elementIds = submitSelected!(e);
      }
    };

    const submitSelectedBinder = (submitFormParam: any) => {
      submitSelected = submitFormParam;
    };

    const submitFormBinder = (submitFormParam: any) => {
      submitForm = submitFormParam;
    };

    return (
      <>
        <ToolbarEdit onSave={onSubmitSelectedHandler} />
        <PresetForm
          element={element!}
          submiFormBinder={submitFormBinder}
          onSubmit={(val) => onSave(val)}
          visualization={{ showModifiedDate: true, showCreatedDate: false }}
        />
        <PresetsElementSelector
          elements={elements}
          selected={element!.elementIds}
          submitSelectedBinder={submitSelectedBinder}
        />
      </>
    );
  };
  return <PageLoader loading={loading} content={createContent} title={title} />;
};
