import { InvitationState, RoleType } from "@domain";
import { FC, useState } from "react";
import { Form, Input } from "taltech-styleguide";
import { inject, observer } from "mobx-react";
import { PagedResult } from "@dirootie-core/core-types";
import { configSettings } from "../../configuration/ConfigurationSettings";
import { UsersSearchTable } from "./UsersSearchTable";

type UsersSearchTableProps = {
  id: string;
  number: number;
  displayName: string;
  state: InvitationState;
  email: string;
  role: RoleType;
  owner: [
    {
      id: string;
      displayName: string;
      description: string;
    }
  ];
  createdDate: Date;
};

export const UsersSearch: React.FC<any> = inject("serviceFactory")(
  observer((props) => {
    const [searchedUser, setSearchedUser] = useState("");
    const [elements, setElements] = useState<PagedResult<any>>({
      total: 0,
      page: 0,
      size: 0,
      items: [],
    });
    const service = props.serviceFactory.createApiService(
      configSettings.api.endpoints.users.azuread
    );

    return (
      <div>
        <div>
          <Form.Group controlId="id-7" className="mt-4">
            <Form.Label>Search</Form.Label>
            <Input
              placeholder="Enter user name"
              value={searchedUser}
              //onChange={(e) => searchUsers(e.target.value)}
              type="search"
            />
          </Form.Group>
        </div>

        <div>
          <UsersSearchTable />
        </div>
      </div>
    );
  })
);
