import { ToolbarCreate, ToolbarEdit, ToolbarList } from "@components";
import { PageTest } from "@components/testing";
import { FC } from "react";

export const ToolbarUitest = () => {
  return (
    <PageTest title="Toolbar testing">
      <ToolbarList />
      <ToolbarCreate />
      <ToolbarEdit />
    </PageTest>
  );
};
