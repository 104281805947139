import {ToolbarList} from "@components/navigation";
import {PagedResult} from "@dirootie-core/core-types";
import {BuildingComponent, BuildingComponentsStore, ComponentType} from "@domain/building-components";
import {inject, observer} from "mobx-react";
import {useState} from "react";
import {ElementsTable} from "./BuildingComponentsTable";

export type ComponentsBaseScreenProps = {
  componentType?: ComponentType;
  componentId?: string;
  buildingComponentsStore?: BuildingComponentsStore
};

export const BuildingComponentsIndexBaseScreen: React.FC<ComponentsBaseScreenProps> = inject("buildingComponentsStore")(
  observer((props) => {
      const [parameters, setParameters] = useState<PagedResult<BuildingComponent>>({
        total: 0,
        page: 0,
        size: 0,
        items: []
      });

      //  useEffect(() => {
      //   if(props.componentType !== undefined){
      //     props.buildingComponentsStore?.getComponents(props.componentType!,{}).then(x => {
      //       setParameters(x)
      //     })
      //   }

      //  }, []);
      return <div>
        <ToolbarList/>

        <ElementsTable source={{path: "buildingComponents.components", query: {category: props.componentType}}}/>
      </div>;
    }
  ));

export default BuildingComponentsIndexBaseScreen;
