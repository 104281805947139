import {SelectComponent, ShuffleRadioButton} from "@components";
import {FormikProps, FormikValues} from "formik";
import React, {FC, useContext} from "react";
import {Form, Table} from "taltech-styleguide";
import BuildingComponentsContext from "../../../domain/forms/buildingcomponents.context";
import {useStore} from "../../../domain/store";

export const BuildingComponentsTable: FC<{ formikElement: FormikProps<FormikValues>, EnumValues: any }> = ({
                                                                                                             formikElement,
                                                                                                             ...props
                                                                                                           }) => {

  const ctx = useContext(BuildingComponentsContext);
  const {buildingConfigurationFormStore} = useStore()

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th>Component</th>
          <th>Type</th>
        </tr>
        </thead>
        <tbody>
        {ctx.elements.map((el: any, index: number) => {
          return (
            <tr
              key={el.key}>
              <td
                className={ctx.selectedIndex === index ? 'ebd-component-elements-selected w-25' : "ebd-component-elements w-25"}
                onClick={() => ctx.setSelectedIndex(index)}>{el.key}</td>
              <td>
                <div className={'d-flex align-items-center'}>
                  <Form.Group className={'w-50'}>
                    <SelectComponent
                      changeFn={() => console.log("AA")}
                      disabled={buildingConfigurationFormStore.isReadOnly || ctx.config.disabled || formikElement.values[`${el.key}Shuffle`]}
                      name={`${el.key}`}
                      options={["\<None\>", ...props.EnumValues[index].map((val: any) => val.name)]}
                    />
                  </Form.Group>
                  <Form.Group>
                    <ShuffleRadioButton
                      disabled={buildingConfigurationFormStore.isReadOnly || ctx.config.disabled}
                      name={`${el.key}Shuffle`}
                      changeFn={() => {
                        formikElement.setFieldValue(
                          `${el.key}`,
                          ctx.elements[ctx.selectedIndex].shuffle ? formikElement.values[`${ctx.elements[ctx.selectedIndex].key}`] : 0)
                      }}
                    />
                  </Form.Group>
                </div>
              </td>
            </tr>
          )
        })}
        </tbody>
      </Table>
    </>
  );
};
