import {FC, useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {MenuItem} from "../../domain/web-applications/AreaConfiguration";
import {IconMenu} from "../icons";

export interface INavBarLeft {
  isOpenFn?: (value: boolean) => void;
}

export const NavBarLeft: FC<MenuItem & INavBarLeft> = (
  props: MenuItem & INavBarLeft
) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const url = useLocation();
  const [activeIndex, setActiveIndex] = useState<number>(
    props.children?.findIndex((el) => {
      const elementSegments = el.to.split("/");
      const urlSegments = url.pathname.split("/");
      return elementSegments[2] == urlSegments[2]
    }) || 0
  );

  useEffect(() => {
    props.isOpenFn && props.isOpenFn(isOpen);
  }, [isOpen]);

  return (
    <div className={"d-flex"}>
      <div ref={wrapperRef} className="ebd-nav-bar-left-bg">
        {props.children?.map((el, index) => {
          const linkProps: any = {
            key: el.to,
            to: el.to,
            target: undefined,
          };
          if (el.newTab) {
            linkProps.target = "_blank";
          }
          const result = (
            <Link {...linkProps}>
              <button
                onClick={() => setActiveIndex(index)}
                className={
                  activeIndex === index
                    ? "ebd-navigation-button-active"
                    : "ebd-navigation-button"
                }
              >
                <div className="d-flex align-items-center">
                  {el.icon && <div className="ebd-button-icon">{el.icon}</div>}
                  <p className="my-2 mx-3">{el.label}</p>
                </div>
              </button>
            </Link>
          );
          return result;
        })}
      </div>
      <div>
        <button className="ebd-button-up" onClick={() => setIsOpen(!isOpen)}>
          <IconMenu/>
        </button>
      </div>
    </div>
  );
};
