import {cols, createColActions, defaultProps, TableTalTech2,} from "@components";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";

export const MaterialTable: React.FC<any> = observer(
  (props: { elements: any }) => {
    const navigator = useNavigate();
    const columns: any = [
      cols.id(),
      cols.name(),
      cols.description(),
      cols.createdDate(),
      cols.modifiedDate(),
      {
        formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => createColActions(navigator, {
          showCopy: true,
          showDelete: true,
          showEdit: true,
          onEdit: () => navigator(row.id)
        })
      },
    ];
    const tableProps = {
      ...defaultProps,

      columns,
    };
    return (
      <TableTalTech2
        source={{
          path: "buildingComponents.materials",
          // query: { componentType: "1" },
        }}
        // source={{
        //   elements: props.elements, // path: "buildingComponents.materials",
        //   // query: { componentType: "1" },
        // }}
        table={tableProps}
      ></TableTalTech2>
    );
  }
);
