import _ from "lodash";
import { inject, observer } from "mobx-react";
import { STATUS_TYPE } from "taltech-styleguide";
import { UserRegisteredTable } from "./UserRegisteredTable";
import { isNotNullOrEmptyString } from "@dirootie-extensions/javascript";
import { configSettings } from "../../../../configuration/ConfigurationSettings";

export const stateBuilder = (
  state: string,
  statusState: STATUS_TYPE,
  name?: string
) => {
  const result = {} as any;
  result[state.toUpperCase()] = {
    state,
    type: statusState,
    name: isNotNullOrEmptyString(name) ? name : _.startCase(state),
  };
  return result;
};

const UsersRegisteredScreen: React.FC<any> = inject("serviceFactory")(
  observer((props) => {
    const service = props.serviceFactory.createApiService(
      configSettings.api.endpoints.users.registered
    );

    return (
      <div>
        <UserRegisteredTable service={service} />
      </div>
    );
  })
);

export default UsersRegisteredScreen;
