import {
  EntityOptions,
  HandlerDefinition,
  IAction,
  IDispatcher,
} from './Actions.types';
export class DispatcherBase implements IDispatcher {
  private handlers: Map<string, HandlerDefinition> = new Map<
    string,
    HandlerDefinition
  >();

  constructor() {}

  registerHandler(definition: HandlerDefinition): void {
    this.handlers.set(definition.action as string, definition);
  }

  dispatch(action: IAction) {
    const options = this.handlers.get(action.action)!;
    if (options) {
      const act =
        options.converter !== undefined ? options.converter(action) : action;
      options.handler!.handle(act);
    }
  }

  execute(action: IAction): Promise<any> {
    const options = this.handlers.get(action.action)!;
    if (options) {
      const act = options.converter!(action);
      return options.handler!.handle(act);
    }
    return Promise.reject();
  }
}
