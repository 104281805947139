import { PageTest } from "../../../components/PageTest";
import { PageTestProps } from "@components/testing";





const CommonTypoSamples = () => (
  <div>
    <h1>(h1) This is a heading</h1>

    <h2>(h2) This is a heading</h2>

    <h3>(h3) This is a heading</h3>

    <h4>(h4) This is a heading</h4>

    <h5>(h5) This is a heading</h5>
  </div>
)




export const TypographyUitest = () => {
  const props: PageTestProps = {
    title: "Typography - uitest",
    samples: <CommonTypoSamples />
  };

  return (
    <PageTest {...props} />
  );
};

