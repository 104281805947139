import {Page, PresetsTable, ToolbarList} from "@components";

const ComponentsPresetScreen: React.FC<any> = (props: any) => {
  return (
    <Page title="Components preset">
      <div>
        <ToolbarList/>

        <PresetsTable type="COMPONENT"/>
      </div>
    </Page>
  );
};

export default ComponentsPresetScreen;
