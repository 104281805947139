import { EntityOptions, EntityStore } from "@components";
import { BuildingComponentsIndexBaseScreen } from "@components/building-components";
import { WallBuildingComponent, getBuildingComponentValidationSchema } from "@domain";


const componentEntityOptions: EntityOptions<WallBuildingComponent> = {
  schema: getBuildingComponentValidationSchema,
  empty: {
    name: '',
    description: '',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    category: "WALLS",
    layer: "INTERIOR"
  },
  path: 'buildingComponents.components.walls',
  actions: {
    FORM_CREATE: {
      converter: (action: any) => {
        action.options.path = "buildingComponents.components.walls"; return action;
      }
    }
  }
};

export const wallEntityStore = new EntityStore(componentEntityOptions)

export const WallTypeIndexScreen: React.FC<any> = () => (
  <BuildingComponentsIndexBaseScreen componentType={"WALLS" as any} />
);
