import {ServiceFactory,} from '../../packages/core/api-types/src/api.service';
import {PagedResult} from '../../packages/core/core-types/src';
import {IQuery} from '../../packages/web/components-types/src/tables/tables.types';
import {Assignment} from "./Assignment";
import {assignmentsData} from "./Assignments.samples";
import {Course} from './Course';
import {CourseApiService} from './CourseApiService';
import {coursesData} from './Courses.samples';

export class CoursesStore {
  searchSore;
  isReadOnly: boolean = false;

  constructor(
    private apiService: CourseApiService,
    private serviceFactory: ServiceFactory
  ) {
    this.searchSore = this.serviceFactory.createSearchStoreFromPath('courses');
  }

  getLecturerCurses(
    lecturerId: string,
    query: IQuery
  ): Promise<PagedResult<Course>> {
    const queryServer = {...query};
    return this.apiService.getAll(queryServer);
    // return Promise.resolve({
    //   items: coursesData,
    //   total: coursesData.length,
    //   page: 1,
    //   size: 10
    // })//this.apiService.getAll(queryServer);
  }

  getStudentCurses(
    studentId: string,
    query: IQuery
  ): Promise<PagedResult<Course>> {
    const queryServer = {...query, studentId};
    //return this.apiService.getAll(queryServer);
    return Promise.resolve({
      items: coursesData,
      total: coursesData.length,
      page: 1,
      size: 10
    })//this.apiService.getAll(queryServer);
  }

  getAssignmentById(
    assignmentId: string,
    query: IQuery
  ): Promise<Assignment> {
    const queryServer = {...query, assignmentId};
    //return this.apiService.getAll(queryServer);
    return Promise.resolve(assignmentsData.items[0])//this.apiService.getAll(queryServer);
  }
}
