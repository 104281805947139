import { ServiceFactory } from '@dirootie-core/api-types';
import {
  IUserNotificationService,
  badRequestNotification,
  saveSuccessNotification,
} from '@dirootie-web/components-types';
import { IFormAction } from './FormActions.types';
import { IEntity } from '@dirootie-core/core-types';
import { FormActionHandlerBase } from './Forms.handlers';

export class FormSaveActionHandler extends FormActionHandlerBase {
  constructor(
    userNotificationService: IUserNotificationService,
    serviceFactory: ServiceFactory
  ) {
    super(userNotificationService, serviceFactory);
  }

  public handle<T extends IEntity>(action: IFormAction<T>) {
    const apiService = this.serviceFactory.createApiServiceFromPath<T, T>(
      action.options.path
    );
    return apiService?.update(action.payload!).then(
      (x) => {
        this.userNotificationService.success(saveSuccessNotification);
      },
      (error) => {
        let responseErrors = error?.response?.data?.extensions?.errors;
        let responseErrors_2 = error?.response?.data?.errors;

        console.log('errors', responseErrors);
        if (responseErrors) {
          //se ha erros nos inputs
          let errorsKeys = Object.keys(responseErrors);
          console.log('errorsKeys', errorsKeys);
          errorsKeys.forEach((key) => {
            let errorMsg = responseErrors[key][0].message;

            this.userNotificationService.error(
              badRequestNotification(errorMsg)
            );
          });
        }
        if (responseErrors_2) {
          //se ha erros nos inputs
          let errorsKeys = Object.keys(responseErrors_2);
          console.log('errorsKeys 2', errorsKeys);
          errorsKeys.forEach((key) => {
            let errorMsg = responseErrors_2[key][0];
            this.userNotificationService.error(
              badRequestNotification(errorMsg)
            );
          });
        }
      }
    );
  }
}
