import React, {FC, useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {application} from "../../configuration/Application.bootstrap";

type IUploadComponent = {
  id?: string,
  setImageUrl?: (url: string) => void
}

export const UploadComponent: FC<IUploadComponent> = ({...props}) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [base64String, setBase64String] = useState<string>('');

  useEffect(() => {
    console.log(base64String)
    if (base64String.length > 10) {
      application.getHttpClient().put<any>(`/coursesms/courses/${props.id}/logo`, {
        fileName: "A",
        contents: base64String
      }).then((r) => {
        props.setImageUrl && props.setImageUrl(r.body.url)
        console.log(r.body.url)
      })
    }
  }, [base64String])

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0] || null;
    setSelectedImage(file);
    convertToBase64String(file);
  }, []);

  const convertToBase64String = (file: File | null) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          const base64String = arrayBufferToBase64(reader.result);
          setBase64String(base64String);
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      setBase64String('');
    }
  };

  const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };
  const {acceptedFiles, getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: onDrop,
    multiple: true,
    onDragOver: () => console.log("A")
  });
  const files = acceptedFiles.map(file => (
    <div className={'ebd-file-element'} key={file.name}>
      {file.name}
    </div>
  ));
  return (
    <section className={isDragActive ? "ebd-active-drag" : "container ebd-file-container"}>


      <div {...getRootProps({className: 'dropzone disabled py-5 d-flex justify-content-center ebd-drop-area'})}>
        <input {...getInputProps()} />
        <p>Drag & drop some files here, or click to select files</p>
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
    </section>
  );
};

export default UploadComponent;
