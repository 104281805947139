import {Form as FormikForm, Formik} from "formik";
import React, {FC} from "react";
import {studentsData} from "../../domain/users/Students.samples";
import {InputText} from "../forms";
import {InputSearch} from "../forms/inputs/InputSearch";
import {getGroupValidation} from "./GroupsFormValidation";

export type IGroupsCreateForm = {
  parameters: {},
  actions: {
    onSubmit: (values: any) => void
  }
}

export const GroupsCreateForm: FC<IGroupsCreateForm> = ({...props}) => {
  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={getGroupValidation()}
        initialValues={props.parameters}
        onSubmit={(values) => {
          props.actions.onSubmit(values)
        }}
      >
        {(formik) => {
          return (
            <FormikForm>
              <InputText id={'name'} formState={formik as any} label={"Group name"}/>
              {/*<GroupsLecturerTable elements={groupsData}/>*/}
              <InputSearch users={studentsData.items as any}/>
            </FormikForm>)
        }}
      </Formik>
    </>
  );
};
