// @ts-ignore
import {
  convertToTitleCase,
  notEmptyArray,
} from '@dirootie-extensions/javascript';
import { FormState } from './forms.types';
import { InputSelectProps, SelectOptions } from './select.types';

export const isFormFieldValid = (id: string, formState: FormState): boolean => {
  return !(
    formState.getFieldMeta(id).touched !== undefined &&
    formState.getFieldMeta(id).error !== undefined
  );
};

export const getArrayAsOptions = (values: readonly string[]): SelectOptions => {
  return values.map((x) => {
    return {
      text: convertToTitleCase(x),
      value: x,
    };
  });
};

export const getSelectOptions = (props: InputSelectProps): SelectOptions => {
  if (notEmptyArray(props.options!)) {
    return props.options!;
  }
  if (notEmptyArray(props.items!)) {
    return getArrayAsOptions(props.items!);
  }
  return [];
};
