import {BuildingComponentDataForm, ElementDataForm, ITypesMaterialStructure, ParameterStructure} from "@components/building-components";
import {Material, materialData, StructureLayerFunctionTypeList} from "@domain";
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {TabPanel, Tabs} from "taltech-styleguide";
import {BuildingComponentParametersTable} from "../_common/BuildingComponentParametersTable";
import TypesMaterialStructure from "../_common/TypesMaterialStructure";
import { PageContentForm } from "@components/layouts";
import { createFormProperties } from "@components";
import { useNavigate } from "react-router-dom";

export const WallDetails: React.FC<any> = observer(
  (props: { element: any, entityStore: any }) => {
    const navigator = useNavigate();
    const [elements, setElements] = useState<ParameterStructure>(props.element.layers);
    const [materialOptions, setMaterialOptions] = useState<Material[]>([]);

    useEffect(() => {
      setMaterialOptions(materialData)
    }, []);
    const materialStructureProps: ITypesMaterialStructure = {
      parameters: elements,
      viewModel: {
        materialOptions: materialOptions,
        functionOptions: [...StructureLayerFunctionTypeList],
      },
      actions: {
        onAddNew: (type: "core" | "interior" | "exterior") => {
          elements[type].push({
            id: `${elements[type].length + 1}`,
            type: type as any,
            material: "Material 01",
            thickness: 1,
            function: "STRUCTURE"
          })
          setElements({...elements})
        },
        oncancel: () => console.log("Cancel"),
        onsubmitAll: (values) => console.log(values),
        onElementSubmit: () => console.log("Element Submit"),
        onDeleteElement: (index: number, type: "core" | "interior" | "exterior") => elements[type].splice(index, 1)
      }
    }
    const formProps = createFormProperties(
      "EDIT" ,
       {
         onAction: (action) => { return Promise.resolve(props.entityStore.onAction(action))},
         schema: props.entityStore.options.schema,
         element: props.element
       },
       props.entityStore.options,
       navigator
     );
     formProps.showLayer = true;
    const content = (
      <Tabs
        id="page-tabs-wall"
        navSize="sm"
        labels={["Data", "Structure", "Parameters"]}
      >
        <TabPanel key="Data">
        <PageContentForm
        form={{ Component: ElementDataForm, options: formProps  }}
        mode="EDIT"
        dispatcher={{onAction: (action) => { return Promise.resolve(props.entityStore.onAction(action));}}}
      />
        </TabPanel>
        <TabPanel key="Structure">
          <TypesMaterialStructure {...materialStructureProps}/>
        </TabPanel>
        <TabPanel key="Parameters">
          <BuildingComponentParametersTable element={props.element}/>
        </TabPanel>
      </Tabs>
    );
    return content;
  }
);
