import {
  DisabledTabComponent,
  BuildingParameterForm,
  Page,
  ToolbarEdit,
} from "@components";
import { ParameterDescriptor } from "@domain";
import React from "react";

const spaceFunctionParameter: ParameterDescriptor = {
  id: "undefined",
  name: "",
  description: "",
  createdDate: new Date(Date.now()),
  modifiedDate: new Date(Date.now()),
  category: "SPACE",
  type: "TEXT",
  creationOptions: { max: 0, min: 1 },
  creationType: "CONSTANT",
  valueDefault: "",
};

const SpaceParameterCreateScreen: React.FC<any> = () => {
  let submitForm: any = null;
  const onSubmitSelectedHandler = (e: any) => {
    if (submitForm) {
      submitForm(e).then((x: any) => console.log(x));
    }
  };
  const submitSelectedBinder = (submitFormParam: any) => {
    submitForm = submitFormParam;
  };

  return (
    <Page title="Create new Parameter Space Function" showBreadcrumbs={true}>
      <div className={"container"}>
        <DisabledTabComponent
          elements={["Functions", "Parameters"]}
          activeDisabled={1}
        />
        <ToolbarEdit onSave={onSubmitSelectedHandler} />
        <BuildingParameterForm
          element={spaceFunctionParameter}
          onCancel={() => {}}
          onSubmit={() => {}}
        />
      </div>
    </Page>
  );
};

export default SpaceParameterCreateScreen;
