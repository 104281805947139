import {DisabledTabComponent, Page} from "@components";
import {observer} from "mobx-react";
import React from "react";
import {UsersAzureAdSearchTable} from "./UsersAzureAdSearchTable";

const InvitationCreateScreen: React.FC<any> = observer((props) => {
  return (
    <Page title="Invite users">
      <div className={'container'}>
        <DisabledTabComponent elements={["Registered", " ", "Invitations"]} activeDisabled={2}/>
        <UsersAzureAdSearchTable/>
      </div>
    </Page>
  );
});
export default InvitationCreateScreen;
