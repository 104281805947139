import {
  isNotNullOrEmptyString,
  notEmptyArray,
} from "@dirootie-extensions/javascript";
import { useNavigate, useParams } from "react-router-dom";
import { Container, TabPanel, Tabs } from "taltech-styleguide";
import { Page } from "./Page";
import _ from "lodash";
import {
  PageTabedProps,
  TabItem,
  getSelectedTabByIndex,
  getSelectedTabByPath,
} from "./Tabs.types";

const getComponent = (tab: TabItem, props: PageTabedProps, params: any) => {
  if (notEmptyArray(tab.children!)) {
    if (params.actionId === undefined) {
      return tab.component;
    }
    const t = tab.children?.filter((x) => x.path === params.actionId);
    if (t!.length > 0) {
      return t![0].component;
    }
    return tab.component;
  } else {
    return tab.component;
  }
};

export const PageTabed: any = (props: PageTabedProps) => {
  const { tabs, ...rest } = props;
  const history = useNavigate();
  let selectedIndex = 0;
  let { tabId, actionId } = useParams();
  let params = useParams();
  if (tabId === undefined) {
    return;
  }
  if (isNotNullOrEmptyString(tabId)) {
    const selectedTab = getSelectedTabByPath(props, tabId);
    if (notEmptyArray(selectedTab)) {
      const tempIndex = selectedTab[0].index;
      selectedIndex = selectedIndex !== tempIndex ? tempIndex : selectedIndex;
    }
  }
  const labels = tabs.map((x) => x.label);
  return (
    <Page {...rest}>
      <Container>
        <Tabs
          id="page-tabs"
          navSize="lg"
          labels={labels}
          selectedIndex={selectedIndex}
          onSelect={(
            index: number,
            last: undefined | number,
            evt: Event | undefined
          ) => {
            const selectedTab = getSelectedTabByIndex(props, index);
            if (notEmptyArray(selectedTab)) {
              console.log(props.pathBase + "/" + selectedTab[0].path);
              history(props.pathBase + "/" + selectedTab[0].path);
            }
            return true;
          }}
        >
          {tabs.map((x) => (
            // <TabPanel key={x.index + x.path}>{getComponent(x,props,params)}</TabPanel>
            <TabPanel key={x.index + x.path}>{x.component}</TabPanel>
          ))}
        </Tabs>
      </Container>
    </Page>
  );
};
