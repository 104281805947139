import { Separator } from "taltech-styleguide";

export type WidgetEnumerationProps = {
  title: string;
  description?: string;
  values: string[];
};

export const WidgetEnumeration = (props: WidgetEnumerationProps) => {
  return (
    <div>
      <h4>{props.title}</h4>
      <p>{props.description}</p>
      <ul>
        {props.values.map((x) => (
          <li>{x}</li>
        ))}
      </ul>
    </div>
  );
};

export type WidgetEnumerationSectionProps = {
  title: string;
  enumerations: WidgetEnumerationProps[];
};

export const WidgetEnumerationSection = (
  props: WidgetEnumerationSectionProps
) => {
  return (
    <div>
      <h3>{props.title}</h3>
      {props.enumerations.map((x) => (
        <WidgetEnumeration {...x} />
      ))}
      <Separator size="sm" />
    </div>
  );
};
