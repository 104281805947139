import {cols, createColActions, defaultProps, TableTalTech} from "@components";
import {observer} from "mobx-react";
import React, {FC} from "react";
import {useNavigate} from "react-router-dom";

export const BuildingConfigurationTable: FC<any> = observer(({...props}) => {

  const navigator = useNavigate();
  const columns = [
    cols.id(),
    cols.name(),
    cols.description(),
    cols.createdDate(),
    cols.modifiedDate(),
    {
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => createColActions(navigator, {
        showCopy: false,
        showEdit: false, showView: true, showDelete: false,
        onEdit: () => navigator(row.id),
        onViewClick: () => navigator(row.id)
      })
    },
  ];
  const prop = {...defaultProps, data: props.elements.items, columns};
  return <TableTalTech tableProps={prop}></TableTalTech>;
});
