import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  BUTTON_COLOR,
  Col,
  Container,
  Row,
  STATUS_TYPE,
} from "taltech-styleguide";
import { configSettings } from "../../../../configuration/ConfigurationSettings";

const LoginScreen: React.FC<any> = inject(
  "logger",
  "authenticationService"
)(
  observer((props: any) => {
    const { scopes, redirectUri } = configSettings.authentication.options.auth;
    const popupRequest = { scopes };
    const { instance } = useMsal();

    const handleLogin = (loginType: string) => {
      if (loginType === "popup") {
        instance
          .loginPopup(popupRequest)
          .then((x) => {
            return props.authenticationService.loginSuccess(x);
          })
          .catch((e) => {
            props.logger("[LoginScreen:handleLogin]: Error", e);
            return props.authenticationService.loginUnsuccess(e);
          })
          .finally(() => {});
      }
    };

    return (
      <Container className="App">
        <AuthenticatedTemplate>
          <h5 className="Heading_Regular">You are Logged In !!!!!</h5>
          <Button className="mt-4">
            <Link to={"/auth/logout"}>Logout</Link>
          </Button>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Row>
            <Col>
              <h1>Please login</h1>
              <p>Using your University account credentials</p>
            </Col>
          </Row>
          <Button
            variant={BUTTON_COLOR.SECONDARY}
            size="lg"
            onClick={() => {
              handleLogin("popup");
            }}
          >
            Login
          </Button>
          <Row style={{ marginTop: "50px" }}>
            <Col className="col-4" />
            <Col className="col-4">
              <Alert variant={STATUS_TYPE.INFO}>
                You will be redirect to TalTech University identity provider
              </Alert>
            </Col>
          </Row>
        </UnauthenticatedTemplate>
      </Container>
    );
  })
);

export default LoginScreen;
