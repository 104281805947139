import { IKeyedObject } from '@dirootie-extensions/typescript';
import { RoleType, UserProfile } from '@domain/users';
import { action, makeAutoObservable, observable } from 'mobx';
 
const roleMapper: IKeyedObject<RoleType> = {
  Admin: 'STUDY_DIRECTOR',
  Lecturer: 'LECTURER',
  Student: 'STUDENT',
};
 
export class SessionStore {
  @observable userProfile: undefined | UserProfile = undefined;
  currentView: string | undefined = undefined;
 
  constructor() {
    makeAutoObservable(this);
  }
 
  @action
  setUserProfile(userProfile: UserProfile) {
    const { role, ...rest } = userProfile;
    const userRole = roleMapper[role] || roleMapper[`Access.${role}`];
    this.userProfile = { role: userRole, ...rest };
  }
}
 
