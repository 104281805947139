import React from "react";
import {Col, Row, Table} from "taltech-styleguide";


export const InfoComponent: React.FC<{ jsonData: any }> = ({jsonData}) => {

  return <>
    <section>
      <h5>Info:</h5>
      <Row>
        <Col sm={2}><h5>Id:</h5></Col>
        <Col sm={10}><h6>{jsonData.info.id}</h6></Col>
      </Row>

      <Row>
        <Col sm={2}><h5>Name:</h5></Col>
        <Col sm={10}><h6>{jsonData.info.name}</h6></Col>
      </Row>

      <Row>
        <Col sm={2}><h5>Description:</h5></Col>
        <Col sm={10}><h6>{jsonData.info.description}</h6></Col>
      </Row>

      <Row>
        <Col sm={2}><h6>Location:</h6></Col>
        <Col sm={10}><Table>
          <thead>
          <tr>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Radius</th>
            <th>Surrounding radius</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{jsonData.info.location.coordinates.latitude}</td>
            <td>{jsonData.info.location.coordinates.longitude}</td>
            <td>{jsonData.info.location.radius}</td>
            <td>{jsonData.info.location.surroundingRadius}</td>
          </tr>
          </tbody>
        </Table></Col>
      </Row>

      <Row>
        <Col sm={2}><h6>Rotation:</h6></Col>
        <Col sm={10}><Table>
          <thead>
          <tr>
            <th>Type</th>
            <th>Unit</th>
            <th>Value</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{jsonData.info.rotation.type}</td>
            <td>{jsonData.info.rotation.angle.unit}</td>
            <td>{jsonData.info.rotation.angle.value}</td>
          </tr>
          </tbody>
        </Table></Col>
      </Row>
    </section>
  </>
};
