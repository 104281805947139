import {FormBase, FormProps, InputRadioGroup, InputSingleDatePicker, InputText, InputTextArea,} from "@components";
import {WindowBuildingComponent,} from "@domain/building-components";
import {Form as FormikForm} from "formik";
import React, {FC} from "react";
import {InputWindowMeasure} from "./InputWindowMeasure";

export const WindowDataForm: FC<FormProps<WindowBuildingComponent>> = ({...props}) => {
  const render = (formik: any) => {
    return (
      <FormikForm>
        <InputText id="name" label="Name" formState={formik as any}/>
        <InputRadioGroup
          isInline={true}
          id="style"
          label="Style"
          formState={formik as any}
          options={[
            {id: "isolated", label: "Isolated", value: "ISOLATED"},
            {
              id: "wall_to_wall",
              label: "Wall to wall",
              value: "WALL_TO_WALL",
            },
          ]}
        />
        <InputWindowMeasure
          id="width"
          label="Width"
          formState={formik as any}
        />

        <InputWindowMeasure
          id="sillHeight"
          label="Sill height"
          formState={formik as any}
          disabled={formik.getFieldMeta("height.maximum").value === true}
        />
        <InputWindowMeasure
          id="height"
          label="Height"
          formState={formik as any}
          disabled={formik.getFieldMeta("height.maximum").value === true}
          showMaximum={true}
        />
        <InputTextArea
          id="description"
          label="Description"
          formState={formik as any}
        />
        {props.visualization?.showModifiedDate &&
          <InputSingleDatePicker id={'modifiedDate'} formState={formik as any} label={'Modified date'}/>}
        {props.visualization?.showCreatedDate &&
          <InputSingleDatePicker label={'Created date'} id={'createdDate'} formState={formik as any}/>}
      </FormikForm>
    );
  };
  return <FormBase {...props} render={render}/>;
}
