import { Route } from "react-router-dom";
import UserProfileScreen from "./screens/UserProfileScreen";
import UserSettingsScreen from "./screens/UserSettingsScreen";

export const usersAreaRoutes = (
  <Route path="/users">
    <Route index={true} element={<UserProfileScreen />} />
    <Route path="profile" element={<UserProfileScreen />} />
    <Route path="settings" element={<UserSettingsScreen />} />
  </Route>
);
