import { Breadcrumbs } from "../navigation/Breadcrumbs";
import { FC } from "react";
import { observer } from "mobx-react";

export type PageHeaderProps = {
  title: string;
  showBreadcrumbs?: boolean;
};

const PageHeader: FC<PageHeaderProps> = observer((props: PageHeaderProps) => {
  const breadcrumbs = props.showBreadcrumbs ? <Breadcrumbs /> : <></>;
  return (
    <div>
      <h1>{props.title}</h1>
      {breadcrumbs}
    </div>
  );
});

export default PageHeader;
