import {
  HttpMethodRequestOptions,
  HttpResponse,
  IHttpClient,
} from '@dirootie-core/http-types';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';

export type AxiosHttpClientOptions = {
  baseUrl: string;
  interceptors?: {
    request?: any[];
    response?: any[];
  };
};

export class AxiosHttpClient implements IHttpClient {
  private axiosInstance;

  constructor(options: AxiosHttpClientOptions) {
    this.axiosInstance = axios.create({
      baseURL: options.baseUrl,
    });
    if (!_.isUndefined(options?.interceptors)) {
      const { request: requests = [], response: responses = [] } =
        options.interceptors;
      requests.forEach((interceptor) => {
        this.axiosInstance.interceptors.request.use(interceptor);
      });
      responses.forEach((interceptor) => {
        this.axiosInstance.interceptors.response.use(
          interceptor.response,
          interceptor.error
        );
      });
    }

    // this.axiosInstance.interceptors.response.use((response) => {
    //     return response;
    // },(error) =>{
    //   // const errorMessage = apiErrorHandlder.mapSourceToHttp(error.response);
    //   // if(errorMessage.type === "ApplicationError"){
    //   //   return applicationErrorHandler(error,userNotificationService);
    //   // }
    //   // return apiErrorHandlder.handleError(errorMessage);
    // });
  }

  async get(
    url: string,
    options?: HttpMethodRequestOptions
  ): Promise<HttpResponse> {
    const response = await this.axiosInstance.get(url, {
      headers: options?.headers,
    });
    return await Promise.resolve(this.convertResponse(response));
  }

  async patch(
    url: string,
    body: any,
    options?: HttpMethodRequestOptions | undefined
  ): Promise<HttpResponse> {
    const response = await this.axiosInstance.post(url, body, {
      headers: options?.headers,
    });
    return await Promise.resolve(this.convertResponse(response));
  }

  async post(
    url: string,
    body: any,
    options?: HttpMethodRequestOptions
  ): Promise<HttpResponse> {
    const response = await this.axiosInstance.post(url, body, {
      headers: options?.headers,
    });
    return await Promise.resolve(this.convertResponse(response));
  }

  async put(
    url: string,
    body: any,
    options?: HttpMethodRequestOptions
  ): Promise<HttpResponse> {
    const response = await this.axiosInstance.put(url, body, {
      headers: options?.headers,
    });
    return await Promise.resolve(this.convertResponse(response));
  }

  async delete(
    url: string,
    options?: HttpMethodRequestOptions
  ): Promise<HttpResponse> {
    const response = await this.axiosInstance.delete(url, {
      headers: options?.headers,
    });
    return await Promise.resolve(this.convertResponse(response));
  }

  private convertResponse(response: AxiosResponse): HttpResponse {
    const result: HttpResponse = {
      statusCode: response.status,
      isError: false,
      headers: response.headers as any,
      url: response.request.url,
      body: response.data,
      statusMessage: response.statusText,
    };
    return result;
  }
}
