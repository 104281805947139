import {Button, EntityOptions, FormCreateNewActionHandler, FormSaveActionHandler, PeopleTable, ToolbarList} from "@components";
import {Form, useLocation, useParams} from "react-router-dom";
import {application} from "../../../../../configuration/Application.bootstrap";
import React, { useState } from "react";
import { Modal, Input } from "taltech-styleguide";

const courseLecturerEntityOptions: EntityOptions<any> = {
  empty: {
    name: '',
    description: '',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now())
  },
  path: 'courses.members.lecturers',
  actions: {
    FORM_CREATE: {
      converter: (action: any) => {
        action.options.path = "courses.members.lecturers"; return action;
      }
    },
    TOOLBAR_CREATE: {
      converter: (action: any) => {
        action.options.path = "courses.members.lecturers"; return action;
      },
      handler: { handle: (action) => {
        return Promise.resolve();
      }}
    }
  }
};

export const LecturersIndexScreen: React.FC<any> = () => {
  const location = useLocation();
  const { courseId } = useParams();
  const [selected, setSelected] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  const segments = location.pathname.split("/");
  return (
    <>
      <ToolbarList
        disabled={segments[1] === "students"}
        onAction={(a: any) => {
          if(a.action === "NAVIGATE_TO_NEW"){
            toggleModal();
          }
          else {
            application.getHandlerFactory().createDispatcherBasic().dispatch(a)
          }
        }}
      />

      <PeopleTable
        isEditable={segments[1] === "lecturers"}
        source={{
          path: "courses.lecturers.summary",
          query: {
            courseId
          }
        }}
      ></PeopleTable>

      <Modal fullscreen={true} enforceFocus={false} size="xl" show={modalOpen} onHide={toggleModal}>
          <Modal.Header closeButton>Select assignment </Modal.Header>
          <Modal.Body>
          <ToolbarList
        disabled={segments[1] === "students"}
        onAction={(a: any) => {
          if(a.action === "NAVIGATE_BACK"){
            toggleModal();
          }
          const handlerFactory = application.getHandlerFactory();
          const serviceFactory = application.getStores()["serviceFactory"];
          if(a.action === "NAVIGATE_TO_NEW"){

            handlerFactory.executeAction({action: "FORM_SAVE_MULTIPLE", payload: { members: selected, courseId, role: "LECTURER"}, options: {path: "courses.members.invitations"}}, new FormCreateNewActionHandler(
              application.getUserNotificationService(),
              serviceFactory
            ))
          }
          else {
            application.getHandlerFactory().createDispatcherBasic().dispatch(a)
          }
        }}
      />
          <PeopleTable
            isEditable={segments[1] === "lecturers"}
            source={{
              path: "courses.members.invitations",
              query: {
                courseId,
                role: "LECTURER"
              }
            }}
            selectRow = {{
              mode: "checkbox",
              selected: selected,
              selectColumnPosition: "left",

              className: "ebd-initials-first",
              oonSelect: (row: any, isSelect: boolean, rowIndex: number, e: any) => {
                if (isSelect) {
                  setSelected([...selected, row.id]);
                } else {
                  setSelected([...selected.filter((x) => x !== row.id)]);
                }
              },

              onSelectAll: (isSelect: boolean, rows: string[], e: any) => {
                if (isSelect) {
                  setSelected(rows.map((x: any) => x.id));
                } else {
                  setSelected([]);
                }
              },
              }}


          ></PeopleTable>
          </Modal.Body>
          <Modal.Footer>
            <Button kind={"create"} onClick={toggleModal} />{" "}
            <Button kind={"cancel"} onClick={toggleModal} />
          </Modal.Footer>
        </Modal>
    </>
  );
};
