import {
  InputComponent,
  InputContainer,
  InputText,
  RadioButtonComponent,
  SelectComponent,
  TextAreaComponent,
  useWindowSize
} from '@components';
import {Form as FormikForm, Formik} from "formik";
import {observer} from "mobx-react";
import React, {FC, useEffect, useState} from "react";
import {Button, Col, Form, Row} from "taltech-styleguide";
import {useStore} from "../../../domain/store";
import {BuildingConfigurationStateTypes} from "../../../packages/web/components-types/src/forms";
import {ComponentTypes} from "../../../packages/web/components-types/src/forms/BuildingConfigurationTypes";
import {getConfigurationValidationSchema} from "./configurationValidationSchema";

export const BuildingConfigurationForm: FC<BuildingConfigurationStateTypes> = observer(({
                                                                                          config,
                                                                                          ...props
                                                                                        }) => {
  const {buildingConfigurationFormStore} = useStore();
  const validationSchema = getConfigurationValidationSchema();
  const [buildingOrientation, setBuildingOrientation] = useState(props.buildingOrientation);
  const [floorHeight, setFloorHeight] = useState(props.FloorHeight);
  const [buildingHeightType, setBuildingHeightType] = useState(props.buildingHeightType);
  const {width} = useWindowSize();

  const [buildingParameter, setBuildingParameter] = useState<ComponentTypes[]>([]);

  useEffect(() => {
    buildingConfigurationFormStore.getBuildingParameterTypes().then(val => {
      setBuildingParameter(val)
    })
  }, [buildingParameter])

  return (
    <>

      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={buildingConfigurationFormStore.firstStep}
        onSubmit={(values) => {
          buildingConfigurationFormStore.setBuildingConfigurationState(
            values as BuildingConfigurationStateTypes
          );
          buildingConfigurationFormStore.setNextStep(config.stepNumber + 1);
        }}
      >
        {(formik) => {
          return (

            <FormikForm className={!config.disabled ? "active" : ""}>
              <Row>
                <Col xs={width > 1200 ? "6" : "12"}>
                  <Form.Group className="mb-3">
                    <InputComponent
                      disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                      type={"text"}
                      label={"Name"}
                      name={"name"}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <TextAreaComponent
                      disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                      type={"text"}
                      label={"Description"}
                      name={"description"}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <SelectComponent
                      changeFn={() => console.log("A")}
                      disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                      type={"text"}
                      label={"Select building type"}
                      name={"buildingType"}
                      options={["Commercial", "Residential", "Other"]}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <InputComponent
                      disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                      type={"text"}
                      label={"Building location coordinates"}
                      name={"coordinates"}
                    />
                  </Form.Group>
                  <SelectComponent
                    label={"Building parameters"}
                    changeFn={() => console.log("A")}
                    disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                    name={`buildingParameter`}
                    options={["\<None\>", ...buildingParameter.map((el) => el.name)]}
                  />
                </Col>
                <Col xs={width > 1200 ? "6" : "12"}>
                  <InputContainer>
                    <Form.Group className="mb-3">
                      <RadioButtonComponent
                        changeFn={(e) => setBuildingOrientation(+e.target.value)}
                        disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                        isInline={true}
                        label={"Building orientation"}
                        name={"buildingOrientation"}
                        options={["Fixed", "Random"]}/>

                      {buildingOrientation === 1 ? <div className={"d-flex"}>
                        <div className={'w-50 mr-4'}>
                          <InputComponent
                            isInteger={true}
                            disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                            type={"number"}
                            label={"Min."}
                            min={0}
                            max={360}
                            name={"orientationMin"}/>
                        </div>
                        <div className={'w-50'}>
                          <InputComponent
                            isInteger={true}
                            disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                            type={"number"}
                            label={"Max."}
                            min={0}
                            max={360}
                            name={"orientationMax"}/>
                        </div>
                      </div> : <InputComponent
                        isInteger={true}
                        disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                        type={"number"}
                        min={0}
                        label={"Total value"}
                        name={"orientationTotalValue"}
                      />}
                    </Form.Group>
                  </InputContainer>
                  <InputContainer>
                    <Form.Group className={"mb-3 d-flex"}>
                      <div className={"w-50 mr-4"}>
                        <InputText id={"measureTypes"} formState={formik as any} label={"Measure types"} disabled={true}/>
                        {/*<SelectComponent*/}
                        {/*  disabled={true}*/}
                        {/*  type={"text"}*/}
                        {/*  label={"Measure types"}*/}
                        {/*  name={"measureTypes"}*/}
                        {/*  options={["Meter", "Modular"]}*/}
                        {/*/>*/}
                      </div>

                      <div className={"w-50"}>
                        {/*<InputIntegerNumber id={'moduleSize'}*/}
                        {/*                    label={"Module size (m)"}*/}
                        {/*                    formState={formik as any}*/}
                        {/*                    min={1}*/}
                        {/*                    disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}/>*/}
                        <InputComponent
                          isInteger={true}
                          disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                          type={"number"}
                          label={"Module size (m)"}
                          min={1}
                          name={"moduleSize"}
                        />
                      </div>
                    </Form.Group>
                  </InputContainer>
                  <InputContainer>
                    <Form.Group className={"mb-3"}>
                      <InputComponent
                        isInteger={true}
                        customClassName={'mb-3'}
                        disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                        type={"number"}
                        label={"Number of floors"}
                        min={1}
                        name={"numberOfFloor"}
                      />
                      <RadioButtonComponent
                        changeFn={(e) => setBuildingHeightType(+e.target.value)}
                        disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                        isInline={true}
                        label={"Building height type"}
                        name={"buildingHeightType"}
                        options={[
                          "Floor height",
                          "Building height",
                        ]}
                      />


                    </Form.Group>

                    <Form.Group className="mb-3">
                      <RadioButtonComponent
                        changeFn={(e) => setFloorHeight(+e.target.value)}
                        disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                        isInline={true}
                        label={buildingHeightType === 0 ? "Floor height" : "Building height"}
                        name={"FloorHeight"}
                        options={["Fixed", "Random"]}
                      />

                      {floorHeight === 1 ? <div className={"d-flex justify-content-between"}>
                          <div className={'w-50 mr-4'}>
                            <InputComponent
                              disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                              type={"number"}
                              label={"Min."}
                              min={1}
                              name={"floorHeightMin"}
                            />
                          </div>
                          <div className={'w-50'}>
                            <InputComponent
                              disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                              type={"number"}
                              label={"Max."}
                              min={1}
                              name={"floorHeightMax"}
                            />
                          </div>
                        </div> :
                        <InputComponent
                          disabled={buildingConfigurationFormStore.isReadOnly || config.disabled}
                          type={"number"}
                          label={""}
                          min={1}
                          name={"floorHeightTotalValue"}
                        />}
                    </Form.Group>
                  </InputContainer>
                </Col>
              </Row>
              {!config.disabled && <Button type="submit" className={'position-fixed invisible'}>Next</Button>}
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
});

