import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Navigate } from "react-router-dom";
import {
  Alert,
  Button,
  BUTTON_COLOR,
  Col,
  Container,
  Row,
  STATUS_TYPE,
} from "taltech-styleguide";

const LogoutScreen: React.FC<any> = () => {
  const { instance } = useMsal();
  const isLoggedIn = useIsAuthenticated();

  const handleLogout = (logoutType: string) => {
    if (logoutType === "popup") {
      instance
        .logoutPopup({
          postLogoutRedirectUri: "/",
          mainWindowRedirectUri: "/",
        })
        .then(() => {});
    }
  };
  return (
    <Container className="App">
      <Row>
        <Col>
          <h1>Please logout</h1>
        </Col>
      </Row>
      <Button
        variant={BUTTON_COLOR.SECONDARY}
        size="lg"
        onClick={() => {
          handleLogout("popup");
        }}
      >
        Logout
      </Button>
      {!isLoggedIn && <Navigate to="/" replace />}
      <Row style={{ marginTop: "50px" }}>
        <Col className="col-4" />
        <Col className="col-4">
          <Alert variant={STATUS_TYPE.INFO}>
            You will be redirect to TalTech University identity provider logout
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default LogoutScreen;
