import { useSearchParams } from "react-router-dom";
import { IfcEmbedderProps } from "./ifc-embedder/IfcEmbedder.types";
import IfcEmbedder from "./ifc-embedder/ifc-embedder";
import { application } from "../../configuration/Application.bootstrap";
import { isNullOrEmptyString } from "@dirootie-extensions/javascript";

export const DiStellarViewer = (props: any) => {
  const [searchParams] = useSearchParams();
  const fileUrl = searchParams.get("fileUrl")!;
  if (isNullOrEmptyString(fileUrl)) {
    alert("No file was provided");
    return <></>;
  }
  const distellarConfiguration = application.getSettings()?.diStellar!;
  const ifcProps: IfcEmbedderProps = {
    ...distellarConfiguration,
    fileUrl: fileUrl,
    style: { width: "100%", height: "100%" },
  };
  return <IfcEmbedder {...ifcProps} fileUrl={fileUrl} />;
};
