import {
  Parameter,
  ParameterDescriptor,
  parametersPerTypeFloor,
  parametersPerTypeRoof,
  parametersPerTypeWall,
  parametersPerTypeWindow,
} from '@domain/building-components/shared';
import _ from 'lodash';
import { SpaceFunction } from './SpaceFunction';

const createParametersList = (list: Parameter[], id: string) => {
  return _.cloneDeep(list).map((x) => ({
    ...x,
    elementId: id,
    override: true,
  }));
};

export const spaceFunctionParametersData: ParameterDescriptor[] = [
  {
    id: 'id_spf_1',
    category: 'SPACE',
    name: 'Space 1',
    description: 'Space description 2',
    type: 'TEXT',
    valueDefault: '1',
    creationType: 'CONSTANT',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_spf_2',
    category: 'SPACE',
    name: 'Space 2',
    description: 'Space description 2',
    type: 'NUMBER',
    valueDefault: 1,
    creationType: 'RANDOM',
    creationOptions: {
      min: 12,
      max: 19,
    },
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_spf_3',
    category: 'SPACE',
    name: 'Space 3',
    description: 'Space description 2',
    type: 'TEXT',
    valueDefault: '3',
    creationType: 'CONSTANT',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_spf_4',
    category: 'SPACE',
    name: 'Space 2',
    description: 'Space description 2',
    type: 'NUMBER',
    valueDefault: 11,
    creationType: 'RANDOM',
    creationOptions: {
      min: 1,
      max: 14,
    },
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
];

export const spaceFunctionsData: SpaceFunction[] = [
  // {
  //   id: 'id_space_1',
  //   name: 'name 1',
  //   description: 'Space description 2',
  //   createdDate: new Date(Date.now()),
  //   modifiedDate: new Date(Date.now()),
  //   parameters: createParametersList(spaceFunctionParametersData, 'id_space_1'),
  // },
  // {
  //   id: 'id_space_2',
  //   name: 'name 2',
  //   description: 'Space description 2',
  //   createdDate: new Date(Date.now()),
  //   modifiedDate: new Date(Date.now()),
  //   parameters: createParametersList(spaceFunctionParametersData, 'id_space_2'),
  // },
  // {
  //   id: 'id_space_3',
  //   name: 'name 3',
  //   description: 'Space description 2',
  //   createdDate: new Date(Date.now()),
  //   modifiedDate: new Date(Date.now()),
  //   parameters: createParametersList(spaceFunctionParametersData, 'id_space_3'),
  // },
  // {
  //   id: 'id_space_4',
  //   name: 'name 4',
  //   description: 'Space description 2',
  //   createdDate: new Date(Date.now()),
  //   modifiedDate: new Date(Date.now()),
  //   parameters: createParametersList(spaceFunctionParametersData, 'id_space_4'),
  // },
];
