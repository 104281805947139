import { HandlerDefinition } from '../Actions.types';
import {
  NavigationActionHandlerBase,
  NavigationActionType,
} from './Navigation.types';

export const navigationHandlers: HandlerDefinition<NavigationActionType>[] = [
  {
    action: 'NAVIGATE_TO',
    type: NavigationActionHandlerBase,
  },
  {
    action: 'NAVIGATE_TO_EDIT',
    type: NavigationActionHandlerBase,
  },
  {
    action: 'NAVIGATE_TO_NEW',
    type: NavigationActionHandlerBase,
  },
  {
    action: 'NAVIGATE_BACK',
    type: NavigationActionHandlerBase,
  },
  {
    action: 'NAVIGATE_PREVIOUS',
    type: NavigationActionHandlerBase,
  },
];
