import { PagedResult } from '@dirootie-core/core-types';

export const groupsData: PagedResult<any> = {
  page: 1,
  size: 10,
  total: 5,
  items: [
    {
      id: 'id_group_1',
      number: 5,
      name: 'Group name 1',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
    },
    {
      id: 'id_group_1',
      number: 5,
      name: 'Group name 1',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
    },
    {
      id: 'id_group_3',
      number: 10,
      name: 'Group name 3',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
        {
          id: 'idl2',
          displayName: 'lect1',
          description: 'Blabla',
        },
        {
          id: 'idl3',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
    },
    {
      id: 'id_group_4',
      number: 30,
      name: 'Group name 4',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
    },
    {
      id: 'id_group_5',
      number: 15,
      name: 'Group name 5',
      lecturers: [
        {
          id: 'idl1',
          displayName: 'lect1',
          description: 'Blabla',
        },
      ],
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
    },
  ],
};
