import {FormBase, FormProps, InputSelect, InputText, InputTextArea,} from "@components";
import {MediaItem, ResourcesType} from "@domain";
import {Form as FormikForm} from "formik";
import React, {FC, useEffect, useState} from "react";
import {Alert, STATUS_TYPE} from "taltech-styleguide";
import FileUploadComponent from "../forms/FileUploadComponent";

export const MediaItemEditForm: FC<FormProps<MediaItem>> = ({...props}) => {
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState<string>("");
  useEffect(() => {
    console.log(fileName)
  }, [fileName])
  const render = (formik: any) => {
    return (
      <FormikForm>
        <InputSelect
          label={"Type"}
          id={"type"}
          formState={formik as any}
          items={ResourcesType}
          disabled={props.mode === "EDIT"}
        />
        <InputText id={"name"} formState={formik as any} label={"Name"}/>
        <InputTextArea
          id={"description"}
          formState={formik as any}
          label={"Description"}
        />

        {formik.values.type === "FILE" && props.mode === "CREATE" ? (
          <>
            <div>Upload</div>
            <FileUploadComponent setFileName={setFileName} setFileUrl={setFile}/>
          </>
        ) : props.mode === "CREATE" ? (
          <InputText id={"url"} formState={formik as any} label={"Url"}/>
        ) : <>
          <div>Uploaded File</div>
          <Alert variant={STATUS_TYPE.SUCCESS}>
            {props.element.fileName}
          </Alert>
        </>
        }
        <div className={'invisible position-absolute top-0'}>
          <InputText id={'contents'} formState={formik as any} label={"Contents"} outsideValue={file.length > 1 && file}/>
          <InputText id={'fileName'} formState={formik as any} label={"File name"} outsideValue={fileName?.length > 1 && fileName}/>
        </div>
      </FormikForm>
    );
  };
  return <FormBase {...props} render={render}/>;
};
