import _ from "lodash";
import { inject, observer } from "mobx-react";
import { Outlet } from "react-router-dom";
import { LayoutConfiguration } from "../../domain/web-applications/AreaConfiguration";
import { NavBarTop } from "@components/navigation";
import { LayoutBase } from "./LayoutBase";
import { SessionStore } from "../../domain/access-control/Session.store";
import {
  AuthenticationService,
  AuthorizationService,
} from "domain/access-control/Authentication.service";

export const LayoutPublic: React.FC<LayoutConfiguration> = inject(
  "sessionStore",
  "authorizationService",
  "authenticationService"
)(
  observer(
    (
      props: LayoutConfiguration & {
        children?: JSX.Element[];
        authorizationService?: AuthorizationService;
        authenticationService?: AuthenticationService;
        sessionStore?: SessionStore;
      }
    ) => {
      const { userProfile } = props.sessionStore!;
      const {
        authorizationService,
        authenticationService,
        route,
        sessionStore,
        ...rest
      } = props;
      const profile =
        userProfile !== undefined
          ? {
              firstName: userProfile!.firstName,
              lastName: userProfile!.lastName,
              role: userProfile!.role,
              photo: "no-profile-picture.png",
              // userProfile!.picture !== undefined
              //   ? userProfile!.picture.url
              //   : "no-user-profile.png",
            }
          : undefined;
      const roleRoute = authenticationService?.isAuthenticated()
        ? authorizationService?.isAuthorized()
          ? authorizationService?.getStartPageForRole(userProfile!.role)
          : "errors/no-account"
        : "/";
      const header = (
        <NavBarTop {...rest} route={roleRoute} profile={profile}></NavBarTop>
      );
      const children = _.isUndefined(props.children) ? (
        <Outlet />
      ) : (
        props.children
      );
      return (
        <LayoutBase header={header}>
          <div className={"ebd-no-margin"}>{children}</div>
        </LayoutBase>
      );
    }
  )
);
