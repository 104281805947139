import { Material, getMaterialValidationSchema } from '@domain';
import { application } from '../../configuration/Application.bootstrap';
import { ServiceFactory } from '@dirootie-core/api-types';
import { IKeyedObject } from '@dirootie-extensions/typescript';
import { IAction, IActionHandler } from './Actions.types';
import { FormState } from '@dirootie-web/components-types';
import { Dispatcher } from './Dispatcher';
import { EntityOptions } from './Actions.types';
import { IFormStateProvider } from './forms/Forms.handlers';
import { IViewNavigator } from 'configuration/ViewNavigator';
import { mergeBuilder, mergeProps } from '@dirootie-extensions/javascript';
import _ from 'lodash';
import { ILogger } from '@dirootie-core/logging-types';

export class EntityStore<T> implements IFormStateProvider {
  protected serviceFactory: ServiceFactory;
  private userNotificationService;
  protected dispatcher;
  private formState: any = undefined;
  public options: EntityOptions<T>;
  protected viewNavigator: IViewNavigator;
  protected logger: ILogger;

  constructor(options: EntityOptions<T>) {
    this.options = options;
    this.userNotificationService = application.getUserNotificationService();
    this.serviceFactory = application.getStores()['serviceFactory'];
    this.viewNavigator = application.getNavigator();
    this.logger = application.getLogger();
    this.dispatcher = new Dispatcher(this.logger);
    this.initialize({
      options: this.options,
      serviceFactory: this.serviceFactory,
      formStateProvider: this,
      userNotificationService: this.userNotificationService,
      defaultActionConverter: (x: IAction) => this.defaultActionConverter(x),
      viewNavigator: this.viewNavigator,
    });
  }

  defaultActionConverter(action: IAction): IAction {
    if (
      [
        'GET',
        'CREATE',
        'UPDATE',
        'DELETE',
        'FORM_GET',
        'FORM_CREATE',
        'FORM_SAVE',
        'FORM_DELETE',
        'TOOLBAR_DELETE',
      ].includes(action.action)
    ) {
      this.logger.info('[EntityStore:converter]: Called.', action);
      action.options = (action.options as any) || ({} as any);
      (action.options! as any).path = this.options.path;
      this.logger.info('[EntityStore:converter]: Result.', action);
    }
    return action;
  }

  setState(s: FormState): void {
    this.formState = s;
  }

  getState(): FormState {
    return this.formState;
  }

  initialize(options: any) {
    this.logger.info('[EntityStore:initialize]: Called.', options);
    this.dispatcher.initialize(options);
  }

  onAction(action: IAction) {
    this.dispatcher.dispatch(action);
  }

  executeAction<T>(action: IAction): Promise<T> {
    this.logger.info('[EntityStore:executeAction]: Called.', action);
    return this.dispatcher.execute(action);
  }

  getEmpty(params?: Partial<T>): T {
    if (_.isUndefined(params)) {
      return this.options.empty! as T;
    }
    const result = mergeBuilder<T>(this.options.empty!, true)
      .merge(params)
      .build() as T;
    return result;
  }
}
