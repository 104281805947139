import { createFormProperties, PageContentForm } from "@components";
import { Course } from "@domain/courses";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { entityStore } from "../CourseIndexScreen";
import CourseEditForm from "./CourseEditForm";

export const CoursesSummaryScreen: React.FC<any> = observer((props) => {
  const location = useLocation();
  const segments = location.pathname.split("/");
  const [element, setElement] = useState<Course | undefined>(undefined);
  const { courseId } = useParams();
  const navigator = useNavigate();
  useEffect(() => {
    if (courseId !== undefined) {
      entityStore
        .executeAction<Course>({
          action: "FORM_GET",
          payload: courseId,
          options: {
            path: "courses",
          },
        })
        .then((x) => {
          setElement(x);
        });
    }
  }, [setElement, courseId]);
  const formProps = createFormProperties(
    segments[1] === "students" ? "VIEW" : "EDIT",
    {
      onAction: (action) => {
        return Promise.resolve(entityStore.onAction(action));
      },
      schema: entityStore.options.schema,
      element: element,
    },
    entityStore.options,
    navigator
  );
  return (
    <PageContentForm
      form={{ Component: CourseEditForm, options: { ...formProps } }}
      mode={segments[1] === "students" ? "VIEW" : "EDIT"}
      dispatcher={{
        onAction: (action) => {
          return Promise.resolve(entityStore.onAction(action));
        },
      }}
      toolbar={{ options: { deleteDisabled: true } }}
    />
  );
});
