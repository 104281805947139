import { EntityOptions, EntityStore } from "@components";
import { BuildingComponentsIndexBaseScreen } from "@components/building-components";
import { FloorBuildingComponent, getBuildingComponentValidationSchema } from "@domain";

const componentEntityOptions: EntityOptions<FloorBuildingComponent> = {
  schema: getBuildingComponentValidationSchema,
  empty: {
    name: '',
    description: '',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    category: "FLOORS"
  },
  path: 'buildingComponents.components',
};

export const floorEntityStore = new EntityStore(componentEntityOptions)

export const FloorTypeIndexScreen: React.FC<any> = () => (
  <BuildingComponentsIndexBaseScreen componentType={"FLOORS" as any} />
);
