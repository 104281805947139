import {observer} from "mobx-react";
import React, {FC, useState} from "react";
import BuildingComponentsContext from "../../domain/forms/buildingcomponents.context";
import {useStore} from "../../domain/store";
import "../../styles/form.css";
import {BuildingComponentsForm} from "./sixth-step";

export const BuildingComponentsWrapper: FC<any> = observer(({...props}) => {

  const {buildingConfigurationFormStore} = useStore();
  const [selectedIndex2, setSelectedIndex2] = useState(0);

  return (
    <>
      <BuildingComponentsContext.Provider value={{
        ...buildingConfigurationFormStore.sixthStep,
        selectedIndex: selectedIndex2,
        config: {
          ...buildingConfigurationFormStore.sixthStep.config,
          disabled: !(buildingConfigurationFormStore.currentStep === 3)
        },
        setSelectedIndex: setSelectedIndex2
      }}>

        <BuildingComponentsForm/>
      </BuildingComponentsContext.Provider>
    </>
  );
});

