import {
  FormBase,
  FormProps,
  InputSingleDatePicker,
  InputText,
} from "@components";
import { UserProfile } from "@domain";
import { FC } from "react";
import { Form as FormikForm } from "formik";
import { observer } from "mobx-react";

export const AccountEditForm: FC<FormProps<UserProfile>> = observer(
  ({ ...props }) => {
    const render = (formik: any) => {
      return (
        <FormikForm>
          <InputText
            disabled={props.visualization?.isReadOnly}
            id="id"
            label="Id"
            formState={formik as any}
          />
          <InputText
            disabled={props.visualization?.isReadOnly}
            id="role"
            label="Role"
            formState={formik as any}
          />
          <InputText
            disabled={props.visualization?.isReadOnly}
            id="accountState"
            label="accountState"
            formState={formik as any}
          />
          <InputSingleDatePicker
            id={"firstAccessDate"}
            formState={formik as any}
            label={"First access"}
          />
          <InputSingleDatePicker
            id={"lastAccessDate"}
            formState={formik as any}
            label={"Last access"}
          />
          <InputSingleDatePicker
            id={"modifiedDate"}
            formState={formik as any}
            label={"Modified at"}
          />
          <InputSingleDatePicker
            id={"createdDate"}
            formState={formik as any}
            label={"Created at"}
          />
        </FormikForm>
      );
    };
    return <FormBase {...props} render={render} />;
  }
);

export default AccountEditForm;
