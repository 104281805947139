import {
  Page,
  WidgetShortcuts,
  WidgetListProps,
  WidgetList
} from "@components";
import { PageTest } from "@components/testing";

const testingWidgetListProps: WidgetListProps = {
  title: "Areas shortcuts",
  items: [
    { name: "Administation", route: "/management", title: "Management area" },
    { name: "Lecturers", route: "/lecturers", title: "Lecturers area" },
    { name: "Students", route: "/students", title: "Students area" },
    { name: "Testing", route: "/testing", title: "Testing area" },
  ],
};


const testingWidgetShortcutsProps: WidgetListProps = {
  title: "Management shortcuts",
  items: [
    { name: "Administation", route: "/management", title: "Management area" },
    { name: "Lecturers", route: "/lecturers", title: "Lecturers area" },
    { name: "Students", route: "/students", title: "Students area" },
    { name: "Testing", route: "/testing", title: "Testing area" },
    {
      name: "Download Revit",
      route: "/erros/not-found",
      title: "Download Revit plugin",
    },
  ],
};

export const WidgetsUitest = () => {

  return (
    <PageTest title="Widgets - uitest">
      <h2 className={"edb-heading-bold"}>Widgets examples</h2>
      <div>
        <h3 className={"edb-heading-bold"}>Widget list</h3>
        <p className={"my-3"}>
          This widget is used to show only a list of links
        </p>
        <WidgetList {...testingWidgetListProps}/>
      </div>

      <div>
        <h3 className={"edb-heading-bold"}>Widget shortcuts</h3>
        <p className={"my-3"}>
          This widget is used to show a card with links and title and subtitle
        </p>
        <WidgetShortcuts {...testingWidgetShortcutsProps}/>
      </div>
    </PageTest>
  );
};

