import {createMaxMessage, createMinMessage, createRequiredMessage} from "@domain/validations";
import * as Yup from "yup";

export const getAssignmentFormValidationSchema = () => {
  const AssignmentFormValidationSchema = Yup.object<any>().shape({
    number: Yup.string().required(createRequiredMessage('Number')),
    title: Yup.string().min(3, createMinMessage('title', 3)).max(300, createMaxMessage('title', 300)).required(createRequiredMessage('Title')),
    startDate: Yup.date().required(createRequiredMessage('Start date')),
    endDate: Yup.date().required(createRequiredMessage('End date')),
  });

  return AssignmentFormValidationSchema;
};
