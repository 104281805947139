import {
  BuildingComponentDataForm,
  ComponentDataFormProps,
  Page,
  ToolbarEdit,
} from "@components";
import React from "react";

const ComponentsPresetCreateScreen: React.FC<any> = (props: any) => {
  const emptyProps: ComponentDataFormProps = {
    showLayer: false,
    onCancel: () => {},
    element: {
      id: "0",
      createdDate: new Date(Date.now()),
      modifiedDate: new Date(Date.now()),
      type: "WALL",
      name: "",
      description: "",
      layers: { core: [], exterior: [], interior: [] },
      parameters: [],
    },
    onSubmit: (values: any) => {
      console.log("A");
    },
    onReset: () => {},
  };

  let submitForm: any = null;
  const onSubmitSelectedHandler = (e: any) => {
    if (submitForm) {
      submitForm(e).then((x: any) => console.log(x));
    }
  };
  const submitSelectedBinder = (submitFormParam: any) => {
    submitForm = submitFormParam;
  };

  return (
    <Page title="Components preset">
      <ToolbarEdit onSave={onSubmitSelectedHandler} />
      <BuildingComponentDataForm
        submiFormBinder={submitSelectedBinder}
        {...emptyProps}
      />
    </Page>
  );
};

export default ComponentsPresetCreateScreen;
