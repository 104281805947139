import {Field, useField} from "formik";
import React, {FC, useEffect, useState} from "react";
import {Form, Input} from "taltech-styleguide";
import {useStore} from "../../domain/store";
import ErrorComponent from "./ErrorComponent";

type IInputComponent = {
  changeFn?: (e: any) => void;
  customClassName?: string,
  size?: "sm" | "lg",
  step?: string,
  placeholder?: string,
  type: string,
  name: string,
  label?: string,
  disabled?: boolean,
  min?: number,
  max?: number,
  countStep?: number
  isInteger?: boolean
}

export const InputComponent: FC<IInputComponent> = ({
                                                      countStep = 0.01,
                                                      isInteger,
                                                      changeFn,
                                                      customClassName,
                                                      size,
                                                      step,
                                                      placeholder,
                                                      type,
                                                      name,
                                                      label,
                                                      disabled,
                                                      ...props
                                                    }) => {
  const {buildingConfigurationFormStore} = useStore();
  const [field, meta, helpers] = useField(name);
  const [placeholder2, setPlaceholder2] = useState<string>(placeholder || "")
  useEffect(() => {
    if (disabled && step === "space Configuration" && !buildingConfigurationFormStore.isReadOnly) {
      helpers.setValue("...");
      setPlaceholder2(`\<...Value...\>`)
    }
    if (!disabled &&
      step === "space Configuration") {
      setPlaceholder2(placeholder || "")
      if (field.value === "...") helpers.setValue("")
      else helpers.setValue(field.value);
    }
  }, [disabled]);
  useEffect(() => {
    field.value === 0 && helpers.setValue("")
  }, [])
  const handleInputChange = (e: any) => {
    const input = e.target.value;
    const key = e.key;
    const decimalIndex = input.indexOf('.');
    console.log(input, key, decimalIndex)

    if (decimalIndex !== -1 && key === '.') {
      // If decimal point already exists and user tries to add another
      e.preventDefault();
    } else if (decimalIndex !== -1 && input.split('.')[1].length >= 2) {
      // If decimal point exists and there are already two decimal places
      e.preventDefault();
    } else if (key !== '.' && !/[\d.]/.test(key)) {
      // Allow only digits and a single decimal point
      e.preventDefault();
    }
  };
  const handleInput = (event: any) => {
    const {value} = event.target;
    event.target.value = value.replace(/\D/g, '');
  };
  return (
    <div>
      {label && <Form.Label>{label}</Form.Label>}
      <Field isInvalid={meta.touched && meta.error} name={name}>
        {() => (
          <Input
            {...field}
            onKeyPress={type === "number" ? handleInputChange : () => console.log("A")}
            step={countStep}
            className={customClassName && customClassName}
            disabled={disabled}
            size={size && size}
            type={type}
            placeholder={placeholder2 || placeholder || ""}
            min={props.min && props.min}
            onInput={(isInteger && type === "number") ? handleInput : () => console.log("Changed")}
            onChange={(e) => {
              if (changeFn) {
                console.log("sdfss")
                changeFn(e);
                field.onChange(e);
              } else {
                field.onChange(e);
              }
            }}
            onClick={(e) => changeFn && changeFn(e)}
          />
        )}
      </Field>
      <ErrorComponent fieldName={name}/>
    </div>
  );
};

export default InputComponent;
