import { inject, observer } from "mobx-react";
import { Page }  from "@components";

const UserSettingsScreen: React.FC<any> = inject("coursesStore")(
  observer((props: any) => {
    return <Page title="User settings" showBreadcrumbs={true}></Page>;
  })
);

export default UserSettingsScreen;
