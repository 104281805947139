import { RoofParameterIndexScreen } from "./RoofParameterIndexScreen";
import { RoofTypeIndexScreen } from "./RoofTypeIndexScreen";

import {
  PageTabed,
  PageTabedProps
} from "@components";

const RoofsScreen: React.FC<any> = (props: any) => {
  const newProps: PageTabedProps = {
    title: "Roofs",
    pathBase: "/lecturers/building-components/system/components/roofs",
    tabs: [
      {
        label: "Types",
        component: <RoofTypeIndexScreen />,
        index: 0,
        path: "types",
      },
      {
        label: "Parameters",
        component: <RoofParameterIndexScreen />,
        index: 1,
        path: "parameters",
      }
    ],
  };
  return <PageTabed {...newProps}></PageTabed>;
};

export default RoofsScreen;
