import { useNavigate } from "react-router-dom";
import { Button, BUTTON_COLOR } from "taltech-styleguide";
import { ButtonProps } from "./Buttons.types";

export const BackBtn: React.FC<any> = (props: ButtonProps) => {
  const navigator = useNavigate();
  const onClick = props.onClickUrl
    ? (evt: any) => {
        navigator(props.onClickUrl!);
      }
    : (evt: any) => { navigator(-1);}
  return (
    <Button onClick={(evt) => onClick(evt)} variant={BUTTON_COLOR.PRIMARY}>
      {props.text}
    </Button>
  );
};

export const CancelBtn: React.FC<any> = (props: ButtonProps) => {
  const navigator = useNavigate();
  const onClick = props.onClickUrl
    ? (evt: any) => {
        navigator(props.onClickUrl!);
      }
    : (evt: any) => { navigator(-1);}
  return (
    <Button onClick={(evt) => onClick(evt)} variant={BUTTON_COLOR.PRIMARY}>
      {props.text}
    </Button>
  );
};

export const DeleteBtn: React.FC<any> = (
  props: ButtonProps & { disabled: boolean }
) => {
  const navigator = useNavigate();
  const onClick = props.onClickUrl
    ? (evt: any) => {
        navigator(props.onClickUrl!);
      }
    : props.onClick!;
  return (
    <Button
      disabled={props.disabled}
      onClick={(evt) => onClick(evt)}
      variant={BUTTON_COLOR.PRIMARY}
    >
      {props.text}
    </Button>
  );
};
