import {PageTest, PageTestProps, useOutputWindowService} from "@components/testing";
import {FC} from "react";
import {Course} from "../../../../../domain/courses/Course";
import CourseEditForm, {CourseFormProps,} from "../../../../lecturers/screens/courses/summary/CourseEditForm";

export const CourseEditFormUitest: FC<any> = ({}) => {
  const service = useOutputWindowService();
  const formProps: CourseFormProps = {
    course: {
      id: "",
      name: "",
      number: 0,
      state: "Active",
      description: "sds",
      lecturers: [],
      endDate: new Date(Date.now()),
      startDate: new Date(Date.now())
    },
    onSubmit: (value: Course) => {
      service.log("onSubmit", value);
    },
    onCancel: (evt: any) => {
      service.log("onCancel");
    },
  };

  const props: PageTestProps = {
    title: "Parameter form - uitest",
    demo: {
      outputWindow: true,
      outputTracker: ["onSubmit", "onCancel"],
      Component: CourseEditForm,
      properties: formProps,
    },
  };

  return <PageTest {...props} />;
};
