import { LayoutWorkspace } from "@components";
import { AreaConfiguration } from "../../domain/web-applications/AreaConfiguration";
//import { studentsAreaRoutes } from "./StudentsAreaRouting";

export const studentsAreaConfiguration: AreaConfiguration = {
  name: "students",
  roles: ["STUDENT", "LECTURER", "STUDY_DIRECTOR"],
  protected: true,
  layout: {
    name: "",
    route: "students",
    component: LayoutWorkspace,
    menuProfile: {
      items: [
        {
          id: "students_11",
          children: "Profile",
          to: "/users/profile",
        },
        {
          id: "students_12",
          children: "Settings",
          to: "/users/settings",
        },
        {
          id: "admin_13",
          children: "Management",
          to: "/management",
          roles: ["STUDY_DIRECTOR"],
        },
        {
          id: "admin_14",
          children: "Lecturers",
          to: "/lecturers",
          roles: ["STUDY_DIRECTOR", "LECTURER"],
        },
        {
          id: "admin_15",
          children: "Students",
          to: "/students",
          roles: ["STUDY_DIRECTOR", "LECTURER", "STUDENT"],
        },
      ],
    } as any,
    menuMain: {
      items: [
        {
          id: "students_1",
          label: "Dashboard",
          to: "/students",
        },
        {
          id: "students_2",
          label: "Courses",
          to: "/students/courses",
        },
        {
          id: "lecturer_7",
          label: "Assignments",
          to: "/students/assignments",
        },
        {
          id: "students_3",
          label: "Configurations",
          to: "/students/building-configurations",
        },
        {
          id: "students_4",
          label: "Components",
          to: "/students/building-components",
        },
        // {
        //   id: "students_5",
        //   label: "Notifications",
        //   to: "/students/notifications",
        // },
        // {
        //   id: "lecturer_6",
        //   label: "Resource library",
        //   to: "/students/resources",
        // },
        {
          id: "students_7",
          label: "Support",
          to: "/support/revit-plugin",
          newTab: true,
        },
      ],
    },
  },
};
