import { notEmptyArray } from "@dirootie-extensions/javascript";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import { Icon, ICONS } from "taltech-styleguide";
import "../../../styles/testing.css";
import CodeViewer from "./CodeViewer";
import { useOutputWindowService } from "./OutputWindow.service";

export type LogMessage = {
  text: string;
  data?: any;
  expanded: boolean;
};

type OutputWindowProps = {
  messages: LogMessage[];
};

const MessageData = observer((props: { message: LogMessage }) => {
  const { message } = props;
  const expanded = message.expanded;
  const iconName = expanded ? ICONS.ARROW_OPEN_RIGHT : ICONS.ARROW_OPEN_DOWN;
  console.log(message);
  const expandedContent = expanded ? (
    message.data !== undefined ? (
      <div className="output-window-message-expanded">
        <CodeViewer json={message.data} />
      </div>
    ) : (
      "No data"
    )
  ) : (
    <></>
  );
  return (
    <div className="output-window-message-line">
      <span> {message.text}</span>
      <Icon
        iconName={iconName}
        onClick={() => {
          message.expanded = !message.expanded;
        }}
      />
      {expandedContent}
    </div>
  );
});

const MessageLine = observer((props: { message: LogMessage }) => {
  const { message } = props;
  const content = !_.isUndefined(message.data) ? (
    <MessageData {...props} />
  ) : (
    <span> {props.message.text}</span>
  );
  return <div className="output-window-message-line">{content}</div>;
});

export const OutputWindow: React.FC<any> = //inject()(
  observer((props: OutputWindowProps) => {
    const service = useOutputWindowService();
    const content = notEmptyArray(props.messages)
      ? props.messages.map((x, i) => <MessageLine key={i} message={x} />)
      : "No messages";

    return (
      <div className="output-window">
        <div className="output-window-header">
          <span>Output window</span>
          <span
            className="output-window-clear"
            onClick={() => {
              service.clear();
            }}
          >
            clear
          </span>
        </div>
        <div className="output-window-content">{content}</div>
      </div>
    );
  });
//);
