import { Field, Formik } from "formik";
import { Form, Input } from "taltech-styleguide";
import FeedbackMessage from "./FeedbackMessage";
import {
  getSelectOptions,
  InputSelectProps,
  isFormFieldValid,
  SelectOption,
  SelectOptions,
} from "@dirootie-web/components-types";
import _ from "lodash";

export const InputSelect = (props: InputSelectProps) => {
  const { id, formState, disabled } = props;

  const OptionsInner = () => {
    const options = getSelectOptions(props);
    const selected = props.selected || formState.getFieldMeta(id).value;
    return (
      <Input
        as="select"
        value={selected}
        onChange={(evt) => {
          formState.setFieldValue(id, evt.target.value);
        }}
        disabled={disabled}
      >
        {options.map((x) => (
          <option key={x.value} value={x.value}>
            {x.text.toString()}
          </option>
        ))}
      </Input>
    );
  };

  return (
    <Form.Group className={props.elementClass}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <Field
        name={id}
        disabled={disabled}
        renderAs="select"
        component={OptionsInner}
        isInvalid={!isFormFieldValid(id, formState)}
      />
      <FeedbackMessage fieldId={id} formState={formState}></FeedbackMessage>
    </Form.Group>
  );
};

export const InputSelectForm = (
  props: {
    options:
      | SelectOptions
      | { elements: any[]; selector: (elem: any) => SelectOption };
    onClick: (val: any) => void;
  } & Omit<InputSelectProps, "options" | "formState">
) => {
  const { selected, options, onClick, ...rest } = props;
  const opts = _.isArray(options)
    ? options
    : options.elements.map((x) => options.selector(x));
  const init: any = {};
  init.id = props.id;
  init.selected = selected;
  return (
    <Formik
      initialValues={init}
      onSubmit={(values) => {
        props.onClick(values);
      }}
    >
      {(formik) => {
        return (
          <InputSelect
            selected={selected}
            options={opts}
            {...rest}
            formState={formik as any}
          />
        );
      }}
    </Formik>
  );
};
