import { PageTabed, PageTabedProps }  from "@components";
import { WindowParameterIndexScreen } from "./WindowParameterIndexScreen";
import { WindowTypeIndexScreen } from "./WindowTypeIndexScreen";

const WindowsScreen: React.FC<any> = (props: any) => {
  const newProps: PageTabedProps = {
    title: "Windows",
    pathBase: "/lecturers/building-components/system/components/windows",
    tabs: [
      {
        label: "Types",
        component: <WindowTypeIndexScreen />,
        index: 0,
        path: "types",
      },
      {
        label: "Parameters",
        component: <WindowParameterIndexScreen />,
        index: 1,
        path: "parameters",
      }
    ],
  };
  return <PageTabed {...newProps}></PageTabed>;
};

export default WindowsScreen;
