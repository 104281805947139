//Parameter value type -> parameter value type on Revit - subset of the revit types, only text and number.

export const ParameterTypeList = ['TEXT', 'NUMBER', "INTEGER"] as const;

export type ParameterType = typeof ParameterTypeList[number];

//Component type -> category on Revit - subset of the revit categories
export const ComponentTypeList = ['FLOOR', 'WINDOW', 'WALL', 'ROOF'] as const;

export type ComponentType = typeof ComponentTypeList[number];

//Parameter parent element type
export const ParameterCategoryList = [
  ...ComponentTypeList,
  'BUILDING',
  'SPACE',
] as const;

export type ParameterCategory = typeof ParameterCategoryList[number];

//Component Material List type
export const MaterialList = ['Material 01', 'Material 02', 'Material 03', 'Material 04'] as const;

export type ComponentMaterialType = typeof MaterialList[number];
