import {
  ParameterDescriptor
} from '@domain/building-components/shared';

export const buildingParametersData: ParameterDescriptor[] = [
  {
    id: 'id_bparam_1',
    category: 'BUILDING',
    name: 'Building 1',
    description: 'Building description 2',
    type: 'TEXT',
    valueDefault: '1',
    creationType: 'CONSTANT',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_bparam_2',
    category: 'BUILDING',
    name: 'Building 2',
    description: 'Building description 2',
    type: 'NUMBER',
    valueDefault: 1,
    creationType: 'RANDOM',
    creationOptions: {
      min: 12,
      max: 19,
    },
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_bparam_3',
    category: 'BUILDING',
    name: 'Building 3',
    description: 'Building description 2',
    type: 'TEXT',
    valueDefault: '3',
    creationType: 'CONSTANT',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
  {
    id: 'id_bparam_4',
    category: 'BUILDING',
    name: 'Building 2',
    description: 'Building description 2',
    type: 'NUMBER',
    valueDefault: 11,
    creationType: 'RANDOM',
    creationOptions: {
      min: 1,
      max: 14,
    },
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
  },
];
