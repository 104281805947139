import { LayoutWorkspace } from "@components";
import { AreaConfiguration } from "../../domain/web-applications/AreaConfiguration";
//import { studentsAreaRoutes } from "./StudentsAreaRouting";

export const usersAreaConfiguration: AreaConfiguration = {
  name: "users",
  roles: ["STUDENT", "LECTURER", "STUDY_DIRECTOR"],
  protected: true,
  layout: {
    name: "",
    route: "users",
    component: LayoutWorkspace,
    menuProfile: {
      items: [
        {
          id: "users_11",
          children: "Profile",
          to: "/users/profile",
        },
        {
          id: "users_12",
          children: "Settings",
          to: "/users/settings",
        },
        {
          id: "admin_13",
          children: "Management",
          to: "/management",
          roles: ["STUDY_DIRECTOR"],
        },
        {
          id: "admin_14",
          children: "Lecturers",
          to: "/lecturers",
          roles: ["STUDY_DIRECTOR", "LECTURER"],
        },
        {
          id: "admin_15",
          children: "Students",
          to: "/students",
          roles: ["STUDY_DIRECTOR", "LECTURER", "STUDENT"],
        },
      ],
    } as any,
    menuMain: {
      items: [
        {
          id: "users_1",
          label: "Profile",
          to: "/users/profile",
        },
        {
          id: "users_2",
          label: "Settings",
          to: "/users/settings",
        },
        {
          id: "users_3",
          label: "Support",
          to: "/support/revit-plugin",
          newTab: true,
        },
      ],
    },
  },
};
