import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import AccountEditForm from "./AccountEditForm";
import ProfileEditForm from "./ProfileEditForm";
import { UserProfile } from "@domain";
import { Loader } from "taltech-styleguide";

export type UserInfoProps = {
  profile: UserProfile;
};

const UserInfo: React.FC<any> = observer((props: UserInfoProps) => {
  const navigator = useNavigate();
  const profileFormProps: any = {
    element: props.profile,
    mode: "VIEW",
    visualization: {
      isReadOnly: true,
      showModifiedDate: true,
      showCreatedDate: false,
    },
    onAction: (action: any) => Promise.resolve(),
  };
  if (props.profile === undefined) {
    return <Loader fullScreen />;
  }
  return (
    <>
      <div>
        <h3>Profile information</h3>
        <ProfileEditForm {...profileFormProps} />
      </div>
      <div>
        <h3>Account information</h3>
        <AccountEditForm {...profileFormProps} />
      </div>
    </>
  );
});

export default UserInfo;
