import { Mailbox, Widget, Separator } from "taltech-styleguide";

export const NotificationBox = (props: any) => {
  return (
    <>
      <Widget
        collapsable
        header={
          <div className="d-flex align-items-start flex-wrap">
            <h3 className="mb-0">Kirjad</h3>
            <Separator size="sm" vertical />
            <div>
              <strong>teele.vaarikas@ttu.ee</strong>{" "}
              <span className="text-nowrap">(2 lugemata)</span>
            </div>
          </div>
        }
        headerVariant="purple"
        id="mailbox-widget"
        onCollapseToggle={function noRefCheck() {}}
      >
        <Mailbox
          aria-labelledby="mailbox-widget-title"
          ctaText="Mine postkasti kirja lugema"
          messages={[
            {
              date: "2020-06-03T10:15:41Z",
              id: "1",
              isRead: true,
              sender: "marju.kirju@ttu.ee",
              subject: "Järgmise nädala loengud",
              url: "www.ttu.ee",
            },
            {
              date: "2020-06-03T10:15:41Z",
              id: "2",
              isRead: true,
              sender: "marju.kirju@ttu.ee",
              subject:
                "Järgmise nädala loengud, Järgmise nädala loengud, Järgmise nädala loengud, Järgmise nädala loengud",
              url: "www.ttu.ee",
            },
            {
              date: "2020-06-03T10:15:41Z",
              id: "3",
              isRead: true,
              sender: "kalju.kirju@ttu.ee",
              subject: "Järgmise nädala loengud",
              url: "www.ttu.ee",
            },
          ]}
          onMessageClick={function noRefCheck() {}}
          placeholder="Sul pole ühtegi kirja"
        />
      </Widget>
    </>
  );
};
