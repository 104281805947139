import { ILogger } from "@dirootie-core/logging-types";
import {
  NotificationSeverity,
  UserNotification,
  UserNotificationService,
} from "../../packages/web/components-types/src/notifications";
import { toast } from "taltech-styleguide";

export class ReactToastifyUserNotificationService extends UserNotificationService {
  mapper = new Map<NotificationSeverity, (a: any, o?: any) => void>();

  constructor(logger: ILogger) {
    super(logger);
    this.mapper.set("error", toast.error);
    this.mapper.set("info", toast.info);
    this.mapper.set("success", toast.success);
    this.mapper.set("warning", toast.warn);
  }

  showNotification(
    severity: NotificationSeverity,
    notification: UserNotification
  ) {
    const f = this.mapper.get(severity)!;
    const notificationText = notification.title?.key
      ? notification.title?.key
      : notification.title;
    notification.className = notification.className || "ebd-toast-custom-styling";
    let content = notificationText;
    if(notification.component !== undefined){
      content = <NotificationView header={notificationText} content={<div>{notification.message}</div>}/>
    }
    f(content, notification);
  }
}

export const NotificationView = (props: { header?:string, content:JSX.Element|string }): JSX.Element => {
  const { header, content} = props;
  const resultHeader = header !== undefined
      ? <h4>{header}</h4>
      : <></>;
  return <div>
      {resultHeader}
      {content}
  </div>;
}
