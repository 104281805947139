import {Formik} from "formik";
import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {Loader} from "taltech-styleguide";
import {FormProps} from "./Forms.types";

export const FormBase: FC<FormProps<any>> = ({...props}: FormProps<any>) => {
  const navigator = useNavigate();
  if (props.element === undefined) {
    return <Loader fullScreen/>;
  }
  return (
    <>
      <Formik
        validationSchema={props.schema}
        initialValues={props.element}
        onSubmit={(values) => {
          console.log(values)
          props.onSubmit!(values);
        }}
      >
        {(formik) => {
          props.onAction!({action: "FORM_RENDER", payload: formik, options: {navigator: navigator}});
          return props.render(formik, props);
        }}
      </Formik>
    </>
  );
};

