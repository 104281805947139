import {InputSelectProps} from "@dirootie-web/components-types";
import {
  ComponentTypeList,
  MaterialList,
  parameterCreationTypeOptions,
  ParameterTypeList,
  StructureLayerFunctionTypeList,
  wallLayerOptions,
  windowPositionOptions,
  windowStyleOptions,
} from "@domain/building-components";
import {Formik} from "formik";
import {Component} from "react";
import {InputSelect} from "../inputs";

export const SelectParameterType = (
  props: Omit<InputSelectProps, "items" | "options">
) => {
  const items = ParameterTypeList as any;
  const newProps = {items, ...props};
  return <InputSelect {...newProps} />;
};

export const SelectStructureMaterial = (
  props: Omit<InputSelectProps, "items" | "options">
) => {
  const items = MaterialList as any;
  const newProps = {items, ...props};
  return <InputSelect {...newProps} />;
};

export const SelectStructureFunction = (
  props: Omit<InputSelectProps, "items" | "options">
) => {
  const items = StructureLayerFunctionTypeList as any;
  const newProps = {items, ...props};
  return <InputSelect {...newProps} />;
};

export const SelectComponentType = (
  props: Omit<InputSelectProps, "items" | "options">
) => {
  const items = ComponentTypeList as any;
  const newProps = {items, ...props};
  return <InputSelect {...newProps} />;
};

export const SelectWallLayer = (
  props: Omit<InputSelectProps, "items" | "options">
) => {
  const items = wallLayerOptions as any;
  const newProps = {items, ...props};
  return <InputSelect {...newProps} />;
};

export const SelectSystemEnums = (
  props: { options: any } & Omit<InputSelectProps, "items" | "options">
) => {
  const {options, ...rest} = props;
  const opts = options as any;
  const newProps = {options: opts, ...rest};
  return <InputSelect {...newProps} />;
};

export const SelectWindowStyle = (props: Omit<InputSelectProps, "options">) => (
  <SelectSystemEnums {...props} options={windowStyleOptions}/>
);

export const SelectWindowPosition = (
  props: Omit<InputSelectProps, "options">
) => <SelectSystemEnums {...props} options={windowPositionOptions}/>;

export const SelectParameterCreationType = (
  props: Omit<InputSelectProps, "options">
) => <SelectSystemEnums {...props} options={parameterCreationTypeOptions}/>;

export const SelectForm = (
  props: { Component: any; onClick: (val: any) => void } & Omit<InputSelectProps,
    "options" | "formState">
) => {
  const {selected, Component, onClick, ...rest} = props;
  const init: any = {};
  init.id = props.id;
  init.selected = selected;
  return (
    <Formik
      initialValues={init}
      onSubmit={(values) => {
        props.onClick(values);
      }}
    >
      {(formik) => {
        return <Component selected={selected} {...rest} formState={formik}/>;
      }}
    </Formik>
  );
};
