import { PageTabed, PageTabedProps }  from "@components";
import SpaceParametersScreen from "./SpaceParametersScreen";
import SpaceFunctionTypesScreen from "./SpaceFunctionTypesScreen";

const SpaceFunctionsScreen: React.FC<any> = (props: any) => {
  const newProps: PageTabedProps = {
    title: "Space functions",
    pathBase: "/lecturers/building-components/system/data/space-functions",
    tabs: [
      {
        label: "Functions",
        component: <SpaceFunctionTypesScreen />,
        index: 0,
        path: "types",
      },
      {
        label: "Parameters",
        component: <SpaceParametersScreen />,
        index: 1,
        path: "parameters",
      }
    ],
  };
  return <PageTabed {...newProps}></PageTabed>;
};

export default SpaceFunctionsScreen;
