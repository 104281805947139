import {InputIntegerNumber, InputNumber, InputText} from "@components";
import {FormState} from "@dirootie-web/components-types";
import {ParameterType} from "@domain";

export const createParameterTypeInput = (
  type: ParameterType,
  formState: FormState,
  props: any = {}
) => {
  const {id = "valueDefault", ...rest} = props;
  return type.toUpperCase() === "NUMBER" ? (
    <InputNumber elementClass={'w-100'} id={id} formState={formState} {...rest} />
  ) : type.toUpperCase() === "INTEGER" ?
    <InputIntegerNumber step={1} value={""} elementClass={'w-100'} id={id} formState={formState} {...rest}  />
    : (
      <InputText elementClass={'w-100'} id={id} formState={formState} {...rest} />
    );
};
