import {
  ToolbarCreate,
  ToolbarEdit,
  ToolbarWithActionsProps,
} from "@components/navigation";
import { FormMode } from "components/forms";
import { observer } from "mobx-react-lite";
import { FC } from "react";

export type PageContentFormOptions = {
  Component?: any;
  element?: any;
  options?: any;
};

export type PageContentToolbarOptions = {
  Component?: any;
  element?: any;
  options?: any;
};

export type PageContentFormProps = {
  mode: FormMode;
  form: PageContentFormOptions;
  toolbar?: PageContentToolbarOptions;
  dispatcher?: { onAction: (ac: any) => Promise<any> };
};

const createToolbar = (options: PageContentFormProps) => {
  const { mode, form, toolbar } = options;
  if (toolbar !== undefined) {
    if (toolbar.element !== undefined) {
      return toolbar.element;
    }
    if (toolbar.Component !== undefined) {
      const Component = toolbar.Component;
      return (
        <Component
          {...toolbar.options}
          onAction={(ev: any) => options.dispatcher!.onAction(ev)}
        />
      );
    }
  }
  let resultToolbar = undefined;
  let resultToolbarOptions: Partial<ToolbarWithActionsProps> = {};
  if (toolbar?.options) {
    resultToolbarOptions = toolbar!.options;
  }
  if (resultToolbarOptions.onAction === undefined) {
    resultToolbarOptions.onAction = (ev: any) =>
      options.dispatcher!.onAction(ev);
  }
  switch (mode) {
    case "CREATE":
      resultToolbar = <ToolbarCreate {...resultToolbarOptions} />;
      break;
    case "EDIT":
      resultToolbar = <ToolbarEdit {...resultToolbarOptions} />;
      break;
    case "VIEW":
      resultToolbar = <></>;
      break;
  }
  return resultToolbar;
};

const createForm = (options: PageContentFormProps) => {
  const { mode, form, toolbar } = options;
  if (form !== undefined) {
    if (form.element !== undefined) {
      return form.element;
    }
    if (form.Component !== undefined) {
      const Component = form.Component;
      return (
        <Component
          {...form.options}
          onAction={(c: any) => options.dispatcher!.onAction(c)}
        />
      );
    }
  }
  return <></>;
};

export const PageContentForm: FC<PageContentFormProps> = observer(
  (props: PageContentFormProps) => {
    const { mode = "VIEW", form, toolbar, ...rest } = props;
    let resultToolbar = createToolbar(props);
    const resultForm = createForm(props);
    return (
      <>
        {resultToolbar}
        {resultForm}
      </>
    );
  }
);
