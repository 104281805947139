import {IColumnProps} from 'taltech-styleguide';
import {
  colCourseLogoDefaults,
  colCreatedDateDefaults,
  colDescriptionDefaults,
  colEmailDefaults,
  colEndDateDefaults,
  colEnumDefaults,
  colIdDefaults,
  colLecturerDefaults,
  colMaxDefaults,
  colMinDefaults,
  colModifiedDateDefaults,
  colNameDefaults,
  colNumberDefaults,
  colStartDateDefaults,
  colStateDefaults,
  colSubmissionsDefaults,
  colTitleDefaults,
  columnDefaultProps,
  colValueDefaultDefaults,
} from './Col.defaults';
import {ColumnBuilder} from './Col.types';
import {createColumnProps} from './Col.utils';

export const columBuilder: ColumnBuilder = (
  props,
  defaults = columnDefaultProps
) => {
  return createColumnProps(defaults, props) as IColumnProps;
};

export const columBuilderFactory: (
  props: Partial<IColumnProps>
) => (props?: Partial<IColumnProps>) => Partial<IColumnProps> = (
  colDefaults
) => {
  return (props?: Partial<IColumnProps>) => {
    const defaults = createColumnProps(columnDefaultProps, colDefaults);
    if (props === undefined) {
      return defaults;
    } else {
      return createColumnProps(defaults, props);
    }
  };
};

export const cols = {
  id: columBuilderFactory(colIdDefaults),
  number: columBuilderFactory(colNumberDefaults),
  name: columBuilderFactory(colNameDefaults),
  description: columBuilderFactory(colDescriptionDefaults),
  type: columBuilderFactory(colEnumDefaults),
  createdDate: columBuilderFactory(colCreatedDateDefaults),
  modifiedDate: columBuilderFactory(colModifiedDateDefaults),
  startDate: columBuilderFactory(colStartDateDefaults),
  endDate: columBuilderFactory(colEndDateDefaults),
  valueDefault: columBuilderFactory(colValueDefaultDefaults),
  min: columBuilderFactory(colMinDefaults),
  max: columBuilderFactory(colMaxDefaults),
  courseLogo: columBuilderFactory(colCourseLogoDefaults),
  lecturer: columBuilderFactory(colLecturerDefaults),
  email: columBuilderFactory(colEmailDefaults),
  title: columBuilderFactory(colTitleDefaults),
  submissions: columBuilderFactory(colSubmissionsDefaults),
  state: columBuilderFactory(
    createColumnProps(colEnumDefaults, {dataField: 'state', name: 'State'})
  ),
  state2: columBuilderFactory(colStateDefaults),
  build: (props: Partial<IColumnProps>) =>
    createColumnProps(columnDefaultProps, props),
};

// export const colId = columns.id();
// export const colNumber = columns.number();
// export const colName = columns.name();
