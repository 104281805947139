import { IAction, IActionHandler,IActionResult } from "../Actions.types";

export const CrudActionTypeList = [
  'GET_ALL',
  'GET',
  'CREATE',
  'UPDATE',
  'DELETE'
] as const;

export type CrudActionType = typeof CrudActionTypeList[number];

export interface ICrudAction<TActionType extends CrudActionType = any, TData = any, TOptions = undefined>
  extends IAction<TActionType,TData,TOptions> {}

export interface ICrudActionHandler extends IActionHandler<ICrudAction,IActionResult>{
}
