import React from "react";
import {Col, Row, Table} from "taltech-styleguide";


export const LayoutSpecifications: React.FC<{ jsonData: any }> = ({jsonData}) => {

  return <>
    <section>
      <h5>Layout:</h5>
      <h6>Row first:</h6>
      <Row>
        <Col sm={2}><h6>Number of rooms:</h6></Col>
        <Col sm={10}><h6>{jsonData.layout.rowFirst.numberOfRooms}</h6></Col>
      </Row>
      <Row>
        <Col sm={2}><h6>Width:</h6></Col>
        <Col sm={10}><h6>{jsonData.layout.rowFirst.width}</h6></Col>
      </Row>

      <Row>
        <Col sm={2}><h6>Rooms:</h6></Col>
        <Col sm={10}><Table>
          <thead>
          <tr>
            <th>Number</th>
            <th>Width</th>
            <th>Length</th>
            <th>Space function id</th>
          </tr>
          </thead>
          <tbody>
          {jsonData.layout.rowFirst.rooms.map((room: any, index: any) => {
            return <tr>
              <td>{room.number}</td>
              <td>{room.width}</td>
              <td>{room.length}</td>
              <td>{room.spaceFunctionId}</td>
            </tr>

          })}
          </tbody>
        </Table></Col>
      </Row>

      <h6>Row second:</h6>
      <Row>
        <Col sm={2}><h6>Number of rooms:</h6></Col>
        <Col sm={10}><h6>{jsonData.layout.rowSecond.numberOfRooms}</h6></Col>
      </Row>
      <Row>
        <Col sm={2}><h6>Width:</h6></Col>
        <Col sm={10}><h6>{jsonData.layout.rowSecond.width}</h6></Col>
      </Row>
      <Row>
        <Col sm={2}><h6>Rooms:</h6></Col>
        <Col sm={10}><Table>
          <thead>
          <tr>
            <th>Number</th>
            <th>Width</th>
            <th>Length</th>
            <th>Space function id</th>
          </tr>
          </thead>
          <tbody>
          {jsonData.layout.rowSecond.rooms.map((room: any, index: any) => {
            return <tr>
              <td>{room.number}</td>
              <td>{room.width}</td>
              <td>{room.length}</td>
              <td>{room.spaceFunctionId}</td>
            </tr>

          })}
          </tbody>
        </Table></Col>
      </Row>
    </section>
  </>
};
