import {FormInputExtendedProps,} from "@dirootie-web/components-types";
import {Field} from "formik";
import {CustomInput, Form} from "taltech-styleguide";
import FeedbackMessage from "./FeedbackMessage";

export const InputCheckbox = ({
                                id,
                                formState,
                                disabled,
                                ...props
                              }: FormInputExtendedProps) => {
  return (
    <Form.Group className={`${props.elementClass} mt-2 d-flex justify-content-end align-items-center flex-row-reverse`}>
      {props.label && <Form.Label className={'mx-2 ebd-checkbox-label'}>{props.label}</Form.Label>}
      <Field
        name={id}
        component={CustomInput}
        disabled={disabled}
        renderAs="checkbox"
        checked={formState.getFieldMeta(id).value}
      />
      <FeedbackMessage fieldId={id} formState={formState}></FeedbackMessage>
    </Form.Group>
  );
};
