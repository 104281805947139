import {Field, useField} from "formik";
import React, {FC} from "react";
import {Form, Input} from "taltech-styleguide";
import ErrorComponent from "./ErrorComponent";

type ITextArea = {
  rows?: number
  type: string,
  name: string,
  component?: FC,
  label?: string,
  disabled?: boolean,
}

export const TextAreaComponent: FC<ITextArea> = ({
                                                   rows,
                                                   type,
                                                   name,
                                                   component,
                                                   label,
                                                   disabled,
                                                   ...props
                                                 }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      <Field isInvalid={meta.touched && meta.error} name={name}>
        {() => (
          <Input
            disabled={disabled}
            as="textarea"
            placeholder={field.value}
            rows={rows}
            {...field}
          />
        )}
      </Field>
      <ErrorComponent fieldName={name}/>
    </>
  );
};

export default TextAreaComponent;
