import { useEffect, useRef, useState } from "react";
import Encryption from "./models/Encryption";
import "./style.css";
import { IfcEmbedderProps } from "./IfcEmbedder.types";

function IfcEmbedder({
  fileUrl,
  style,
  iFrameUrl,
  ...rest
}: IfcEmbedderProps): JSX.Element {
  const [token, setToken] = useState<string | null>(null);
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  // const REACT_APP_IFRAME_URL =
  //   "https://taltech-edubim-test-euw-webdistellar-app.azurewebsites.net";
  useEffect(() => {
    const encryption = new Encryption(rest);
    setToken(encryption.getToken());
  }, []);

  useEffect(() => {
    if (token) {
      setIframeUrl(
        `${iFrameUrl}?showDonation=false&showSettings=true&showSearch=true&mode=light&lang=en&token=${token}`
      );
    }
  }, [token]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      const iframeLoadHandler = () => {
        showFile();
      };
      iframe.addEventListener("load", iframeLoadHandler);
      return () => iframe.removeEventListener("load", iframeLoadHandler);
    }
  }, [iframeUrl]);

  function showFile() {
    const readFile = async () => {
      const response = await fetch(fileUrl);
      if (response.ok) {
        const ifcText = await response.text();

        // Encode the text as UTF-8 and convert it to a base64 string
        const utf8Encoder = new TextEncoder();
        const utf8Data = utf8Encoder.encode(ifcText);
        const binaryString = utf8Data.reduce(
          (acc, byte) => acc + String.fromCharCode(byte),
          ""
        );
        const base64Data = btoa(binaryString);
        sendFile(base64Data);
      } else {
        console.error("Failed to fetch the file: ", response.status);
      }
    };

    const sendFile = (ifcText: string) => {
      if (iframeRef.current) {
        const iframe = iframeRef.current;
        iframe.contentWindow?.postMessage(
          { func: "loadFile", file: { name: "model.ifc", contents: ifcText } },
          "*"
        );
      }
    };

    if (iframeUrl) {
      readFile();
    }
  }

  return (
    <div id="iframe-div">
      <iframe
        style={style}
        ref={iframeRef}
        id="iframe_id"
        className="iframe-style"
        src={iframeUrl ?? ""}
        title="map"
      ></iframe>
    </div>
  );
}

export default IfcEmbedder;
