export enum ApplicationState {
  DOWN = 'down',
  UP = 'up',
  INITIALIZING = 'initializing',
  INITIALIZED = 'initialized',
  LOADING = 'loading',
  LOADED = 'loaded',
  STARTING = 'starting',
  STARTED = 'started',
  RUNNING = 'running',
  READY = 'ready',
  STOPPING = 'stopping',
  STOPPED = 'stopped',
  RESTARTING = 'restarting',
  RESTARTED = 'restarted',
}
