import { ApplicationState } from './application-state';
import { IApplication } from './application.types';
import { ILogger } from '@dirootie-core/logging-types';
import { IHttpClient } from '@dirootie-core/http-types';
import { AreaConfiguration } from '../../../../domain/web-applications/AreaConfiguration';
import { IUserNotificationService } from '../../../web/components-types/src/notifications';
import { ViewNavigator } from '../../../../configuration/ViewNavigator';
import { HandlerFactory } from '../../../../components/actions/Handler.factory';
export type ApplicationOptions<T> = {
  name: string;
  httpClient: IHttpClient;
  settings?: T;
  userNofificationService: IUserNotificationService;
  stores: { [key: string]: any };
  areas: AreaConfiguration[];
  logger: ILogger;
};

export class Application<T> implements IApplication<T> {
  private name: string;
  private state: ApplicationState = ApplicationState.DOWN;
  private settings: T | undefined;
  private httpClient: IHttpClient;
  private userNotificationService: IUserNotificationService;
  private stores;
  private areas: AreaConfiguration[] = [];
  private handlerFactory: HandlerFactory;
  private logger: ILogger;

  constructor(options: ApplicationOptions<T>) {
    this.name = options.name;
    this.settings = options.settings;
    this.httpClient = options.httpClient;
    this.changeState(ApplicationState.UP);
    this.userNotificationService = options.userNofificationService;
    this.stores = options.stores;
    this.areas = options.areas;
    this.logger = options.logger;
    this.handlerFactory = options.stores['handlerFactory'];
    this.handlerFactory.initialize(this);
  }

  //configuration properties
  getSettings(): T | undefined {
    return this.settings;
  }

  setSettings(settings: T): void {
    this.settings = settings;
  }

  getName(): string {
    return this.name;
  }

  setRouter(router: any) {
    this.stores.viewNavigator.setRouter(router);
  }
  getAreas(): AreaConfiguration[] {
    return this.areas;
  }

  setAreas(areas: AreaConfiguration[]) {
    this.areas = areas;
    this.stores.viewNavigator.setAreas(this.areas);
  }

  //state
  private changeState(state: ApplicationState) {
    //const previousState = this.state;
    if (state !== this.state) {
      this.state = state;
      //this.logger.trace(`Application state changed: from ${previousState} => ${this.state}`);
    }
  }

  getState(): ApplicationState {
    return this.state;
  }

  //Logger
  getLogger(): ILogger {
    return this.logger;
  }

  getHttpClient(): IHttpClient {
    return this.httpClient;
  }

  getUserNotificationService(): IUserNotificationService {
    return this.userNotificationService;
  }

  getStores() {
    return this.stores;
  }

  getHandlerFactory(): HandlerFactory{
    return this.handlerFactory;
  }

  async start(): Promise<void> {
    this.stores.mainAppViewStore.startLoading();
    this.changeState(ApplicationState.STARTING);
    this.changeState(ApplicationState.STARTED);
    this.changeState(ApplicationState.RUNNING);
    return this.selectEnteringPage().finally(() => {
      this.stores.mainAppViewStore.stopLoading();
    });
  }
  async selectEnteringPage() {
    const { viewNavigator, authenticationService, mainAppViewStore } =
      this.stores;
    const currentArea = viewNavigator.getCurrentArea();
    const isAuthenticated = authenticationService.isAuthenticated();
    if (currentArea.protected) {
      if (isAuthenticated) {
        return this.handleAuthenticatedUser();
      } else {
        return viewNavigator.navigateToLogin().then((x: any) => {
          mainAppViewStore.stopLoading();
          return Promise.resolve();
        });
      }
    } else {
      if (isAuthenticated) {
        if (this.getNavigator().isLogoutRoute()) {
          mainAppViewStore.stopLoading();
          return Promise.resolve();
        }
        return this.handleAuthenticatedUser();
      } else {
        mainAppViewStore.stopLoading();
        return Promise.resolve();
      }
    }
  }

  getNavigator(): ViewNavigator {
    return this.stores.viewNavigator;
  }

  async handleAuthenticatedUser(): Promise<void> {
    return this.stores.authenticationService
      .getAccountInfo(this.getNavigator().getCurrentUrlExcept())
      .then((x: any) => {
        this.stores.mainAppViewStore.stopLoading();
      });
  }
}
