import React, {FC} from "react";

type ITableMask = {};

export const TableMask: FC<ITableMask> = ({...props}) => {
  return (
    <div className={'ebd-table-mask'}>
    </div>
  );
};
