import {
  BackBtn,
  IGroupsCreateForm,
  Page,
  SaveBtn,
  Toolbar,
} from "@components";
import React from "react";

const emptyResourceForm: IGroupsCreateForm = {
  parameters: {},
  actions: {
    onSubmit: (values) => console.log(values),
  },
};

const GroupsEditScreen: React.FC<any> = (props: any) => {
  return (
    <Page title="Edit group" showBreadcrumbs={true}>
      <Toolbar
        left={<SaveBtn text="Save" />}
        right={<BackBtn text={"Back"} />}
      ></Toolbar>
    </Page>
  );
};

export default GroupsEditScreen;
