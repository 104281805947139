import {FormBase, FormProps, InputSingleDatePicker, InputText, InputTextArea,} from "@components";
import {Form as FormikForm} from "formik";
import React, {useEffect} from "react";
import {Col, Row} from "taltech-styleguide";
import {Assignment} from "../../domain/courses/Assignment";

export type IAssignmentGeneralForm = {
  parameters: Assignment;
  actions: {
    onSubmit: (values: any) => void;
  };
  visualization?: {
    isReadOnly: boolean;
    showModifiedDate: boolean;
    showCreatedDate: boolean;
  };
};

export const AssignmentGeneralForm: React.FC<FormProps<IAssignmentGeneralForm>> = ({...props}) => {
  const groupOptions: any = [
    {value: 0, text: "Group 01"},
    {value: 1, text: "Group 02"},
    {
      value: 2,
      text: "Group 03",
    },
    {value: 3, text: "All Students"},
  ];
  useEffect(() => {
    console.log(props);
  }, []);

  const render = (formik: any) => {
    return (
      <FormikForm>
        <InputText
          disabled={props.visualization?.isReadOnly}
          label={"Number"}
          id={"number"}
          formState={formik as any}
        />
        <InputText
          disabled={props.visualization?.isReadOnly}
          label={"Title"}
          id={"title"}
          formState={formik as any}
        />

        {/* <InputSelect disabled={props.visualization?.isReadOnly} selected={3} options={groupOptions} id={'group'} formState={formik as any}/> */}
        <InputTextArea
          disabled={props.visualization?.isReadOnly}
          label={"Description"}
          id={"description"}
          formState={formik as any}
        />

        <Row>
          <Col sm={6}>
            <InputSingleDatePicker
              id={"startDate"}
              formState={formik as any}
              label={"Start date"}
              disabled={false}
            />
          </Col>
          <Col sm={6}>
            <InputSingleDatePicker
              id={"endDate"}
              formState={formik as any}
              label={"End date"}
              {...{startId: "startDate"}}
              disabled={false}
            />
          </Col>
        </Row>
        {props.visualization?.showModifiedDate && (
          // <InputTextDate label={'Modified date'} id={'modifiedDate'} formState={formik as any}/>
          <InputSingleDatePicker
            id={"modifiedDate"}
            formState={formik as any}
            label={"Modified date"}
          />
        )}
        {props.visualization?.showCreatedDate && (
          // <InputTextDate label={'Created date'} id={'createdDate'} formState={formik as any}/>
          <InputSingleDatePicker
            id={"createdDate"}
            formState={formik as any}
            label={"Created date"}
          />
        )}
      </FormikForm>
    );
  };
  return <FormBase {...props} render={render}/>;
};
