import { FormikProps } from 'formik';
import _ from 'lodash';
import { mergeProps } from '@dirootie-extensions/javascript';
import { FormMode } from './inputs';
import { EntityOptions, IAction, IFormAction } from 'components/actions';
import { NavigateFunction } from 'react-router-dom';

export type FormProps<T = any> = {
  element: T;
  mode: FormMode;
  onSubmit?: (element: T) => void;
  onCancel?: (evt: any) => void;
  onReset?: () => void;
  visualization?: {
    isReadOnly?: boolean;
    showModifiedDate: boolean;
    showCreatedDate: boolean;
  };
  schema?: () => any;
  render: (formState: FormikProps<T>, props: any) => JSX.Element;
  onAction?: (action: IAction) => Promise<any>;
};

export const defaultFormProps: Partial<FormProps> = {
  visualization: {
    showCreatedDate: false,
    showModifiedDate: true,
    isReadOnly: true,
  },
};

export const defaultFormEditProps: Partial<FormProps> = {
  visualization: {
    showCreatedDate: false,
    showModifiedDate: true,
    isReadOnly: false,
  },
};

export const defaultFormCreateProps: Partial<FormProps> = {
  visualization: {
    isReadOnly: false,
    showCreatedDate: false,
    showModifiedDate: false,
  },
};

export const defaults = {
  CREATE: defaultFormCreateProps,
  EDIT: defaultFormEditProps,
  VIEW: defaultFormProps,
};

// export const createFormCreateAction = <T>(element: T) => {
//   var action: IFormAction = {
//     element: element,
//   };

//   return action;
// };

// export const createOnSubmit = <T>(
//   options: Partial<FormProps<T>>,
//   serviceOptions: EntityServiceOptions<T>
// ) => {
//   var onSubmit = (values: any) => {
//     var action = createFormCreateAction(values);
//     action.path = serviceOptions.path;
//     options.dispatch!(action);
//   };
//   return onSubmit;
// };

// export const createFormEditAction = <T>(element: T) => {
//   var action: IFormAction = {
//     type: FormActionType.SAVE,
//     element: element,
//   };

//   return action;
// };

// export const createOnEditSubmit = <T>(
//   options: Partial<FormProps<T>>,
//   serviceOptions: EntityServiceOptions<T>
// ) => {
//   var onSubmit = (values: any) => {
//     var action = createFormEditAction(values);
//     action.path = serviceOptions.path;
//     alert(action.path);
//     options.dispatch!(action);
//   };
//   return onSubmit;
// };

export const createFormProperties = <T>(
  mode: FormMode,
  options: Partial<FormProps<T>>,
  entityOptions: EntityOptions<T>,
  navigator: NavigateFunction
) => {
  const defaultFormOptions = (defaults as any)[mode];
  const defOptions = _.cloneDeep(defaultFormOptions);
  const props = mergeProps(defOptions, options as any);
  if (mode === 'VIEW') {
    return props;
  }
  props.onSubmit =
    mode === 'CREATE'
      ? (v: any) => {
          options!.onAction!({
            action: 'FORM_CREATE',
            payload: v,
            options: { navigator: navigator },
          });
        }
      : (v: any) => {
          options!.onAction!({
            action: 'FORM_SAVE',
            payload: v,
            options: { navigator: navigator },
          });
        };
  return { ...props, mode };
};
