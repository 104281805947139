import { IconTalTech, buttonKindList, Button2 } from "@components";
import { PageTest } from "../../../components/PageTest";
import { FC } from "react";

export const ButtonsUitest = () => {
  return (
    <PageTest title="Buttons testing">
      <div>
        {buttonKindList.map((x) => (
          <div>
            <IconTalTech action={x} />
            <Button2 kind={x} />
          </div>
        ))}
      </div>
    </PageTest>
  );
};
