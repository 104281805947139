import { ApiEndpoints } from './Configuration.types';

export const apiEndpoints: any = {
  users: {
    invitations: {
      prefix: 'usersms',
      collection: '/invitations',
    },
    azuread: {
      prefix: 'usersms',
      collection: '/azuread',
    },
    registered: {
      prefix: '/usersms',
      collection: '/users',
    },
  },
  auditing: {
    collection: '/auditms',
  },
  courses: {
    prefix: '/coursesms',
    collection: '/courses',
    lecturers: {
      prefix: '/coursesms',
      collection: '/lecturers',
      summary: {
        prefix: '/coursesms',
        collection: '/members/lecturers',
      },
    },
    students: {
      prefix: '/coursesms',
      collection: '/students',
      summary: {
        prefix: '/coursesms',
        collection: '/members/students',
      },
    },
    members: {
      prefix: '/coursesms',
      collection: '/members',
      lecturers: {
        prefix: '/coursesms',
        collection: '/members/lecturers',
      },
      students: {
        prefix: '/coursesms',
        collection: '/members/students',
      },
      invitations: {
        prefix: '/coursesms',
        collection: '/members/invitations',
      },
    },
  },
  media: {
    prefix: '/mediasms',
    collection: '/mediaitemresources',
  },
  assignments: {
    prefix: 'coursesms',
    collection: '/assignments',
    summary: {
      prefix: '/coursesms',
      collection: '/assignments/self',
    },
    members: {
      prefix: '/coursesms',
      collection: '/assignments/members/students',
    },
  },
  buildingConfigurations: {
    collection: '/buildingms',
  },
  buildingComponents: {
    components: {
      prefix: 'buildingsms',
      collection: '/types/elements',
      walls: {
        prefix: 'buildingsms',
        collection: '/types/elements/walls',
      },
      windows: {
        prefix: 'buildingsms',
        collection: '/types/elements/windows',
      },
    },
    parameters: {
      collection: '/building-components/parameters',
    },
    presets: {
      collection: '/building-components/presets',
    },
    materials: {
      prefix: 'buildingsms',
      collection: '/materials',
    },
  },
};
