import { LayoutWorkspace } from "@components";
import { AreaConfiguration } from "../../domain/web-applications/AreaConfiguration";

export const adminAreaConfiguration: AreaConfiguration = {
  name: "management",
  roles: ["STUDY_DIRECTOR"],
  protected: true,
  layout: {
    name: "",
    route: "management",
    component: LayoutWorkspace,
    menuProfile: {
      items: [
        {
          id: "admin_11",
          children: "Profile",
          to: "/users/profile",
        },
        {
          id: "admin_13",
          children: "Settings",
          to: "/users/settings",
        },
        {
          id: "admin_12",
          children: "Management",
          to: "/management",
        },
        {
          id: "admin_12",
          children: "Lecturers",
          to: "/lecturers",
        },
        {
          id: "admin_14",
          children: "Students",
          to: "/students",
        },
      ],
    } as any,
    menuMain: {
      items: [
        {
          id: "admin_1",
          label: "Dashboard",
          to: "/management",
        },
        {
          id: "admin_2",
          label: "Users",
          to: "/management/users/registered",
        },
        // {
        //   id: "admin_3",
        //   label: "Auditing",
        //   to: "/management/auditing",
        // },
        {
          id: "admin_4",
          label: "Analytics",
          to: "/management/analytics",
        },
        // {
        //   id: "admin_5",
        //   label: "Notifications",
        //   to: "/management/notifications",
        // },
        {
          id: "admin_6",
          label: "Support",
          to: "/support/revit-plugin",
          newTab: true,
        },
      ],
    },
  },
};
