import { FormFeedback, STATUS_TYPE } from "taltech-styleguide";
import { isFormFieldValid } from "@dirootie-web/components-types";

export const FeedbackMessage = (props: { fieldId: string; formState: any }) => {
  const showError = isFormFieldValid(props.fieldId, props.formState);
  if (showError) {
    return <></>;
  }
  const message = props.formState.getFieldMeta(props.fieldId).error;
  return <FormFeedback type={STATUS_TYPE.DANGER}>{message}</FormFeedback>;
};

export default FeedbackMessage;
