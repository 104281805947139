import { NavigateFunction } from 'react-router-dom';
import {
  EntityOptions,
  HandlerOptions,
  IAction,
  IActionHandler,
} from './Actions.types';
import { IUserNotificationService } from 'packages/web/components-types/src/notifications';
import {
  FormCreateNewActionHandler,
  FormDeleteActionHandler,
  FormRenderActionHandler,
  FormSaveActionHandler,
  FormSubmitActionHandler,
  IFormStateProvider,
} from './forms';
import { ServiceFactory } from '@dirootie-core/api-types';
import {
  GetActionHandler,
  CreateActionHandler,
  UpdateActionHandler,
  DeleteActionHandler,
} from './crud/Crud.handlers';
import { NavigationActionHandlerBase } from './navigation/Navigation.types';
import { FormGetActionHandler } from './forms/FormGetActionHandler';
import { FormGetAllActionHandler } from './forms/FormGetAllActionHandler';
import { IViewNavigator } from 'configuration/ViewNavigator';
import { ILogger } from '@dirootie-core/logging-types';

export type HandlersBuildOptions2 = {
  viewNavigator: IViewNavigator;
  userNotificationService: IUserNotificationService;
  formStateProvider: IFormStateProvider;
  serviceFactory: ServiceFactory;
  defaultActionConverter: (x: IAction) => IAction;
  options?: EntityOptions<any>;
};

export class Dispatcher {
  private handlers: Map<string, HandlerOptions> = new Map<
    string,
    HandlerOptions
  >();

  constructor(private logger: ILogger) {}

  initialize(options: HandlersBuildOptions2) {
    const defaults = this.buildHandlers(options);
    defaults.forEach((x) =>
      this.handlers.set(x[0] as string, {
        handler: x[1] as IActionHandler,
        converter:
          x.length === 3
            ? (x[2] as any)
            : (x) => {
                return options.defaultActionConverter(x);
              },
      })
    );
  }

  dispatch(action: IAction) {
    this.logger.info('[Dispatcher:dispatch]: Called', action);
    const options = this.handlers.get(action.action)!;
    if (options) {
      const act = options.converter!(action);
      options.handler!.handle(act);
    }
  }

  execute(action: IAction): Promise<any> {
    const options = this.handlers.get(action.action)!;
    if (options) {
      const act = options.converter!(action);
      return options.handler!.handle(act);
    }
    return Promise.reject();
  }

  buildHandlers(options: HandlersBuildOptions2) {
    var defaults = [
      ['GET', new GetActionHandler()],
      ['CREATE', new CreateActionHandler()],
      ['UPDATE', new UpdateActionHandler()],
      ['DELETE', new DeleteActionHandler()],
      [
        'GO_TO',
        new NavigationActionHandlerBase({
          viewNavigator: options.viewNavigator,
        } as any),
      ] as any,
      [
        'TOOLBAR_CREATE',
        new FormSubmitActionHandler(
          options.userNotificationService,
          options.serviceFactory,
          options.formStateProvider
        ),
      ],
      [
        'NAVIGATE_BACK',
        new NavigationActionHandlerBase({
          viewNavigator: options.viewNavigator,
        } as any) as any,
        // (x: IAction) => {
        //   x.options!.navigateTo = -1;
        //   return x;
        // },
      ],
      [
        'TOOLBAR_SAVE',
        new FormSubmitActionHandler(
          options.userNotificationService,
          options.serviceFactory,
          options.formStateProvider
        ),
      ],
      [
        'NAVIGATE_TO_NEW',
        new NavigationActionHandlerBase({
          viewNavigator: options.viewNavigator,
        } as any),
      ],
      [
        'TOOLBAR_DELETE',
        new FormDeleteActionHandler(
          options.userNotificationService,
          options.serviceFactory,
          options.formStateProvider,
          options.viewNavigator
        ),
      ],
      ['FORM_RENDER', new FormRenderActionHandler(options.formStateProvider)],
      [
        'FORM_CREATE',
        new FormCreateNewActionHandler(
          options.userNotificationService,
          options.serviceFactory
        ),
      ],
      [
        'FORM_SAVE',
        new FormSaveActionHandler(
          options.userNotificationService,
          options.serviceFactory
        ),
      ],
      [
        'FORM_GET',
        new FormGetActionHandler(
          options.userNotificationService,
          options.serviceFactory
        ),
      ],
      [
        'FORM_GETALL',
        new FormGetAllActionHandler(
          options.userNotificationService,
          options.serviceFactory
        ),
      ],
      // ["FORM_DELETE", new FormDeleteActionHandler(options.userNotificationService,options.serviceFactory)],
    ];
    if (options.options?.actions !== undefined) {
      var actions = options.options.actions;
      Object.keys(actions).forEach((x: string) => {
        const index = defaults.findIndex((e) => e[0] === x);
        const elem = actions[x];
        if (elem.handler !== undefined) {
          defaults[index][1] = elem.handler;
        }

        if (elem.converter !== undefined) {
          defaults[index][2] = elem.converter;
        }
      });
    }
    return defaults;
  }
}
