import { EntityOptions, EntityStore } from '@components';
import { MediaItem } from '@domain';
import { getMediaItemValidationSchema } from '@domain';

export const mediaItemEntityOptions: EntityOptions<MediaItem> = {
  schema: getMediaItemValidationSchema,
  empty: {
    name: '',
    description: undefined,
    type: 'LINK',
  },
  path: 'media',
};

export const mediaItemStore = new EntityStore(mediaItemEntityOptions);
