export const IconSubmitted = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    fill="none"
    viewBox="0 0 20 22"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M18.272 14.635V7.362a1.818 1.818 0 00-.91-1.573L11 2.153a1.818 1.818 0 00-1.818 0L2.817 5.789a1.818 1.818 0 00-.909 1.573v7.273a1.818 1.818 0 00.91 1.572l6.363 3.637a1.818 1.818 0 001.818 0l6.364-3.637a1.818 1.818 0 00.909-1.572z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2.154 6.416l7.937 4.591 7.936-4.59M10.092 20.162v-9.164"
    ></path>
  </svg>
);
