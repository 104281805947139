import { Entity } from '@dirootie-core/core-types';
import { ParameterCategory, ParameterType } from './Common.types';

export interface IParameter {
  name: string;
  type: ParameterType;
  valueDefault: number | string;
  description: string;
}

//Base parameter model -> base parameter on Revit
export type ParameterBase = {
  name: string;
  type: ParameterType;
  valueDefault: number | string;
  description: string;
};

export type Parameter = Entity &
  ParameterBase & {
    category: ParameterCategory;
    elementId?: string;
  };

export const parameterCreationTypeOptions = [
  { text: 'Constant', value: 'CONSTANT' },
  { text: 'Random', value: 'RANDOM' },
] as const;
export const ParameterCreationTypeList = parameterCreationTypeOptions.map(
  (x) => x.value
);
export type ParameterCreationType =
  typeof parameterCreationTypeOptions[number]['value'];

export type ParameterDescriptor = Omit<Parameter, 'elementId'> & {
  creationType?: ParameterCreationType;
  creationOptions?: {
    min: number;
    max: number;
  };
};

export type ParameterInstance = Parameter & {
  override?: boolean;
  overrideValue?: string | number;
};
