import { ServiceFactory } from "@dirootie-core/api-types";
import { IUserNotificationService, badRequestNotification, notAuthorizedNotification } from "../../../packages/web/components-types/src/notifications";
import { IFormAction } from "./FormActions.types";
import { createdSuccessNotification, deleteErrorNotification, deleteSuccessNotification, formHasErrorsNotification, saveSuccessNotification } from "../../../packages/web/components-types/src/notifications/forms.notifications";
import { IEntity } from "@dirootie-core/core-types";
import { FormActionHandlerBase } from "./Forms.handlers";

export class FormGetAllActionHandler extends FormActionHandlerBase {
  constructor(
    userNotificationService: IUserNotificationService,
    serviceFactory: ServiceFactory
  ) {
    super(userNotificationService, serviceFactory);
  }

  public handle<T extends IEntity>(action: IFormAction<string>) {
    const apiService = this.serviceFactory.createApiServiceFromPath<T, T>(
      action.options.path
    );
    return apiService?.getAll(action.payload!);
  }
}
