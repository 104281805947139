import { Page } from "@components";
import { CodeViewer } from "@components/testing";
import { application } from "../../../../configuration/Application.bootstrap";

export const ApiConfigurationScreen: React.FC<any> = () => {
  const apiSettings = application.getSettings()?.api

  return <Page title="Api configuration">
    <CodeViewer json={apiSettings}/>
  </Page>
};

export default ApiConfigurationScreen;
