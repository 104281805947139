import { BuildingComponent } from './shared';

export const windowStyleOptions = [
  { text: 'Isolated', value: 'ISOLATED' },
  { text: 'Wall to wall', value: 'WALL_TO_WALL' },
] as const;
export const WindowStyleList = windowStyleOptions.map((x) => x.value);
export type WindowStyle = typeof windowStyleOptions[number]['value'];

export const windowPositionOptions = [
  { text: 'Absolute', value: 'ABSOLUTE' },
  { text: 'Relative', value: 'RELATIVE' },
] as const;
export const PositionList = windowPositionOptions.map((x) => x.value);
export type Position = typeof windowPositionOptions[number]['value'];

export type WindowMeasure = {
  length: number;
  position: Position;
  maximum?: boolean;
};

export type WindowBuildingComponent = BuildingComponent & {
  type: 'WINDOW';
  style: WindowStyle;
  sillHeight: WindowMeasure;
  width: WindowMeasure;
  height: WindowMeasure;
};
