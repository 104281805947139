import { Route } from "react-router-dom";
import ConfigurationDetailsScreen from "../lecturers/screens/building-configurations/ConfigurationDetailsScreen";
import { AssignmentCreateScreen } from "../lecturers/screens/courses/assignments/AssignmentCreateScreen";
import { AssignmentEditScreen } from "../lecturers/screens/courses/assignments/AssignmentEditScreen";
import AssignmentsScreen from "./screens/assignments/AssignmentsScreen";
import BuildingComponentsScreen from "./screens/building-components/BuildingComponentsScreen";
import BuildingConfigurationsScreen from "./screens/building-configurations/BuildingConfigurationScreen";
import ConfigurationCreateScreen from "./screens/building-configurations/ConfigurationCreateScreen";
import CourseDetailsScreen from "./screens/courses/CourseDetailsScreen";
import CourseIndexScreen from "./screens/courses/CourseIndexScreen";
import DashboardScreen from "./screens/dashboard/DashboardScreen";
import NotificationsScreen from "./screens/notifications/NotificationsScreen";
import ResourcesScreen from "./screens/resources/ResouresScreen";

export const studentsAreaRoutes = (
  <Route path="/students">
    <Route index={true} element={<DashboardScreen />} />
    <Route path="courses">
      <Route index={true} element={<CourseIndexScreen />} />
      {/*<Route path=":courseId" element={<CourseDetailsScreen/>}/>*/}
      <Route path=":courseId">
        <Route index={true} element={<CourseDetailsScreen />} />
        <Route path=":tabId" element={<CourseDetailsScreen />} />

        <Route path="assignments/new" element={<AssignmentCreateScreen />} />
        <Route path="assignments/:assignmentId">
          <Route index={true} element={<AssignmentEditScreen />} />
          <Route path=":typeId" element={<ConfigurationDetailsScreen />} />
        </Route>
      </Route>
    </Route>
    <Route path="assignments">
      <Route index={true} element={<AssignmentsScreen />} />
    </Route>
    <Route path="building-configurations">
      <Route index={true} element={<BuildingConfigurationsScreen />} />

      <Route path="new" element={<ConfigurationCreateScreen />} />
      <Route path=":typeId" element={<ConfigurationDetailsScreen />} />
    </Route>
    <Route path="building-components">
      <Route index={true} element={<BuildingComponentsScreen />} />
    </Route>
    <Route path="notifications">
      <Route index={true} element={<NotificationsScreen />} />
    </Route>
    ~
    <Route path="resources">
      <Route index={true} element={<ResourcesScreen />} />
    </Route>
  </Route>
);
