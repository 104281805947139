import * as CryptoJS from 'crypto-js';
import { TokenEncryptionOptions } from '../IfcEmbedder.types';

export default class Encryption {
  private REACT_APP_IFRAME_TOKEN_MIN_SECONDS_DIFFERENCE: number;
  private REACT_APP_IFRAME_KEY;
  constructor(options: TokenEncryptionOptions) {
    this.REACT_APP_IFRAME_KEY = options.iFrameKey;
    this.REACT_APP_IFRAME_TOKEN_MIN_SECONDS_DIFFERENCE =
      options.iFrameTokenMinSecondsDifference;
  }
  get encryptMethodLength() {
    const encryptMethod = this.encryptMethod;
    // get only number from string.
    // @link https://stackoverflow.com/a/10003709/128761 Reference.
    const aesNumber = encryptMethod.match(/\d+/)![0];
    return parseInt(aesNumber);
  }

  get encryptKeySize() {
    const aesNumber = this.encryptMethodLength;
    return aesNumber / 8;
  }

  get encryptMethod() {
    return 'AES-256-CBC';
  }

  getToken(): string {
    return this.encryptTimestamp();
  }

  decryptToken(token: string): string {
    return this.decryptTimestamp(token);
  }

  isIframeTokenValid(token: string): boolean {
    if (!token || token === '') return false;
    const timeStamp = this.decryptTimestamp(token);
    if (timeStamp && timeStamp !== '') {
      const elapsedSeconds = this.getElapsedSeconds(timeStamp);
      if (
        elapsedSeconds <=
        Number(this.REACT_APP_IFRAME_TOKEN_MIN_SECONDS_DIFFERENCE)
      ) {
        return true;
      }
    }
    return false;
  }

  private getElapsedSeconds(timestamp: string | number): number {
    const currentTime = this.getUtcTime();
    const timestampInMilliseconds = Number(timestamp) * 1000;
    const decryptedTime = new Date(timestampInMilliseconds);
    const elapsedMilliseconds = currentTime.getTime() - decryptedTime.getTime();
    const elapsedSeconds = elapsedMilliseconds / 1000;
    return elapsedSeconds;
  }

  private encryptTimestamp(): string {
    const currentTime = this.getUtcTime();
    const timestamp = Math.floor(currentTime.getTime() / 1000).toString();
    const ciphertext: string = this.encrypt(
      timestamp,
      this.REACT_APP_IFRAME_KEY!.toString()
    );
    return this.base64ToBase64Url(ciphertext);
  }

  getUtcTime(): Date {
    const currentTime = new Date();
    //convert curretTime to UTC time
    const currentOffset = currentTime.getTimezoneOffset();
    const utc = currentTime.getTime() + currentOffset * 60000;
    currentTime.setTime(utc);
    return currentTime;
  }

  private decryptTimestamp(encryptedText: string): string {
    const cipherText = this.base64UrlToBase64(encryptedText);
    return this.decrypt(cipherText, this.REACT_APP_IFRAME_KEY);
  }

  /**
   * Decrypt string.
   *
   * @link https://stackoverflow.com/questions/41222162/encrypt-in-php-openssl-and-decrypt-in-javascript-cryptojs Reference.
   * @link https://stackoverflow.com/questions/25492179/decode-a-base64-string-using-cryptojs Crypto JS base64 encode/decode reference.
   * @param string encryptedString The encrypted string to be decrypt.
   * @param string key The key.
   * @return string Return decrypted string.
   */
  decrypt(encryptedString: string, key: string) {
    const json = JSON.parse(
      CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedString))
    );

    const salt = CryptoJS.enc.Hex.parse(json.salt);
    const iv = CryptoJS.enc.Hex.parse(json.iv);

    const encrypted = json.ciphertext; // no need to base64 decode.

    let iterations = parseInt(json.iterations);
    if (iterations <= 0) {
      iterations = 999;
    }
    const encryptMethodLength = this.encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
    const hashKey = CryptoJS.PBKDF2(key, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: encryptMethodLength / 8,
      iterations: iterations,
    });

    const decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv: iv,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  } // decrypt

  /**
   * Encrypt string.
   *
   * @link https://stackoverflow.com/questions/41222162/encrypt-in-php-openssl-and-decrypt-in-javascript-cryptojs Reference.
   * @link https://stackoverflow.com/questions/25492179/decode-a-base64-string-using-cryptojs Crypto JS base64 encode/decode reference.
   * @param string string The original string to be encrypt.
   * @param string key The key.
   * @return string Return encrypted string.
   */
  encrypt(string: string, key: string) {
    const iv = CryptoJS.lib.WordArray.random(16); // the reason to be 16, please read on `encryptMethod` property.

    const salt = CryptoJS.lib.WordArray.random(256);
    const iterations = 999;
    const encryptMethodLength = this.encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
    const hashKey = CryptoJS.PBKDF2(key, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: encryptMethodLength / 8,
      iterations: iterations,
    });

    const encrypted = CryptoJS.AES.encrypt(string, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv: iv,
    });
    const encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

    const output = {
      ciphertext: encryptedString,
      iv: CryptoJS.enc.Hex.stringify(iv),
      salt: CryptoJS.enc.Hex.stringify(salt),
      iterations: iterations,
    };

    return CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(JSON.stringify(output))
    );
  } // encrypt

  private base64UrlToBase64(base64Url: string): string {
    return base64Url.replace('-', '+').replace('_', '/');
  }

  private base64ToBase64Url(base64: string): string {
    return base64.replace('+', '-').replace('/', '_');
  }
}
