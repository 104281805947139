export const IconCourse = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="120"
    fill="none"
    viewBox="0 0 120 120"
  >
    <rect width="120" height="120" fill="#fff" rx="6"></rect>
    <path
      fill="#E4067E"
      d="M87.619 31.429c6.838 0 12.381 5.543 12.381 12.38v43.81C100 94.457 94.457 100 87.619 100h-43.81c-6.837 0-12.38-5.543-12.38-12.381V63.817a24.6 24.6 0 005.714 1.606V87.62c0 .794.139 1.555.393 2.262l22.181-21.717a8.572 8.572 0 0111.505-.444l.49.444 22.18 21.72c.254-.707.394-1.47.394-2.265v-43.81a6.667 6.667 0 00-6.667-6.666H65.423a24.6 24.6 0 00-1.606-5.714h23.802zM64.034 71.977l-.319.27-22.12 21.662a6.665 6.665 0 002.215.377h43.809c.775 0 1.52-.133 2.212-.376L67.713 72.247a2.858 2.858 0 00-3.68-.27zm14.07-27.215a8.58 8.58 0 110 17.159 8.58 8.58 0 010-17.16zM40.951 20c11.572 0 20.953 9.38 20.953 20.952 0 11.572-9.38 20.953-20.953 20.953C29.381 61.905 20 52.525 20 40.952 20 29.381 29.38 20 40.952 20zm37.151 30.476a2.865 2.865 0 100 5.73 2.865 2.865 0 000-5.73zm-37.15-22.86l-.343.03a1.907 1.907 0 00-1.532 1.532l-.03.343-.002 9.524-9.531.003-.343.03a1.907 1.907 0 00-1.531 1.532l-.03.342.03.343c.14.777.754 1.39 1.532 1.531l.342.031 9.535-.003.002 9.54.03.343c.142.777.755 1.39 1.532 1.531l.343.031.342-.03a1.907 1.907 0 001.532-1.532l.03-.343-.001-9.54 9.538.003.343-.03a1.906 1.906 0 001.531-1.532l.031-.343-.03-.342a1.907 1.907 0 00-1.532-1.532l-.343-.03-9.542-.003.001-9.524-.03-.343a1.906 1.906 0 00-1.532-1.531l-.343-.03z"
    ></path>
  </svg>
);
