import { observer } from "mobx-react";
import {
  TableTalTech,
  defaultProps,
  colName,
  colDescription,
  colCreateDate,
  colModifiedDate,
  createColActions,
  TableTalTech2,
  SelectParameterCreationType,
  SelectForm,
} from "@components";
import { useNavigate } from "react-router-dom";

export const SpaceFunctionTypeParametersTable: React.FC<any> = observer(
  (props: { elements: any }) => {
    const navigator = useNavigate();
    const columns = [
      colName,
      {
        classes: "test-column",
        dataField: "type",
        sort: true,
        text: "Type",
      },
      {
        classes: "test-column",
        dataField: "creationOptions.min",
        text: "Min",
      },
      {
        classes: "test-column",
        dataField: "creationOptions.max",
        text: "Max",
      },
      {
        classes: "test-column",
        dataField: "valueDefault",
        text: "Default",
      },
      {
        classes: "test-column",
        dataField: "creationType",
        text: "Generation",
        formatter: (
          cell: any,
          row: any,
          rowIndex: number,
          formatExtraData: any
        ) => {
          return (
            <SelectForm
              id="creationType"
              Component={SelectParameterCreationType}
              selected={row.creationType}
              onClick={(val) => {}}
            />
          );
        },
      },
      // { formatter: createColActions(navigator) },
    ];
    const tableProps: any = {
      ...defaultProps,

      columns,
    };
    return (
      <TableTalTech2
        source={{
          elements: props.elements,
        }}
        table={tableProps}
      ></TableTalTech2>
    );
  }
);
