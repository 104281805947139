//import { Link } from 'react-router-dom'
import { Link } from "react-router-dom";
import { Breadcrumb } from "taltech-styleguide";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { observer } from "mobx-react";

const routes = [
  { path: "/lecturers", breadcrumb: "Home" },
  { path: "/students", breadcrumb: "Home" },
  { path: "/management/users/registered", breadcrumb: null },
  { path: "/management/users", breadcrumb: "Users" },
  { path: "/management", breadcrumb: "Home" },
  { path: "/testing", breadcrumb: "Home" },
  { path: "/users", breadcrumb: "Home" },
];

const isActive = (pathname: string) =>
  // eslint-disable-next-line no-restricted-globals
  location.pathname === pathname;

function BreadcrumbsInner() {
  const breadcrumbs = useBreadcrumbs(routes).slice(1);
  return (
    <Breadcrumb>
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <Breadcrumb.Item
          key={match.pathname}
          active={isActive(match.pathname)}
          linkAs={Link}
        >
          <Link to={match.pathname}>{breadcrumb}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export const Breadcrumbs = observer(BreadcrumbsInner);
