import React, {FC} from "react";
import {useLocation} from "react-router-dom";
import {Table} from "taltech-styleguide";
import {useStore} from "../../domain/store";

type IEmptyTable = {
  headerElements: string[],
  addNewIcon?: { val: boolean, fn: (x: any, y: string) => void }
}

export const EmptyTable: FC<IEmptyTable> = ({
                                       addNewIcon,
                                       headerElements,
                                       ...props
                                     }) => {
  const {buildingStore} = useStore();
  const history = useLocation();
  const URLObject = buildingStore.getElementNameFromURL(history);


  return (
    <div>
      <Table className={"w-100"}>
        <thead className={"w-100"}>
        <tr>
          {headerElements.map((el, index) => <th key={index}>{el}</th>)}
          {addNewIcon?.val && <th>
            <div onClick={() => addNewIcon.fn(URLObject.elementName, URLObject.elementTypeNumber)}>
              <img src={'/addNew.svg'} alt={'Add New'}/></div>
          </th>}
        </tr>
        </thead>
      </Table>
      <div className={"ebd-table-height"}>
        <h6>No Items to preview</h6>
      </div>
    </div>
  );
};

export default EmptyTable;
