import { ParametersTable } from "@components/building-components";
import { PageTest } from "@components/testing";
// import { ParameterFormProps } from "../../../../domain/building/Building.types";
import { inject, observer } from "mobx-react";
import { TableSearchStoreFactory } from "packages/web/components-types/src/tables/table-search-store";
import { parametersPerType } from "@domain/building-components";

const tableProps: Partial<any> = {
  // columns: columnsData,
  onRowClickNavigate: false,
  pageable: true,
  pageableProps: {
    rows: 10,
    rowsPerPageOptions: [2, 5, 10],
    lazy: false,
  } as any,
};

export const ParameterTableUitest: React.FC<any> = inject(
  "tableSearchStoreFactory"
)(
  observer((props: { tableSearchStoreFactory: TableSearchStoreFactory }) => {
    let dataStore = props.tableSearchStoreFactory.getFactory(
      "lectures-building-parameters"
    );

    if (dataStore === undefined) {
      dataStore = props.tableSearchStoreFactory.createTableStore(
        tableProps as any,
        "building-components",
        "lectures-building-parameters",
        [...parametersPerType]
      );
    }
    const elements = {
      page: 1,
      size: 2,
      total: 3,
      items: parametersPerType,
    };
    return (
      <PageTest title="Parameter table - uitest">
        <ParametersTable elements={elements}></ParametersTable>
      </PageTest>
    );
  })
);
