import {
  FormBase,
  FormProps,
  InputSelect,
  InputSingleDatePicker,
  InputText,
  InputTextArea,
} from "@components";
import { Material, MaterialClassList } from "@domain/building-components";
import { Form as FormikForm } from "formik";
import React, { FC } from "react";

export const MaterialForm: FC<FormProps<Material>> = ({ ...props }) => {
  const render = (formik: any) => {
    return (
      <FormikForm>
        <InputText id="name" label="Name" formState={formik as any} />
        {/*<InputText id="class" label="Class" formState={formik as any}/>*/}
        <InputSelect
          label={"Class"}
          id={"class"}
          formState={formik as any}
          items={MaterialClassList as any}
        />
        <InputTextArea
          id="description"
          label="Description"
          formState={formik as any}
        />
        {props.visualization?.showModifiedDate && (
          <InputSingleDatePicker
            id={"modifiedDate"}
            formState={formik as any}
            label={"Modified date"}
          />
        )}
        {props.visualization?.showCreatedDate && (
          <InputSingleDatePicker
            label={"Created date"}
            id={"createdDate"}
            formState={formik as any}
          />
        )}
      </FormikForm>
    );
  };
  return <FormBase {...props} render={render} />;
};
