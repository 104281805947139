import {EntityOptions, EntityStore, SpaceFunctionsTable, ToolbarList} from "@components";
import {getBuildingComponentValidationSchema, SpaceFunction} from "@domain";
import React from "react";

const componentEntityOptions: EntityOptions<SpaceFunction> = {
  schema: getBuildingComponentValidationSchema,
  empty: {
    name: '',
    description: '',
    createdDate: new Date(Date.now()),
    modifiedDate: new Date(Date.now()),
    category: "SPACES"
  } as any,
  path: 'buildingComponents.components'
};

export const spaceEntityStore = new EntityStore(componentEntityOptions)

const SpaceFunctionTypesScreen: React.FC<any> = () => (
  <div>
    <ToolbarList/>

    <SpaceFunctionsTable/>
  </div>
);

export default SpaceFunctionTypesScreen;
