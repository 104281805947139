import { Button } from "@components/buttons";
import React, { FC } from "react";
import {
  Button as TaltechButton,
  BUTTON_COLOR,
  Form,
  ICONS,
  Input,
  Modal,
} from "taltech-styleguide";
import { useStore } from "../../domain/store";

export const NavigationBtnGroup: FC<any> = ({ ...props }) => {
  const { buildingConfigurationFormStore } = useStore();
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);
  return (
    <>
      <div className={"d-flex justify-content-center"}>
        <TaltechButton
          className={"w-100 mr-4"}
          onClick={(e) => {
            let submitBtn: HTMLElement = document.querySelector(
              "form.active button[type=submit]"
            ) as HTMLElement;
            console.log(submitBtn);
            if (buildingConfigurationFormStore.currentStep === 3) {
              submitBtn = document.querySelector(
                "button[type=submit]"
              ) as HTMLElement;
              toggleModal();
            }
            submitBtn.click();
          }}
          variant={BUTTON_COLOR.SECONDARY}
          iconRight={ICONS.ARROW_OPEN_RIGHT}
          size="sm"
        >
          {buildingConfigurationFormStore.currentStep === 3 ? "SUBMIT" : "NEXT"}
        </TaltechButton>

        <Modal show={modalOpen} onHide={toggleModal}>
          <Modal.Header closeButton>Select assignment </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="id-8" className="mb-3">
              <Form.Label>Course</Form.Label>
              <Input as="select" label="Course">
                <option></option>
                <option>Course demo 1</option>
                <option>Course demo 2</option>
                <option>Course demo 3</option>
              </Input>
            </Form.Group>
            <Form.Group controlId="id-8" className="mb-3">
              <Form.Label>Assignment</Form.Label>
              <Input as="select" label="Assignment">
                <option></option>
                <option>Course assignment 1</option>
                <option>Course assignment 2</option>
                <option>Course assignment 3</option>
              </Input>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button kind={"create"} onClick={toggleModal} />{" "}
            <Button kind={"cancel"} onClick={toggleModal} />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default NavigationBtnGroup;
