import { BuildingParameterForm, Page, ToolbarEdit } from "@components";
import { ParameterDescriptor } from "@domain";

const emptyParameter: ParameterDescriptor = {
  createdDate: new Date(Date.now()),
  modifiedDate: new Date(Date.now()),
  id: "undefined",
  name: "",
  description: "",
  category: "BUILDING",
  type: "NUMBER",
  valueDefault: 0,
  creationType: "CONSTANT",
};

export const BuildingParameterCreateScreen: React.FC<any> = (props) => {
  return (
    <Page title="Create new Floor type" showBreadcrumbs={true}>
      <ToolbarEdit />
      <BuildingParameterForm
        element={emptyParameter}
        onCancel={() => {}}
        onSubmit={(values) => {}}
      />
    </Page>
  );
};

export default BuildingParameterCreateScreen;
