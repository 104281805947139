import {createRequiredMessage} from "@domain/validations";
import * as Yup from 'yup';

export const getFunctionValidationSchema = () => {
  return Yup.object<any>().shape({
    firstRawNumberOfRooms: Yup.number().required(createRequiredMessage('Row 1 No. rooms')).integer("Must be integer").test(
      'Is positive?',
      'Must be greater than 0!',
      (value) => value! > 0
    ),
    firstRawWidth: Yup.number().required(createRequiredMessage('Row 1 width')).test(
      'Is positive?',
      'Must be greater than 0!',
      (value) => value! > 0
    ),
    secondRowNumberOfRooms: Yup.number().required(createRequiredMessage('Row 2 No. rooms')).integer("Must be integer").test(
      'Is positive?',
      'Must be greater than 0!',
      (value) => value! > 0
    ),
    secondRawWidth: Yup.number().required(createRequiredMessage('Row 2 width')).test(
      'Is positive?',
      'Must be greater than 0!',
      (value) => value! > 0
    ),
  });
};
