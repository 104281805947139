import {createFormProperties, DisabledTabComponent, MediaItemEditForm, Page, PageContentForm,} from "@components";
import {MediaItem} from "@domain";
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {mediaItemStore as entityStore} from "./MediaItem.store";

export const MediaItemDetailsScreen: React.FC<any> = observer((props) => {
  const navigator = useNavigate();
  const [element, setElement] = useState<MediaItem | undefined>(undefined);
  const {resourceId} = useParams();
  useEffect(() => {
    if (resourceId !== undefined) {
      entityStore
        .executeAction({action: "FORM_GET", payload: resourceId})
        .then((x: any) => {
          setElement(x);
        });
    }
  }, [resourceId]);

  const formProps = createFormProperties(
    "EDIT",
    {
      onAction: (action) => {
        return Promise.resolve(entityStore.onAction(action));
      },
      schema: entityStore.options.schema,
      element: element,
    },
    entityStore.options,
    navigator
  );
  return (
    <Page title="Edit resource">
      <div className={'container'}>
        <DisabledTabComponent elements={["Summary", "Lecturers", "Students", "Groups", "Resources", "Assignments"]} activeDisabled={4}/>
        <PageContentForm
          form={{Component: MediaItemEditForm, options: {...formProps}}}
          mode="EDIT"
          dispatcher={{
            onAction: (action) => {
              return Promise.resolve(entityStore.onAction(action));
            },
          }}
        />
      </div>
    </Page>
  );
});
