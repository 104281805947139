import {Route} from "react-router-dom";

import {
  FloorComponentUitest,
  ParameterFormUitest,
  ParameterTableUitest,
  RoofComponentUitest,
  WallComponentUitest,
  WindowComponentUitest,
} from "./screens/areas/building-components/components";

import {
  BuildingParameterFormUitest,
  MaterialTableUitest,
  StructureLayerFunctionTableUitest,
} from "./screens/areas/building-components/data";
import {
  MaterialStructureTableUitest
} from "./screens/areas/building-components/data/material-structure-table/MaterialStructureTableUitest";
import {CourseEditFormUitest} from "./screens/areas/courses/CourseEditFormUitest";
import {ButtonsUitest} from "./screens/components/buttons/ButtonsUitest";
import {ComponentsScreen} from "./screens/components/ComponentsScreen";
import {TypographyUitest} from "./screens/components/design/TypographyUitest";
import {InputRandomUitest} from "./screens/components/forms/InputRandomUitest";
import {ToolbarUitest} from "./screens/components/navigation/ToolbarUitest";
import {NotificationsUitest} from "./screens/components/notifications/NotificationsUitest";
import {AdvancedTableUitest} from "./screens/components/tables/AdvancedTableUitest";
import {FigmaTableUitest} from "./screens/components/tables/FigmaTableUitest";
import {TableDiUitest} from "./screens/components/tables/TableDiUitest";
import {TablesScreen} from "./screens/components/tables/TablesScreen";
import {TableTalTechUitest} from "./screens/components/tables/TableTalTechUitest";
import {WidgetsUitest} from "./screens/components/widgets/WidgetsUitest";
import ApiConfigurationScreen from "./screens/configurations/ApiConfigurationScreen";
import ApplicationConfigurationScreen from "./screens/configurations/ApplicationConfigurationScreen";
import {ConfigurationScreen} from "./screens/configurations/ConfigurationsScreen";
import DashboardScreen from "./screens/dashboard/DashboardScreen";
import EnumerationsScreen from "./screens/domain/EnumerationsScreen";
import {UploadScreen} from "./screens/upload/uploadScreen";

export const testingAreaRoutes = (
  <>
    <Route path="/testing">
      <Route index={true} element={<DashboardScreen/>}/>

      <Route path="configuration">
        <Route index={true} element={<ConfigurationScreen/>}/>
        <Route
          path="application"
          element={<ApplicationConfigurationScreen/>}
        />
        <Route path="api" element={<ApiConfigurationScreen/>}/>
      </Route>

      <Route path="domain">
        {/* <Route
            index={true}
            element={<DomainScreen/>}
        /> */}
        <Route path="enumerations" element={<EnumerationsScreen/>}/>
      </Route>

      <Route path="components">
        <Route index={true} element={<ComponentsScreen/>}/>
        <Route path="design">
          <Route path="typography" element={<TypographyUitest/>}/>
        </Route>
        <Route path="buttons" element={<ButtonsUitest/>}/>
        <Route path="notifications" element={<NotificationsUitest/>}/>
        <Route path="widgets" element={<WidgetsUitest/>}/>
        <Route path="forms">
          <Route path="input-random" element={<InputRandomUitest/>}/>
        </Route>
        <Route path="tables">
          <Route index={true} element={<TablesScreen/>}/>
          <Route path="advanced-table" element={<AdvancedTableUitest/>}/>
          <Route path="figma-table" element={<FigmaTableUitest/>}/>
          <Route path="taltech" element={<TableTalTechUitest/>}/>
          <Route path="di" element={<TableDiUitest/>}/>
        </Route>
        <Route path="navigation">
          <Route index={true} element={<ToolbarUitest/>}/>
          <Route path="toolbar" element={<ToolbarUitest/>}/>
        </Route>
      </Route>

      <Route path="areas">
        <Route
          path="upload"
          element={<UploadScreen/>}
        />
        <Route path="building-components">
          <Route path="data">
            <Route path="structure-layer-functions">
              <Route
                path="structure-layer-function-table"
                element={<StructureLayerFunctionTableUitest/>}
              ></Route>
            </Route>
            <Route path="materials">
              <Route path="material-table" element={<MaterialTableUitest/>}/>
            </Route>
            <Route path="parameters">
              <Route
                path="building-parameter-form"
                element={<BuildingParameterFormUitest/>}
              />
            </Route>
            <Route path="structure">
              <Route
                path="building-structure-table"
                element={<MaterialStructureTableUitest/>}
              />
            </Route>
          </Route>
          <Route path="components">
            <Route path="parameters">
              <Route
                index={true}
                path="parameter-form"
                element={<ParameterFormUitest/>}
              />
              <Route
                path="parameter-table"
                element={<ParameterTableUitest/>}
              />
            </Route>
            <Route path="types">
              <Route path="floor" element={<FloorComponentUitest/>}/>
              <Route path="window" element={<WindowComponentUitest/>}/>
              <Route path="wall" element={<WallComponentUitest/>}/>
              <Route path="floor" element={<RoofComponentUitest/>}/>
            </Route>
          </Route>
        </Route>

        <Route path="courses">
          <Route
            index={true}
            path="course-edit-form"
            element={<CourseEditFormUitest/>}
          />
        </Route>

        <Route path="refactor">
          <Route path="forms">
            {/*<Route*/}
            {/*  index={true}*/}
            {/*  path="building-configurations"*/}
            {/*  element={<FormBuildingGenerator/>}*/}
            {/*/>*/}
          </Route>

          <Route path="types">

            <Route path="building-types">
            </Route>
          </Route>
        </Route>
      </Route>

    </Route>
  </>
);
