import { ServiceFactory } from "@dirootie-core/api-types";
import { PagedResult } from "@dirootie-core/core-types";
import { IQuery } from "@dirootie-web/components-types";
import { Material } from "./Material";
import { materialData } from "./Material.samples";
import { MaterialApiService } from "./MaterialApiService";


export class MaterialStore {
  // searchSore;
   constructor(
     private apiService: MaterialApiService,
     private serviceFactory: ServiceFactory
   ) {
   }

   getMaterials(
     query: IQuery
   ): Promise<PagedResult<Material>> {
     const queryServer = { ...query };
     //const data = materialData;
     return this.apiService.getAll(queryServer);
   }

   getMaterial(id: string): Promise<Material> {
     return this.apiService.get(id);
   }

   createMaterial(element: Material): Promise<Material> {
     return this.apiService.create(element);
   }

   saveMaterial(element: Material): Promise<Material> {
     return this.apiService.update(element);
   }
 }
