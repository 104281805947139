import { FigmaTable } from "@components";
import { PageTest } from "@components/testing";

export const FigmaTableUitest = () => {
  return  <PageTest title="Figma table using prime react table - uitest">
    <p>
      This table is much similar to the Figma TableComponent in TalTech Figma
      files
    </p>
    <FigmaTable />
  </PageTest>;
}
