import {createMaxMessage, createMinMessage, createRequiredMessage,} from '@domain/validations';
import * as Yup from 'yup';

export const getMediaItemValidationSchema = () => {
  const elemSchema = Yup.object<any>().shape({
    name: Yup.string()
      .min(1, createMinMessage('name', 1))
      .max(100, createMaxMessage('name', 100))
      .required(createRequiredMessage('name')),
    description: Yup.string()
      .min(1, createMinMessage('description', 1))
      .max(100, createMaxMessage('description', 10000))
      .required(createRequiredMessage('description')),
    url: Yup.string().when('type', {
      is: (field1Value: any) => field1Value === 'LINK',
      then: Yup.string().required(createRequiredMessage('url')),
      otherwise: Yup.string().max(2000, createMaxMessage('link URL', 2000))
        .url('Please enter a valid URL'),
    }),

  });

  return elemSchema;
};
