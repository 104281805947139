import { StructureLayerFunctionTable } from "@components/building-components";
import { PageTest } from "@components/testing";

export const StructureLayerFunctionTableUitest: React.FC<any> = (
  props: any
) => {
  return (
    <PageTest title="Structural layer function table - uitest">
      <StructureLayerFunctionTable />
    </PageTest>
  );
};
