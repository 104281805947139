import {
  ParameterForm,
  ParameterFormProps,
} from "@components/building-components";
import { PageTest, PageTestProps } from "@components/testing";
import { FC } from "react";
import { Parameter } from "@domain/building-components";
import { useOutputWindowService } from "../../../../../components/OutputWindow.service";

export const ParameterFormUitest: FC<any> = () => {
  const service = useOutputWindowService();
  const formProps: ParameterFormProps = {
    parameter: {
      category: "FLOOR",
      id: "1",
      name: "Name",
      description: "sdfdsfds",
      type: "TEXT",
      valueDefault: "1",
    },
    onSubmit: (parameter: Parameter) => {
      service.log("onSubmit", parameter);
    },
    onCancel: (evt: any) => {
      service.log("onCancel");
    },
  };

  const props: PageTestProps = {
    title: "Parameter form - uitest",
    demo: {
      outputWindow: true,
      outputTracker: ["onSubmit", "onCancel"],
      Component: ParameterForm,
      properties: formProps,
    },
  };

  return <PageTest {...props} />;
};
