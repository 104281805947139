import { FC } from "react";
import {
  PageTest,
  PageTestProps,
  useOutputWindowService,
} from "@components/testing";
import { FloorDetails } from "@components/building-components";

export const FloorComponentUitest: FC<any> = () => {
  const props: PageTestProps = {
    title: "Floor component - uitest",
    demo: {
      outputWindow: true,
      Component: FloorDetails,
      properties: {},
    },
  };

  return <PageTest {...props} />;
};
