import {
  DisabledTabComponent,
  Breadcrumbs,
  BuildingParameterForm,
  ToolbarEdit,
} from "@components";
import {
  ParameterDescriptor,
  spaceFunctionParametersData,
} from "@domain/building-components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const SpaceParameterDetailsScreen: React.FC<any> = (props) => {
  const { parameterId } = useParams();
  const [parameter, setParameter] = useState<ParameterDescriptor | undefined>(
    undefined
  );

  useEffect(() => {
    if (parameterId !== undefined) {
      const param = spaceFunctionParametersData.filter(
        (x) => x.id === parameterId
      )[0];
      setParameter(param);
    } else {
      setParameter(undefined);
    }
  });
  if (parameter === undefined) {
    return <></>;
  }
  return (
    <div>
      <h1>Function</h1>
      <Breadcrumbs />
      <div className={"container"}>
        <DisabledTabComponent
          elements={["Functions", "Parameters"]}
          activeDisabled={1}
        />
        <ToolbarEdit />
        <BuildingParameterForm
          element={parameter}
          onCancel={() => {}}
          onSubmit={() => {}}
          visualization={{
            showCreatedDate: false,
            showModifiedDate: true,
          }}
        />
      </div>
    </div>
  );
};

export default SpaceParameterDetailsScreen;
