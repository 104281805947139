import { inject, observer } from "mobx-react";
import { TableColumn, TableProps } from "packages/web/components-types/src";
import { TableSearchStoreFactory } from "packages/web/components-types/src/tables/table-search-store";
import { Page, TableDi } from "@components";
import { auditData } from "./Audit.samples";

const columnsData: TableColumn[] = [
  {
    header: "",
    name: "id",
    type: "text",
    display: false,
  },
  {
    header: "Category",
    name: "category",
    type: "text",
    display: true,
    sortable: true,
  },
  {
    header: "Type",
    name: "type",
    type: "text",
    display: true,
    sortable: true,
  },
  {
    header: "Actor",
    name: "actorId",
    type: "text",
    display: true,
    sortable: true,
  },
  {
    header: "App",
    name: "appId",
    type: "text",
    display: true,
    sortable: true,
  },
  {
    header: "Data",
    name: "createdDate",
    type: "date",
    display: true,
    sortable: true,
  },
];

const tableProps: TableProps = {
  columns: columnsData,
  onRowClickNavigate: true,
  pageable: true,
  pageableProps: {
    rows: 10,
    rowsPerPageOptions: [2, 5, 10],
    lazy: true,
  },
};

const AuditingScreen: React.FC<any> = inject("tableSearchStoreFactory")(
  observer((props: { tableSearchStoreFactory: TableSearchStoreFactory }) => {
    let dataStore =
      props.tableSearchStoreFactory.getFactory("admin-audit-table");
    if (dataStore === undefined) {
      dataStore = props.tableSearchStoreFactory.createTableStore(
        tableProps,
        "auditing",
        "admin-audit-table",
        auditData
      );
      dataStore.search();
    }
    return (
      <Page title="Auditing" showBreadcrumbs={true}>
        <TableDi {...dataStore.getTableProps()} dataStore={dataStore} />
      </Page>
    );
  })
);

export default AuditingScreen;
