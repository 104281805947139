import { PresetDetails, PresetDetailsProps } from "@components";
import { presetsData, spaceFunctionsData } from "@domain";
import { useParams } from "react-router-dom";

const SpaceFunctionTypeDetailsScreen: React.FC<any> = () => {
  const { presetId } = useParams();
  const props: PresetDetailsProps = {
    elementId: presetId!,
    getElement: (id: string) =>
      Promise.resolve(presetsData.filter((x) => x.id === id)[0]),
    getElements: () => Promise.resolve(spaceFunctionsData),
    title: "Space function preset details",
    onSave: (elem) => Promise.resolve(console.log("Saved", elem)),
  };
  return <PresetDetails {...props} />;
};
export default SpaceFunctionTypeDetailsScreen;
