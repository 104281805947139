import {InputSingleDatePicker, InputText, InputTextArea} from "@components";
import {getPresetValidationSchema, IPreset,} from "@domain";
import {Form, Formik} from "formik";
import React, {FC} from "react";

export type PresetFormProps = {
  element: IPreset;
  submiFormBinder: any;
  onSubmit: (data: IPreset) => Promise<void>;
  visualization?: {
    showModifiedDate: boolean,
    showCreatedDate: boolean
  }
};

export const PresetForm: FC<PresetFormProps> = ({...props}) => {
  return (
    <>
      <Formik
        validationSchema={getPresetValidationSchema()}
        initialValues={props.element}
        onSubmit={(data) => props.onSubmit(data)}
      >
        {(formik) => {
          const submitAndValidate = (): Promise<void> => {
            return formik.validateForm().then((x) => {
              if (formik.isValid) {
                return formik.submitForm();
              } else {
                //userNotificationService.error(createFormHasErrorsNotification());
                return Promise.resolve();
              }
            });
          };

          props.submiFormBinder(submitAndValidate);
          return (
            <Form>
              <InputText id="name" label="Name" formState={formik as any}/>
              <InputTextArea
                id="description"
                label="Description"
                formState={formik as any}
              />
              {props.visualization?.showModifiedDate &&
                <InputSingleDatePicker id={'modifiedDate'} formState={formik as any} label={'Modified date'}/>}
              {props.visualization?.showCreatedDate &&
                <InputSingleDatePicker label={'Created date'} id={'createdDate'} formState={formik as any}/>}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
