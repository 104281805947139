import { FormikProps } from "formik";
import { useNavigate } from "react-router-dom";
import { application } from "../../configuration/Application.bootstrap";
import { IUserNotificationService } from "../../packages/web/components-types/src/notifications";
import { formHasErrorsNotification } from "../../packages/web/components-types/src/notifications/forms.notifications";
import { ButtonProps } from "./Buttons.types";
import { Button } from "./Button";

export const SaveBtn: React.FC<any> = (
  props: ButtonProps & { disabled: boolean }
) => {
  const navigator = useNavigate();
  const { onClick: onClickProps, ...rest } = props;
  const onClick = props.onClickUrl
    ? (evt: any) => {
        navigator(props.onClickUrl!);
      }
    : onClickProps!;
  return <Button onClick={(evt: any) => onClick(evt)} kind="save" {...rest} />;
};

export const SaveFormBtn: React.FC<any> = (
  props: ButtonProps & { formik: FormikProps<any> }
) => {
  const userNitificationService: IUserNotificationService =
    application.getUserNotificationService();
  const { onClick, ...rest } = props;
  const onClickHandler = (evt: any) => {
    if (props.formik.isValid) {
      onClick!(evt);
    } else {
      userNitificationService.error(formHasErrorsNotification);
    }
  };

  return (
    <Button onClick={(evt: any) => onClickHandler(evt)} kind="save" {...rest} />
  );
};
